import React, { Component } from 'react'
import { HttpError, HttpSuccess } from '../../snackbars'
import {
	Paper, withStyles, Grid, Card, TableRow,
	Table, TableHead, TableCell, TableBody,
	Button, LinearProgress, Typography,
} from '@material-ui/core';
import { useStyles, DashDrawer } from '../drawer'
import Moment from 'moment'
import TablePagination from '@material-ui/core/TablePagination';
import stringConverter, { addComma } from "../../../utils/stringConverter";

import '../Dashboard.scss'
import './Invoices.scss'
import { DoneOutlined, CloseOutlined } from '@material-ui/icons'

const mql = window.matchMedia(`(max-width: 1024px)`)

class Invoices extends Component {
	constructor(props) {
		super(props)
		this.state = {
			drawerOpen: false,
			profileAnchorEl: null,
			eligibleItems: [],
			receivedItems: [],
			shippedItems: [],
			notifications: [],
			invoices: [],
			notificationsDialogOpen: false,
			mobileScreen: mql.matches,
			page: 0,
			rowsPerPage: 10,
			filterLimit: 10,
			filterSkip: 0,
			invoicesCount: 0
		}
	}
	componentWillMount = () => {
		const { filterLimit, filterSkip } = this.state;
		this.props.getUserInfo()
		this.props.getInvoices(filterLimit, filterSkip)
	}
	componentWillReceiveProps = (nextProps) => {
		const { invoices, invoicesCount } = nextProps.dashboard
		if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
		this.setState({ invoices, invoicesCount })
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
	toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}
	toggleNotifications = () => {
		this.setState({ notificationsDialogOpen: !this.state.notificationsDialogOpen })
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
	handleChangePage = (event, newPage) => {
		const { filterLimit } = this.state;

		this.setState({ page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { filterLimit, filterSkip } = this.state;
			this.props.getInvoices(filterLimit, filterSkip);
		})
	};
	render() {
		const { classes } = this.props
		const { errorMsg, successMsg, userInfoFetched, exchangeRates } = this.props.dashboard
		console.log(exchangeRates)
		if (!userInfoFetched) {
			return (
				<div className="linear_progress">
					<LinearProgress />
				</div>
			)
		} else {
			const AllInvoices = () => {
				if (mql.matches) {
					return (<div>
						<TablePagination
							component="div"
							count={this.state.invoicesCount}
							page={this.state.page}
							onChangePage={this.handleChangePage}
							rowsPerPage={this.state.rowsPerPage}
							style={{ margin: '0 auto', width: '50%' }}
						/>
						<Table style={{ marginTop: '2%' }}>
							<TableHead>
								<TableRow selected>
									<TableCell>Invoice</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.invoices.length > 0 && this.state.invoices.map(invoice => {
									return (<TableRow key={invoice.id}
										className={invoice.paymentStatus ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}>
										<TableCell>
											<b>Date:&nbsp;</b>{Moment(invoice.invoiceUpdatedOn).format('LLL')}<br />
											<b>Balance Due:&nbsp;</b>
										  USD {invoice.totalCostUsd}&nbsp;&nbsp;-&nbsp;
											KES: {addComma(invoice.totalCostKes)}<br />
										</TableCell>
										<TableCell>
											<Button onClick={() => this.props.history.push(`/dashboard/invoices/${invoice.id}`)}
												style={{ color: 'blue' }}>View</Button>
										</TableCell>
									</TableRow>)
								})}
							</TableBody>
						</Table>
					</div>
					)
				}
				return (<div>
					<Table style={{ marginTop: '2%' }}>
						<TableHead>
							<TableRow selected>
								<TableCell></TableCell>
								<TableCell>Date</TableCell>
								<TableCell>Balance Due</TableCell>
								<TableCell>Status</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.invoices.length > 0 && this.state.invoices.map((invoice, index) => {
								return (<TableRow key={invoice.id}
									className={invoice.paymentStatus === 'FULLY_PAID' ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}>
									<TableCell>{index + 1}</TableCell>
									<TableCell>{Moment(invoice.invoiceUpdatedOn).format('LLL')}</TableCell>
									<TableCell>
										USD. {invoice.totalCostUsd}&nbsp;&nbsp;-&nbsp;
									KES. {addComma(invoice.totalCostKes)}
									</TableCell>
									<TableCell>{stringConverter(invoice.paymentStatus)}</TableCell>
									<TableCell>
										<Button onClick={() => this.props.history.push(`/dashboard/invoices/${invoice.id}`)}
											style={{ color: 'blue' }}>View</Button>
									</TableCell>
								</TableRow>)
							})}
						</TableBody>
					</Table>
					<TablePagination
						component="div"
						count={this.state.invoicesCount}
						page={this.state.page}
						onChangePage={this.handleChangePage}
						rowsPerPage={this.state.rowsPerPage}
						style={{ margin: '0 auto', width: '50%' }}
					/>
				</div>)
			}
			return (
				<div>
					<DashDrawer classes={classes} state={this.state} props={this.props}
						activeLink='inv' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
						closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
						logout={this.onLogout} toggleNotifications={this.toggleNotifications} />
					<Paper square={true} style={{
						background: 'rgba(0,0,0,0)', boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px', paddingTop: '10vh'
					}}>
						{/* The Invoice */}
						<Grid item xs={12}>
							<Card className="invoice_card">
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<Typography style={{ fontSize: '1.6em' }}>INVOICES</Typography>
										<Typography className="align_icons" style={{ color: 'rgb(0,0,255)' }}>
											<DoneOutlined />&nbsp;Paid
										</Typography><br />
										<Typography className="align_icons" style={{ color: 'rgb(255,122,0)' }}>
											<CloseOutlined />&nbsp;Unpaid
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<AllInvoices />
									</Grid>
								</Grid>
							</Card>
						</Grid>
					</Paper>
					{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
					{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				</div>
			)
		}
	}
}

export default withStyles(useStyles)(Invoices)