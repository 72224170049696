import React, { Component } from 'react'
import {
	withStyles, Paper, LinearProgress, Typography,
	Card, CardContent, ExpansionPanel, ExpansionPanelDetails,
	ExpansionPanelSummary, TableRow, TableCell, Button, Table, TableHead, TableBody, Input
} from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import { useStyles, AdminDrawer } from '../drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
import { SanitizedItems } from '../../dashboard/registereditems/sanitizedItems'
import Moment from 'moment';
import _ from 'lodash'
import stringConverter, { addComma } from '../../../utils/stringConverter'


import '../../dashboard/Dashboard.scss'
import '../Admin.scss'

function filterInvoiceItems(collection, substring) {
	const invoices = _.filter(collection, _.flow(
		_.identity, _.values, _.join, _.toLower, _.partialRight(
			_.includes,
			substring.toLowerCase()
		)
	))
	return invoices
}

const mql = window.matchMedia(`(max-width: 1024px)`)

class AdminClient extends Component {
	constructor(props) {
		super(props)
		this.state = {
			drawerOpen: false,
			profileAnchorEl: null,
			clientInfo: {},
			clientItems: [],
			clientInvoices: [],
			filteredInvoices: [],
			filterString: '',
			userAddress: []
		}
	}
	componentDidMount = () => {
		this.props.getClient(this.props.match.params.id);
		this.props.getClientInvoices(this.props.match.params.id);
	}
	componentWillReceiveProps = (nextProps) => {
		const { client, clientInvoices, clientInvoicesFetched, userAddress, addressFetched } = nextProps.admin
		if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}

		if (clientInvoicesFetched) {
			this.setState({ clientInvoices, filteredInvoices: clientInvoices })
		}

		if (addressFetched) {
			this.setState({ userAddress })
		}

		this.setState({ clientInfo: client, clientItems: client.packages })
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = (event) => {
		this.setState({ profileAnchorEl: null })
	}
	toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}
	onFilterInvoices = substring => {
		this.setState({
			filterString: substring,
			filteredInvoices: filterInvoiceItems(this.state.clientInvoices, substring)
		})
	}
	render() {
		const { classes } = this.props
		const { adminHttpErrors, adminHttpSuccess, clientsFetched, invoicesFetched } = this.props.admin
		if (!clientsFetched && !invoicesFetched) {
			return (
				<div className="linear_progress">
					<LinearProgress />
				</div>
			)
		} else {
			const UnpaidInvoices = this.state.filteredInvoices.map(invoice => {
				return (<TableRow key={invoice.id}
					className={invoice.paymentStatus === 'FULLY_PAID' ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}>
					<TableCell>{invoice.id && invoice.id.toUpperCase()}</TableCell>
					<TableCell>{Moment(invoice.invoiceUpdatedOn).format('LLL')}</TableCell>
					<TableCell>USD. {invoice.totalCostUsd} - KES. {addComma(invoice.totalCostKes)}</TableCell>
					<TableCell>{stringConverter(invoice.paymentStatus)}</TableCell>
					<TableCell>
						<Button onClick={() => this.props.history.push(`/admin/invoices/${invoice.id}`)}
							style={{ color: 'blue' }}>View</Button>
					</TableCell>
				</TableRow>)
			})

			const UnpaidInvoicesSmall = this.state.filteredInvoices.map(invoice => {
				if (!invoice.paid) {
					return (<TableRow key={invoice.id}>
						<TableCell>
							<Typography><b>Date:&nbsp;</b>{Moment(invoice.invoiceUpdatedOn).format('LLL')}</Typography>
							<Typography><b>Shipping cost:&nbsp;</b>USD. {invoice.totalCostUsd} - KES. {addComma(invoice.totalCostKes)}</Typography>
							<Typography><b>Items weight:&nbsp;</b>{invoice.weight} Kg</Typography>
						</TableCell>
						<TableCell>
							<Button onClick={() => this.props.history.push(`/admin/invoices/${invoice.id}`)}
								style={{ color: 'blue' }}>View</Button>
						</TableCell>
					</TableRow>)
				}
				return (<TableRow key={invoice.id}></TableRow>)
			})

			const { clientInfo, clientItems } = this.state
			return (
				<div>
					<AdminDrawer classes={classes} state={this.state} props={this.props}
						activeLink='clnts' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
						closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
						logout={this.onLogout} />
					<Paper square={true} style={{
						background: 'rgba(0,0,0,0)', boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px', paddingTop: '10vh'
					}}>
						<Paper style={{ padding: '2%', margin: '2%' }}>
							<Typography variant="body1" style={{ padding: '0.5%' }}>
								<b>Client number:&nbsp;</b>{clientInfo.id && clientInfo.id.toUpperCase()}
							</Typography>
							<Typography variant="body1" style={{ padding: '0.5%' }}><b>Name:&nbsp;</b>
								{clientInfo.firstName || ''}&nbsp;{clientInfo.lastName || ''}
							</Typography>
							<Typography variant="body1" style={{ padding: '0.5%' }}>
								<b>Mobile:&nbsp;</b>{clientInfo.phoneNumber}<br />
							</Typography>
							<Typography variant="body1" style={{ padding: '0.5%' }}>
								<b>Email:&nbsp;</b>{clientInfo.email}<br />
							</Typography>
							{this.state.userAddress.length > 0 &&
								(<Typography variant="body1" style={{ padding: '0.5%' }}>
									<b>Addresses:&nbsp;</b>{this.state.userAddress.map(address => {
										return (<ExpansionPanel key={address.id}>
											<ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
												<Typography>{address.addressName}</Typography>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>
												Town:&nbsp;{address.addressTown}<br />
												Street:&nbsp;{address.addressStreet}<br />
												Building:&nbsp;{address.addressApartmentName}<br />
												Additional information:&nbsp;{address.addressOtherLocationInfo}<br />
											</ExpansionPanelDetails>
										</ExpansionPanel>)
									})}<br />
								</Typography>)
							}
							<Typography variant="body1" style={{ padding: '0.5%' }}>
								<b>Status:&nbsp;</b>Todo:<br />
							</Typography>
							<Typography variant="body1" style={{ padding: '0.5%' }}>
								{/* <b>Date joined:&nbsp;</b>{Moment(clientInfo.createdAt).format('LLL')}<br/> */}
							</Typography>
						</Paper>
						{/* Client items */}
						<Card style={{ margin: '2%' }}>
							<CardContent>
								<SanitizedItems sorted={false} registeredItems={clientItems} />
							</CardContent>
						</Card>
						<Paper style={{ padding: '2%', margin: '2%' }}>
							<Input value={this.state.filterString} placeholder="Search invoices"
								onChange={event => { this.onFilterInvoices(event.target.value) }}
								className={classes.searchField} />
							<Table style={{ display: mql.matches ? 'none' : 'table' }}>
								<TableHead>
									<TableRow>
										<TableCell>Invoice number</TableCell>
										<TableCell>Date</TableCell>
										<TableCell>Cost</TableCell>
										<TableCell>Status</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableHead>
								</TableHead>
								<TableBody>
									{UnpaidInvoices}
								</TableBody>
							</Table>
							{/* Mobile screens */}
							<Table style={{ display: mql.matches ? 'block' : 'none' }}>
								<TableBody>
									{UnpaidInvoicesSmall}
								</TableBody>
							</Table>
						</Paper>
					</Paper>
					{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
					{adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
				</div>
			)
		}
	}
}

export default withStyles(useStyles)(AdminClient)
