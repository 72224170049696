import React, { Component } from 'react'
import {
	withStyles, Paper, LinearProgress,
	Grid, Table, TableHead, TableRow, TableCell,
	TableBody, Button, Input, Typography,
} from '@material-ui/core'
import { DoneOutlined, CloseOutlined } from '@material-ui/icons'
import { useStyles, AdminDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../snackbars'
import Moment from 'moment'
// import _ from 'lodash'
import TablePagination from '@material-ui/core/TablePagination';
import stringConverter, { addComma } from "../../utils/stringConverter";
import '../dashboard/Dashboard.scss'
import './Admin.scss'
import Axios from 'axios'
import { baseUrl } from '../../actions/DashAction'

// function filterInvoiceItems(collection, substring) {
// 	const invoices = _.filter(collection, _.flow(
// 		_.identity, _.values, _.join, _.toLower, _.partialRight(
// 			_.includes,
// 			substring.toLowerCase()
// 		)
// 	))
// 	return invoices
// }

const mql = window.matchMedia(`(max-width: 1024px)`)

class AdminInvoices extends Component {
	constructor(props) {
		super(props)
		this.state = {
			invoices: [],
			filteredInvoices: [],
			drawerOpen: false,
			profileAnchorEl: null,
			filterString: '',
			page: 0,
			rowsPerPage: 10,
			filterLimit: 10,
			filterSkip: 0,
			invoicesCount: 0
		}
	}


	async handlesearchInvoices(pattern) {
		try {
			let filteredInvoices = (await Axios.get(`${baseUrl}/invoices?filter[search]=${pattern}`)).data
			let { count: invoicesCount } = (await Axios.get(`${baseUrl}/invoices/count?filter[search]=${pattern}`)).data
			return {
				filteredInvoices, invoicesCount
			}
		} catch (error) {
			return {
				filteredInvoices: [],
				invoicesCount: 0
			}
		}
	}


	componentDidMount = () => {
		const { filterLimit, filterSkip } = this.state;
		this.props.getInvoices(filterLimit, filterSkip)
	}

	componentWillReceiveProps = (nextProps) => {
		const { invoices, invoicesFetched, invoicesCount } = nextProps.admin
		if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
		if (invoicesFetched) {
			this.setState({ invoices })
		}
		this.setState({ invoices, filteredInvoices: invoices, invoicesCount })
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = (event) => {
		this.setState({ profileAnchorEl: null })
	}
	toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}

	handleChangePage = (event, newPage) => {
		const { filterLimit } = this.state;

		this.setState({ page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { filterLimit, filterSkip } = this.state;
			this.props.getInvoices(filterLimit, filterSkip);
		})
	};

	onFilterInvoices = substring => {
		this.setState({ filterString: substring, })
		this.handlesearchInvoices(substring).then(data => {
			this.setState({ ...this.state, ...data })
		})

	}

	render() {
		const { classes } = this.props
		const { adminHttpErrors, adminHttpSuccess, invoicesFetched, } = this.props.admin
		if (!invoicesFetched) {
			return (
				<div className="linear_progress">
					<LinearProgress />
				</div>
			)
		} else {
			const UnpaidInvoices = this.state.filteredInvoices.map(invoice => {
				return (<TableRow key={invoice.id}
					className={invoice.paymentStatus === 'FULLY_PAID' ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}>
					<TableCell>{invoice.clientNumber.toUpperCase()}</TableCell>
					<TableCell>{invoice.id.toUpperCase()}</TableCell>
					<TableCell>{Moment(invoice.invoiceUpdatedOn).format('LLL')}</TableCell>
					<TableCell>USD. {invoice.totalCostUsd} - KES. {addComma(invoice.totalCostKes)}</TableCell>
					<TableCell>{stringConverter(invoice.paymentStatus)}</TableCell>
					<TableCell>
						<Button onClick={() => this.props.history.push(`/admin/invoices/${invoice.id}`)}
							style={{ color: 'blue' }}>View</Button>
					</TableCell>
				</TableRow>)
			})
			const UnpaidInvoicesSmall = this.state.filteredInvoices.map(invoice => {
				if (!invoice.paid) {
					return (<TableRow key={invoice.id}>
						<TableCell>
							<Typography><b>Client number:&nbsp;</b>{invoice.clientNumber.toUpperCase()}</Typography>
							<Typography><b>Invoice number:&nbsp;</b>{invoice.id.toUpperCase()}</Typography>
							<Typography><b>Date:&nbsp;</b>{Moment(invoice.invoiceUpdatedOn).format('LLL')}</Typography>
							<Typography><b>Shipping cost:&nbsp;</b>USD. {invoice.totalCostUsd} - KES. {addComma(invoice.totalCostKes)}</Typography>
						</TableCell>
						<TableCell>
							<Button onClick={() => this.props.history.push(`/admin/invoices/${invoice.id}`)}
								style={{ color: 'blue' }}>View</Button>
						</TableCell>
					</TableRow>)
				}
				return (<TableRow key={invoice.id}></TableRow>)
			})
			return (
				<div>
					<AdminDrawer classes={classes} state={this.state} props={this.props}
						activeLink='inv' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
						closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
						logout={this.onLogout} />
					<Paper square={true} style={{
						background: 'rgba(0,0,0,0)', boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
					}}>
						<Grid container spacing={0}>
							<Grid item xs={6}>
								<Paper className="clean_paper" style={{ marginLeft: '8%' }}>
									<Typography style={{ fontSize: '1.6em' }}>INVOICES</Typography>
									<Typography className="align_icons" style={{ color: 'blue' }}>
										<DoneOutlined />&nbsp;Paid
									</Typography><br />
									<Typography className="align_icons" style={{ color: 'rgb(255,122,0)' }}>
										<CloseOutlined />&nbsp;Unpaid
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={12}>
								<Paper style={{ margin: '3%', padding: '3%' }}>
									<Input
										value={this.state.filterString} placeholder="Search invoices"
										onChange={event => { this.onFilterInvoices(event.target.value) }}
										className={classes.searchField} />
									<Table style={{ display: mql.matches ? 'none' : 'block' }}>
										<TableHead>
											<TableRow>
												<TableCell>Client number</TableCell>
												<TableCell>Invoice number</TableCell>
												<TableCell>Date</TableCell>
												<TableCell>Balance Due</TableCell>
												<TableCell>Status</TableCell>
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableHead>
										</TableHead>
										<TableBody>
											{UnpaidInvoices}
										</TableBody>
									</Table>
									<TablePagination
										component="div"
										count={typeof this.state.invoicesCount === "number" ? this.state.invoicesCount : 0}
										page={typeof this.state.page === "number" ? this.state.page : 0}
										onChangePage={this.handleChangePage}
										rowsPerPage={typeof this.state.rowsPerPage === "number" ? this.state.rowsPerPage : 0}
										rowsPerPageOptions={[10]}
										style={{ margin: '0 auto', width: '50%' }}
									/>
									{/* Mobile screens */}
									<Table style={{ display: mql.matches ? 'block' : 'none' }}>
										<TableBody>
											{UnpaidInvoicesSmall}
										</TableBody>
									</Table>
								</Paper>
							</Grid>
						</Grid>
					</Paper>
					{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
					{adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
				</div>
			)
		}
	}
}

export default withStyles(useStyles)(AdminInvoices)
