import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, Input, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AdminDrawer, useStyles } from "../drawer";
import "../../admin/Admin.scss"
import Axios from "axios";
import { handleError } from "../../../actions/ErrorHandler";
import { useDispatch } from "react-redux";


const mql = window.matchMedia(`(max-width: 1024px)`);

const AdminVariables = props => {
    const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/api';
    const classes = makeStyles(useStyles)();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        drawerOpen: false,
		profileAnchorEl: null,
		notificationsDialogOpen: false,
    });
    const [openCalc, setOpenCalc] = useState(false);
    const [values, setValues] = useState({
        ukLargeWeight: 0,
        usaLargeWeight: 0,
        usaLessWeight: 0,
        ukExchangeRate: 0
    });
    const [variables, setVariables] = useState({
        ukLargeWeight: "",
        usaLargeWeight: "",
        usaLessWeight: "",
        ukExchangeRate: ""
    });

    useEffect(() => {
        Axios.get(`${baseUrl}/calculation-variables`).then(snaps => {
            setValues({
              ukLargeWeight: snaps.data.ukLargeWeight,
              usaLargeWeight: snaps.data.usaLargeWeight,
              usaLessWeight: snaps.data.usaLessWeight,
              ukExchangeRate: snaps.data.ukExchangeRate
            })
          }).catch(() => {
            this.setState({ calcErrors: 'An error occured while getting calculation rates.' })
          })
    },
    // eslint-disable-next-line
    [])

    const onLogout = () => {
		setState({ ...state, profileAnchorEl: null });
		window.location.href = '/accounts/signin';
	};
	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, drawerOpen: open });
	};
	const toggleNotifications = () => {
		setState({ ...state, notificationsDialogOpen: !state.notificationsDialogOpen });
	};
	const openProfileMenu = event => {
		setState({ ...state, profileAnchorEl: event.currentTarget });
	};
	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null });
	};

  const onChange = (e) => {
    setVariables({
        ...variables,
        [e.target.name]: e.target.value
    });
  }
    return(
        <>
			<AdminDrawer
				classes={classes}
				state={state}
				props={props}
				activeLink='var'
				toggleDrawer={toggleDrawer}
				openProfile={openProfileMenu}
				closeProfile={closeProfileMenu}
				profileAnchorEl={state.profileAnchorEl}
				logout={onLogout}
				toggleNotifications={toggleNotifications}
			/>
            <Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
                <div className="variables-content">
                    {/* Calculator Variable Dialogue Start*/}
                    <Dialog
                      open={openCalc}
                      fullWidth
                      onClose={() => setOpenCalc(false)}>
                              <DialogTitle style={{ color: 'blue' }}>Update Calculator Variables</DialogTitle>
                            <DialogContent>
                              <Typography style={{ padding: '2%', color: 'blue' }}><b>Current Values</b>:</Typography>
                              <Typography style={{ padding: '2%' }}><b>UK Weight</b> - &nbsp;{values.ukLargeWeight}</Typography>
                              <Typography style={{ padding: '2%' }}><b>USA Weight Greater than 2</b> - &nbsp;{values.usaLargeWeight}</Typography>
                              <Typography style={{ padding: '2%' }}><b>USA Weight Below 2</b> - &nbsp;{values.usaLessWeight}</Typography>
                              <Typography style={{ padding: '2%' }}><b>UK Exchange Rate</b> - &nbsp;{values.ukExchangeRate}</Typography>
                              <Divider />
                            </DialogContent>
                            <Card style={{
                                marginTop: '6%', boxShadow: 'none', borderRadius: '0',
                                borderLeft: '2px solid blue'
                              }}>
                                <CardContent>
                                  <form onSubmit={(e) => {
                                    e.preventDefault();

                                  const payload = {};
                                  
                                  if (variables.ukLargeWeight !== '') payload.ukLargeWeight = variables.ukLargeWeight;
                                  if (variables.ukExchangeRate !== '') payload.ukExchangeRate = variables.ukExchangeRate;
                                  if (variables.usaLargeWeight !== '') payload.usaLargeWeight = variables.usaLargeWeight;
                                  if (variables.usaLessWeight !== '') payload.usaLessWeight = variables.usaLessWeight;

                                  if (payload
                                  && Object.keys(payload).length === 0
                                  && Object.getPrototypeOf(payload) === Object.prototype) return;

                                  Axios.patch(`${baseUrl}/calculation-variables/${1}`, payload).then(() => {
                                    // refetch
                                    Axios.get(`${baseUrl}/calculation-variables`).then(snaps => {
                                      setValues({
                                        ukLargeWeight: snaps.data.ukLargeWeight,
                                        usaLargeWeight: snaps.data.usaLargeWeight,
                                        usaLessWeight: snaps.data.usaLessWeight,
                                        ukExchangeRate: snaps.data.ukExchangeRate
                                      })
                                      }).catch(() => {
                                          this.setState({ calcErrors: 'An error occured while getting calculation rates.' })
                                      })
                                      setVariables({
                                          ukLargeWeight: "",
                                          usaLargeWeight: "",
                                          usaLessWeight: "",
                                          ukExchangeRate: ""
                                      })

                                    }).catch(error => dispatch(handleError(error.response.data)))

                                  }}>
                                    <Input type="text" placeholder="UK Weight" name="ukLargeWeight"
                                      onChange={onChange} value={variables.ukLargeWeight}
                                      style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0, }}
                                    />
                                    <Input type="text" placeholder="USA Weight Greater than 2" name="usaLargeWeight"
                                      onChange={onChange} value={variables.usaLargeWeight}
                                      style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0, }}
                                    />
                                    <Input type="text" placeholder="USA Weight Below 2" name="usaLessWeight"
                                      onChange={onChange} value={variables.usaLessWeight}
                                      style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0, }}
                                    />
                                    <Input type="text" placeholder="UK Exchange Rate" name="ukExchangeRate"
                                      onChange={onChange} value={variables.ukExchangeRate}
                                      style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0, }}
                                    />
                                    <Button type="submit"
                                      style={{ background: '#232C39', color: 'white', margin: '2%' }}>
                                      Update Values
                                    </Button>
                                  </form> 
                                </CardContent>
                            </Card>
                    </Dialog>
                    {/* Calculator Variable Dialogue End*/}
                    <Button
                      style={{
                          background: '#232C39', color: 'white', width: '155px',
                          borderRadius: '4', cursor: 'pointer'
                      }}
                      onClick={() => setOpenCalc(!openCalc)}
                    >
                        Edit Calculator
                    </Button>
                </div>
            </Paper>
        </>
    )
}

export default AdminVariables;