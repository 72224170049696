import React, { useEffect, useState } from 'react';
import { HttpError, HttpSuccess } from '../../snackbars';
import {
    Paper,
    Grid,
    Card,
    TableRow,
    Table,
    TableHead,
    TableCell,
    TableBody,
    Button,
    Typography,
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import stringConverter, { addComma } from '../../../utils/stringConverter';
// import { makeStyles } from '@material-ui/core/styles';
import {  AdminDrawer } from '../drawer'

import '../../dashboard/Dashboard.scss';
import '../../dashboard/invoices/Invoices.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                //   <Box p={3}>
                <Typography>{children}</Typography>
                //   </Box>
            )}
        </div>
    );
}

// const useStyles = makeStyles((theme) => ({
//     root: {
//         borderColor: "green",
//     },
// }));

const Receipts = props => {

    const [state, setState] = useState({
        drawerOpen: false,
        profileAnchorEl: null,
        eligibleItems: [],
        receivedItems: [],
        shippedItems: [],
        notifications: [],
        receipts: [],
        receipt: {},
        notificationsDialogOpen: false,
        mobileScreen: mql.matches,
        page: 0,
        rowsPerPage: 10,
        filterLimit: 10,
        filterSkip: 0,
        receiptsCount: 0,
        paymentLimit: 0,
        paymentSkip: 0
        , packageReceipts: [], packageReceiptsCount: 0
    });

    useEffect(() => {
        const { filterLimit, filterSkip, paymentLimit,
            paymentSkip } = state;
        // props.getUserInfo();
        props.getReceipts(filterLimit, filterSkip);
        props.getPaymentReceipts(paymentLimit, paymentSkip);
    },
    // eslint-disable-next-line
    [state.filterSkip, state.filterLimit]);

    // const receipts = useSelector(state => state.dash.receipts)

    useEffect(() => {
        const { receipts, receiptsCount, packageReceipts, packageReceiptsCount } = props.dashboard;

        if (props.auth.unauthorized) {
            window.location.href = '/accounts/signin';
        }

        setState({ ...state, receipts, receiptsCount, packageReceipts, packageReceiptsCount });
    },
    // eslint-disable-next-line
    [props]);

    useEffect(() => {
        console.log(state.packageReceipts)
    })

    const onLogout = () => {
        setState({ ...state, profileAnchorEl: null });
        window.location.href = '/accounts/signin';
    };
    const toggleDrawer = open => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, drawerOpen: open });
    };
    // const toggleNotifications = () => {
    //     setState({ ...state, notificationsDialogOpen: !state.notificationsDialogOpen });
    // };
    const openProfileMenu = event => {
        setState({ ...state, profileAnchorEl: event.currentTarget });
    };
    const closeProfileMenu = () => {
        setState({ ...state, profileAnchorEl: null });
    };
    const handleChangePage = (event, newPage) => {
        const { filterLimit } = state;

        setState({ ...state, page: newPage, filterSkip: newPage * filterLimit }, () => {
            const { filterLimit, filterSkip } = state;
            props.getReceipts(filterLimit, filterSkip);
        });
    };

    const [value, setValue] = React.useState('Invoice');

    const { classes } = props;
    const { errorMsg, successMsg } = props.dashboard;

    // if (!userInfoFetched) {
    //     return (
    //         <div className='linear_progress'>
    //             <LinearProgress />
    //         </div>
    //     );
    // }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AdminDrawer currentUser="officer" classes={classes} state={state} props={props}
            activeLink='rec' toggleDrawer={toggleDrawer} openProfile={openProfileMenu}
            closeProfile={closeProfileMenu} profileAnchorEl={state.profileAnchorEl}
            logout={onLogout} />
   
            <Paper
                square={true}
                style={{
                    background: 'rgba(0,0,0,0)',
                    boxShadow: 'none',
                    paddingLeft: mql.matches ? '0' : '240px',
                    paddingTop: '10vh',
                }}
            >
                {/* The Invoice */}
                <Grid item xs={12}>
                    <Card className='invoice_card'>
                        <Typography style={{ fontSize: '1.6em' }}>RECEIPTS</Typography>
                        <Tabs
                            indicatorColor="primary"
                            value={value} onChange={handleChange} aria-label='wrapped label tabs example'>
                            <Tab value='Invoice' label='Shipping Receipts' wrapped {...a11yProps('one')} />
                            <Tab value='Shipments' label='Package protection Receipts' {...a11yProps('shipments')} />
                        </Tabs>
                        <Grid container spacing={0}>

                            <Grid item xs={12}>
                                <TabPanel value={value} index='Invoice'>
                                    <AllInvoices
                                        handleChangePage={handleChangePage}
                                        page={state.page}
                                        receipts={state.receipts}
                                        receiptsCount={state.receiptsCount}
                                        rowsPerPage={state.rowsPerPage}
                                        props={props}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index='Shipments'>
                                    <AllShipments
                                        handleChangePage={handleChangePage}
                                        page={state.page}
                                        receipts={state.packageReceipts}
                                        receiptsCount={state.packageReceiptsCount}
                                        rowsPerPage={state.rowsPerPage}
                                        props={props}
                                    />
                                </TabPanel>
                            </Grid>

                        </Grid>
                    </Card>
                </Grid>
            </Paper>
            {successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
            {errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
        </div>
    );
};

export default Receipts;

const AllInvoices = ({ receipts, receiptsCount, page, handleChangePage, rowsPerPage, props }) => {
    if (mql.matches) {
        return (
            <div>
                <TablePagination
                    component='div'
                    count={receiptsCount || 0}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    style={{ margin: '0 auto', width: '50%' }}
                />
                <Table style={{ marginTop: '2%' }}>
                    <TableHead>
                        <TableRow selected>
                            <TableCell>Receipt </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {receipts > 0 &&
                            receipts.length > 0 &&
                            receipts.map(receipt => {
                                return (
                                    <TableRow
                                        key={receipt.id}
                                    // className={receipt.paid ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}
                                    >
                                        <TableCell>
                                            <b>Date:&nbsp;</b>
                                            {Moment(receipt.paidOn).format('LLL')}
                                            <br />
                                            <b>Cost:&nbsp;</b>
											USD {addComma(receipt.amountPaidKes)}&nbsp;&nbsp;-&nbsp; KES: {receipt.amountPaidUsd}
                                            <br />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() => props.history.push(`/admin/receipts/${receipt.id}?payment=invoice`)}
                                                style={{ color: 'blue' }}
                                            >
                                                View
                                                
											</Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </div>
        );
    }

    return (
        <div>
            <Table style={{ marginTop: '2%' }}>
                <TableHead>
                    <TableRow selected>
                        <TableCell></TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Reference Number</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Payment Type</TableCell>
                        <TableCell>View</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {receipts.length > 0 &&
                        receipts.map((receipt, index) => {
                            return (
                                <TableRow
                                    key={receipt.id}
                                // className={receipt.paymentStatus === 'FULLY_PAID' ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}
                                >
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{Moment(receipt.paidOn).format('LLL')}</TableCell>
                                    <TableCell>{receipt.referenceNumber}</TableCell>
                                    <TableCell>
                                        USD. {receipt.amountPaidUsd}&nbsp;&nbsp;-&nbsp; KES. {addComma(receipt.amountPaidKes)}
                                    </TableCell>
                                    <TableCell>{stringConverter(receipt.paymentType || "")}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => props.history.push(`/admin/receipts/${receipt.id}?payment=invoice`)}
                                            style={{ color: 'blue' }}
                                        >
                                            View
											</Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <TablePagination
                component='div'
                count={receiptsCount || 0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                style={{ margin: '0 auto', width: '50%' }}
            />
        </div>
    );
};

const AllShipments = ({ receipts, receiptsCount, page, handleChangePage, rowsPerPage, props }) => {
    if (mql.matches) {
        return (
            <div>
                <TablePagination
                    component='div'
                    count={receiptsCount || 0}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    style={{ margin: '0 auto', width: '50%' }}
                />
                <Table style={{ marginTop: '2%' }}>
                    <TableHead>
                        <TableRow selected>
                            <TableCell>Receipt </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {receipts > 0 &&
                            receipts.length > 0 &&
                            receipts.map(receipt => {
                                return (
                                    <TableRow
                                        key={receipt.id}
                                    // className={receipt.paid ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}
                                    >
                                        <TableCell>
                                            <b>Date:&nbsp;</b>
                                            {Moment(receipt.paymentDate || Date.now()).format('LLL')}
                                            <br />
                                            <b>Cost:&nbsp;</b>
											USD {addComma(receipt.amountPaidKes)}&nbsp;&nbsp;-&nbsp; KES: {receipt.amountPaidUsd}
                                            <br />
                                        </TableCell>
                                        <TableCell><Button
                                            onClick={() => props.history.push(`/admin/receipts/${receipt.id}?payment=packages`)}
                                            style={{ color: 'blue' }}
                                        >
                                            View
											</Button></TableCell>

                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </div>
        );
    }

    return (
        <div>
            <Table style={{ marginTop: '2%' }}>
                <TableHead>
                    <TableRow selected>
                        <TableCell></TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Reference Number</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Payment Type</TableCell>
                        <TableCell>View</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {receipts && receipts.length > 0 &&
                        receipts.map((receipt, index) => {
                            return (
                                <TableRow
                                    key={receipt.id}
                                // className={receipt.paymentStatus === 'FULLY_PAID' ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}
                                >
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{Moment(receipt.paymentDate || Date.now()).format('LLL')}</TableCell>
                                    <TableCell>{receipt.referenceNumber}</TableCell>
                                    <TableCell>
                                        USD. {receipt.amountPaidUsd}&nbsp;&nbsp;-&nbsp; KES. {addComma(receipt.amountPaidKes)}
                                    </TableCell>
                                    <TableCell>{stringConverter(receipt.paymentType || "")}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => props.history.push(`/admin/receipts/${receipt.id}?payment=packages`)}
                                            style={{ color: 'blue' }}
                                        >
                                            View
											</Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <TablePagination
                component='div'
                count={receiptsCount || 0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                style={{ margin: '0 auto', width: '50%' }}
            />
        </div>
    );
};

// export default withStyles(useStyles)(Receipts)
