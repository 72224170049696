import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Routes } from '../../../routes'
import { Grid, LinearProgress, Input, InputLabel,
  InputAdornment, Button, } from '@material-ui/core'
import { MailOutlineSharp, LockOutlined } from '@material-ui/icons'

import './AdminSignin.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)

class AdminSignin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabValue: 0,
      email: '', password: '',
    }
  }
  componentWillReceiveProps = (nextProps) => {
    const { adminLoggedIn } = nextProps.auth
    if (adminLoggedIn) {
      this.props.history.push(Routes.AdminDash)
    }
  }
  handleFormInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  onSignIn = (event) => {
    event.preventDefault()
    const { email, password } = this.state
    this.props.adminSignIn(email, password)
  }
  render() {
    return (
      <div>
        <div className="linear_progress">
					<LinearProgress style={{ display: this.props.auth.fetchingData? 'block' : 'none' }} />
				</div>
        <Grid container spacing={0}>
          <Grid item xs={mql.matches? 12 : 6}>
            <div className="admin_signin_main_image"></div>
          </Grid>
          <Grid item xs={mql.matches? 12 : 6}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="admin_signin_form_logo"></div>
              </Grid>
              <Grid item xs={12}>
                <div className="admin_signin_form_title">Login to proceed</div>
              </Grid>
              <Grid item xs={12}>
                <form className="admin_signin_form">
                  <InputLabel>Email or Username</InputLabel>
                  <Input name="email" onChange={this.handleFormInputChange}
                    value={this.state.email} style={{width: '100%'}}
                    startAdornment={ <InputAdornment position="start">
                      <MailOutlineSharp /> </InputAdornment> } />
                  <InputLabel>Password</InputLabel>
                  <Input name="password" type="password" onChange={this.handleFormInputChange}
                    value={this.state.password} style={{width: '100%'}}
                    startAdornment={ <InputAdornment position="start">
                      <LockOutlined /> </InputAdornment> } />
                  <Button type="submit" onClick={this.onSignIn}
                  style={{border: '2px solid #232C39', color: '#232C39'}}>Sign In</Button><br/>
                  <Link to="/accounts/forgot-password/admins"
                  style={{textDecoration: 'none'}}>Forgot password?</Link>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default AdminSignin
