import { connect } from 'react-redux'
import {
  officerGetOfficerInfoAction, officerCheckInItemAction,
  officerGetDataAction, officerConsolidateAction, searchOfficerAction,
  officerReplyIssueAction, officerGetInvoiceAction, officerMarksDeliveredAction,
  officerGetClientAction, officerPayInvoiceAction, searchShippingAction, getArchivedAction,
  receiveItemsInNairobiAction, officerGetClientInvoicesAction,
  officerSendInvoiceAction
} from '../actions/OfficerActions'
import {
  adminGetInvoiceReceiptsAction,
  adminGetPackagesReceiptsAction
}
  from '../actions/AdminActions'
import OfficerDashboard from '../components/officer/Officer'
import OfficerSuspended from '../components/officer/OfficerSuspended'
import LocalOfficerDashboard from '../components/officer/localOfficer/localOfficer'
import OfficerMessages from '../components/officer/localOfficer/Messages'
import OfficerInvoices from '../components/officer/localOfficer/Invoices'
import OfficerInvoice from '../components/officer/localOfficer/Invoice'
import OfficerClients from '../components/officer/localOfficer/Clients'
import OfficerClient from '../components/officer/localOfficer/Client'
import OfficerArchived from "../components/officer/localOfficer/archived";
import Receipts from '../components/officer/receipts/Receipts'
import Receipt from '../components/officer/receipts/Receipt'
import { dashGetUserInfoAction } from '../actions/DashAction'

export const OfficerDashboardPage = connect(
  state => ({
    auth: state.auth,
    officer: state.officer,
  }),
  dispatch => ({
    getOfficerInfo() {
      dispatch(officerGetOfficerInfoAction())
    },
  })
)(OfficerDashboard)

export const OfficerSuspendedPage = connect(
  state => ({
    auth: state.auth,
    officer: state.officer,
  }),
  dispatch => ({
    getOfficerInfo() {
      dispatch(officerGetOfficerInfoAction())
    }
  })
)(OfficerSuspended)

/* Local Officer */
export const LocalOfficerDashboardPage = connect(
  state => ({
    auth: state.auth,
    officer: state.officer,
  }),
  dispatch => ({
    getOfficerInfo() {
      dispatch(officerGetOfficerInfoAction())
    },
    getData(limit, skip, viewing) {
      dispatch(officerGetDataAction(limit, skip, viewing))
    },
    checkInShippingItem(payload) {
      dispatch(officerCheckInItemAction(payload))
    },
    consolidateItems(trackingNumbersList, clientNumber, weight) {
      dispatch(officerConsolidateAction(trackingNumbersList, clientNumber, weight))
    },
    searchShipping(pattern, status) {
      dispatch(searchShippingAction(pattern, status))
    },
    receiveItemsInNairobi(payload) {
      dispatch(receiveItemsInNairobiAction(payload))
    }
  })
)(LocalOfficerDashboard)

export const OfficerMessagesPage = connect(
  state => ({
    auth: state.auth,
    officer: state.officer
  }),
  dispatch => ({
    getOfficerInfo() {
      dispatch(officerGetOfficerInfoAction())
    },
    getData(limit, skip) {
      dispatch(officerGetDataAction(limit, skip))
    },
    respondToIssue(id, message) {
      dispatch(officerReplyIssueAction(id, message))
    }
  })
)(OfficerMessages)

export const OfficerInvoicesPage = connect(
  state => ({
    auth: state.auth,
    officer: state.officer
  }),
  dispatch => ({
    getOfficerInfo() {
      dispatch(officerGetOfficerInfoAction())
    },
    getData(limit, skip, viewing) {
      dispatch(officerGetDataAction(limit, skip, viewing))
    },
    markDelivered(payload, type) {
      dispatch(officerMarksDeliveredAction(payload, type))
    }
  })
)(OfficerInvoices)

export const OfficerInvoicePage = connect(
  state => ({
    auth: state.auth,
    officer: state.officer
  }),
  dispatch => ({
    getOfficerInfo() {
      dispatch(officerGetOfficerInfoAction())
    },
    getInvoice(id) {
      dispatch(officerGetInvoiceAction(id))
    },
    payInvoice(referenceNumber, phoneNumber, amountPaidKes, invoiceNumber, paymentType, getInvoice) {
      dispatch(officerPayInvoiceAction(referenceNumber, phoneNumber, amountPaidKes, invoiceNumber, paymentType, getInvoice))
    },
    markDelivered(payload, type) {
      dispatch(officerMarksDeliveredAction(payload, type))
    },
    sendInvoice(invoiceId) {
      dispatch(officerSendInvoiceAction(invoiceId))
    }
  })
)(OfficerInvoice)

export const OfficerClientsPage = connect(
  state => ({
    auth: state.auth,
    officer: state.officer
  }),
  dispatch => ({
    getOfficerInfo() {
      dispatch(officerGetOfficerInfoAction())
    },
    getData(limit, skip, viewing) {
      dispatch(officerGetDataAction(limit, skip, viewing))
    },
    searchOfficer(pattern) {
      dispatch(searchOfficerAction(pattern))
    }
  })
)(OfficerClients)

export const OfficerArchivedPage = connect(
  state => ({
    auth: state.auth,
    officer: state.officer
  }),
  dispatch => ({
    getOfficerInfo() {
      dispatch(officerGetOfficerInfoAction())
    },
    getArchived(limit, skip) {
      dispatch(getArchivedAction(limit, skip))
    }
  })
)(OfficerArchived)

export const OfficerClientPage = connect(
  state => ({
    auth: state.auth,
    officer: state.officer
  }),
  dispatch => ({
    getOfficerInfo() {
      dispatch(officerGetOfficerInfoAction())
    },
    getClient(id) {
      dispatch(officerGetClientAction(id))
    },
    getClientInvoices(clientId) {
      dispatch(officerGetClientInvoicesAction(clientId))
    }
  })
)(OfficerClient)

export const OfficerReceiptsPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getReceipts(limit, skip) {
      dispatch(adminGetInvoiceReceiptsAction(limit, skip))
    },
    getPaymentReceipts(limit, skip) {
      dispatch(adminGetPackagesReceiptsAction(limit, skip))
    }
  })
)(Receipts)

export const OfficerReceiptPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      // dispatch(dashGetUserInfoAction())
    },
    getReceipts(limit, skip) {
      dispatch(adminGetInvoiceReceiptsAction(limit, skip))
    },
    getPaymentReceipts(limit, skip) {
      dispatch(adminGetPackagesReceiptsAction(limit, skip))
    }
  })
)(Receipt)