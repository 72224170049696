import Axios from 'axios'
import { handleError } from './ErrorHandler'

/* Get foreman info */
export const foremanGetForemanInfoAction = () => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const userId = localStorage.getItem('client')
  const endpoint = `/api/officers/${userId}`
  return (dispatch) => {
    dispatch({ type: 'FOREMAN_GET_FOREMAN_INFO' })
    Axios.get(endpoint).then(snapshots => {
      if (snapshots.data.status === 'deactivated') {
        dispatch({ type: 'FOREMAN_SUSPENDED' })
      } else {
        dispatch({
          type: 'FOREMAN_GET_FOREMAN_INFO_SUCCESS',
          payload: snapshots.data,
        })
      }
    }).catch(error => dispatch(handleError(error)))
  }
}

export const foremanGetOfficersAction = (id) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const endpoint = `/api/officers?filter[where][warehouseId]=${id}`
  return (dispatch) => {
    dispatch({ type: 'FOREMAN_GET_OFFICERS' })
    Axios.get(endpoint).then(snapshots => {
      dispatch({
        type: 'FOREMAN_GET_OFFICERS_SUCCESS',
        payload: snapshots.data
      })
    }).catch(error => dispatch(handleError(error)))
  }
}

export const foremanAddOfficerAction = (first_name, last_name, email, date_joined, role, warehouse) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const endpoint = `/api/warehouses/${warehouse}/officers`
  return (dispatch) => {
    dispatch({ type: 'FOREMAN_ADD_OFFICER' })
    Axios.post(endpoint, {first_name, last_name, email, date_joined,
      role, warehouseId: warehouse }).then(snapshots => {
      dispatch({ type: 'FOREMAN_ADD_OFFICER_SUCCESS', payload: 'Officer Added' })}
    ).catch(error => dispatch(handleError(error)))
  }
}

export const foremanSuspendOfficerAction = (id) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const endpoint = `/api/officers/${id}`
  return (dispatch) => {
    dispatch({ type: 'FOREMAN_ADD_OFFICER' })
    Axios.patch(endpoint, { status: 'deactivated' }).then(snapshots => {
      dispatch({ type: 'FOREMAN_ADD_OFFICER_SUCCESS', payload: 'Officer Added' })}
    ).catch(error => dispatch(handleError(error)))
  }
}
