import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";
import { Routes } from ".";


const ClientRoute = ({ component: Component, ...rest}) => {
  const { isAuthenticated, isClient } = useSelector(state => ({
    isAuthenticated: state.auth.authenticated,
    isClient: state.auth.isClient
  }));

  const location = useLocation();
  let expectedPath = location.pathname.substring(0, 20).toString();
  let invoiceId = location.pathname.substring(20, location.pathname.length);
  let checkPath = '/dashboard/invoices/';
  
  if (expectedPath === checkPath && !isAuthenticated) {
    localStorage.invoiceLink = invoiceId;
  }

  return(
    <Route
      {...rest}
      render={props =>
        isAuthenticated && isClient ? (
          <div>
            <Component {...props} />
          </div>
        ) : (
          <Redirect to={Routes.Signin} />
        )
      }
    />
  )};

export default ClientRoute;