import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { format } from 'date-fns';
import stringConverter from "../../../utils/stringConverter";


const mql = window.matchMedia(`(max-width: 1024px)`)

const StatusTemplate = ({statusDates}) => {    

    const finalStatus = (status) => {
        if (status === 'DELIVERED') {
            return true;
        }
    }

    return(        
        <div style={{paddingLeft: mql.matches? '0' : '240px'}}>
                <div style={{padding: '0 0 2% 4%', fontSize: '19px'}}>Package History</div>
                <div style={{paddingLeft: '4%'}}>
                    {
                        statusDates.length > 0 && statusDates.slice().sort((a, b) => new Date(b.statusDate) - new Date(a.statusDate)).reverse().map(statusDate => (
                            <div>
                                <div style={{display: 'flex', marginTop: '3px'}}>
                                    {
                                        finalStatus(statusDate.packageStatus) ? (
                                            <span><LocationOnIcon style={{color: '#427D3E'}} /></span>
                                        ) : (
                                            <span><CheckCircleIcon style={{color: '#303E52'}} /></span>
                                        )
                                    }
                                    <span style={{marginLeft: '15px', background: `${finalStatus(statusDate.packageStatus) ? '#427D3E' : '#303E52'}`, color: 'white', padding: '3px 10px 0 10px', borderRadius: '4px'}}>
                                        {stringConverter(statusDate.packageStatus)}
                                    </span>
                                </div>
                                <div style={finalStatus(statusDate.packageStatus) ? {marginLeft: '10px'} : {display: 'flex', borderLeft: '3px solid #303E52', height: '45px', marginLeft: '10px'}}>
                                    <span style={{marginLeft: '25px'}}>
                                        {format(new Date(statusDate.statusDate), 'MM/dd/yyyy - HH:mm:ss')}
                                    </span><br />
                                    {
                                        finalStatus(statusDate.packageStatus) && (
                                            <span>
                                                <small style={{marginLeft: '25px', color: 'grey'}}>Your package has been delivered</small>
                                            </span>
                                        )
                                    }
                                </div>
                            </div>  
                        ))
                    }
                </div>
            </div>
    )
}

export default StatusTemplate