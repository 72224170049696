import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Routes } from '../../../routes'
import Axios from 'axios'
import { withStyles, Paper, LinearProgress, Input, Grid } from '@material-ui/core'
import { useStyles, AdminDrawer } from '../drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
import { OfficersListing } from '../../../utils/officersUtil'
import TablePagination from '@material-ui/core/TablePagination';
import { baseUrl } from '../../../actions/DashAction'

import '../../dashboard/Dashboard.scss'
import '../Admin.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)

export const getOffiersCount = async () => {

	try {
		let data = (await Axios.get(`${baseUrl}/officers/count`)).data
		if (data.count) return data.count
		return 0
	} catch (err) {

		return 0
	}
}

class AdminOfficers extends Component {
	constructor(props) {
		super(props)
		this.state = {
			officers: [],
			drawerOpen: false,
			profileAnchorEl: null,
			roles: ['local_officer', 'local_foreman', 'int_officer', 'int_foreman'],
			rolesSanitized: ['Officer', 'Foreman', 'Officer', 'Foreman'],
			count: 0,
			page: 0,
			limit: 10,
			rowsPerPage: 10,
			skip: 0,
			filterString: ''
		}
	}
	componentDidMount = () => {
		getOffiersCount().then(v => {
			this.setState({ ...this.state, count: v })
		})
		const { limit, skip } = this.state
		this.props.getOfficers(limit, skip)
	}
	componentWillReceiveProps = (nextProps) => {
		const { officers } = nextProps.admin
		if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
		this.setState({ officers: officers })
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = (event) => {
		this.setState({ profileAnchorEl: null })
	}
	toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}


	handleChangePage = (event, newPage) => {
		const { limit } = this.state;

		this.setState({ page: newPage, skip: newPage * limit }, () => {
			const { limit, skip } = this.state;
			this.props.getOfficers(limit, skip);
		})
	};


	render() {
		const { classes } = this.props
		const { adminHttpErrors, adminHttpSuccess, officersFetched, } = this.props.admin
		if (!officersFetched) {
			return (
				<div className="linear_progress">
					<LinearProgress />
				</div>
			)
		} else {
			return (
				<div>
					<AdminDrawer classes={classes} state={this.state} props={this.props}
						activeLink='ofc' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
						closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
						logout={this.onLogout} />
					<Paper square={true} style={{
						background: '#fff', boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
					}}>
						<Grid container justify="center" spacing={0}>
							<Grid item xs={12}>
								<Paper className="clean_paper" style={{ fontSize: '1.2em', marginLeft: '4%' }}>
									OFFICERS LIST&nbsp;&nbsp;&nbsp;
									<Link to={Routes.AdminAddOfficers} className="admin_add_officer_btn">Add Officer</Link>
								</Paper>
							</Grid>
							<Input
								value={this.state.filterString}
								placeholder="Search Officers"
								style={{
									marginLeft: 32
								}}
								//onChange={event => {this.onFilterClients(event.target.value)}}
								onChange={event => {
									this.setState({ filterString: event.target.value })
								}}
								onKeyUp={e => {
									if (e.key === 'Enter' || e.keyCode === 13) {
										const { filterString } = this.state;
										// const keywordChange = RegExp.escape(filterString);
										// const pattern = this.patternRegex(keywordChange);
										this.props.searchOfficer(filterString);
									}
								}}
								className={classes.searchField} />
							<OfficersListing state={this.state} currentUser="admin" />
							<TablePagination
								component="div"
								count={this.state.count}
								page={this.state.page}
								onChangePage={this.handleChangePage}
								rowsPerPage={this.state.rowsPerPage}
								rowsPerPageOptions={[10]}
								style={{ margin: '0 auto', width: '50%' }}
							/>
						</Grid>
					</Paper>
					{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
					{adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
				</div>
			)
		}
	}
}

export default withStyles(useStyles)(AdminOfficers)
