import React, { Component } from 'react'
import {
	withStyles, Paper, LinearProgress, Grid,
	Card, Typography, CardContent, Table, TableRow,
	TableCell, TableBody, TableHead
} from '@material-ui/core'
import Moment from 'moment';
import { CSVLink } from 'react-csv';
import { useStyles, AdminDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../snackbars'
import TablePagination from '@material-ui/core/TablePagination';
import { format } from "date-fns";

import '../dashboard/Dashboard.scss'
import './Admin.scss'
const mql = window.matchMedia(`(max-width: 1024px)`)

class AdminDashboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			drawerOpen: false,
			profileAnchorEl: null,
			metrics: {},
			startDate: Moment().add(-30, 'days'),
			endDate: Moment(),
			dateError: null,
			selectedStartDate: Moment().add(-30, 'days'),
			selectedEndDate: Moment(),
			csvData: [],
			payments: '',
			filterLimit: 10,
			filterSkip: 0,
			page: 0,
			rowsPerPage: 10,
			revenueCount: 0,
			revenueJSON: [],
			revenueCSV: ''
		}
	}
	componentDidMount = () => {
		const { selectedStartDate, selectedEndDate, filterLimit, filterSkip } = this.state;
		// this.props.getMetrics(startDate, endDate);
		this.props.getRevenue(selectedStartDate, selectedEndDate, filterLimit, filterSkip);
		// this.props.getPayments(startDate, endDate);
	}
	componentWillReceiveProps = nextProps => {
		const { metrics, metricsFetched, revenue, fetchedRevenue, fetchedPayments, payments, revenueJSON } = nextProps.admin
		if (metricsFetched) {
			this.setState({ metrics })
		}
		console.log(revenue)
		if (fetchedRevenue) {
			this.setState({ csvData: revenue, revenueCount: revenue.length, revenueJSON })

			let headers = {
				"invoiceNumber": "Invoice Number",
				"amountUsd": "Amount (USD)",
				"amountKes": "Amount (KES)",
				"paymentType": "Payment Type",
				"referenceNumber": "Reference Number",
				"phoneNumber": "Phone Number",
				"paidOn": "Paid On"
			};

			let revenues = revenueJSON.map(revenue => {
				return {
					'invoiceNumber': `${revenue.invoiceNumber.toUpperCase()}`,
					'amountUsd': `${revenue.amountPaidUsd}`,
					'amountKes': `${revenue.amountPaidKes}`,
					'paymentType': `${revenue.paymentType}`,
					'referenceNumber': `${revenue.referenceNumber}`,
					'phoneNumber': `${revenue.phoneNumber}`,
					'paidOn': `${format(new Date(revenue.paidOn), 'MM/dd/yyyy - HH:mm:ss')}`
				}
			});
			revenues.unshift(headers);

			this.setState({
				revenueCSV: this.ConvertToCSV(revenues)
			})
		}
		if (fetchedPayments) {
			this.setState({ payments })
		}
	}
	// Convert JSON to CSV Function
	ConvertToCSV = (objArray) => {
		let array = objArray;
		let str = '';

		for (let i = 0; i < array.length; i++) {
			let line = '';
			for (let index in array[i]) {
				if (line !== '') line += ','

				line += array[i][index];
			}

			str += line + '\r\n';
		}

		return str;
	}

	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}

	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}

	closeProfileMenu = (event) => {
		this.setState({ profileAnchorEl: null })
	}

	toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}

	formatNumber = number => {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	onChangeStartDate = date => {
		this.setState({ dateError: null })
		const { endDate } = this.state
		if (Moment(date).isBefore(endDate)) {
			this.setState({ startDate: Moment(date) }, () => {
				this.props.getMetrics(date, endDate)
			})
		} else {
			this.setState({ dateError: 'Start date should be any day before the end date' })
		}
	}

	onChangeSelectStart = date => {
		this.setState({ dateError: null })
		const { selectedEndDate, filterLimit, filterSkip } = this.state
		if (Moment(date).isBefore(selectedEndDate)) {
			this.setState({ selectedStartDate: Moment(date) }, () => {
				this.props.getRevenue(date, selectedEndDate, filterLimit, filterSkip)
			})
		} else {
			this.setState({ dateError: 'Start date should be any day before the end date' })
		}
	}

	onChangeSelectEnd = date => {
		this.setState({ dateError: null })
		const { selectedStartDate, filterLimit, filterSkip } = this.state
		if (Moment(date).isBefore(Moment())) {
			if (Moment(date).isAfter(selectedStartDate)) {
				this.setState({ selectedEndDate: Moment(date) }, () => {
					this.props.getRevenue(selectedStartDate, date, filterLimit, filterSkip)
				})
			} else {
				this.setState({ dateError: 'The end date cannot be after the start date' })
			}
		} else {
			this.setState({ dateError: "Oops! we cannot tell the future" })
		}
	}

	onChangeEndDate = date => {
		this.setState({ dateError: null })
		const { startDate } = this.state
		if (Moment(date).isBefore(Moment())) {
			if (Moment(date).isAfter(startDate)) {
				this.setState({ endDate: Moment(date) }, () => {
					this.props.getMetrics(startDate, date)
				})
			} else {
				this.setState({ dateError: 'The end date cannot be after the start date' })
			}
		} else {
			this.setState({ dateError: "Oops! we cannot tell the future" })
		}
	}

	handleDateChange = date => {
		this.setState({ csvData: [] });
		this.setState({ selectedDate: Moment(date) }, () => {
			const { selectedDate } = this.state
			const payload = {};
			payload.month = selectedDate.month().toString();
			payload.year = selectedDate.year().toString();
			this.props.getRevenue(payload)
		});
	}

	handleChangePage = (event, newPage) => {
		const { filterLimit } = this.state;

		this.setState({ page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { selectedStartDate, selectedEndDate, filterLimit, filterSkip } = this.state;
			this.props.getRevenue(selectedStartDate, selectedEndDate, filterLimit, filterSkip);
		})
	};

	render() {
		const { classes } = this.props
		const { adminHttpErrors, adminHttpSuccess } = this.props.admin
		// const { startDate, endDate } = this.state
		let revenueLarge;
		let revenueSmall;
		// !metricsFetched
		if (false) {
			return (
				<div className="linear_progress">
					<LinearProgress />
				</div>
			)
		} else {

			if (this.state.csvData.length > 0) {
				revenueLarge = this.state.csvData.map(revenue => {

					return (<TableRow key={revenue.id}>
						<TableCell>{revenue.invoiceNumber && revenue.invoiceNumber.toUpperCase()}</TableCell>
						<TableCell>{revenue.amountPaidUsd.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
						<TableCell>{revenue.amountPaidKes.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
						<TableCell>{revenue.paymentType}</TableCell>
						<TableCell>{revenue.referenceNumber}</TableCell>
						<TableCell>{revenue.phoneNumber}</TableCell>
						<TableCell>{format(new Date(revenue.paidOn), 'MM/dd/yyyy - HH:mm:ss')}</TableCell>
					</TableRow>)
				})
				revenueSmall = this.state.csvData.map(revenue => {
					return (<TableRow key={revenue.id}>
						<TableCell>
							<Typography><b>Invoice Number:&nbsp;</b>{revenue.invoiceNumber && revenue.invoiceNumber.toUpperCase()}</Typography>
							<Typography><b>Amount (USD):&nbsp;</b>{revenue.amountPaidUsd.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
							<Typography><b>Amount (KES):&nbsp;</b>{revenue.amountPaidKes.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
							<Typography><b>Payment Type:&nbsp;</b>{revenue.paymentType}</Typography>
							<Typography><b>Reference Number:&nbsp;</b>{revenue.referenceNumber}</Typography>
							<Typography><b>Phone Number:&nbsp;</b>{revenue.phoneNumber}</Typography>
							<Typography><b>Paid On:&nbsp;</b>{format(new Date(revenue.paidOn), 'MM/dd/yyyy - HH:mm:ss')}</Typography>
						</TableCell>
					</TableRow>)
				})
			}
			// const MerchantMetrics = () => {
			// 	const weightMetrics = metrics.merchants.weightMetrics
			// 	let totalWeight = 0
			// 	weightMetrics.map(weight => totalWeight += parseFloat(weight.merchantTotalWeight))
			// 	return weightMetrics.map(weight => {
			// 		const percentage = ((weight.merchantTotalWeight / totalWeight) * 100).toFixed(2)
			// 		return (<TableRow key={Math.random()}>
			// 			<TableCell>{weight.merchantName}</TableCell>
			// 			<TableCell>
			// 				<span style={{background: '#734B5E', color: 'white',
			// 				borderRadius: '16px', padding: '4px 12px'}}>
			// 					{percentage === 'NaN' ? 0 : percentage}&nbsp;%
			// 				</span>
			// 			</TableCell>
			// 			<TableCell>{Math.round(weight.merchantTotalWeight)} Pounds</TableCell>
			// 		</TableRow>)
			// 	})
			// }
			return (
				<div>
					<AdminDrawer classes={classes} state={this.state} props={this.props}
						activeLink='dash' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
						closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
						changeStartDate={this.onChangeStartDate} changeEndDate={this.onChangeEndDate}
						logout={this.onLogout} />
					<Paper square={true} style={{
						background: 'rgba(0,0,0,0)', boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
					}}>
						<Grid container spacing={0}>
							{/* Quick details
							*****************
							*****************
							***************/}
							<Grid item xs={12}>
								{/* <Card className="admin_metrics_card" style={{ margin: '1%' }}>
									<CardContent>
										<Typography variant="h6">Quick details</Typography>
										<Grid container spacing={0}>
											<Grid item xs={3}>
												<List style={{ margin: '3%', boxShadow: '0px 8px 8px 0 #efeffb' }}> */}
								{/* <ListItem>
														<ListItemIcon><PersonAddOutlined htmlColor="blue" /></ListItemIcon>
														<ListItemText primary={`${metrics.clients.newUserCount || 0} New users`}
														secondary="Last week" />
													</ListItem> */}
								{/* removed earlier */}
								{/* <ListItem>
														<ListItemIcon><PersonOutlined htmlColor="green" /></ListItemIcon>
														<ListItemText primary={`${metrics.clients.activeUserCount || 0} Active users`} />
													</ListItem>
													<ListItem>
														<ListItemIcon><PersonOutlined htmlColor="purple" /></ListItemIcon>
														<ListItemText primary={`${metrics.clients.inactiveUserCount || 0} Inactive users`} />
													</ListItem>
													<ListItem>
														<ListItemIcon><PersonOutlined htmlColor="slategray" /></ListItemIcon>
														<ListItemText primary={`${metrics.clients.dormantUserCount || 0} Dormant users`} />
													</ListItem> */}
								{/* <ListItem>
														<ListItemIcon><PersonOutlined htmlColor="orange" /></ListItemIcon>
														<ListItemText primary={`Total users: ${metrics.clients.totalUserCount || 0}`} />
													</ListItem> */}
								{/* </List>
											</Grid> */}
								{/* <Grid item xs={3}> */}
								{/* <List style={{margin: '3%', boxShadow: '0px 8px 8px 0 #efeffb'}}>
													<ListItem>
														<ListItemIcon><DoneOutlined htmlColor="blue" /></ListItemIcon>
														<ListItemText primary={`${metrics.itemsCount.checkedIn.count || 0} Items`}
														secondary="Checked In" />
													</ListItem>
													<ListItem>
														<ListItemIcon><DoneOutlined htmlColor="green" /></ListItemIcon>
														<ListItemText primary={`${metrics.itemsCount.shipping.count || 0} Items`}
														secondary="Shipping" />
													</ListItem>
													<ListItem>
														<ListItemIcon><DoneOutlined htmlColor="purple" /></ListItemIcon>
														<ListItemText primary={`${metrics.itemsCount.arrived.count || 0} Items`}
														secondary="Arrived" />
													</ListItem>
													<ListItem>
														<ListItemIcon><DoneOutlined htmlColor="slategray" /></ListItemIcon>
														<ListItemText primary={`${metrics.itemsCount.paid.count || 0} Items`}
														secondary="Paid" />
													</ListItem>
													<ListItem>
														<ListItemIcon><DoneOutlined htmlColor="slategray" /></ListItemIcon>
														<ListItemText primary={`${metrics.itemsCount.delivered.count || 0} Items`}
														secondary="Delivered" />
													</ListItem>
												</List> */}
								{/* </Grid> */}
								{/* <Grid item xs={3}> */}
								{/* <List style={{margin: '3%', boxShadow: '0px 8px 8px 0 #efeffb'}}>
													<ListItem>
														<ListItemIcon><DoneOutlined htmlColor="blue" /></ListItemIcon>
														<ListItemText primary={`${metrics.invoice.paid || 0} Invoices`}
														secondary="Paid" />
													</ListItem>
													<ListItem>
														<ListItemIcon><ClearOutlined htmlColor="orange" /></ListItemIcon>
														<ListItemText primary={`${metrics.invoice.unpaid || 0} Invoices`}
														secondary="Unpaid" />
													</ListItem>
													<ListItem>
														<ListItemIcon><DoneOutlined htmlColor="purple" /></ListItemIcon>
														<ListItemText primary={`Total Invoices: ${metrics.invoice.total || 0}`} />
													</ListItem>
												</List> */}
								{/* </Grid> */}
								{/* <Grid item xs={3}> */}
								{/* <List style={{margin: '3%', boxShadow: '0px 8px 8px 0 #efeffb'}}>
													<ListItem>
														<ListItemIcon><ClearOutlined htmlColor="orange" /></ListItemIcon>
														<ListItemText primary={`${metrics.messages.openedMessages || 0} Messages`}
														secondary="Open" />
													</ListItem>
													<ListItem>
														<ListItemIcon><DoneOutlined htmlColor="green" /></ListItemIcon>
														<ListItemText primary={`${metrics.messages.closedMessages || 0} Messages`}
														secondary="Closed" />
													</ListItem>
													<ListItem>
														<ListItemIcon><DoneOutlined htmlColor="purple" /></ListItemIcon>
														<ListItemText primary={`Total messages: ${metrics.messages.totalMessages || 0}`} />
													</ListItem>
												</List> */}
								{/* </Grid> */}
								{/* </Grid>
									</CardContent>
								</Card> */}
							</Grid>
							{/* Total amount
							****************
							****************
							**************/}
							{/*
							<Grid item xs={12}>
								<Card className="admin_metrics_card"
								style={{margin: '1%', background: '#E2FCEF', color:  'black'}}>
									<CardContent>
										<Typography variant="h6">
											All time shipping and items protection
										</Typography>
										<Grid container spacing={0}>
											<Grid item xs={3}>
												<List>
													<ListItem>
														<ListItemText primary={`Ksh. ${this.formatNumber(Math.round(metrics.amount.totalAllTimeShippingFeePaid))}`}
														secondary="All time total shipping amount" />
													</ListItem>
                        </List>
											</Grid>
											<Grid item xs={3}>
												<List>
													<ListItem>
														<ListItemText primary={`Ksh. ${this.formatNumber(Math.round(metrics.amount.totalAllTimeProtectionFeePaid))}`}
														secondary="Total shipping protection amount" />
													</ListItem>
													<ListItem>
														<ListItemText primary={`${this.formatNumber(Math.round(metrics.amount.totalAllTimeProtectedNumber))}`}
														secondary="Total items protected" />
													</ListItem>
                        </List>
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>*/}
							{/* Total Weight, Items,
							Protection and Delivery.*/}
							{/*
							<Grid item xs={12}> 
								<Card className="admin_metrics_card"
								style={{margin: '1%', background: '#BACBA9'}}>
									<CardContent>
										<Typography variant="h6">
											Metrics
											from {Moment(startDate).format('MM/DD/YYYY')} to {Moment(endDate).format('MM/DD/YYYY')}
										</Typography>
										<Grid container spacing={0}>
											<Grid item xs={3}>
												<List>
													<List>
														<ListItem>
															<ListItemText primary={`Ksh. ${this.formatNumber(Math.round(metrics.amount.totalShippingFeePaid))}`}
															secondary="Total shipping amount" />
														</ListItem>
													</List>
													<ListItem>
														<ListItemText primary={`${this.formatNumber(120)}`}
														secondary="Total items shipped" />
													</ListItem>
													<ListItem>
														<ListItemText primary={`${this.formatNumber(4)}`}
														secondary={`Average items shipped per day`} />
													</ListItem>
                        </List>
											</Grid>
											<Grid item xs={3}>
												<List>
													<ListItem>
														<ListItemText primary={`${this.formatNumber(120)} Pounds`}
														secondary="Total weight shipped" />
													</ListItem>
													<ListItem>
														<ListItemText primary={`${this.formatNumber(18)} Pounds`}
														secondary={`Average weight shipped per day`} />
													</ListItem>
                        </List>
											</Grid>
											<Grid item xs={3}>
												<List>
													<ListItem>
														<ListItemText primary={`${this.formatNumber(Math.round(metrics.amount.totalProtectedItemNumber))}`}
														secondary="Items protected" />
													</ListItem>
													<ListItem>
														<ListItemText primary={`Ksh. ${this.formatNumber(Math.round(metrics.amount.totalProtectionFeePaid))}`}
														secondary={`Protection payment total`} />
													</ListItem>
                        </List>
											</Grid>
											<Grid item xs={3}>
												<List>
													<ListItem>
														<ListItemText primary={`Usd. ${this.formatNumber(6940)}`}
														secondary="Delivery total" />
													</ListItem>
													<ListItem>
														<ListItemText primary={`Usd. ${this.formatNumber(940)}`}
														secondary={`Average delivery per day`} />
													</ListItem>
                        </List>
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>*/}
							{/* Items per day graph*/}
							{/* <Grid item xs={12}>
								<Card className="admin_metrics_card"
								style={{margin: '1%', background: '#E2FCEF'}}>
									<CardContent>
										<Typography variant="h6">
										  A graph of items registered per day
											from {Moment(startDate).format('MM/DD/YYYY')} to {Moment(endDate).format('MM/DD/YYYY')}
										</Typography>
									</CardContent>
									<ItemsPerDayChart />
								</Card>
							</Grid> */}
							{/* Reporting
							********************
							********************
							******************/}
							<Grid item xs={12}>
								<Card
									className="admin_metrics_card"
									style={{
										margin: '1%'
									}}
								>
									<CardContent>
										Reporting: Revenue received
									</CardContent>
									<CardContent
										style={{
											display: 'flex',
											justify: 'space-around'
										}}
									>
										<div>
											<form style={{
												display: 'flex',
												justify: 'space-around'
											}}>
												{/* <MuiPickersUtilsProvider
													utils={DateFnsUtils}
													>
													<DatePicker
														variant="inline"
														openTo="year"
														views={["year", "month", "day"]}
														label="Select Year and Month"
														helperText="Start from year selection, then month and hit enter"
														value={this.state.selectedDate}
														onChange={date => this.handleDateChange(date)}
													/>
												</MuiPickersUtilsProvider>
												
												<div>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<KeyboardDatePicker variant="dialog" format="MM/dd/yyyy"
													margin="normal" value={this.state.selectedStartDate} label="From date:"
													onChange={date => this.onChangeSelectStart(date)} />
													</MuiPickersUtilsProvider>
												</div>
												<div style={{marginLeft: '5px'}}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<KeyboardDatePicker variant="dialog" format="MM/dd/yyyy"
													margin="normal" value={this.state.selectedEndDate} label="End date:"
													onChange={date => this.onChangeSelectEnd(date)} />
													</MuiPickersUtilsProvider>
												</div> */}
											</form>
										</div>
										<div style={{ paddingLeft: '80%' }}>
											{this.state.revenueJSON.length > 0 ?
												<CSVLink data={this.state.revenueCSV}>Download as CSV</CSVLink> :
												null
											}
										</div>
									</CardContent>
									<CardContent>
										<Table style={{ display: mql.matches ? 'none' : 'table' }}>
											<TableHead>
												<TableRow>
													<TableCell>Invoice Number</TableCell>
													<TableCell>Amount (USD)</TableCell>
													<TableCell>Amount (KES)</TableCell>
													<TableCell>Payment Type</TableCell>
													<TableCell>Reference Number</TableCell>
													<TableCell>Phone Number</TableCell>
													<TableCell>Paid On</TableCell>
												</TableRow>
											</TableHead>
											<TableHead>
											</TableHead>
											<TableBody>
												{revenueLarge}
											</TableBody>
										</Table>
										<TablePagination
											component="div"
											count={this.state.revenueCount}
											page={this.state.page}
											onChangePage={this.handleChangePage}
											rowsPerPage={this.state.rowsPerPage}
											rowsPerPageOptions={[10]}
											style={{ margin: '0 auto', width: '50%' }}
										/>
										{/* Mobile screens */}
										<Table style={{ display: mql.matches ? 'table' : 'none' }}>
											<TableBody>
												{revenueSmall}
											</TableBody>
										</Table>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Paper>
					{Boolean(this.state.dateError) ? <HttpError errorMessage={this.state.dateError} /> : null}
					{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
					{adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
				</div>
			)
		}
	}
}

export default withStyles(useStyles)(AdminDashboard)
