import Axios from 'axios'
import { handleAuthError, handleError } from './ErrorHandler'
import { Routes } from '../routes'
import setAuthToken from "../services/setAuthToken";

/* Client
*********
*********
********/
/* Client Signin */
const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/api';

export const clientLoginAction = (email, password, history) => {
  return (dispatch) => {
    dispatch({ type: 'ACCOUNTS_CLIENT_LOGIN' })
    dispatch({ type: 'OFFICER_SIGNIN' })
    dispatch({ type: 'ADMIN_SIGNIN' })

    Axios.post(`${baseUrl}/users/login`, { email: email.toLowerCase(), password }).then(
      snapshots => {
        const { token, profileId, role } = snapshots.data;
        setAuthToken(token);
        if (role === 'client') {
          dispatch(loginSuccess(token, profileId, 'ACCOUNTS_CLIENT_LOGIN_SUCCESS'))
          // redirect to invoice if request was saved
          if (localStorage.invoiceLink) {
            let invoiceId = localStorage.invoiceLink
            return history.push(Routes.Invoice.replace(":id", `${invoiceId}`))
          } else {
            history.push(Routes.Dashboard)
          }
        }

        if (role === 'officer') {
          dispatch(loginSuccess(token, profileId, 'OFFICER_SIGNIN_SUCCESS'))
          history.push(Routes.OfficerLocalOfficer)
        }
        if (role === 'admin') {
          dispatch(loginSuccess(token, profileId, "ADMIN_SIGNIN_SUCCESS"))
          history.push(Routes.AdminDash)
        }
      }
    ).catch(error => dispatch(handleAuthError(error)))
  }
}
const loginSuccess = (token, profileId, type) => {
  localStorage.setItem('token', token)
  localStorage.setItem('profileId', profileId)
  return {
    type: `${type}`,
    payload: 'Logged In'
  }
}

/* Client Logout */
export const logout = (history, type) => dispatch => {
  localStorage.removeItem('token');
  localStorage.removeItem('profileId');
  setAuthToken(false);
  dispatch({
    type: 'UNAUTHORIZED',
    payload: ''
  });
  dispatch({
    type: 'LOGOUT',
    payload: ''
  });
  if (type === 1) {
    history.push(Routes.Signin)
  }
}

/* Client Signup */
export const clientSignupAction = (email, password) => {
  return (dispatch) => {
    dispatch({ type: 'ACCOUNTS_CLIENT_SIGNUP' })
    Axios.post(
      `${baseUrl}/users/signup`,
      { email: email.toLowerCase(), password }
    ).then(
      snapshots => dispatch({
        type: 'ACCOUNTS_CLIENT_SIGNUP_SUCCESS',
        payload: 'Click the link sent to your Email'
      })
    ).catch(error => dispatch(handleAuthError(error)))
  }
}

/* Verify Email Address */
export const verifyEmailAction = token => {
  return (dispatch) => {
    dispatch({ type: 'ACCOUNTS_VERIFY_EMAIL' })
    const endpoint = `${baseUrl}/users/verify_email/${token}`
    Axios.get(endpoint).then(() => dispatch({
      type: 'ACCOUNTS_VERIFY_EMAIL_SUCCESS',
      payload: 'The email has been verified'
    })
    ).catch(error => dispatch(handleError(error.response.data)));
  }
}

/* Reset Password Request */
export const requestNewPasswordAction = email => {
  return (dispatch) => {
    dispatch({ type: 'ACCOUNTS_REQUEST_PASS' })
    Axios.post(`${baseUrl}/users/reset-token`, { email: email.toLowerCase() }).then(
      snapshot => dispatch({
        type: 'ACCOUNTS_REQUEST_PASS_SUCCESS',
        payload: 'Click the link sent to your email'
      })
    ).catch(error => console.log(error))
  }
}

/* Reset Password Real */
export const resetNewPasswordAction = (newPassword, token) => {
  // to use token
  return (dispatch) => {
    dispatch({ type: 'ACCOUNTS_SET_NEW_PASS' })
    Axios.patch(`${baseUrl}/users/reset_password/${token}`, { newPassword }).then(
      snapshots => dispatch({
        type: 'ACCOUNTS_SET_NEW_PASS_SUCCESS',
        payload: 'Password reset was successful'
      })
    ).catch(error => console.log(error))
  }
}

/* Officer
**********
**********
*********/
export const officerSignInAction = (email, password) => {
  const endpoint = `${baseUrl}/officers/login`
  return (dispatch) => {
    dispatch({ type: 'OFFICER_SIGNIN' })
    Axios.post(endpoint, { email, password }).then(snapshot => {
      const { id, userId } = snapshot.data
      dispatch(officerSignInSuccess(id, userId))
    }).catch(error => dispatch(handleAuthError(error)))
  }
}
const officerSignInSuccess = (id, userId) => {
  localStorage.setItem('token', id)
  localStorage.setItem('client', userId)
  return {
    type: 'OFFICER_SIGNIN_SUCCESS',
    payload: 'Logged In'
  }
}

export const officerVerifyEmailAction = (uid, token) => {
  const endpoint = `${baseUrl}/officers/confirm?${uid}&redirect=%2F&token=${token}`
  return (dispatch) => {
    dispatch({ type: 'OFFICER_VERIFY_EMAIL' })
    Axios.get(endpoint).then(snapshots => {
      console.log(snapshots)
      dispatch({ type: 'OFFICER_VERIFY_EMAIL_SUCCESS', payload: 'Email Verified. Login' })
    }).catch(error => dispatch(handleAuthError(error)))
  }
}

export const officerChangePassAction = (oldPassword, newPassword) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const endpoint = `${baseUrl}/officers/change-password`
  return (dispatch) => {
    dispatch({ type: 'OFFICER_CHANGE_PASS' })
    Axios.post(endpoint, { oldPassword, newPassword }).then(snapshots => {
      dispatch({ type: 'OFFICER_CHANGE_PASS_SUCCESS', payload: 'Password Changed' })
    }).catch(error => dispatch(handleAuthError(error)))
  }
}

/* Admin
********
********
*******/
/* Authentication */
export const adminSignInAction = (email, password) => {
  const endpoint = `${baseUrl}/Admins/login`
  return (dispatch) => {
    dispatch({ type: 'ADMIN_SIGNIN' })
    Axios.post(endpoint, { email, password }).then(
      snapshots => {
        console.log(snapshots)
        const { id, userId } = snapshots.data
        dispatch(adminSignInSuccess(id, userId))
      }
    ).catch(error => dispatch(handleAuthError(error)))
  }
}
const adminSignInSuccess = (id, userId) => {
  localStorage.setItem('token', id)
  localStorage.setItem('client', userId)
  return {
    type: 'ADMIN_SIGNIN_SUCCESS',
    payload: 'Logged In'
  }
}

export const adminVerifyEmailAction = (uid, token) => {
  const endpoint = `${baseUrl}/admins/confirm?${uid}&redirect=%2F&token=${token}`
  return (dispatch) => {
    dispatch({ type: 'ADMIN_VERIFY_EMAIL' })
    Axios.get(endpoint).then(snapshots => {
      console.log(snapshots)
      dispatch({ type: 'ADMIN_VERIFY_EMAIL_SUCCESS', payload: 'Email Verified. Login' })
    }).catch(error => dispatch(handleAuthError(error)))
  }
}



