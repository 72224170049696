import React, { Component } from 'react'
import { withStyles, Paper, Card, CardHeader,
  CardContent, Input, Button, LinearProgress} from '@material-ui/core'
import { useStyles, LocalOfficerDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
import Moment from 'moment'

import '../../dashboard/Dashboard.scss'
import '../../admin/Admin.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)

class OfficerMessages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      profileAnchorEl: null,
      officerInfo: {},
      messages: [],
      filterLimit: 100,
      filterSkip: 0
    }
  }
  componentWillReceiveProps = (nextProps) => {
    const { officerInfo, messages, messagesUpdated } = nextProps.officer
    if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
    if (messagesUpdated) {
      this.props.getData()
    }
    this.setState({ officerInfo, messages })
  }
  componentDidMount = () => {
    const { filterLimit, filterSkip } = this.state
    this.props.getOfficerInfo()
    this.props.getData(filterLimit, filterSkip)
  }
  onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
  openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
  }
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
  onReplyToIssue = (event, id) => {
		event.preventDefault()
		this.props.respondToIssue(id, event.target[0].value)
	}
  render() {
    const { officerInfoFetched } = this.props.officer
    if (!officerInfoFetched) {
      return (
        <div className="linear_progress">
          <LinearProgress />
        </div>
      )
    } else {
      const { classes } = this.props
      const { errorMsg, successMsg } = this.props.officer
      return (
        <div>
          <LocalOfficerDrawer currentUser="officer" classes={classes} state={this.state} props={this.props}
            activeLink='iss' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
            closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
            logout={this.onLogout} searchFieldChange={this.searchFieldChange}
            onSearchClient={this.onSearchClient} />
          <Paper square={true} style={{ background: 'rgba(0,0,0,0)', boxShadow: 'none',
            paddingLeft: mql.matches? '0' : '240px', paddingTop: '16vh'}}>
            {this.state.messages.reverse().map(message => {
              return (<Card key={message.id} style={{margin: '1% 4%', paddingBottom: '2%'}}>
                <CardHeader subheader={`From: ${message.client.client_number || ''}, 
                ${message.client.first_name || ''} ${message.client.last_name || ''}`} />
                <CardHeader title={`${message.issue}`} avatar={<span></span>}
                  subheader={`${Moment(message.createdAt).format('LLL')}`} style={{color: 'blue'}} />
                {message._responses.map(response => {
                  if (response.userType === 'client') {
                    return (<CardContent style={{padding: '0.6%', margin: '0% 7%',
                      background: 'rgba(0,0,255,0.05)', color: 'blue'}} key={response.id}>
                      <b>Client:&nbsp;</b>{response.message}
                    </CardContent>)
                  }
                  return (<CardContent style={{padding: '0.6%', margin: '0% 7%',
                    background: 'whitesmoke',color: 'darkslategray'}} key={response.id}>
                    <b>Officer:&nbsp;</b>{response.message}
                  </CardContent>)
                })}
                <CardContent style={{padding: '0.4% 7%', margin: '0', marginTop: '2%'}}>
                  <form onSubmit={event => this.onReplyToIssue(event, message.id)}>
                    <Input required	placeholder="Reply to this issue"
                      style={{width: '60%', marginRight: '2%'}} />
                    <Button type="submit"
                      style={{background: '#232C39', color: 'white'}}>Reply</Button>
                  </form>
                </CardContent>
              </Card>)
            })}
          </Paper>
          {errorMsg? <HttpError errorMessage={errorMsg} /> : null}
          {successMsg? <HttpSuccess successMessage={successMsg} /> : null}
        </div>
      )
    }
  }
}

export default withStyles(useStyles)(OfficerMessages)
