import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Routes } from '../../routes'
import {
	MailOutline, Phone, Notes, LensOutlined,
	EditOutlined
} from '@material-ui/icons'
import {
	Card, CardContent, Typography, CardActions, LinearProgress,
	Paper, Grid, Dialog, DialogContent, DialogActions,
	Button
} from '@material-ui/core'
import { useStyless, DashDrawer } from './drawer'
import { HttpSuccess, HttpError } from '../snackbars'
import './Dashboard.scss'
import Reactour from 'reactour'
import Moment from 'moment'
import { dashGetUserInfoAction, dashRecentItemsAction } from '../../actions/DashAction'

const mql = window.matchMedia(`(max-width: 1024px)`)

const RecentActivities = ({ text, display, legendColor }) => {
	if (display === 'none') {
		return (<Grid container spacing={0}></Grid>)
	} else {
		return (
			<Grid container spacing={0}>
				<Grid item xs={1}></Grid>
				<Grid item xs={1}><div className="dash_recent_activity_pipe">|</div></Grid>
				<Grid item xs={10}></Grid>
				<Grid item xs={1}><div className="dash_recent_activity_txt"></div></Grid>
				<Grid item xs={1}>
					<div className="dash_recent_activity_0">
						<LensOutlined htmlColor={`${legendColor}`} />
					</div>
				</Grid>
				<Grid item xs={10}>
					<div className="dash_recent_activity_txt">{text}</div>
				</Grid>
			</Grid>
		)
	}
}

const DashBoardPage = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { isClient } = useSelector(state => ({
		isClient: state.auth.isClient
	}));

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		arrivedItems: [],
		invoicedItems: [],
		paidItems: [],
		deliveredItems: [],
		notifications: [],
		invoices: [],
		dashTourOpen: !Boolean(localStorage.getItem('dashtour')),
		closeTourDialogOpen: false,
		notificationsDialogOpen: false,
		mobileScreen: mql.matches,
	});

	const { recentUploads, recentUploadsSuccess, invoicesSuccess,
		invoices, userInfo, userAddress, userInfoFetched, successMsg, errorMsg } = useSelector(state => ({
			recentUploads: state.dash.recentUploads,
			recentUploadsSuccess: state.dash.recentUploadsSuccess,
			invoicesSuccess: state.dash.invoicesSuccess,
			invoices: state.dash.invoices,
			userInfo: state.dash.userInfo,
			userAddress: state.dash.userAddress,
			userInfoFetched: state.dash.userInfoFetched,
			successMsg: state.dash.successMsg,
			errorMsg: state.dash.errorMsg
		}))

	useEffect(() => {
		dispatch(dashGetUserInfoAction());
		dispatch(dashRecentItemsAction());
	},
		// eslint-disable-next-line
		[]);

	useEffect(() => {
		if (recentUploadsSuccess) {
			let arrivedItems = []
			let receivedItems = []
			let shippedItems = []
			let invoicedItems = []
			let deliveredItems = []
			let paidItems = []
			let eligibleItems = []
			recentUploads && recentUploads.map(item => {
				if (item.packageStatus === 'IN_DELAWARE') receivedItems.push(item)
				if (item.packageStatus === 'IN_TRANSIT') shippedItems.push(item)
				if (item.packageStatus === 'IN_NAIROBI') arrivedItems.push(item)
				if (item.packageStatus === 'invoiced') invoicedItems.push(item)
				if (item.packageStatus === 'paid') paidItems.push(item)
				if (item.packageStatus === 'delivered') deliveredItems.push(item)
				if (item.isEligibleForProtection === true) eligibleItems.push(item)
				return item
			})
			setState({ ...state, receivedItems, shippedItems, eligibleItems, arrivedItems, invoicedItems, paidItems, deliveredItems })
		}

		if (invoicesSuccess) {
			setState({ ...state, invoices: invoices })
		}
	},
		// eslint-disable-next-line
		[recentUploadsSuccess, recentUploads, invoices, invoicesSuccess])

	const openProfileMenu = (event) => {
		setState({ ...state, profileAnchorEl: event.currentTarget })
	}

	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null })
	}

	const toggleNotifications = () => {
		setState({ ...state, notificationsDialogOpen: !state.notificationsDialogOpen })
	}

	const toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setState({ ...state, drawerOpen: open })
	}

	const toggleTourDismissDialog = () => {
		setState({ ...state, closeTourDialogOpen: !state.closeTourDialogOpen })
	}

	const hideTourForever = () => {
		localStorage.setItem('dashtour', `${Moment()}`)
		setState({ ...state, closeTourDialogOpen: !state.closeTourDialogOpen })
	}

	const onHelp = () => {
		setState({ ...state, dashTourOpen: true })
	}

	const { id, email, phoneNumber } = userInfo
	const { receivedItems, shippedItems, arrivedItems, invoicedItems, paidItems, deliveredItems } = state
	const classes = useStyless();
	if (!userInfoFetched) {
		return (
			<div className="linear_progress">
				<LinearProgress />
			</div>
		)
	} else {
		return (
			<div>
				<DashDrawer classes={classes} state={state}
					activeLink='dash' toggleDrawer={toggleDrawer} openProfile={openProfileMenu}
					closeProfile={closeProfileMenu} profileAnchorEl={state.profileAnchorEl}
					toggleNotifications={toggleNotifications}
					onHelp={onHelp} />
				<Paper square={true} style={{
					background: 'rgba(0,0,0,0)', boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
				}}>
					<Grid container justify="center" spacing={0}>
						<Grid item xs={12}>
							<Paper className="clean_paper rt_our_address" style={{ margin: '0 4%' }}>
								<Grid container spacing={0}>
									<Grid item xs={4}>
										<Paper className="clean_paper" style={{ marginLeft: '3%' }}>
											Our <b>Customer</b> Service Contacts<br />
												Phone: <a href="tel:+254717363433">+254717363433</a> <br />
												Email: <a href="mailto:support@midnight.com">support@midnight.com</a>
										</Paper>
									</Grid>
									<Grid item xs={4}>
										<Paper className="clean_paper" style={{ marginLeft: '3%' }}>
											Our <b>United States</b> Shipping Address:<br />
                        Midnight<br />
												1255 E 16th Street<br />
												Suite {id.toUpperCase()}<br />
												Wilmington, Delaware 19802<br />
												USA.<br />
                        Phone: <a href="tel:+1 (609) 848-8662">+1 (609) 848-8662</a>
										</Paper>
									</Grid>
									<Grid item xs={4}>
										<Paper className="clean_paper">
											Our <b>UK</b> Shipping Address:<br />
												Access Self Storage,<br />
												First Way ACG VM {id.toUpperCase()}<br />
												Wembley HA9 0JD<br />
												United Kingdom.<br />
												Phone: <a href="tel:+44 0207 993 2763">+44 0207 993 2763</a>
										</Paper>
									</Grid>
									<Grid item xs={12}>
										<div style={{ paddingLeft: '12px', paddingTop: '10px', color: 'red' }}>
											<p>
												There is no need to enter any customer name or information on your shipping label. Ensure the shipping label has the correct address and your unique suite number.
                        </p>
										</div>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
						{userAddress && userAddress.length === 0 ?
							(<Grid item xs={12}>
								<Paper style={{
									margin: '2% 4%', padding: '1%', marginBottom: '0',
									background: 'rgba(255,69,0,0.2)', color: 'red'
								}}>
									You need a delivery address in order to start shipping.
									Please add at least one address&nbsp;
										<Button onClick={() => history.push(Routes.EditProfile)}
										color="primary">here</Button>
								</Paper>
							</Grid>)
							: (<Grid item xs={12}></Grid>)}
						<Grid item xs={mql.matches ? 12 : 5}>
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<Card className="dashboard_profile_card rt_profile_info">
										<CardContent>
											<Typography className="align_icons" color="textSecondary">
												<Notes />&nbsp;&nbsp;&nbsp;<b>Client Number:&nbsp;</b>{id && id.toUpperCase()}
											</Typography><br />
											<Typography className="align_icons" color="textSecondary">
												<MailOutline />&nbsp;&nbsp;&nbsp;{email}
											</Typography><br />
											<Typography className="align_icons" color="textSecondary">
												<Phone />&nbsp;&nbsp;&nbsp;{phoneNumber}
											</Typography><br />
										</CardContent>
										<CardActions>
											<Typography className="align_icons">
												<Link to="/dashboard/editprofile" style={{ textDecoration: 'none' }}>
													&nbsp;&nbsp;&nbsp;<EditOutlined />&nbsp;
														Edit information
													</Link>
											</Typography>
										</CardActions>
									</Card>
								</Grid>
								<Grid item xs={12}>
									<Card className="dashboard_legend_card">
										<CardContent>
											<Typography>Legend</Typography>
											<Typography className="align_icons" style={{ color: 'orange' }}>
												<LensOutlined />&nbsp;At Warehouse
												</Typography><br />
											<Typography className="align_icons" style={{ color: 'blue' }}>
												<LensOutlined />&nbsp;In Transit</Typography><br />
											{isClient ? <><Typography className="align_icons" style={{ color: 'magenta' }}>
												<LensOutlined />&nbsp;At Customs
      </Typography><br /></>
												: <> <Typography className="align_icons" style={{ color: 'red' }}>
													<LensOutlined />&nbsp;In Kenya</Typography><br />
													<Typography className="align_icons" style={{ color: 'cyan' }}>
														<LensOutlined />&nbsp;In Nairobi
												</Typography><br />
													<Typography className="align_icons" style={{ color: 'magenta' }}>
														<LensOutlined />&nbsp;Being Processed
												</Typography><br />
												</>
											}

											<Typography className="align_icons" style={{ color: 'darkslategray' }}>
												<LensOutlined />&nbsp;Awaiting Payment
												</Typography><br />
											<Typography className="align_icons" style={{ color: 'purple' }}>
												<LensOutlined />&nbsp;Pending Delivery
												</Typography><br />
											<Typography className="align_icons" style={{ color: 'green' }}>
												<LensOutlined />&nbsp;Delivered
												</Typography>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={mql.matches ? 12 : 7}>
							<Card className="dashboard_recent_activity rt_dash_recent_act">
								<CardContent>Recent Activity</CardContent>
								<CardContent>
									<RecentActivities
										text={`${receivedItems.length} item(s) have been received at the warehouse`}
										display={receivedItems.length === 0 ? 'none' : 'block'}
										legendColor="orange" />
									<RecentActivities
										text={`${shippedItems.length} item(s) have been shipped`}
										display={shippedItems.length === 0 ? 'none' : 'block'}
										legendColor="purple" />
									<RecentActivities
										text={`${arrivedItems.length} item(s) have arrived in Nairobi`}
										display={arrivedItems.length === 0 ? 'none' : 'block'}
										legendColor="blue" />
									<RecentActivities
										text={`${invoicedItems.length} item(s) have been invoiced`}
										display={invoicedItems.length === 0 ? 'none' : 'block'}
										legendColor="darkslategray" />
									<RecentActivities
										text={`${paidItems.length} item(s) have been paid`}
										display={paidItems.length === 0 ? 'none' : 'block'}
										legendColor="cyan" />
									<RecentActivities
										text={`${deliveredItems.length} item(s) have been delivered`}
										display={deliveredItems.length === 0 ? 'none' : 'block'}
										legendColor="green" />
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Paper>
				{/* Tour */}
				<Reactour steps={reactourSteps}
					isOpen={state.dashTourOpen} onBeforeClose={toggleTourDismissDialog}
					onRequestClose={() => setState({ ...state, dashTourOpen: false })} />
				{/* Dismiss tour dialog */}
				<Dialog open={state.closeTourDialogOpen}
					onClose={toggleTourDismissDialog}>
					<DialogContent style={{ paddingTop: '32px' }}>
						Dismiss the dashboard guide popup forever?
						</DialogContent>
					<DialogActions>
						<Button onClick={toggleTourDismissDialog} color="primary">No</Button>
						<Button onClick={hideTourForever} color="primary" autoFocus>Yes</Button>
					</DialogActions>
				</Dialog>
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
			</div>
		)
	}
}

const reactourSteps = [
	{
		selector: '.rt_our_address',
		content: `Welcome, These are our US and UK shipping addresses, Use them as the delivery
		          address when ordering goods.`,
	},
	{
		selector: '.rt_profile_info',
		content: `Here is your Client number and profile information. Click
		          the EDIT button to make changes to your info`
	},
	{
		selector: '.rt_dash_recent_act',
		content: `View recent activities here (The status of the different items
			        that you registered)`
	}
]

export default DashBoardPage;
