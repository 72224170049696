import React, { Component } from 'react'
import { Routes } from '../../../routes'
import {
  withStyles, Paper,
  LinearProgress, Button, Card, CardContent,
  Fab,
  Modal,
  Fade
} from '@material-ui/core'
import {
  ArrowDropUp, ArrowDropDown
} from '@material-ui/icons'
import { compareAsc } from 'date-fns'
import { useStyles, DashDrawer } from '../drawer'
import { SanitizedItems } from './sanitizedItems'
import { HttpSuccess, HttpError } from '../../snackbars'
import '../../admin/Admin.scss'
import { Info } from '@material-ui/icons'
import { Link } from 'react-router-dom'

const mql = window.matchMedia(`(max-width: 1024px)`)

class ScrollableTabsButtonAuto extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      profileAnchorEl: null,
      eligibleItems: [],
      receivedItems: [],
      invoicedItems: [],
      arrivedItems: [],
      paidItems: [],
      shippedItems: [],
      recentUploads: [],
      notifications: [],
      invoices: [],
      notificationsDialogOpen: false,
      mobileScreen: mql.matches,
      showModal: false,
      sorted: false
    }
  }
  handleClose = () => {
    this.setState({ ...this.state, showModal: false })
  };
  componentDidMount = () => {
    this.props.getUserInfo()
    this.props.getRecentUploads()

    // SHow modal when visiting for the first time
    let notice = localStorage.__notice


    if (!notice) {
      setTimeout(() => {
        this.setState({ ...this.state, showModal: true })
        localStorage.setItem('__notice', Date.now())
      }, 2000)
    }
  }


  componentWillReceiveProps = (nextProps) => {
    const { recentUploadsSuccess, recentUploads, recentItemEdited } = nextProps.dashboard
    if (nextProps.auth.unauthorized) {
      window.location.href = '/accounts/signin'
    }
    if (recentItemEdited) {
      this.props.getRecentUploads()
    }
    if (recentUploadsSuccess) {
      const invoicedItems = []
      const receivedItems = []
      const shippedItems = []
      let eligibleItems = []
      const arrivedItems = []
      const paidItems = []
      recentUploads && recentUploads.map(pack => {
        if (pack.packageStatus === 'IN_DELAWARE') receivedItems.push(pack)
        if (pack.packageStatus === 'invoiced') invoicedItems.push(pack)
        if (pack.packageStatus === 'shipping') shippedItems.push(pack)
        if (pack.packageStatus === 'arrived') arrivedItems.push(pack)
        if (pack.packageStatus === 'paid') paidItems.push(pack)
        if (compareAsc(new Date(pack.packageProtectionEligibiltyExpiry), new Date()) === 1
          && !pack.isShipmentProtected
        ) eligibleItems.push(pack)
        return pack
      })
      // eligibleItems = eligibleItems.filter(item => !item.isShipmentProtected)

      this.setState({ invoicedItems, receivedItems, shippedItems, eligibleItems, arrivedItems, paidItems, recentUploads })
    }
  }
  onLogout = () => {
    this.setState({ profileAnchorEl: null })
  }
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
  openProfileMenu = (event) => {
    this.setState({ profileAnchorEl: event.currentTarget })
  }
  closeProfileMenu = () => {
    this.setState({ profileAnchorEl: null })
  }
  toggleNotifications = () => {
    this.setState({ notificationsDialogOpen: !this.state.notificationsDialogOpen })
  }
  onDeleteItem = id => {
    this.props.deleteItem(id)
  }
  markDelivered = id => {
    this.props.markDelivered(id, this.props.getRecentUploads)
  }
  render() {
    const { classes } = this.props
    const { userInfoFetched, successMsg, errorMsg } = this.props.dashboard
    const { recentUploads } = this.state
    if (!userInfoFetched) {
      return (
        <div className="linear_progress">
          <LinearProgress />
        </div>
      )
    } else {
      return (
        <div>
          <Modal
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
            }}
            onClose={this.handleClose} open={this.state.showModal}>
            <Fade in={this.state.showModal}>
              <div style={
                {
                  padding: 24,
                  minHeight: 150,
                  border: 'none',
                  borderRadius: 8,
                  background: '#fff',
                  minWidth: 300,
                  maxWidth: 500,
                  textAlign: 'center',
                  outline: 'none'
                }
              }>
                <div>
                  <Info
                    fontSize={"large"}
                    color={"crimson"} />
                  <h4>Welcome to the new Midnight Platform!</h4>
                  <p>We are excited to inform you that we have updated our site to give you a better
                  experience! While we are
                  currently transitioning all
                  our operations to this platform,
                  any older invoices or shipments
                   can be found at <a target="_blank" href="https://old-ship.vitumob.com" rel="noopener noreferrer"> old site</a>. (Current logins are still active).</p>
                  <p>We apologise for any inconvenience caused and look forward to servicing you better on this site.</p>
                  <Button style={{
                    background: "#f3742a"
                  }} onClick={this.handleClose}>
                    Ok
                  </Button>
                </div>
              </div>
            </Fade>


          </Modal>
          <DashDrawer classes={classes} state={this.state} props={this.props}
            activeLink='reg' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
            closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
            logout={this.onLogout} toggleNotifications={this.toggleNotifications} />
          <Paper square={true} style={{
            background: 'rgba(0,0,0,0)', boxShadow: 'none',
            paddingLeft: mql.matches ? '0' : '260px', paddingTop: '16vh'
          }}>
            <Card style={{ margin: '2%' }}>
              {Boolean(this.state.eligibleItems.length) ?
                (<CardContent style={{ background: 'rgba(0,0,255,0.2)' }}>
                  <Fab onClick={() => this.props.history.push(Routes.EligibleItems)}
                    color="primary" size="small">{this.state.eligibleItems.length}</Fab>&nbsp;
                  &nbsp;Item(s) are eligible for protection.&nbsp;
                  <Button onClick={() => this.props.history.push(Routes.EligibleItems)}
                    color="primary">Protect items</Button>
                </CardContent>)
                : <span></span>}
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '15px'
                }}>
                  <Link className="sort_packages"
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        sorted: !this.state.sorted
                      })
                    }}
                  >
                    Sort
                    {this.state.sorted ? <ArrowDropUp htmlColor="white" /> : <ArrowDropDown htmlColor="white" />}
                  </Link>
                </div>
              <CardContent>
                <SanitizedItems sorted={this.state.sorted} registeredItems={recentUploads} deleteItem={this.onDeleteItem} markDelivered={this.markDelivered} />
              </CardContent>
            </Card>
          </Paper>
          {successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
          {errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
        </div>
      )
    }
  }
}

export default withStyles(useStyles)(ScrollableTabsButtonAuto)

