export const FAQs = [
  {
    question: `How do I get started?`,
    response: `<a href="/accounts/signup">Sign up</a> on our platform to create an account.
      Then follow the instructions that will be shared to your
      email.`
  },
  {
    question: `From where can I ship?`,
    response: `Any items that can be shipped to our US or UK warehouses
      can be shipped to East Africa. This includes purchases made from
      South America and Europe, as well as Australia, China and other
      parts of Asia, so long as they can ship to a US or UK address.`
  },
  {
    question: `How long does delivery take?`,
    response: `5 to 21 days`
  },
  {
    question: `Where does Midnight deliver?`,
    response: `<a href="/dashboard/pricing">Click here</a> to see where we deliver`
  },
  {
    question: `How do I pay?`,
    response: `We accept Mpesa, cheque, Credit cards and PayPal (PayPal
      and Credit cards are paid in US Dollars).`
  },
  {
    question: `When do I pay?`,
    response: `Payment is done any time prior to pick up of the package.`
  },
  {
    question: `Does Midnight offer any protection against damage or
      loss of my items while they’re being shipped?`,
    response: `Midnight offers purchase protection for your items.
      When your items arrive at our US/UK warehouse, you’ll receive an
      email offering purchase protection, you will have 48 hours to
      communicate your interest, kindly note that the amount to be paid
      is 10% of the value of the items, and this amount is non-refundable.
      Shipping protection allows you to file a claim and receive full compensation.
      If you chose to not have shipping protection, Midnight will not
      accept any liability for loss or damage of your items.`
  },
  {
    question: `How am I charged?`,
    response: `Midnight charges the greater of actual weight or
      volumetric weight. To calculate the volumetric weight of
      your package, <a href="/">Click here.</a>
      This charging method is an industry standard applied by
      our airline partners.`
  },
  {
    question: `Is there any Insurance for my packages?`,
    response: `What we offer is protection of items, you pay us
      10% the value of your package and in case anything happens to
      your package we will ensure that you’re compensated.`
  },
  {
    question: `To what countries can I have my order shipped?`,
    response: `Midnight will ship and deliver your goods to you
      any where within East Africa`
  },
  {
    question: `Is my credit card information secure?`,
    response: `Midnight employs a secured socket layer encrypted connection
      to manage all transmissions between Midnight and you, the customer.
      Further, your credit card purchase is transacted directly with
      our secured credit card processor. Midnight actually never sees,
      stores or holds in any way your credit card information.
      We have provided extensive cyber security protocols to
      protect Midnight and protect you.`
  },
  {
    question: `Should I tip my Midnight delivery person?`,
    response: `Tipping is not expected but always appreciated.`
  }
]