import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardContent, CardHeader, InputLabel,
  Input, withStyles, Paper, } from '@material-ui/core'
import { HttpError, HttpSuccess } from '../../snackbars'
import { useStyles, DashDrawer, useStyless } from '../drawer'
import { dashGetUserInfoAction, dashChangePasswordAction } from '../../../actions/DashAction'
import { Routes } from '../../../routes'

import '../Dashboard.scss'


const mql = window.matchMedia(`(max-width: 1024px)`)

const Changepassword = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyless();
	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		notifications: [],
		invoices: [],
		notificationsDialogOpen: false,
		mobileScreen: mql.matches,
		currentPassword: '',
		newPassword: '',
		repeatNewPassword: '',
		formFieldError: '',
	});

	const { passwordChangeSuccess, errorMsg, successMsg } = useSelector(state => ({
		passwordChangeSuccess: state.dash.passwordChangeSuccess,
		errorMsg: state.dash.errorMsg,
		successMsg: state.dash.successMsg
	}));

	const { mobileScreen } = state;

	useEffect(() => {
		dispatch(dashGetUserInfoAction());
	},
	// eslint-disable-next-line
	[]);

	useEffect(() => {
		if (passwordChangeSuccess) {
			history.push(Routes.Dashboard)
		}
	},
	// eslint-disable-next-line
	[passwordChangeSuccess]);
  
  	const onPasswordInputChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.value
		});
	}

	const openProfileMenu = (event) => {
		setState({ ...state, profileAnchorEl: event.currentTarget })
	}

	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null })
	}

	const toggleNotifications = () => {
		setState({ ...state, notificationsDialogOpen: !state.notificationsDialogOpen })
	}

	const toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
		return
		}
		setState({ ...state, drawerOpen: open })
	}

	const onChangePassword = (event) => {
		event.preventDefault();

		setState({ ...state, formFieldError: '' })
		const { currentPassword, newPassword, repeatNewPassword } = state;
		if (currentPassword === '' || newPassword === '' || repeatNewPassword === '') {
			setState({ ...state, formFieldError: '* Password fields cannot be empty' })
			return;
		}
		else if (newPassword !== repeatNewPassword) {
			setState({ ...state, formFieldError: '* Passwords do not match' })
			return;
		} else {
			dispatch(dashChangePasswordAction(currentPassword, newPassword))
		}
	}
   
    return (
		<div>	
				<DashDrawer classes={classes} state={state}
						activeLink='dash' toggleDrawer={toggleDrawer} openProfile={openProfileMenu}
						closeProfile={closeProfileMenu} profileAnchorEl={state.profileAnchorEl}
						toggleNotifications={toggleNotifications}
				/>
				<Paper square={true} style={{ background: 'rgba(0,0,0,0)', boxShadow: 'none',
					paddingLeft: mql.matches? '0' : '240px', paddingTop: '10vh'}}>
					<Grid container spacing={0}>
						<Grid item xs={mobileScreen? 12 : 6}>
							<Card style={{margin: '6%', borderRadius: '12px', padding: '2%'}}>
								<form onSubmit={onChangePassword}>
									<CardHeader title="Change Password" />
									<CardContent>
										<InputLabel>Old Password</InputLabel>
										<Input value={state.currentPassword} name="currentPassword" type="password"
											onChange={onPasswordInputChange} style={{width: '100%'}} />
									</CardContent>
									<CardContent>
										<InputLabel>New Password</InputLabel>
										<Input value={state.newPassword} name="newPassword" type="password"
											onChange={onPasswordInputChange} style={{width: '100%'}} />
									</CardContent>
									<CardContent>
										<InputLabel>Repeat New Password</InputLabel>
										<Input value={state.repeatNewPassword} name="repeatNewPassword" type="password"
											onChange={onPasswordInputChange} style={{width: '100%'}} />
									</CardContent>
									<CardContent style={{color: 'red'}}>{state.formFieldError}</CardContent>
									<Button type="submit"
										style={{background: '#232C39', color: 'white', marginLeft: '40%'}}>Submit</Button>
								</form>
							</Card>
						</Grid>
					</Grid>
				</Paper>
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				{successMsg? <HttpSuccess successMessage={successMsg} /> : null}
			</div>
    )
}

export default withStyles(useStyles)(Changepassword)
