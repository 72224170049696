const stringConverter = (string) => {
    // get index of underscore
    let n = string.indexOf('_');
    let n2 = n + 1;
    // separate prefix and suffix
    let prefix = string.substr(0, n);
    let suffix = string.substr(n2, string.length);
    // get first character
    let upperPrefix = prefix.substr(0, 1);
    // get remaining character to lowercase
    let lowerPrefix = prefix.substr(1, prefix.length).toLowerCase();
    // get first character
    let upperSuffix = suffix.substr(0, 1);
    // get remaining character to lowercase
    let lowerSuffix = suffix.substr(1, suffix.length).toLowerCase();
    // form new string
    let newString = `${upperPrefix}${lowerPrefix} ${upperSuffix}${lowerSuffix}`;
    return newString;
}

const addComma = num => {
    if (!num) {
        return 0
    }

    if (num < 1000) {
        return num.toString()
    }

    let s = ''
    let decimal
    num = num.toString()

    const hasDecimal = num.includes('.')

    if (hasDecimal) {
        decimal = num.split(".")[1]
        num = num.split(".")[0]
    }

    let first = true

    while (num.length > 3) {
        s = num.slice(num.length - 3) + (first ? '' : ',') + s
        num = num.slice(0, num.length - 3)
        first = false
    }

    return hasDecimal ? `${num},${s}.${decimal}` : `${num},${s}`
}

export { addComma }

export default stringConverter;