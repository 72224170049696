import React, { Component } from 'react'
import { Routes } from '../../../routes'
import {
  Paper, withStyles, TableRow, Typography,
  Table, TableHead, TableCell, TableBody,
  Button, LinearProgress, Dialog, Badge, Checkbox,
  DialogTitle, DialogContent, ExpansionPanelDetails,
  Divider, Input, ExpansionPanelSummary,
  ExpansionPanel, Grid
} from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
// import PaypalExpressBtn from 'react-paypal-express-checkout'
import { useStyles, DashDrawer } from '../drawer'
import { HttpSuccess, HttpError, } from '../../snackbars'
import { compareAsc } from 'date-fns'
import { baseUrl } from "../../../actions/DashAction"
import '../../admin/Admin.scss'
import Axios from 'axios'

const mql = window.matchMedia(`(max-width: 1024px)`)

const code = process.env.REACT_APP_FATTMERCHANT

class DashEligibleItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      tabValue: 0,
      profileAnchorEl: null,
      eligibleItems: [],
      eligibleItemsTotal: 0,
      protectionTotal: 0,
      paymentNumber: '',
      receivedItems: [],
      shippedItems: [],
      notifications: [],
      invoices: [],
      paypalError: '',
      paypalSuccess: '',
      notificationsDialogOpen: false,
      paymentDialogOpen: false,
      mobileScreen: mql.matches,
      updateError: '',
      price: [],
      priceDetails: {
        itemId: '',
        price: '',
        currency: ''
      },
      uploadFormsBExpanded: false,
      currency: '',
      itemId: '',
      external: '',
      checkedItems: [],
      amountKes: '',
      amountPaidUsd: '',
      protectionAmountKes: '',
      loading: false,
      errorMessage: "",
      successMessage: '',
      memo: ''
    }
  }

  async protectItems(body) {
    this.setState({ ...this.state, loading: true })
    try {
      const resp = await Axios.patch(`${baseUrl}/packages/packages-protection-id`, body,)
      console.log(resp.data)
      this.setState({ ...this.state, loading: false })
      return resp.data
    } catch (error) {
      this.setState({ ...this.state, loading: false, errorMessage: "Try again later" })
      setTimeout(
        () => this.setState({ ...this.state, errorMessage: "" }),
        4000
      )
      return null
    }
  }
  componentDidMount = () => {
    this.props.getUserInfo()
    this.props.getRecentUploads()
  }
  componentWillReceiveProps = (nextProps) => {
    const { recentUploads, recentUploadsSuccess, userInfo,
      protectionPaid } = nextProps.dashboard
    if (nextProps.auth.unauthorized) {
      window.location.href = '/accounts/signin'
    }
    if (protectionPaid) {
      this.props.history.push(Routes.RegisteredItems)
    }
    if (recentUploadsSuccess) {
      let eligibleItems = []
      recentUploads.map(pack => {
        if (compareAsc(new Date(pack.packageProtectionEligibiltyExpiry), new Date()) === 1
          && !pack.isShipmentProtected
        ) eligibleItems.push(pack)
        // if (compareAsc(new Date(item.packageProtectionEligibiltyExpiry), new Date())) {
        //   // item.protected = false
        //   eligibleItems.push(item)
        // }
        return pack
      })
      console.log(eligibleItems)
      // eligibleItems = eligibleItems.filter(item => item.isShipmentProtected)
      this.setState({ eligibleItems })

      this.setState({ price: eligibleItems })
    }
    this.setState({ paymentNumber: userInfo.phoneNumber || '', })
  }

  componentDidUpdate(prevProps) {
    if (this.props.dashboard.recentUploads !== prevProps.dashboard.recentUploads) {
      if (prevProps.dashboard.recentUploadsSuccess) {
        let eligibleItems = []
        this.props.dashboard.recentUploads.map(pack => {
          if (compareAsc(new Date(pack.packageProtectionEligibiltyExpiry), new Date()) === 1
            && !pack.isShipmentProtected
          ) eligibleItems.push(pack)
          return pack
        })

        console.log(100, eligibleItems)

        // eligibleItems = eligibleItems.filter(item => !item.isShipmentProtected)
        this.setState({ eligibleItems })
      }
    }
  }

  handleChange = (e, id) => {
    this.setState({ price: e.target.value });
  }

  onLogout = () => {
    this.setState({ profileAnchorEl: null })
    window.location.href = '/accounts/signin'
  }
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
  openProfileMenu = (event) => {
    this.setState({ profileAnchorEl: event.currentTarget })
  }
  closeProfileMenu = () => {
    this.setState({ profileAnchorEl: null })
  }
  toggleNotifications = () => {
    this.setState({ notificationsDialogOpen: !this.state.notificationsDialogOpen })
  }
  onProtectItem = (event, id) => {
    const { eligibleItems } = this.state
    const index = eligibleItems.findIndex(x => x.id === id)
    let newEligibleItems = [...eligibleItems]
    newEligibleItems[index].protected = event.target.checked

    this.setState({
      eligibleItems: newEligibleItems
    }, () => {
      let checkedItems = []
      this.state.eligibleItems.map(item => {
        if (item.protected) {
          checkedItems.push(item)
        }
        return item
      })
      this.setState({ checkedItems })
    })
  }
  onPayProtection = () => {
    const { checkedItems, paymentNumber, amountKes, memo } = this.state
    const trackingNumbers = checkedItems.map(item => item.trackingNumber);
    this.props.payProtection(trackingNumbers, paymentNumber, amountKes, memo)
    this.setState({ paymentDialogOpen: false, protectionTotal: 0, eligibleItemsTotal: 0, amountKes: 0, checkedItems: [] })
  }

  reset = () => {
    this.setState({ price: '', currency: '' })
  }

  render() {
    const { classes } = this.props
    // eslint-disable-next-line 
    const { userInfoFetched, successMsg, errorMsg } = this.props.dashboard

    if (!userInfoFetched || this.state.loading) {
      return (
        <div className="linear_progress">
          <LinearProgress />
        </div>
      )
    } else {
      // exchangeRates.data.USD
      // let exchangeRate = Math.round(100)
      // if (typeof exchangeRates.exchangeRate !== 'undefined') {
      //   exchangeRate =
      // }
      // const onPayPalSuccess = payment => {
      //   console.log(payment)
      //   this.setState({paypalSuccess: 'Success. Please wait'})
      // }
      // const onPayPalCancel = (data) => {
      //   this.setState({paypalError: 'Payment cancelled'})
      // }
      // const onPayPalError = (err) => {
      //   console.log(err)
      //   this.setState({paypalError: 'An error occured'})
      // }
      // const client = {
      //   sandbox:    'Ab0ADalLRfrfVV8hWSkwkW44nH2YiSJwK52xPZ2m2PfY6x6o6DiY_oOxopznoyy5RfntyXZFMRMVoUr-',
      //   production: '',
      // }
      return (
        <div>
          <DashDrawer classes={classes} state={this.state} props={this.props}
            activeLink='reg' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
            closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
            logout={this.onLogout} toggleNotifications={this.toggleNotifications} />
          <Paper square={true} style={{
            background: 'rgba(0,0,0,0)', boxShadow: 'none',
            paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
          }}>
            <Paper className="clean_paper" style={{ padding: '4%', fontSize: '1.2em' }}>
              <Badge badgeContent={this.state.eligibleItems.length} color="primary">Items Eligible for protection</Badge>
            </Paper>
            <Paper style={{ margin: '4%', marginTop: '0' }}>
              {/*EligibleItems*/}
              {(mql.matches) ? (<Table style={{ width: '100%' }}>
                <TableHead>
                  <TableRow selected>
                    <TableCell>Items</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.eligibleItems.map((item, i) => {
                    return (<TableRow key={item.id}>
                      <TableCell>
                        <b>Tracking Number:&nbsp;</b>{item.trackingNumber}<br />
                        <b>Price:&nbsp;</b>USD.&nbsp;{item.price}<br />
                        {/* <b>Date registered:&nbsp;</b>{item.packageProtectionEligibiltyExpiry}<br /> */}
                      </TableCell>
                      <TableCell>
                        <Checkbox color="primary" checked={item.protected}
                          onChange={event => this.onProtectItem(event, item.id)} />
                      </TableCell>
                    </TableRow>)
                  })}
                </TableBody>
              </Table>)
                :
                (<Table style={{ width: '100%' }}>
                  <TableHead>
                    <TableRow selected>
                      <TableCell></TableCell>
                      <TableCell>Tracking Number</TableCell>
                      <TableCell>Package Protection Eligibility Expiry</TableCell>
                      {/*<TableCell>Submit</TableCell>*/}
                      <TableCell>Protect</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.eligibleItems.map((item, index) => {
                      return (<TableRow key={index} style={{ width: '100%' }}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.trackingNumber}</TableCell>
                        <TableCell>{item.packageProtectionEligibiltyExpiry}</TableCell>
                        <TableCell>
                          <Checkbox color="primary" checked={item.protected}
                            onChange={event => this.onProtectItem(event, item.id)} />
                        </TableCell>
                      </TableRow>)
                    })}
                  </TableBody>
                </Table>)}
              <Paper classes={classes} style={{ padding: '2%', background: 'whitesmoke' }}>
                {/* <Button onClick={event => {
                  event.preventDefault();

                  const { eligibleItems } = this.state;
                  const payload = eligibleItems.map(item => {return {'id': `${item.id}`, 'currency': `${item.currency}`, 'price': `${item.price}`}})                  

                  this.props.updatePrice(payload, this.props.getRecentUploads);
                }}
                style={{background: '#232C39', color: 'white'}}>Calculate</Button> */}
                {/* <Typography style={{fontSize: '1.2em'}}>
                  <b>Selected items total:&nbsp;</b>
                  USD. {parseFloat(this.state.eligibleItemsTotal).toFixed(2)}&nbsp;&nbsp;-&nbsp;
                  KES. {(parseFloat(this.state.eligibleItemsTotal) * exchangeRate).toFixed(2)}
                </Typography>
                <Typography style={{fontSize: '1.2em'}}>
                <b>Protection total:&nbsp;</b>
                USD. {parseFloat(this.state.protectionTotal).toFixed(2)}&nbsp;&nbsp;-&nbsp;
                KES. {(parseFloat(this.state.protectionTotal) * exchangeRate).toFixed(2)}<br/>
                {Boolean(this.state.protectionTotal)?
                  (<Button onClick={() => this.setState({paymentDialogOpen: true})}
                  style={{background: '#232C39', color: 'white'}}>Protect items</Button>)
                : (<span></span>)}
                </Typography> */}
                <Grid item xs={8}>
                  <div></div>
                </Grid>
                <Grid item xs={4}>
                  <Input placeholder="Enter amount in KES"
                    onChange={e => this.setState({ ...this.state, protectionAmountKes: e.target.value })}
                    type="number"
                  />
                  <Typography>
                    <Button
                      style={{
                        background: '#232C39',
                        color: 'white',
                        marginLeft: '40%',
                        textTransform: 'none',
                        marginBottom: '20px',
                        width: '100px',
                        margin: '5px'
                      }}
                      onClick={async () => {
                        if (this.state.checkedItems.length === 0) {
                          this.setState({ ...this.state, loading: false, errorMessage: "Select Items to protect" })
                          setTimeout(
                            () => this.setState({ ...this.state, errorMessage: "" }),
                            4000
                          )
                          return
                        }
                        if (!this.state.protectionAmountKes.trim()) {
                          this.setState({ ...this.state, loading: false, errorMessage: "Enter protection amount" })
                          setTimeout(
                            () => this.setState({ ...this.state, errorMessage: "" }),
                            4000
                          )
                          return
                        }

                        let body = {
                          "trackingNumbers": this.state.checkedItems.map(el => el.trackingNumber),
                          "amountKes": this.state.protectionAmountKes
                        }

                        let resp = await this.protectItems(body)

                        if (resp) {
                          this.setState({
                            ...this.state,
                            amountPaidUsd: resp.amountPaidUsd,
                            amountKes: resp.amountPaidKes,
                            memo: resp.memo,
                            paymentDialogOpen: true
                          })
                        }

                      }}
                    > Protect</Button>
                  </Typography>
                </Grid>

              </Paper>
            </Paper>
            <Divider />

            {/* Payment Dialog
            ******************
            ******************
            ****************/}
            <Dialog open={this.state.paymentDialogOpen} fullWidth
              onClose={() => this.setState({ paymentDialogOpen: false })}>
              <DialogTitle>Payment</DialogTitle>
              <DialogContent>
                {/* M-PESA */}
                {/* <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
                    <Typography className="invoice_pay_mpesa_logo">Pay with M-PESA</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>1. Confirm or enter your payment phone number below</Typography>
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Input value={this.state.paymentNumber} name="paymentNumber"
                      onChange={event => this.setState({ paymentNumber: event.target.value })}
                      placeholder="Phone number" style={{ margin: '3%', width: '90%' }} />
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Input value={this.state.amountKes} name="amountKes"
                      onChange={event => this.setState({ amountKes: event.target.value })}
                      placeholder="Amount" style={{ margin: '3%', width: '90%' }} />
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Typography>3. Make sure your phone is unlocked</Typography>
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Typography>4. Click the pay button below</Typography>
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Typography>5. You will be prompted to enter your M-Pesa pin to pay
                      KES {addComma(this.state.amountKes)}
                    </Typography>
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Paper className="clean_paper" style={{ textAlign: 'right' }}>
                      <Button onClick={this.onPayProtection}
                        style={{ background: 'green', color: 'white' }}>Pay</Button>
                    </Paper>
                  </ExpansionPanelDetails>
                </ExpansionPanel> */}
                {/* Fatt Merchant */}
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
                    <Typography className="invoice_pay_fatt_merchant_logo">Pay with Fatt Merchant</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>Please click the link below</Typography>
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Typography>
                      <Button style={{ background: '#EF6B20' }}>
                        <a style={{ textDecoration: 'none', color: '#00416A' }}
                          href={`https://omni.fattmerchant.com/#/pay/${code}?memo=${this.state.memo}&total=${parseFloat(this.state.amountPaidUsd).toFixed(2)}&r=${window.location.href}`}

                        >Pay</a>
                      </Button>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </DialogContent>
            </Dialog>
          </Paper>
          {successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
          {errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
          {this.state.errorMessage ? <HttpError errorMessage={this.state.errorMessage} /> : null}
          {Boolean(this.state.paypalError) ? <HttpError errorMessage={this.state.paypalError} /> : null}
          {Boolean(this.state.paypalSuccess) ? <HttpSuccess successMessage={this.state.paypalSuccess} /> : null}
        </div>
      )
    }
  }
}

export default withStyles(useStyles)(DashEligibleItems)
