import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles, Paper, LinearProgress, Card, Button, CardContent, Input, } from '@material-ui/core'
import { useStyles, AdminDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../snackbars'

import '../dashboard/Dashboard.scss'
import './Admin.scss'
import { DeleteOutline, CheckOutlined } from '@material-ui/icons'

const mql = window.matchMedia(`(max-width: 1024px)`)

class AdminMerchants extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
			profileAnchorEl: null,
			merchants: [],
			merchant: '',
		}
	}
	componentDidMount = () => {
		this.props.getMerchants()
	}
	componentWillReceiveProps = (nextProps) => {
		const {  merchants, merchantAdded } = nextProps.admin
		if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
		if (merchantAdded) {
			this.props.getMerchants()
		}
		this.setState({ merchants })
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = (event) => {
		this.setState({ profileAnchorEl: null })
	}
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
	}
	onInputChange = event => {
		this.setState({[event.target.name]: event.target.value})
	}
	onAddMerchant = () => {
		const { merchant } = this.state
		if (Boolean(merchant)) {
			this.props.addMerchant(merchant)
		}
		this.setState({merchant: ''})
	}
  render() {
		const { classes } = this.props
		const { adminHttpErrors, adminHttpSuccess, } = this.props.admin
		const AllMerchants = this.state.merchants.map(merchant => {
			return (<Grid item xs={mql.matches? 12 : 4} key={merchant.name}>
				<Card style={{margin: '2%', padding: '2%'}}>
					<Grid container spacing={0}>
						<Grid item xs={10}>
							<CardContent style={{padding: '2%'}}>{merchant.name}</CardContent>
						</Grid>
						<Grid item xs={2}>
							{merchant.verified? (
								<Button onClick={() => this.props.editMerchant(merchant.id, 'delete')}>
									<DeleteOutline htmlColor="orangered" /></Button>
							) : (<div>
								<Button onClick={() => this.props.editMerchant(merchant.id, 'verify')}>
									<CheckOutlined htmlColor="green" /></Button>
								<Button onClick={() => this.props.editMerchant(merchant.id, 'delete')}>
									<DeleteOutline htmlColor="orangered" /></Button>
							</div>)}
						</Grid>
					</Grid>
				</Card>
			</Grid>)
		})
		if (!true) {
			return (
				<div className="linear_progress">
					<LinearProgress />
				</div>
			)
		} else {
			return (
				<div>
					<AdminDrawer classes={classes} state={this.state} props={this.props}
						activeLink='merch' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
						closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
						logout={this.onLogout} />
					<Paper square={true} style={{ background: 'rgba(0,0,0,0)', boxShadow: 'none',
						paddingLeft: mql.matches? '12px' : '260px', paddingTop: '16vh'}}>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Paper className="clean_paper"
								  style={{padding: '1%', margin: '0.5%'}}>
									<Input name="merchant" onChange={this.onInputChange} value={this.state.merchant}
									  placeholder="Add merchant" className={classes.largeInput} />
									<Button onClick={this.onAddMerchant}>Add</Button>
								</Paper>
							</Grid>
							{AllMerchants}
						</Grid>
					</Paper>
					{adminHttpErrors? <HttpError errorMessage={adminHttpErrors} /> : null}
					{adminHttpSuccess? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
				</div>
			)
		}
  }
}

export default withStyles(useStyles)(AdminMerchants)
