import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Routes } from ".";


const GuestRoute = ({ component: Component, ...rest}) => {
  const { isAuthenticated, isClient, isOfficer, isAdmin } = useSelector(state => ({
    isAuthenticated: state.auth.authenticated,
    isClient: state.auth.isClient,
    isOfficer: state.auth.isOfficer,
    isAdmin: state.auth.isAdmin
  }));

  const redirectTo = () => {
      if (isClient) {
          return Routes.Dashboard
      }
      if (isOfficer) {
          return Routes.OfficerLocalOfficer
      }
      if (isAdmin) {
          return Routes.AdminDash
      }
  }

  return(
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          <div>
            <Component {...props} />
          </div>
        ) : (
            <Redirect to={redirectTo()} />
        )
      }
    />
  )};

export default GuestRoute;