import React, { useEffect, useState } from 'react'
import { HttpError, HttpSuccess } from '../snackbars'
import {
	LinearProgress,
	withStyles, Paper,
} from '@material-ui/core'
import { useStyles, DashDrawer, useStyless } from './drawer'
import { TermsAndConditions } from '../static/TermsAndC'
import './Dashboard.scss'
import { dashGetUserInfoAction } from '../../actions/DashAction'
import { useDispatch, useSelector } from 'react-redux'

const mql = window.matchMedia(`(max-width: 1024px)`)

const TermsAndC = () => {
	const dispatch = useDispatch();
	const classes = useStyless();
	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		notifications: [],
		invoices: [],
		notificationsDialogOpen: false,
	});

	useEffect(() => {
		dispatch(dashGetUserInfoAction());
	},
		// eslint-disable-next-line
		[]);

	const { recentUploads, recentUploadsSuccess, invoicesSuccess, invoices,
		successMsg, errorMsg, userInfoFetched
	} = useSelector(state => ({
		recentUploads: state.dash.recentUploads,
		recentUploadsSuccess: state.dash.recentUploadsSuccess,
		invoicesSuccess: state.dash.invoicesSuccess,
		invoices: state.dash.invoices,
		successMsg: state.dash.successMsg,
		errorMsg: state.dash.errorMsg,
		userInfoFetched: state.dash.userInfoFetched
	}))

	useEffect(() => {
		if (recentUploadsSuccess) {
			const receivedItems = []
			const shippedItems = []
			let eligibleItems = []
			recentUploads && recentUploads.map(item => {
				if (item.packageStatus === 'IN_DELAWARE') receivedItems.push(item)
				if (item.packageStatus === 'IN_TRANSIT') shippedItems.push(item)
				if (item.isEligibleForProtection === true) eligibleItems.push(item)
				return item
			})
			setState({ ...state, receivedItems, shippedItems, eligibleItems })
		}
		if (invoicesSuccess) {
			setState({ ...state, invoices })
		}
	},
	// eslint-disable-next-line
	[recentUploadsSuccess, recentUploads, invoices, invoicesSuccess]);

	// const onLogout = () => {
	// 	setState({ ...state, profileAnchorEl: null })
	// 	window.location.href = '/accounts/signin'
	// }

	const openProfileMenu = (event) => {
		setState({ ...state, profileAnchorEl: event.currentTarget })
	}

	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null })
	}
	const toggleNotifications = () => {
		setState({ ...state, notificationsDialogOpen: !state.notificationsDialogOpen })
	}

	const toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		setState({ ...state, drawerOpen: open })
	}

	if (!userInfoFetched) {
		return (
			<div className="linear_progress">
				<LinearProgress />
			</div>
		)
	} else {
		return (
			<div>
				<DashDrawer classes={classes} state={state}
					activeLink='tnc' toggleDrawer={toggleDrawer} openProfile={openProfileMenu}
					closeProfile={closeProfileMenu} profileAnchorEl={state.profileAnchorEl}
					toggleNotifications={toggleNotifications} />
				<Paper square={true} style={{
					background: 'rgba(0,0,0,0)', boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
				}}>
					<TermsAndConditions />
					{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
					{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				</Paper>
			</div>
		)
	}
}

export default withStyles(useStyles)(TermsAndC)
