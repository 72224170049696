import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, Link } from 'react-router-dom'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Routes } from '../../../routes'
import { LinearProgress } from '@material-ui/core'
import { verifyEmailAction } from '../../../actions/AccountsActions'

import '../accounts.scss'


const VerifyEmail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { access_token } = useParams();

  const { verifyEmailSuccess, successMsg, errorTrue } = useSelector(state => ({
    verifyEmailSuccess: state.auth.verifyEmailSuccess,
    successMsg: state.auth.successMsg,
    errorTrue: state.auth.errorTrue
  }));
  
  useEffect(() => {
    dispatch(verifyEmailAction(access_token))
  },
  // eslint-disable-next-line
  [])

  useEffect(() => {
    if (verifyEmailSuccess) {
      setTimeout(() => {
        history.push(Routes.Signin)
      }, 3000);
    }
  },
  // eslint-disable-next-line
  [verifyEmailSuccess])

	return (
    <div>
      {
        !verifyEmailSuccess && !errorTrue && (
          <div className="linear_progress">
            <LinearProgress />
          </div>
        )
      }
      {
        verifyEmailSuccess && (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div style={{ marginTop: '50px',  textAlign: 'center', color: '#427D3E', display: 'flex', justifyContent: 'space-around' }}>
            <span>{successMsg}</span>
            <span style={{paddingLeft: '20px'}}><CheckCircleIcon /></span>
          </div>
        </div>
        )
      }
      {
        errorTrue && (
          <div style={{paddingTop: '50px', textAlign: 'center'}}>
            <Link to={Routes.Signup}>Sign up</Link>
          </div>
        )
      }
    </div>
		)
}

export default VerifyEmail;
