import { combineReducers } from 'redux'
import { AuthReducer as auth } from './AuthReducer'
import { dashReducer as dash } from './DashReducer'
import { AdminReducer as admin } from './AdminReducer'
import { OfficerReducer as officer } from './OfficerReducer'
import { ForemanReducer as foreman } from './ForemanReducer'

const rootReducer = combineReducers({
  auth, dash, admin, officer, foreman
})

export default rootReducer;
