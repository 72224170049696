import React, { Component } from 'react'
import { withStyles, Paper, Table, TableHead,
  LinearProgress, TableRow, TableCell, Typography,
  Card, CardHeader, CardContent, Avatar,TableBody,
  Button } from '@material-ui/core'
import { useStyles, LocalOfficerDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
import TablePagination from '@material-ui/core/TablePagination';

import '../../dashboard/Dashboard.scss'
import '../../admin/Admin.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)


class LocalOfficerDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      drawerOpen: false,
      profileAnchorEl: null,
      officerInfo: {},
      archivedItems: [],
      formErrors: '',
      filterString: '',
      filterLimit: 10,
      filterSkip: 0,
      countArchived: 0
    }
  }
  componentWillReceiveProps = (nextProps) => {
    const { officerInfo, archivedItems,
       countArchived } = nextProps.officer      
    
    if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
    this.setState({ officerInfo, archivedItems,
     countArchived })
     
  }
  componentDidMount = () => {
    const { filterLimit, filterSkip } = this.state
    this.props.getOfficerInfo()
    this.props.getArchived(filterLimit, filterSkip)
  }
  onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
  openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
  }
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
    
      // return item
      // const lwh = (parseFloat(item.length) * parseFloat(item.width) * parseFloat(item.height))
      // const weight = parseFloat(item.weight)
      // if (Boolean(lwh)) {
      //   const metricWeight = (lwh / 6000)
      //   if (Boolean(weight)) {
      //     // Push the larger number
      //     payload.push({id: item.id, weight: Math.max(metricWeight, weight)})
      //   } else {
      //     // Proceed with metric weight only
      //     payload.push({id: item.id, weight: metricWeight})
      //   }
      // } else {
      //   if (Boolean(weight)) {
      //     // Proceed with weight only
      //     payload.push({id: item.id, weight})
      //   }
      // }
      // return item


  handleChangePage = (event, newPage) => {
    const { filterLimit } = this.state;

    this.setState({ page: newPage, filterSkip: newPage* filterLimit}, () => {
      const { filterLimit, filterSkip } = this.state;
      this.props.getArchived(filterLimit, filterSkip);
    })    
  };

  // handleChangeRowsPerPage = (event) => {
  //   this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 })
  // };

  render() {
    const { officerInfoFetched } = this.props.officer
    const { archivedItems } = this.state
    if (!officerInfoFetched) {
      return (
        <div className="linear_progress">
          <LinearProgress />
        </div>
      )
    } else {
      const { classes } = this.props
      const { errorMsg, successMsg } = this.props.officer

      const ShippingItemsTable = archivedItems.map((item, index) => {
        return (<TableRow key={item.id}
        >
          <TableCell>{index + 1}</TableCell>
          <TableCell>{item.client_number}</TableCell>
          <TableCell>{item.tracking_number}</TableCell>
          <TableCell>{item.shipment_number}</TableCell>
        </TableRow>)
      })
      const ShippingItemsCard = archivedItems.map((item, index) => {
        return (<Card key={item.id} style={{display: mql.matches? 'block' : 'none'
        }}>
          <CardHeader avatar={<Avatar><small>{index + 1}</small></Avatar>} />
          <CardContent style={{color: 'darkslategray'}}>
            <Typography className="admin_officers_card_p"><b>Client number:&nbsp;</b>{item.client_number}</Typography>
            <Typography className="admin_officers_card_p"><b>Tracking Number:&nbsp;</b>{item.tracking_number}</Typography>
            <Typography className="admin_officers_card_p"><b>Shipment Number:&nbsp;</b>{item.shipment_number}</Typography>
          </CardContent>
        </Card>)
      })
      return (
        <div>
          <LocalOfficerDrawer currentUser="officer" classes={classes} state={this.state} props={this.props}
            activeLink='arc' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
            closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
            logout={this.onLogout} />
          <Paper square={true} style={{ background: 'rgba(0,0,0,0)', boxShadow: 'none',
          paddingLeft: mql.matches? '0' : '240px', paddingTop: '16vh'}}>
            <Table style={{display: mql.matches? 'none' : 'block', padding: '3%', margin: '4%',
            marginBottom: '0', width: '86%', boxShadow: '0 0 4px 0 silver', fontSize: '1.2em'}}>
              
              <div style={{
                display: 'flex', justifyContent: 'space-around'
              }}>
              </div>
              <TableHead>
                <TableRow selected>
                  <TableCell></TableCell>
                  <TableCell>Client Number</TableCell>
                  <TableCell>Tracking Number</TableCell>
                  <TableCell>Shipping Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ShippingItemsTable}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={this.state.countArchived}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              rowsPerPage={this.state.rowsPerPage}
              rowsPerPageOptions={[10]}
              style={{margin: '0 auto', width: '50%'}}
            />
            {/* Mobile Screens */}
            {ShippingItemsCard}
            <Button onClick={() => this.setState({consolidateDialogOpen: true})}
              style={{background: 'darkgreen', color: 'white', position: 'fixed',
                bottom: '0', left: mql.matches? '0' : '240px', borderRadius: '0',
                display: this.state.consolidateBtnOpen? 'block' : 'none'}}>
              Consolidate items
            </Button>
            <Button onClick={this.onCheckinItems}
              style={{background: '#232C39', color: 'white', position: 'fixed',
                bottom: '0', right: '0', borderRadius: '0'}}>Checkin items
            </Button>
          </Paper>
          {errorMsg? <HttpError errorMessage={errorMsg} /> : null}
          {successMsg? <HttpSuccess successMessage={successMsg} /> : null}         
        </div>
      )
    }
  }
}


export default withStyles(useStyles)(LocalOfficerDashboard)
