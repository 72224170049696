import { connect } from 'react-redux'
import {
  adminGetWarehousesAction, adminAddUserAction,
  adminGetOfficersAction,
  adminSuspendOfficerAction, adminGetClientsAction,
  adminGetClientAction, adminGetMetricsAction,
  adminGetOfficerAction, adminEditUserAction,
  adminGetMerchatsAction, adminAddMerchatAction,
  adminEditMerchatAction, adminGetInvoicesAction,
  adminGetInvoiceAction, adminAddCreditAction,
  adminAddDebitAction,
  adminPayInvoiceAction, getRevenueAction,
  adminGetClientInvoicesAction, adminGetClientsJSONAction,
  getPaymentMethodsAction, searchClientAction, searchInvoiceAction, activateOfficerAction,
  adminGetInvoiceReceiptsAction,
  adminGetPackagesReceiptsAction,
  searchOfficerAction
} from '../actions/AdminActions'
import { dashGetUserInfoAction } from '../actions/DashAction'
import AdminDashboard from '../components/admin/Admin'
import AdminOfficers from '../components/admin/officers/Officers'
import AdminAddUser from '../components/admin/officers/AddOfficer'
import AdminEditOfficer from '../components/admin/officers/EditOfficer'
import AdminClients from '../components/admin/clients/Clients'
import AdminClient from '../components/admin/clients/Client'
import AdminInvoices from '../components/admin/Invoices'
import AdminInvoice from '../components/admin/Invoice'
import Receipts from '../components/admin/receipts/Receipts'
import Receipt from '../components/admin/receipts/Receipt'
import AdminMerchants from '../components/admin/Merchants'

export const AdminDashboardPage = connect(
  state => ({
    auth: state.auth,
    admin: state.admin,
  }),

  dispatch => ({
    getMetrics(startDate, endDate) {
      dispatch(adminGetMetricsAction(startDate, endDate))
    },
    getRevenue(selectedStartDate, selectedEndDate, limit, skip) {
      dispatch(getRevenueAction(selectedStartDate, selectedEndDate, limit, skip))
    },
    getPayments(startDate, endDate) {
      dispatch(getPaymentMethodsAction(startDate, endDate))
    }
  })
)(AdminDashboard)

export const AdminOfficersPage = connect(
  state => ({
    auth: state.auth,
    admin: state.admin,
  }),
  dispatch => ({
    getOfficers(limit, skip) {
      dispatch(adminGetOfficersAction(limit, skip))
    },
    searchOfficer(filter) {
      dispatch(searchOfficerAction(filter))
    }
  })
)(AdminOfficers)

export const AdminAddUserPage = connect(
  state => ({
    auth: state.auth,
    admin: state.admin,
  }),
  dispatch => ({
    addUser(email, firstName, lastName) {
      dispatch(adminAddUserAction(email, firstName, lastName))
    }
  })
)(AdminAddUser)


export const AdminReceiptsPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getReceipts(limit, skip) {
      dispatch(adminGetInvoiceReceiptsAction(limit, skip))
    },
    getPaymentReceipts(limit, skip) {
      dispatch(adminGetPackagesReceiptsAction(limit, skip))
    }
  })
)(Receipts)

export const AdminReceiptPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      // dispatch(dashGetUserInfoAction())
    },
    getReceipts(limit, skip) {
      dispatch(adminGetInvoiceReceiptsAction(limit, skip))
    },
    getPaymentReceipts(limit, skip) {
      dispatch(adminGetPackagesReceiptsAction(limit, skip))
    }
  })
)(Receipt)

export const AdminEditOfficerPage = connect(
  state => ({
    auth: state.auth,
    admin: state.admin,
  }),
  dispatch => ({
    getWarehouses() {
      dispatch(adminGetWarehousesAction())
    },
    getOfficer(id) {
      dispatch(adminGetOfficerAction(id))
    },
    editOfficer(id, firstName, lastName) {
      dispatch(adminEditUserAction(id, firstName, lastName))
    },
    suspendOfficer(id) {
      dispatch(adminSuspendOfficerAction(id))
    },
    activate(status, id) {
      dispatch(activateOfficerAction(status, id))
    }
  })
)(AdminEditOfficer)

export const AdminClientsPage = connect(
  state => ({
    auth: state.auth,
    admin: state.admin,
  }),
  dispatch => ({
    getClients(limit, skip) {
      dispatch(adminGetClientsAction(limit, skip))
    },
    searchClient(pattern) {
      dispatch(searchClientAction(pattern))
    },
    getClientsJSON(limit, skip) {
      dispatch(adminGetClientsJSONAction(limit, skip))
    }
  })
)(AdminClients)

export const AdminClientPage = connect(
  state => ({
    auth: state.auth,
    admin: state.admin,
  }),
  dispatch => ({
    getClient(clientNumber) {
      dispatch(adminGetClientAction(clientNumber))
    },
    getClientInvoices(clientId) {
      dispatch(adminGetClientInvoicesAction(clientId))
    }
  })
)(AdminClient)

export const AdminMerchantsPage = connect(
  state => ({
    auth: state.auth,
    admin: state.admin,
  }),
  dispatch => ({
    getMerchants() {
      dispatch(adminGetMerchatsAction())
    },
    addMerchant(name) {
      dispatch(adminAddMerchatAction(name))
    },
    editMerchant(id, action) {
      dispatch(adminEditMerchatAction(id, action))
    }
  })
)(AdminMerchants)

export const AdminInvoicesPage = connect(
  state => ({
    auth: state.auth,
    admin: state.admin,
  }),
  dispatch => ({
    getInvoices(limit, skip) {
      dispatch(adminGetInvoicesAction(limit, skip))
    },
    searchInvoice(pattern) {
      dispatch(searchInvoiceAction(pattern))
    }
  })
)(AdminInvoices)

export const AdminInvoicePage = connect(
  state => ({
    auth: state.auth,
    admin: state.admin,
  }),
  dispatch => ({
    getInvoice(id) {
      dispatch(adminGetInvoiceAction(id))
    },
    payInvoice(referenceNumber, phoneNumber, amountPaidKes, invoiceNumber, paymentType, getInvoice) {
      dispatch(adminPayInvoiceAction(referenceNumber, phoneNumber, amountPaidKes, invoiceNumber, paymentType, getInvoice))
    },
    addCredit(description, amountKes, invoiceNumber, getInvoice) {
      dispatch(adminAddCreditAction(description, amountKes, invoiceNumber, getInvoice))
    },
    addDebit(description, amountKes, invoiceNumber, getInvoice) {
      dispatch(adminAddDebitAction(description, amountKes, invoiceNumber, getInvoice))
    }
  })
)(AdminInvoice)
