import React, { Component } from 'react'
import { Routes } from '../../routes'
import { withStyles, Paper, Grid, LinearProgress, } from '@material-ui/core'
import { useStyles, OfficerDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../snackbars'

import '../dashboard/Dashboard.scss'
import '../admin/Admin.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)

class OfficerDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      profileAnchorEl: null,
      officerInfo: {},
      searchQuery: '',
      searchResults: [],
		}
  }
  componentWillReceiveProps = (nextProps) => {
    const { searchItemSuccess, searchedItemResults,
      officerInfo, officerInfoFetched, officerSuspended } = nextProps.officer
    if (officerSuspended) {
      this.props.history.push(Routes.OfficerSuspended)
    }
    // if (nextProps.auth.unauthorized) {
		// 	this.props.history.push(Routes.Signin)
		// }
    if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
    this.setState({ officerInfo })
    if (searchItemSuccess) {
      this.setState({
        searchResults: searchedItemResults,
        searchResultsDialogOpen: true,
      })
    }
    if (officerInfoFetched) {
      this.props.history.push(`/officers/${officerInfo.role}`)
    }
  }
  componentDidMount = () => {
    this.props.getOfficerInfo()
  }
  onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
  openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
  }
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
  render() {
    const { classes } = this.props
    const { errorMsg, successMsg, officerInfoFetched } = this.props.officer
    if (!officerInfoFetched) {
      return (
        <div className="linear_progress"><LinearProgress /></div>
      )
    } else {
      return (
        <div>
          <OfficerDrawer classes={classes} state={this.state} props={this.props}
            activeLink='dash' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
            closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
            logout={this.onLogout} searchFieldChange={this.searchFieldChange}
            searchItem={this.onSearchItem} />
          <Paper square={true} style={{ background: 'rgba(0,0,0,0)', boxShadow: 'none',
            paddingLeft: mql.matches? '0' : '240px', paddingTop: '10vh'}}>
            <Grid container justify="center" spacing={0}>Officers Dashboard</Grid>
          </Paper>
          {errorMsg? <HttpError errorMessage={errorMsg} /> : null}
          {successMsg? <HttpSuccess successMessage={successMsg} /> : null}
        </div>
      )
    }
  }
}

export default withStyles(useStyles)(OfficerDashboard)
