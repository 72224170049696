import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import io from "socket.io-client";
import { Routes } from './routes'
import GuestRoute from './routes/guestRoute'
import ClientRoute from './routes/clientRoute'
import OfficerRoute from './routes/officerRoute'
import AdminRoute from './routes/adminRoute'
import { LinearProgress } from '@material-ui/core'
/* Accounts and Auth */
import {
	OfficerNewPasswordPage,
	OfficerSigninPage, AdminSignInPage
} from './containers/AuthContainer'
import Signin from "./components/accounts/signin/Signin";
import Signup from "./components/accounts/signup/Signup";
import Editprofile from "./components/dashboard/editprofile/Editprofile";
import VerifyEmailPage from "./components/accounts/verify/Verifyemail";
import ForgotPassword from "./components/accounts/forgot/ForgotPassword";
import NewPassword from "./components/accounts/forgot/NewPassword";
/* Index and Dashboard */
import {
	IndexPage,
	DashboardPage, InvoicesPage,
	ChangePasswordPage, RegisteredItemsPage, EditRegisteredItemPage,
	EligibleItemsPage, TermsAndCPage, DashPricingPage, FAQsPage,
	DashMessagesPage, InvoicePage, ReceiptsPage, ReceiptPage
} from './containers/DashContainer'
import PackageHistoryClient from './components/dashboard/registereditems/packageHistoryClient';
/* Admin */
import {
	AdminDashboardPage, AdminAddUserPage,
	AdminClientsPage, AdminClientPage, AdminEditOfficerPage,
	AdminMerchantsPage, AdminInvoicesPage, AdminInvoicePage,
	AdminOfficersPage, AdminReceiptsPage, AdminReceiptPage
} from './containers/AdminContainer'
/* Officer */
import {
	OfficerDashboardPage, OfficerSuspendedPage,
	LocalOfficerDashboardPage, OfficerMessagesPage,
	OfficerInvoicesPage, OfficerInvoicePage,
	OfficerClientsPage, OfficerClientPage, OfficerArchivedPage,
	OfficerReceiptPage, OfficerReceiptsPage
} from './containers/OfficerContainer'
import PackageHistory from "./components/officer/localOfficer/packageHistory";
/* Foreman */
import { ForemanDashPage, ForemanEditOfficerPage } from './containers/ForemanContainer'
/* 404 */
import NotFound from './components/NotFound'

/* Snackbars and Errors */
import { HttpError, HttpSuccess } from './components/snackbars'
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import './App.css'

import ReactGA from 'react-ga';
import AdminVariables from './components/admin/variables/calculation'
ReactGA.initialize('UA-000000-01');
ReactGA.pageview(window.location.pathname + window.location.search);


class App extends Component {
	componentDidMount () {
		const socket = new WebSocket('wss://api.midnightshipping.com');

		const id = localStorage.getItem('profileId');

		socket.addEventListener("message", event => {
			let invoice = JSON.parse(event.data);
			if (invoice.type === 'new') {
				if (invoice.client === id) {
					window.dataLayer.push({
						'event': 'invoice_new',
						'amount': invoice.amount,
						'kg': invoice.weight,
						'id': invoice.id,
						'shipping_country': invoice.country
					})
				}
			}

			if (invoice.type === 'paid') {
				if (invoice.client === id) {
					window.dataLayer.push({
						'event': 'invoice_paid',
						'amount': invoice.amount,
						'kg': invoice.weight,
						'id': invoice.id,
						'shipping_country': invoice.country
					})
				}
			}
		});
	}
	render() {
		const isProduction = process.env.NODE_ENV === 'production';
		const { errorMsg, successMsg } = this.props.auth
		return (
			<CacheBuster
			currentVersion={version}
			isEnabled={isProduction} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			loadingComponent={<p></p>} //If not pass, nothing appears at the time of new version check.
			metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
		  >
			<div className='app'>
				<div className="linear_progress">
					<LinearProgress style={{ display: this.props.auth.fetchingData ? 'block' : 'none' }} />
				</div>
				<Switch>
					{/* Index */}
					<Route path={Routes.Home} component={IndexPage} exact />
					{/* Accounts */}
					<GuestRoute path={Routes.Signin} component={Signin} exact />
					<GuestRoute path={Routes.Signup} component={Signup} exact />
					<Route path={Routes.VerifyEmail} component={VerifyEmailPage} exact />
					<GuestRoute path={Routes.Forgotpassword} component={ForgotPassword} exact />
					<Route path={Routes.NewPassword} component={NewPassword} exact />
					{/* Dashboard */}
					<ClientRoute path={Routes.Dashboard} component={DashboardPage} exact />
					<ClientRoute path={Routes.EditProfile} component={Editprofile} exact />
					<ClientRoute path={Routes.ChangePassword} component={ChangePasswordPage} exact />
					<ClientRoute path={Routes.Receipts} component={ReceiptsPage} exact />
					<ClientRoute path={Routes.receipt} component={ReceiptPage} exact />
					<ClientRoute path={Routes.Invoices} component={InvoicesPage} exact />
					<ClientRoute path={Routes.Invoice} component={InvoicePage} exact />
					<ClientRoute path={Routes.RegisteredItems} component={RegisteredItemsPage} exact />
					<ClientRoute path={Routes.EditRegisteredItems} component={EditRegisteredItemPage} exact />
					<ClientRoute path={Routes.EligibleItems} component={EligibleItemsPage} exact />
					<ClientRoute path={Routes.Messages} component={DashMessagesPage} exact />
					<ClientRoute path={Routes.Pricing} component={DashPricingPage} exact />
					<ClientRoute path={Routes.TermsAndConditions} component={TermsAndCPage} exact />
					<ClientRoute path={Routes.FAQs} component={FAQsPage} exact />
					<ClientRoute path={Routes.DashHistory} component={PackageHistoryClient} exact />
					{/* Admin */}
					<AdminRoute path={Routes.AdminDash} component={AdminDashboardPage} exact />
					<AdminRoute path={Routes.AdminSignIn} component={AdminSignInPage} exact />
					<AdminRoute path={Routes.AdminOfficers} component={AdminOfficersPage} exact />
					<AdminRoute path={Routes.AdminAddOfficers} component={AdminAddUserPage} exact />
					<AdminRoute path={Routes.AdminEditOfficer} component={AdminEditOfficerPage} exact />
					<AdminRoute path={Routes.AdminClients} component={AdminClientsPage} exact />
					<AdminRoute path={Routes.AdminClient} component={AdminClientPage} exact />
					<AdminRoute path={Routes.AdminMerchants} component={AdminMerchantsPage} exact />
					<AdminRoute path={Routes.AdminInvoices} component={AdminInvoicesPage} exact />
					<AdminRoute path={Routes.AdminInvoice} component={AdminInvoicePage} exact />
					<AdminRoute path={Routes.AdminReceipts} component={AdminReceiptsPage} exact />
					<AdminRoute path={Routes.AdminVariables} component={AdminVariables} exact />
					<AdminRoute path={Routes.AdminReceipt} component={AdminReceiptPage} exact />
					{/* Officer */}
					<OfficerRoute path={Routes.OfficerSignin} component={OfficerSigninPage} exact />
					<OfficerRoute path={Routes.OfficerNewPassword} component={OfficerNewPasswordPage} exact />
					<OfficerRoute path={Routes.OfficerDash} component={OfficerDashboardPage} exact />
					<OfficerRoute path={Routes.OfficerSuspended} component={OfficerSuspendedPage} exact />
					<OfficerRoute path={Routes.OfficerLocalOfficer} component={LocalOfficerDashboardPage} exact />
					<OfficerRoute path={Routes.OfficerMessages} component={OfficerMessagesPage} exact />
					<OfficerRoute path={Routes.OfficerInvoices} component={OfficerInvoicesPage} exact />
					<OfficerRoute path={Routes.OfficerInvoice} component={OfficerInvoicePage} exact />
					<OfficerRoute path={Routes.OfficerReceipts} component={OfficerReceiptsPage} exact />
					<OfficerRoute path={Routes.OfficerReceipt} component={OfficerReceiptPage} exact />
					<OfficerRoute path={Routes.OfficerClients} component={OfficerClientsPage} exact />
					<OfficerRoute path={Routes.OfficerClient} component={OfficerClientPage} exact />
					<OfficerRoute path={Routes.OfficerArchived} component={OfficerArchivedPage} exact />
					<OfficerRoute path={Routes.PackageHistory} component={PackageHistory} exact />
					{/* Foreman */}
					<Route path={Routes.Foreman} component={ForemanDashPage} exact />
					<Route path={Routes.ForemanEditOfficer} component={ForemanEditOfficerPage} exact />
					{/* !exact routes */}
					<Route component={NotFound} />
				</Switch>
				{/* Error handler */}
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			</div>
		</CacheBuster>
		)
	}
}

export default connect(
	state => ({
		auth: state.auth
	})
)(withRouter(App))
