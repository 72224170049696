import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Table, TableRow, TableCell, TableHead, TableBody, Dialog,
  Typography, Button, Checkbox, DialogTitle, DialogContent, Divider, CardContent, Card, Input,
} from '@material-ui/core'
import {
  DoneOutlined, FlightTakeoff, FlightLand,
  PaymentOutlined,
} from '@material-ui/icons'
// import Moment from 'moment'
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Routes } from '../../../routes'
import TablePagination from '@material-ui/core/TablePagination';

import '../../admin/Admin.scss'
import { adminEditDeliveryDateAction, adminGetClientAction } from '../../../actions/AdminActions'
// import { it } from 'date-fns/locale'


const mql = window.matchMedia(`(max-width: 1024px)`)

/* Sanitized Items */
export const SanitizedItems = ({ sorted, registeredItems, deleteItem, markDelivered }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const [count, setCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [packages, setPackages] = useState([]);
  const [openDates, setOpenDates] = useState(false);
  const [formErrors] = useState("");
  const [dateInput, setDateInput] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const { isClient, isOfficer, isAdmin } = useSelector(state => ({
    isClient: state.auth.isClient,
    isOfficer: state.auth.isOfficer,
    isAdmin: state.auth.isAdmin
  }));

  const redirectTo = () => {
    if (isClient) {
      return Routes.DashHistory;
    }
    if (isOfficer) {
      return Routes.PackageHistory;
    }
    if (isAdmin) {
      return;
    }
  }

  const itemStatus = (status, client) => {

    if (status === 'IN_DELAWARE') {
      return (<Typography className="align_icons" style={{ color: 'orange' }}>
        <DoneOutlined htmlColor="orange" />&nbsp;Arrived in the US warehouse
      </Typography>)
    }
    if (client) {
      if (status === 'IN_KENYA' || status === 'IN_NAIROBI' || status === 'BEING_PROCESSED') {
        return (<Typography className="align_icons" style={{ color: 'magenta' }}>
          <AutorenewIcon htmlColor="magenta" />&nbsp;At Customs
        </Typography>)
      }
    } else {
      if (status === 'IN_KENYA') {
        return (<Typography className="align_icons" style={{ color: 'red' }}>
          <FlightLand htmlColor="red" />&nbsp;In Kenya
        </Typography>)
      }
      if (status === 'IN_NAIROBI') {
        return (
          <Typography className="align_icons" style={{ color: 'cyan' }}>
            <DoneOutlined htmlColor="cyan" />&nbsp;In Nairobi
          </Typography>)
      }
      if (status === 'BEING_PROCESSED') {
        return (<Typography className="align_icons" style={{ color: 'magenta' }}>
          <AutorenewIcon htmlColor="magenta" />&nbsp;Being Processed
        </Typography>)
      }
    }
    if (status === 'IN_TRANSIT') {
      return (<Typography className="align_icons" style={{ color: 'blue' }}>
        <FlightTakeoff htmlColor="blue" />&nbsp;In transit
      </Typography>)
    }

    if (status === 'AWAITING_PAYMENT') {
      return (<Typography className="align_icons" style={{ color: 'darkslategray' }}>
        <PaymentOutlined htmlColor="darkslategray" />&nbsp;Awaiting Payment
      </Typography>)
    }
    if (status === 'PENDING_DELIVERY') {
      return (<Typography className="align_icons" style={{ color: 'purple' }}>
        <FlightTakeoff htmlColor="purple" />&nbsp;Pending Delivery
      </Typography>)
    }
    if (status === 'DELIVERED') {
      return (
        <Typography className="align_icons" style={{ color: 'green' }}>
          <DoneOutlined htmlColor="green" />&nbsp;Delivered
        </Typography>)
    }
    return (<Typography className="align_icons">
      {status}
    </Typography>)
  }

  // const validateDate = (date) => {
  //   return date.replace("th, ", "")
  //     .replace("st, ", "")
  //     .replace("rd, ", "")
  // }
  // const archivedFalse = registeredItems.filter(item => item.archived === false);
   // let archivedFalse = registeredItems && registeredItems.length > 0 ? registeredItems.sort((a, b) => {
  //   return Moment(validateDate(a.estimatedPackageArrivalDate)).isBefore(validateDate(b.estimatedPackageArrivalDate)) ? 1 : -1
  // }
  // ) : [];

  let archivedFalse = [];
  const toReverse = registeredItems && registeredItems.length > 0 ? [...registeredItems] : [];

  if (sorted) {
    archivedFalse = registeredItems;
  } else {
    archivedFalse = toReverse.reverse();
  }

  useEffect(() => {
    setCount(archivedFalse.length);
    console.log(count);
  },
  // eslint-disable-next-line
  [archivedFalse])

  const Items = archivedFalse && archivedFalse.map(item => {
    if (mql.matches) {
      return (<TableRow key={item.id}>
        {item.packageStatus === 'registered' ?
          (<TableCell>
            <span onClick={() => history.push(redirectTo().replace(":id", `${item.id}`))} style={{ cursor: 'pointer', color: 'blue' }}>{item.trackingNumber}</span><br />
            <span style={{ color: 'gray' }}>
              {/* Registered:&nbsp;{Moment(item.createdAt).format('LL')} */}
            </span><br />
            {item.shipmentNumber}<br />
            {itemStatus(item.packageStatus, isClient)}&nbsp;&nbsp;
            <Link to={`/dashboard/registered_items/edit/${item.id}`}
              style={{ textDecoration: 'none', color: 'blue', fontSize: '1.2em' }}>Edit item</Link>
            &nbsp;&nbsp;
            <Button onClick={() => deleteItem(item.id)}
              style={{ textTransform: 'none', color: 'orangered' }}>Delete</Button>
          </TableCell>)
          : (<TableCell>
            <span onClick={() => history.push(redirectTo().replace(":id", `${item.id}`))} style={{ cursor: 'pointer', color: 'blue' }}>{item.trackingNumber}</span><br />
            <span style={{ color: 'gray' }}>
              {/* Registered:&nbsp;{Moment(item.createdAt).format('LL')} */}
            </span><br />
            {item.shipmentNumber}<br />
            {itemStatus(item.packageStatus, isClient)}
          </TableCell>)}
        <TableCell>
          {item.estimatedPackageArrivalDate}
        </TableCell>

        {item.packageStatus === 'paid' ?
          <TableCell>
            <button onClick={() => {
              markDelivered(item.id)
            }}>
              Mark Delivered
            </button>
          </TableCell> :
          <TableCell>

          </TableCell>}
          {
            isAdmin && (
              <TableCell>
              <Checkbox color="primary" checked={packages.includes(item.trackingNumber)}
                onClick={(e) => {
                  e.preventDefault();
                  if (packages.includes(item.trackingNumber)) {
                    let spread = [...packages];
                    let index = spread.indexOf(item.trackingNumber);
                    spread.splice(index, 1);
                    setPackages(spread);
                  } else {
                    let spread = [...packages];
                    spread.push(item.trackingNumber);
                    setPackages(spread);
                  }
                }}
              />
            </TableCell>
            )
          }
      </TableRow>)
    }
    return (<TableRow key={item.id}>
      {item.packageStatus === 'registered' ?
        (<TableCell>
          <span onClick={() => history.push(redirectTo().replace(":id", `${item.id}`))} style={{ cursor: 'pointer', color: 'blue' }}>{item.trackingNumber}</span><br />

          <span style={{ color: 'gray' }}>
            {/* Registered:&nbsp;{Moment(item.createdAt).format('LL')} */}
          </span><br />
          <Link to={`/dashboard/registered_items/edit/${item.id}`}
            style={{ textDecoration: 'none', color: 'blue', fontSize: '1.2em' }}>Edit item</Link>
          &nbsp;&nbsp;
          <Button onClick={() => deleteItem(item.id)}
            style={{ textTransform: 'none', color: 'orangered' }}>Delete</Button>
        </TableCell>)
        : (<TableCell>
          <span onClick={() => history.push(redirectTo().replace(":id", `${item.id}`))} style={{ cursor: 'pointer', color: 'blue' }}>{item.trackingNumber}</span><br />
          <span style={{ color: 'gray' }}>
            {/* Registered:&nbsp;{Moment(item.createdAt).format('LL')} */}
          </span><br />
        </TableCell>)}
      <TableCell>{item.shipmentNumber}</TableCell>
      <TableCell>{itemStatus(item.packageStatus, isClient)}</TableCell>
      <TableCell>
        {item.estimatedPackageArrivalDate}
      </TableCell>
      {item.packageStatus === 'paid' ?
        <TableCell>
          <button onClick={() => {
            markDelivered(item.id)
          }}>
            Mark Delivered
        </button>
        </TableCell> :
        <TableCell>

        </TableCell>}
        {
          isAdmin && (
            <TableCell>
          <Checkbox color="primary" checked={packages.includes(item.trackingNumber)}
            onClick={(e) => {
              e.preventDefault();
              if (packages.includes(item.trackingNumber)) {
                let spread = [...packages];
                let index = spread.indexOf(item.trackingNumber);
                spread.splice(index, 1);
                setPackages(spread);
              } else {
                let spread = [...packages];
                spread.push(item.trackingNumber);
                setPackages(spread);
              }
            }}
          />
        </TableCell>
          )
        }
    </TableRow>)
  })

  if (mql.matches) {
    return (
      <>
        <Dialog
          open={openDates}
          fullWidth
          onClose={() => setOpenDates(false)}>
                  <DialogTitle style={{ color: 'blue' }}>Update Delivery Date</DialogTitle>
                <DialogContent>
                  {packages.map((item, index) => {
                    return (<div key={index}>
                      <Typography style={{ padding: '2%' }}>{index + 1}.&nbsp;{item}</Typography>
                      <Divider />
                    </div>)
                  })}
                </DialogContent>
                <Card style={{
                    marginTop: '6%', boxShadow: 'none', borderRadius: '0',
                    borderLeft: '2px solid blue'
                  }}>
                    <CardContent>
                      <form onSubmit={(e) => {
                        e.preventDefault();

                        const payload = {
                          trackingNumbers: packages,
                          delivery_date: dateInput
                        };
                        dispatch(adminEditDeliveryDateAction(payload, adminGetClientAction, params.id, setPackages, setOpenDates));
                      }}>
                        <Input type="date" placeholder="Date" name="date"
                          onChange={(e) => {
                            setDateInput(e.target.value);
                          }}
                          style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0, }}
                        />
                        <Typography style={{ color: 'orangered' }}>
                          {formErrors}
                        </Typography>
                        <Button type="submit"
                          style={{ background: '#232C39', color: 'white', margin: '2%' }}>
                          Change Date
                        </Button>
                      </form> 
                    </CardContent>
                </Card>
        </Dialog>
        <Table style={{ padding: '2%', background: 'white' }}>
          <TableHead>
            <TableRow selected>
              <TableCell>Tracking Number</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{Items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}</TableBody>
        </Table>
        <TablePagination
          component='div'
          count={archivedFalse.length}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5]}
          onChangeRowsPerPage={(p) => {
            console.log(p)
            setRowsPerPage(p)
          }}
        // style={{ margin: '0 auto', width: '100%' }}
        />
        <div>
        <div>
          {
            packages.length > 0 && (
            <Button
              style={{
                background: 'darkgreen', color: 'white', position: 'fixed',
                bottom: '0', left: mql.matches ? '0' : '240px', borderRadius: '0',
                //display: this.state.consolidateBtnOpen ? 'block' : 'none'
              }}
              onClick={() => {
                setOpenDates(!openDates);
              }}
            >
              Change Date
            </Button>
            )}
        </div>
      </div>
      </>
    )
  }
  return (
    <>
      <Dialog
        open={openDates}
        fullWidth
        onClose={() => setOpenDates(false)}>
                <DialogTitle style={{ color: 'blue' }}>Update Delivery Date</DialogTitle>
              <DialogContent>
                {packages.map((item, index) => {
                  return (<div key={index}>
                    <Typography style={{ padding: '2%' }}>{index + 1}.&nbsp;{item}</Typography>
                    <Divider />
                  </div>)
                })}
              </DialogContent>
              <Card style={{
                  marginTop: '6%', boxShadow: 'none', borderRadius: '0',
                  borderLeft: '2px solid blue'
                }}>
                  <CardContent>
                    <form onSubmit={(e) => {
                      e.preventDefault();

                      const payload = {
                        trackingNumbers: packages,
                        delivery_date: dateInput
                      };
                      dispatch(adminEditDeliveryDateAction(payload, adminGetClientAction, params.id, setPackages, setOpenDates));
                    }}>
                      <Input type="date" placeholder="Date" name="date"
                        onChange={(e) => {
                          setDateInput(e.target.value);
                        }}
                        style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0, }}
                      />
                      <Typography style={{ color: 'orangered' }}>
                        {formErrors}
                      </Typography>
                      <Button type="submit"
                        style={{ background: '#232C39', color: 'white', margin: '2%' }}>
                        Change Date
                      </Button>
                    </form> 
                  </CardContent>
              </Card>
      </Dialog>
      <Table style={{ padding: '2%', background: 'white' }}>
        <TableHead>
          <TableRow selected>
            <TableCell>Tracking Number</TableCell>
            <TableCell>Shipping Number</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Estimated Arrival</TableCell>
            <TableCell>Action</TableCell>
            { isAdmin && <TableCell>Select</TableCell> }
          </TableRow>
        </TableHead>
        <TableBody>{Items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}</TableBody>

      </Table>
      <TablePagination
        component='div'
        count={archivedFalse.length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10]}
        onChangeRowsPerPage={(e) => {
          setRowsPerPage(e.target.value)
        }}
      // style={{ margin: '0 auto', width: '100%' }}
      />
      <div>
        <div>
          {
            packages.length > 0 && (
            <Button
              style={{
                background: 'darkgreen', color: 'white', position: 'fixed',
                bottom: '0', left: mql.matches ? '0' : '240px', borderRadius: '0',
              }}
              onClick={() => {
                setOpenDates(!openDates);
              }}
            >
              Change Date
            </Button>
            )}
        </div>
      </div>
    </>
  )
}
