import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Routes } from '../../routes'
import { HttpError, HttpSuccess } from '../snackbars'
import { Card, withStyles, Paper, CardContent, } from '@material-ui/core'
import { useStyles, DashDrawer } from './drawer'
import {
	Accordion, AccordionItem, AccordionItemButton,
	AccordionItemHeading, AccordionItemPanel
} from 'react-accessible-accordion'
import { FAQs } from '../../data/FAQs'
import './Dashboard.scss'
import 'react-accessible-accordion/dist/fancy-example.css'

const mql = window.matchMedia(`(max-width: 1024px)`)

class DashFAQs extends Component {
	constructor(props) {
		super(props)
		this.state = {
			drawerOpen: false,
			profileAnchorEl: null,
			messages: [],
			notArrivedItems: [],
			eligibleItems: [],
			receivedItems: [],
			shippedItems: [],
			notifications: [],
			invoices: [],
			notificationsDialogOpen: false,
		}
	}
	componentWillReceiveProps = (nextProps) => {
		const { messages, recentUploads,
			recentUploadsSuccess, invoices, messageActionsSucces } = nextProps.dashboard
		if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
		if (messageActionsSucces) {
			this.props.getMessages()
		}
		if (recentUploadsSuccess) {
			const notArrivedItems = []
			const receivedItems = []
			const shippedItems = []
			let eligibleItems = []
			recentUploads.map(item => {
				if (item.status === 'registered') notArrivedItems.push(item)
				if (item.status === 'checkedIn') receivedItems.push(item)
				if (item.status === 'shipped') shippedItems.push(item)
				if (item.eligible_for_protection === true) eligibleItems.push(item)
				return item
			})
			this.setState({ notArrivedItems, receivedItems, shippedItems, eligibleItems })
		}
		this.setState({ messages, invoices })
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
	toggleNotifications = () => {
		this.setState({ notificationsDialogOpen: !this.state.notificationsDialogOpen })
	}
	toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}
	render() {
		const { successMsg, errorMsg } = this.props.dashboard
		const { classes } = this.props
		console.log(this.props);
		return (
			<div>
				<DashDrawer classes={classes} state={this.state} props={this.props}
					activeLink='faqs' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
					closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
					logout={this.onLogout} toggleNotifications={this.toggleNotifications} />
				<Paper square={true} style={{
					background: 'rgba(0,0,0,0)', boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
				}}>
					<Card style={{ margin: '4%' }}>
						<Accordion>
							{FAQs.map(FAQ => {
								return (<AccordionItem key={Math.random()}>
									<AccordionItemHeading>
										<AccordionItemButton>
											{FAQ.question}
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<div dangerouslySetInnerHTML={{ __html: FAQ.response }} />
									</AccordionItemPanel>
								</AccordionItem>)
							})}
						</Accordion>
						<CardContent>
							For any other questions, kindly send us a&nbsp;
							<Link to={Routes.Messages}>Direct mesage here</Link>&nbsp;
							or email us on <b>support@midnight.com</b>
						</CardContent>
					</Card>
				</Paper>
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
			</div>
		)
	}
}

export default withStyles(useStyles)(DashFAQs)
