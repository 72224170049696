import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Routes } from '../../../routes'
import { Button, Card, CardContent, Paper, Grid,
Input, withStyles, LinearProgress, Typography, ExpansionPanel,
ExpansionPanelDetails, ExpansionPanelSummary,
Dialog, DialogContent, DialogActions, FormControl, Select, MenuItem } from '@material-ui/core'
import ReactTooltip from 'react-tooltip';
import { ExpandMoreOutlined } from '@material-ui/icons'
import { useStyles, DashDrawer } from '../drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
import readXlsxFile from 'read-excel-file'
import Reactour from 'reactour'
import Moment from 'moment'
import Axios from 'axios'
import { baseUrl } from '../../../config';

import '../Dashboard.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)

class AddItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      profileAnchorEl: null,
      eligibleItems: [],
      receivedItems: [],
      shippedItems: [],
      notifications: [],
      invoices: [],
			notificationsDialogOpen: false,
			mobileScreen: mql.matches,
      tracking_number: '',
      price: '',
      currency: 'usd',
      opt_for_protection: false,
      hasLithium: false,
      hasToxicLiquid: false,
      bulkUploads: [], recentUploads: [],
      uploads: [],
      uploadError: '',
      uploadFormsBExpanded: false,
      addItemTourOpen: !Boolean(localStorage.getItem('additemtour')),
      closeTourDialogOpen: false,
      bulkUploadBtnDisabled: false,
      merchants: [],
      isClosedValueinput: true,
      isOpenShipValueInput: false
    }
  }
	componentDidMount = () => {
    this.props.getUserInfo()
    this.props.getRecentUploads()
    this.getMerchants()
  }
  componentWillReceiveProps = (nextProps) => {
    const { itemUploadSuccess, recentUploadsSuccess } = nextProps.dashboard
    if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
    if (itemUploadSuccess) {
      this.setState({
        tracking_number: '', price: '', bulkUploads: [], uploadError: '',
        hasLithium: false, hasToxicLiquid: false, opt_for_protection: false,
        isClosedValueinput: true, isOpenShipValueInput: false, currency: 'usd'
      })
      this.props.getRecentUploads()
    }
    if (recentUploadsSuccess) {
      this.setState({ recentUploads: nextProps.dashboard.recentUploads })
    }
  }
  getMerchants = () => {
    Axios.get(`${baseUrl}/merchants`).then(snaps => {
      const merchants = []
      snaps.data.map(snap => {
        merchants.push({value: snap.name, label: snap.name})
        return snap
      })
      this.setState({merchants})
    })
  }
  onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
  openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
  }
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
  toggleNotifications = () => {
		this.setState({ notificationsDialogOpen: !this.state.notificationsDialogOpen })
	}
  handleFormInputChange = (event) => {
    this.setState({
			[event.target.name]: event.target.value
		})
  }
  toggleTourDismissDialog = () => {
		this.setState({closeTourDialogOpen: !this.state.closeTourDialogOpen})
	}
	hideTourForever = () => {
		localStorage.setItem('additemtour', `${Moment()}`)
		this.setState({closeTourDialogOpen: !this.state.closeTourDialogOpen})
  }
  onHelp = () => {
    this.setState({ addItemTourOpen: true })
  }

  handleFormFileChange = event => {
    const { userInfo } = this.props.dashboard
    this.setState({ bulkUploads: [], uploadError: '', bulkUploadBtnDisabled: false })
    readXlsxFile(event.target.files[0]).then(items => {
      items.splice(0, 1)
      const mappedItems = [];
      items.map(item => {
        // let trackingNumber = `${item[0]}`
        // if (Boolean(item[1])) trackingNumber = `${item[0]}. (Color: ${item[1]})`
        // if (Boolean(item[2])) trackingNumber = `${item[0]}. (Size: ${item[2]})`
        // if (Boolean(item[2]) && Boolean(item[1])) trackingNumber = `${item[0]}. (Color: ${item[1]}, Size: ${item[2]})`
        if (!Boolean(item[0]) || !Boolean(item[1]) || !Boolean(item[2]) || !Boolean(item[3])) {
          return this.setState({
            uploadError: "All the required fields must be filled",
            bulkUploadBtnDisabled: true
          });
        }

        if (Boolean(item[4]) && !(item[3].toLowerCase() === 'yes')) {
          return this.setState({
            uploadError: "Shipment value needed only when you opt for protection",
            bulkUploadBtnDisabled: true
          });
        }

        if (!Boolean(item[4]) && (item[3].toLowerCase() === 'yes')) {
          return this.setState({
            uploadError: "Shipment value needed since you have opted for protection",
            bulkUploadBtnDisabled: true
          });
        }

        const singleItem = {
          tracking_number: item[0], hasLithium: (item[1].toLowerCase() === 'yes'),
          hasToxicLiquid: (item[2].toLowerCase() === 'yes'), opt_for_protection: (item[3].toLowerCase() === 'yes'),
          price: item[4], client_number: userInfo.client_number, clientId: userInfo.id  }

          mappedItems.push(singleItem)
          return item
        })

      this.setState({ bulkUploads: mappedItems })
    }).catch(err => {
      this.setState({ uploadError: '* Please select a spreadsheet' })
      console.log(err)
    })
  }

  onBulkUpload = event => {
    event.preventDefault();

    document.getElementById('bulkUploadFile').value = ''

    const { bulkUploads } = this.state

    if (this.state.bulkUploads.length === 0) {
      this.setState({ uploadError: '* You file is empty, Kindly add items.' })
      return
    } else {
      const payload = JSON.stringify(bulkUploads)
      this.props.bulkUpload(payload)
    }
  }
  onUploadMany = () => {
    const { uploads } = this.state
    const payload = JSON.stringify(uploads)
    this.props.bulkUpload(payload)
  }

  onSingleUpload = event => {
    event.preventDefault()
    this.setState({ uploadError: '' })

    const { tracking_number, hasLithium, hasToxicLiquid, opt_for_protection, price, isClosedValueinput, currency } = this.state
    // if (currency === '') {
    //   this.setState({ uploadError: 'Please select a currency under price *' })
    //   return
    // }

  if (tracking_number === '') {
    return this.setState({uploadError: 'Please input your tracking number'});
  }

  if (!isClosedValueinput && price === '') {
    return this.setState({uploadError: 'Please input the price value'});
  }

  // userInfo.client_number

  const { userInfo } = this.props.dashboard;
  const client_number = userInfo.client_number;
  const clientId = userInfo.id;

  this.props.singleUpload(
    tracking_number, hasLithium, hasToxicLiquid, opt_for_protection, price,
    client_number, clientId, currency
  )

  }
  // handleMerchantChange = (newValue, actionType) => {
  //   if (actionType.action === 'select-option') {
  //     this.setState({merchant: newValue.label || ''})
  //   }
  // }
  // handleMerchantInputChange = (inputValue, actionType) => {
  //   if (actionType.action === 'input-change') {
  //     this.setState({merchant: inputValue || ''})
  //   }
  // }
  // handleCurrencyChange = event => {
  //   this.setState({
  //     [event.target.name]: event.target.value
  //   })
  // }
  // onCreateMerchant = value => {
  //   const { merchants } = this.state
  //   this.setState({
  //     merchant: value,
  //     merchants: merchants.concat({
  //       value: value, label: value
  //     })
  //   }, () => {
  //     Axios.post(`${baseUrl}/merchants`, {name: value})
  //   })
  // }

  render() {
    const { classes } = this.props
    const { tracking_number, price, opt_for_protection, isClosedValueinput,
              mobileScreen, hasLithium, hasToxicLiquid, isOpenShipValueInput, currency } = this.state
    // const uploadSteps = ['Item descrption', 'Item quantity', 'Item price', 'Store link']
    //const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    // const date = new Date();

    const { errorMsg, successMsg, userInfoFetched, userInfo } = this.props.dashboard

    if (!userInfoFetched) {
      return (
        <div className="linear_progress">
          <LinearProgress />
        </div>
      )
    } else {
      const RecentItemsContent = this.state.recentUploads.slice(0, 10).map((item, index) => {
        const status = ['registered', 'checkedIn', 'shipping', 'arrived', 'paid', 'delivered']
        const colors = ['red', 'green', 'purple', 'blue', 'blue', 'darkslategray']
        const colorIndex = status.indexOf(item.status)
        const color = colors[colorIndex]
        return (
          <CardContent key={item.id}>
            <Grid container spacing={0}>
              <Grid item xs={1}>{index + 1}</Grid>
              <Grid item xs={1}><div style={{width: '2px', height: '100%', background: `${color || 'gray'}`}}></div></Grid>
              <Grid item xs={10}>
                <b>Tracking Number:&nbsp;</b>{item.tracking_number}<br />{item.price ? (<span><b>Price:&nbsp;</b>{item.price}<br/></span>) : null}
                {item.status === 'registered'?
                  (<Link to={`/dashboard/registered_items/edit/${item.id}`}
                  style={{textDecoration: 'none', color: 'blue', fontSize: '1.2em'}}>Edit</Link>)
                : ''}
              </Grid>
            </Grid>
          </CardContent>
        )
      })
      return (
        <div>

          <DashDrawer classes={classes} state={this.state} props={this.props}
            activeLink='add' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
            closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
            logout={this.onLogout} toggleNotifications={this.toggleNotifications}
            onHelp={this.onHelp} />
          <Paper square={true} style={{ background: 'rgba(0,0,0,0)', boxShadow: 'none',
            paddingLeft: mql.matches? '0' : '240px', paddingTop: '10vh'}}>
            <Grid container spacing={0}>
              <Grid item xs={mobileScreen? 12 : 6}>
                <h3 style={{paddingLeft: '35px'}}>Start a shipment.</h3>
                <Card style={{margin: '6%', borderRadius: '12px', padding: '2%'}}>
                  {/* singleItem upload */}
                <CardContent style={{paddingBottom: '80px'}}>
                  <h4>Track using tracking number</h4>
                  <div>
                    <p style={{textAlign: 'center'}}>Enter tracking number to keep track of your goods till delivered to you</p>
                    <form onSubmit={this.onSingleUpload}>
                      <div style={{textAlign: 'center', marginBottom: '10px'}}>
                        <input
                          style={{
                            border: '1px solid #428bca',
                            borderRadius: '4px',
                            height: '30px',
                            width: '60%',
                            padding: '5px',
                            fontSize: '16px'
                          }}
                          type='text'
                          name='tracking_number'
                          value={tracking_number}
                          onChange={this.handleFormInputChange}
                          /><br />
                      </div>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
                        <span>
                          <p>
                            Does the package contain any lithium ?<br/>
                          (laptops, phone, bluetooth devices e.t.c.)
                          </p>
                        </span>
                        <span style={{float: 'right', width: '25%'}}>
                          <span>
                            <label>Yes</label>
                            <input
                              type="checkbox"
                              checked={hasLithium}
                              onClick={() => {
                                this.setState({hasLithium: !hasLithium})
                              }}
                              data-tip data-for='lithiumExtras'
                              />
                              <ReactTooltip id='lithiumExtras' type='success' effect='solid' place='right'>
                                <span>I understand that this option incurs<br /> an additional $15 charge for handling<br /> of hazardous goods</span>
                              </ReactTooltip>
                          </span>
                          <span style={{paddingLeft: '20px'}}>
                            <label>No</label>
                            <input
                              type="checkbox"
                              checked={!hasLithium}
                              onClick={() => {
                                this.setState({hasLithium: !hasLithium})
                              }}
                              />
                          </span>
                        </span>
                      </div>

                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
                        <span>
                          <p>
                            Does the package contain any toxic liquids or<br />
                            flammables ?<br />
                            (bleach, perfumes e.t.c.)
                          </p>
                        </span>
                        <span style={{float: 'right', width: '25%'}}>
                          <span>
                            <label>Yes</label>
                            <input
                              type="checkbox"
                              checked={hasToxicLiquid}
                              onClick={() => {
                                this.setState({hasToxicLiquid: !hasToxicLiquid})
                              }}
                              data-tip data-for='toxicExtras'
                              />
                            <ReactTooltip id='toxicExtras' type='success' effect='solid' place='right'>
                                <span>I understand that this option incurs<br /> an additional $15 charge for handling<br /> of hazardous goods</span>
                              </ReactTooltip>
                          </span>
                          <span style={{paddingLeft: '20px'}}>
                            <label>No</label>
                            <input
                              type="checkbox"
                              checked={!hasToxicLiquid}
                              onClick={() => {
                                this.setState({hasToxicLiquid: !hasToxicLiquid})
                              }}
                              />
                          </span>
                        </span>
                      </div>

                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
                        <span>
                          <p>
                            Would you like to purchase Midnight<br/>
                            shipment protection<br />
                          (Purchase Protection can be purchased <br />
                            after your item arrives at our warehouse.<br />
                            You will be notified at that time.)
                          </p>
                        </span>
                        <span style={{float: 'right', width: '25%'}}>
                          <span>
                            <label>Yes</label>
                            <input
                              checked={opt_for_protection}
                              type="checkbox"
                              onClick={() => {
                                this.setState({opt_for_protection: !opt_for_protection})
                                this.setState({isClosedValueinput: !isClosedValueinput})
                                this.setState({isOpenShipValueInput: !isOpenShipValueInput})
                              }}
                              />
                          </span>
                          <span style={{paddingLeft: '20px'}}>
                            <label>No</label>
                            <input
                              checked={!opt_for_protection}
                              type="checkbox"
                              onClick={() => {
                                this.setState({opt_for_protection: !opt_for_protection})
                                this.setState({isClosedValueinput: !isClosedValueinput})
                                this.setState({isOpenShipValueInput: !isOpenShipValueInput})
                              }}
                              />
                          </span>
                        </span>
                      </div>
                      {isOpenShipValueInput ? (
                        <div style={{textAlign: 'center'}}>
                          <label style={{padding: '5px'}}>Enter value of the shipment</label><br />
                            <FormControl>
                              <Select
                                labelId="weight_select_label"
                                value={currency}
                                variant="outlined"
                                style={{
                                  border: '1px solid #428bca',
                                  borderRadius: '4px',
                                  height: '42px',
                                  padding: '5px'
                                }}
                                onChange={event => {
                                  this.setState({ currency: event.target.value})
                                }}>
                                <MenuItem value="usd">USD</MenuItem>
                                <MenuItem value="pound">Pound</MenuItem>
                              </Select>
                            </FormControl>
                          <input
                            name='price'
                            style={{
                              border: '1px solid #428bca',
                              borderRadius: '4px',
                              height: '30px',
                              padding: '5px'
                            }}
                            type="number"
                            value={price}
                            min='1'
                            onChange={this.handleFormInputChange}
                            disabled={isClosedValueinput}
                            />
                        </div>
                      ) : (
                        null
                      )}
                      <div style={{float: "right", paddingTop: '10px'}}>
                        <span style={{ color: 'orangered'}}>{this.state.uploadError}</span>
                          <Button
                            type="submit"
                            style={{
                              background: '#232C39',
                              color: 'white',
                              marginLeft: '40%',
                              textTransform: 'none',
                              marginBottom: '20px',
                              width: '100px',
                              margin: '5px'
                            }}
                            >
                              Upload
                          </Button>
                      </div>
                    </form>
                  </div>

                </CardContent>

                {/* <ExpansionPanel expanded={this.state.uploadFormsAExpanded}
                  onChange={() => this.setState({
                    uploadFormsAExpanded: !this.state.uploadFormsAExpanded,
                    uploadFormsBExpanded: false
                  })} className="rt_single_item_upload">
                  <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
                    <Typography style={{fontSize: '1.2em', color: 'blue'}}>Single item upload</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <form onSubmit={this.onSingleUpload}>
                      <Stepper activeStep={this.state.activeStep} orientation="vertical">
                        <Step>
                          <StepLabel><span style={{fontSize: '1.4em'}}>Item description</span></StepLabel>
                          <StepContent>
                            <Typography>Please copy and paste the name of the item from
                              the site you bought it from.
                            </Typography>
                            <Input value={name || ''} name="name" required placeholder="Name"
                            onChange={this.handleFormInputChange} style={{width: '100%', margin: '6% 0'}} />
                            <TextField value={color || ''} name="color" required placeholder="Color" helperText="Optional"
                            onChange={this.handleFormInputChange} style={{width: '46%', margin: '1%'}} />
                            <TextField value={size || ''} name="size" required placeholder="Size" helperText="Optional"
                            onChange={this.handleFormInputChange} style={{width: '46%', margin: '1%'}} />
                            <span style={{ color: 'orangered'}}>{this.state.uploadError}</span>
                            <div>
                              <Button disabled={this.state.activeStep === 0}
                                onClick={this.handleBack}>Back</Button>
                              <Button variant="contained" color="primary" onClick={() => this.handleNext(1)}>
                                {this.state.activeStep === uploadSteps.length - 1 ? 'Finish' : 'Next'}
                              </Button>
                            </div>
                          </StepContent>
                        </Step>
                        <Step>
                          <StepLabel><span style={{fontSize: '1.4em'}}>Item quantity</span></StepLabel>
                          <StepContent>
                            <Typography>
                              Please tell us how many of <b>this</b> item you have ordered
                            </Typography>
                            <Input type="number" value={quantity || ''} name="quantity" required
                              onChange={this.handleFormInputChange} style={{width: '100%', margin: '6% 0'}} placeholder="Quantity" />
                            <span style={{ color: 'orangered'}}>{this.state.uploadError}</span>
                            <div>
                              <Button disabled={this.state.activeStep === 0}
                                onClick={this.handleBack}>Back</Button>
                              <Button variant="contained" color="primary" onClick={() => this.handleNext(2)}>
                                {this.state.activeStep === uploadSteps.length - 1 ? 'Finish' : 'Next'}
                              </Button>
                            </div>
                          </StepContent>
                        </Step>
                        <Step>
                          <StepLabel><span style={{fontSize: '1.4em'}}>Item price</span></StepLabel>
                          <StepContent>
                            <Typography>Please tell us how much it cost</Typography>
                            <Input type="number" value={price || ''} name="price" required
                              onChange={this.handleFormInputChange} style={{width: '100%', margin: '6% 0'}} placeholder="Price"
                              startAdornment={<InputAdornment position="start">
                              <AttachMoneyOutlined htmlColor="gray" /><span style={{color: 'gray'}}>&nbsp;USD</span></InputAdornment>} />
                            <span style={{ color: 'orangered'}}>{this.state.uploadError}</span>
                            <div>
                              <Button disabled={this.state.activeStep === 0}
                                onClick={this.handleBack}>Back</Button>
                              <Button variant="contained" color="primary" onClick={() => this.handleNext(3)}>
                                {this.state.activeStep === uploadSteps.length - 1 ? 'Finish' : 'Next'}
                              </Button>
                            </div>
                          </StepContent>
                        </Step>
                        <Step>
                          <StepLabel><span style={{fontSize: '1.4em'}}>Store link</span></StepLabel>
                          <StepContent>
                            <Typography>The URL link of the item from the store and the
                              name of the store
                            </Typography>
                            <Input value={link || ''} name="link" placeholder="Item URL link"
                              onChange={this.handleFormInputChange}
                              style={{width: '100%', margin: '6% 0', marginBottom: '2%'}} />
                            <ReactSelect placeholder="Store name"
                              onChange={this.handleMerchantChange}
                              onInputChange={this.handleMerchantInputChange}
                              options={this.state.merchants}
                              onCreateOption={this.onCreateMerchant}
                            />
                            <Paper className="clean_paper" style={{padding: '4%', width: '90%'}}></Paper>
                            <span style={{ color: 'orangered'}}>{this.state.uploadError}</span>
                            <div>
                              <Button disabled={this.state.activeStep === 0}
                                onClick={this.handleBack}>Back</Button>
                              <Button type="submit"
                                style={{background: '#232C39', color: 'white', marginLeft: '40%'}}>Upload</Button>
                            </div>
                          </StepContent>
                        </Step>
                      </Stepper>
                    </form>
                  </ExpansionPanelDetails>
                </ExpansionPanel> */}

                <CardContent>
                  {userInfo._addresses.length === 0?
                    (<Paper style={{padding: '1%', background: 'rgba(255,69,0,0.2)', color: 'red'}}>
                      You need a delivery address in order to start shipping.
                      Please add at least one address&nbsp;
                      <Button onClick={() => this.props.history.push(Routes.EditProfile)}
                      color="primary">here</Button>
                    </Paper>)
                  : (<span></span>)}
                </CardContent>

                <ExpansionPanel expanded={this.state.uploadFormsBExpanded}
                  onChange={() => this.setState({
                    uploadFormsBExpanded: !this.state.uploadFormsBExpanded,
                    uploadFormsAExpanded: false
                  })} className="rt_bulk_items_upload">
                  <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
                    <Typography style={{fontSize: '1.2em', color: 'blue'}}>Bulk items upload</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Card style={{boxShadow: 'none'}}>
                      <CardContent>
                        <Typography>Please download the template spreadsheet file below,
                        follow the format to fill in multiple items and then upload the file.
                        </Typography>
                        <Link to={`${process.env.PUBLIC_URL}/files/bulk upload sample file new.xlsx`}
                          style={{color: '#303E52'}} target="_blank" download>Download Template File</Link>
                        <form onSubmit={this.onBulkUpload}>
                          <Input type="file" id="bulkUploadFile" required
                            onChange={this.handleFormFileChange} style={{margin: '6% 0'}} /><br />
                          <span style={{ color: 'orangered'}}>{this.state.uploadError}</span><br/>
                            <Button
                              type="submit"
                              style={{
                                background: '#232C39',
                                color: 'white',
                                marginLeft: '40%',
                                textTransform: 'none',
                                marginBottom: '20px',
                                width: '100px',
                                margin: '5px'
                              }}
                              disabled={this.state.bulkUploadBtnDisabled}
                              >
                                Upload bulk
                            </Button>
                        </form>
                      </CardContent>
                    </Card>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                </Card>
              </Grid>

              <Grid item xs={mobileScreen? 12 : 6}>
                <h4>
                  Tracking History&nbsp;
                  <span style={{padding: '2px 6px', background: 'green', color: 'white', borderRadius: '12px'}}>
                    {this.state.recentUploads.length}
                  </span>
                </h4>
                <Card style={{margin: '6%', borderRadius: '12px', padding: '2%'}}
                  className="rt_upload_history">

                  {/*RecentItemsContent*/}
                  {RecentItemsContent}
                  {/*history content todo*/}
                  {/*<CardContent>
                   <h5>{days[date.getDay()]},&nbsp;&nbsp;{date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.{date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()}.{date.getFullYear()}</h5>
                   <div style={{display: 'flex', justifyContent: 'space-between'}}>
                     <div style={{paddingRight: '10px'}}>
                       <p>5/11</p>
                     </div>
                     <div>
                       <p style={{borderLeft: '4px solid purple', paddingLeft: '10px'}}>Package shipped from purchasing warehouse to shipment company</p>
                     </div>
                   </div>
                 </CardContent>*/}

                </Card>
              </Grid>

            </Grid>
          </Paper>
          {successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
          {errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
          <Reactour steps={reactourSteps}
            isOpen={this.state.addItemTourOpen} onBeforeClose={this.toggleTourDismissDialog}
            onRequestClose={() => this.setState({ addItemTourOpen: false })} />

          {/* Dismiss tour dialog */}
          <Dialog open={this.state.closeTourDialogOpen}
            onClose={this.toggleTourDismissDialog}>
            <DialogContent style={{paddingTop: '32px'}}>
              Dismiss the start shipping guide popup forever?
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleTourDismissDialog} color="primary">No</Button>
              <Button onClick={this.hideTourForever} color="primary" autoFocus>Yes</Button>
            </DialogActions>
          </Dialog>
        </div>




                  // <CardContent className="rt_single_item_upload">
                  //   <Table style={{margin: '2%', marginLeft: '0', background: 'whitesmoke'}}>
                  //     <TableBody>
                  //       {this.state.uploads.map((item, index) => {
                  //         return (<TableRow key={index}>
                  //           <TableCell>{index + 1}</TableCell>
                  //           <TableCell>{item.name}</TableCell>
                  //           <TableCell>
                  //             <Button onClick={() => this.onRemoveUploadItem(index)}>
                  //               <ClearOutlined htmlColor="orangered" />
                  //             </Button>
                  //           </TableCell>
                  //         </TableRow>)
                  //       })}
                  //     </TableBody>
                  //   </Table>
                  //   {Boolean(this.state.uploads.length)?
                  //     (<div>
                  //       <Button onClick={this.onUploadMany}
                  //       style={{background: 'orangered', color: 'white',
                  //       textTransform: 'none'}}>Upload {this.state.uploads.length} item(s)</Button>&nbsp;
                  //       <Button onClick={() => this.setState({ addItemDialogOpen: true })}
                  //       style={{textTransform: 'none', color: 'darkblue',
                  //       border: '1px solid darkblue'}}>Add more items before upload</Button>
                  //     </div>)
                  //   : (<Button style={{background: '#232C39', color: 'white', textTransform: 'none'}}
                  //   size="large" disabled={!Boolean(userInfo._addresses.length)}
                  //   onClick={() => this.setState({addItemDialogOpen: true})}>Single item upload</Button>)}
                  // </CardContent>


      )
    }
  }
}


const reactourSteps = [
  {
		selector: '.rt_items_upload',
    content: `Do you want to register one item or many items?`
  },
  {
    selector: '.rt_single_item_upload',
    content: `Click here and carefully fill the form to upload
              one item at a time`
  },
  {
    selector: '.rt_bulk_items_upload',
    content: `Expand this panel to upload your items file or download the sample
              file, add items, then upload`
  },
  {
    selector: '.rt_upload_history',
    content: `You can view your recently registered items here.`
  },
  {
    selector: mql.matches? '.rt_drawer_menu_icon' : '.rt_to_track_items',
    content: `Track your items!!! Get detailed information
              on the status and progress of your registered items.
              Click the Registered items button`
  }
]

export default withStyles(useStyles)(AddItem)
