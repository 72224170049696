const initialState = {
  unauthorized: false,
  successMsg: null,
  errorMsg: null,
  fetchingData: false,
  clientAuthenticated: false,
	clientSignupSuccess: false,
	verifyEmailSuccess: false,
	resetNewSuccess: false,
  dashPasswordChangeSuccess: false,
  officerSignedIn: false,
  officerVerifyEmailSuccess: false,
  officerSuspended: false,
  officerPasswordChangeSuccess: false,
  adminLoggedIn: false,
  adminVerifyEmailSuccess: false,
  isClient: false,
  isOfficer: false,
  isAdmin: false,
  authenticated: false,
  errorTrue: false
}

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UNAUTHORIZED':
      return {
        ...state,
        unauthorized: true,
        fetchingData: false,
        errorMsg: action.payload,
        successMsg: null,
        clientAuthenticated: false,
        clientSignupSuccess: false,
        verifyEmailSuccess: false,
        resetNewSuccess: false,
        dashPasswordChangeSuccess: false,
        officerSignedIn: false,
        adminLoggedIn: false,
        adminVerifyEmailSuccess: false,
      }
    case 'LOGOUT':
      return {
        ...state,
        authenticated: false,
        isClient: false,
        isOfficer: false,
        isAdmin: false
      }
    case 'HTTP_ERROR':
      return {
        ...state,
        errorMsg: action.payload,
        errorTrue: true,
        successMsg: null,
        unauthorized: false,
        fetchingData: false
      }
    /* Client
    *********
    *********
    ********/
    /* SignIn */
    case 'ACCOUNTS_CLIENT_LOGIN':
      return {
        ...state,
        clientAuthenticated: false,
        fetchingData: true,
        errorMsg: null,
        successMsg: null,
        isClient: false,
        authenticated: false
      }
    case 'ACCOUNTS_CLIENT_LOGIN_SUCCESS':
      return {
        ...state,
        unauthorized: false,
        clientAuthenticated: true,
        fetchingData: false,
        successMsg: action.payload,
        isClient: true,
        authenticated: true
      }
    /* SignUp */
    case 'ACCOUNTS_CLIENT_SIGNUP':
      return {
        ...state,
        clientSignupSuccess: false,
        fetchingData: true,
        errorMsg: null,
        successMsg: null,
      }
    case 'ACCOUNTS_CLIENT_SIGNUP_SUCCESS':
      return {
        ...state,
        clientSignupSuccess: true,
        fetchingData: false,
        successMsg: action.payload,
      }
    /* Verify Email after Signup */
    case 'ACCOUNTS_VERIFY_EMAIL':
      return {
        ...state,
        fetchingData: true,
        verifyEmailSuccess: false,
        errorMsg: null,
        successMsg: null,
      }
    case 'ACCOUNTS_VERIFY_EMAIL_SUCCESS':
      return {
        ...state,
        fetchingData: false,
        verifyEmailSuccess: true,
        successMsg: action.payload,
      }
    /* Password Reset */
    case 'ACCOUNTS_REQUEST_PASS':
      return {
        ...state,
        fetchingData: true,
        errorMsg: null,
        successMsg: null,
      }
    case 'ACCOUNTS_REQUEST_PASS_SUCCESS':
      return {
        ...state,
        fetchingData: false,
        successMsg: action.payload,
      }
    /* Password Reset set new Password */
    case 'ACCOUNTS_SET_NEW_PASS':
      return {
        ...state,
        resetNewSuccess: false,
        fetchingData: true,
        errorMsg: null,
        successMsg: null,
      }
    case 'ACCOUNTS_SET_NEW_PASS_SUCCESS':
      return {
        ...state,
        resetNewSuccess: true,
        fetchingData: false,
        successMsg: action.payload,
      }
    
    /* Officer
    **********
    **********
    *********/
    /* Sign In */
    case 'OFFICER_SIGNIN':
      return {
        ...state,
        fetchingData: true,
        officerSuspended: false,
        officerSignedIn: false,
        errorMsg: null,
        successMsg: null,
        isOfficer: false,
        authenticated: false
      }
    case 'OFFICER_SIGNIN_SUCCESS':
      return {
        ...state,
        officerSignedIn: true,
        fetchingData: false,
        successMsg: action.payload,
        isOfficer: true,
        authenticated: true
      }
    /* Verify Email */
    case 'OFFICER_VERIFY_EMAIL':
      return {
        ...state,
        fetchingData: true,
        officerVerifyEmailSuccess: false,
        errorMsg: null,
        successMsg: null,
      }
    case 'OFFICER_VERIFY_EMAIL_SUCCESS':
      return {
        ...state,
        fetchingData: false,
        officerVerifyEmailSuccess: true,
        successMsg: action.payload,
      }
    /* Change Password */
    case 'OFFICER_CHANGE_PASS':
      return {
        ...state,
        fetchingData: true,
        successMsg: null,
        errorMsg: null,
        officerPasswordChangeSuccess: false,
      }
    case 'OFFICER_CHANGE_PASS_SUCCESS':
      return {
        ...state,
        fetchingData: false,
        officerPasswordChangeSuccess: true,
        successMsg: action.payload,
      }
    /* Admin
    ********
    ********
    *******/
    /* Login */
    case 'ADMIN_SIGNIN':
      return {
        ...state,
        adminLoggedIn: false,
        errorMsg: null,
        successMsg: null,
        fetchingData: true,
        isAdmin: false,
        authenticated: false
      }
    case 'ADMIN_SIGNIN_SUCCESS':
      return {
        ...state,
        adminLoggedIn: true,
        successMsg: action.payload,
        fetchingData: false,
        isAdmin: true,
        authenticated: true
      }
    /* Verify Email */
    case 'ADMIN_VERIFY_EMAIL':
      return {
        ...state,
        fetchingData: true,
        adminVerifyEmailSuccess: false,
        errorMsg: null,
        successMsg: null,
      }
    case 'ADMIN_VERIFY_EMAIL_SUCCESS':
      return {
        ...state,
        fetchingData: false,
        adminVerifyEmailSuccess: true,
        successMsg: action.payload,
      }
    default:
      return {
        ...state
      }
  }
}