import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useStyless, LocalOfficerDrawer } from './drawer'
import StatusTemplate from "./statusTemplate";
import { officerGetStatusDatesAction } from "../../../actions/OfficerActions";


const PackageHistory = () => {
    const dispatch = useDispatch();
    const { id: packageId } = useParams();
    const classes = useStyless();

    const [state, setState] = useState({
        officerInfo: {},
        drawerOpen: false,
        profileAnchorEl: null,
    });

    const { fetchingStatusDates, statusDates } = useSelector(state => ({
        fetchingStatusDates: state.officer.fetchingStatusDates,
        statusDates: state.officer.statusDates
    }));

    useEffect(() => {
        dispatch(officerGetStatusDatesAction(packageId));
    },
    // eslint-disable-next-line
    [])

    const toggleDrawer = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return
        }
        setState({ ...state, drawerOpen: open })
        }

    const openProfileMenu = (event) => {
		setState({ ...state, profileAnchorEl: event.currentTarget })
	}

	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null })
    }

    return(
        <div style={{paddingTop: '16vh'}}>
            <LocalOfficerDrawer currentUser="officer" classes={classes} activeLink='clnts' state={state} 
                toggleDrawer={toggleDrawer} fetchingStatusDates={fetchingStatusDates} hooks={true}
                openProfile={openProfileMenu} closeProfile={closeProfileMenu}
                profileAnchorEl={state.profileAnchorEl}
            />
            <StatusTemplate statusDates={statusDates} />
        </div>
    );
}

export default PackageHistory;