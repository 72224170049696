import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Routes } from '../../../routes'
import { Button, Grid, Input, InputLabel,
	InputAdornment, } from '@material-ui/core'
import { MailOutlineSharp, LockOutlined } from '@material-ui/icons'
import { regEx } from '../../../data/data'
import { clientSignupAction } from '../../../actions/AccountsActions'

import '../signin/Signin.scss'


// const mql = window.matchMedia(`(max-width: 1024px)`)

const SignUp = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
	email: '', password: '', password_confirm: '',
	formValidationErrors: ''
  })

  const handleFormInputChange = (event) => {
    setState({
		...state,
      [event.target.name]: event.target.value,
    })
  }

	const signUpSubmit = (event) => {
		event.preventDefault()

		setState({ ...state, formValidationErrors: '' })
		const { email, password, password_confirm } = state
		const isValid = regEx.email.test(String(email).toLowerCase())
		if (email === '' || password === '') {
			setState({ ...state, formValidationErrors: '* Username and Password fields are required' })
			return
		} else if (password !== password_confirm) {
			setState({ ...state, formValidationErrors: '* Passwords must match' })
		} else if (isValid !== true) {
			setState({ ...state, formValidationErrors: '* Enter a valid email address' })
			return
		} else {
			dispatch(clientSignupAction(email, password))
		}
  }
  
	return (
      <div className="signin_container_main">
        <div className="signin_image_main"></div>
        <div className="signin_form_main">
          <div className="signin_form_logo"></div>
          <form onSubmit={signUpSubmit} className="signin_form"
					//   style={{width: '82%', padding: '0 9%'}}
					  >
            <div className="signin_form_title"
						  style={{padding: '6% 6%'}}>Create your Free account</div>
							
								{/* Email */}
								<InputLabel className="signin_label"
									htmlFor="input-with-icon-adornment">Email</InputLabel>
								<Input name="email" onChange={handleFormInputChange}
									value={state.email} className="signin_input" required
									startAdornment={
									<InputAdornment position="start"><MailOutlineSharp /></InputAdornment> } />								
						
								{/* Password */}
								<InputLabel className="signin_label"
									htmlFor="input-with-icon-adornment">Password</InputLabel>
								<Input type="password" name="password" onChange={handleFormInputChange}
									value={state.password} className="signin_input" required
									startAdornment={
									<InputAdornment position="start"><LockOutlined /></InputAdornment> } />								
							
								{/* Confirm Password */}
								<InputLabel className="signin_label"
									htmlFor="input-with-icon-adornment">Confirm Password</InputLabel>
								<Input type="password" name="password_confirm" onChange={handleFormInputChange}
									value={state.password_confirm} className="signin_input" required
									startAdornment={
									<InputAdornment position="start"><LockOutlined /></InputAdornment> } />							
					
						<div className="signin_form_errors">
              {state.formValidationErrors}
						</div>
						<div className="signin_options">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Button type="submit" style={{background: '#232C39', color: 'white'}}>Submit</Button>
								</Grid>
								<Grid item xs={12}>
									<Link to={Routes.Signin}>Already have an account? Signin</Link>
								</Grid>
							</Grid>
						</div>
          </form>
        </div>
      </div>
		);
}

export default SignUp
