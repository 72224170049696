import { connect } from 'react-redux'
import {
  officerSignInAction, officerChangePassAction,
  officerVerifyEmailAction, adminSignInAction,
  adminVerifyEmailAction
} from '../actions/AccountsActions'
import VerifyEmail from '../components/accounts/verify/Verifyemail'
// import ForgotPassword from '../components/accounts/forgot/ForgotPassword'
// import NewPassword from '../components/accounts/forgot/NewPassword'

import OfficerSignIn from '../components/officer/OfficerSignin'
import OfficerNewPassword from '../components/officer/NewPassword'

import AdminSignIn from '../components/admin/accounts/AdminSignin'


export const VerifyEmailPage = connect(
  state => ({
    auth: state.auth,
    officer: state.officer,
    admin: state.admin,
  }),
  dispatch => ({
    onVerifyOfficerEmail(uid, token) {
      dispatch(officerVerifyEmailAction(uid, token))
    },
    onVerifyAdminEmail(uid, token) {
      dispatch(adminVerifyEmailAction(uid, token))
    }
  })
)(VerifyEmail)

/* Officer
**********
**********
*********/

export const OfficerSigninPage = connect(
  state => ({
    auth: state.auth,
  }),
  dispatch => ({
    signIn(email, password) {
      dispatch(officerSignInAction(email, password))
    }
  })
)(OfficerSignIn)

export const OfficerNewPasswordPage = connect(
  state => ({
    auth: state.auth,
  }),
  dispatch => ({
    setNewPassword(oldPassword, newPassword) {
      dispatch(officerChangePassAction(oldPassword, newPassword))
    }
  })
)(OfficerNewPassword)

/* Admin
********
********
*******/

export const AdminSignInPage = connect(
  state => ({
    auth: state.auth
  }),
  dispatch => ({
    adminSignIn(email, password) {
      dispatch(adminSignInAction(email, password))
    }
  })
)(AdminSignIn)


