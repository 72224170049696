import React, { Component } from 'react'
import { Button, Grid, Paper } from '@material-ui/core'
import { Routes } from '../routes'
import '../App.css'

class NotFound extends Component {
  redirect = () => {
    if (!!localStorage.getItem('token') && !!localStorage.getItem('client')) {
      this.props.history.push(Routes.Dashboard)
    } else {
      this.props.history.push(Routes.Signup)
    }
  }
  render() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper className="not_found_logo"></Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className="not_found_text">
            Page not found
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className="not_found_button">
            <Button style={{border: '1px solid #232C39'}}
              onClick={this.redirect}>Get Started Here</Button>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default NotFound