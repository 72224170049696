import React from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import jwtDecode from "jwt-decode";
import dotenv from 'dotenv'
import App from './App'
import store from './store/store'
import { logout } from './actions/AccountsActions';
import setAuthToken from "./services/setAuthToken";
import './index.css'
// import 'bootstrap/dist/css/bootstrap.css';
// import "bootstrap";
import * as serviceWorker from './serviceWorker'

dotenv.config();

if (localStorage.token) {
	const token = localStorage.token;
	const decoded = jwtDecode(token);
	// check token expiration
	const thisTime = Date.now() / 1000;
	if (decoded.exp < thisTime) {
	  store.dispatch(logout('', 2));
	  window.location.href = "/";
	  window.location.reload(true);
	}
  
	// set login
	if (decoded.role === 'client') {
	  store.dispatch({ type: 'ACCOUNTS_CLIENT_LOGIN_SUCCESS', payload: '' });	
	} else if (decoded.role === 'officer') {
	  store.dispatch({ type: 'OFFICER_SIGNIN_SUCCESS', payload: '' });		
	} else if (decoded.role === 'admin') {
	  store.dispatch({ type: 'ADMIN_SIGNIN_SUCCESS', payload: '' })
	}
  
	// auth headers
	setAuthToken(token);
  }
ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
)

serviceWorker.unregister()

RegExp.escape = function (str) {
	return str.replace(/[[\]{}()*+?.\\|^$\-,&#\s]/g, "\\$&");
  };