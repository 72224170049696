export const Routes = {
  /* Home */
  Home: '/',
  /* Auth */
  Signin: '/accounts/signin',
  Signup: '/accounts/signup',
  Forgotpassword: '/accounts/forgot-password',
  NewPassword: '/accounts/new_password/:access_token',
  VerifyEmail: '/accounts/verify_email/:access_token',
  /* Client Dashboard */

  Dashboard: '/dashboard',
  EditProfile: '/dashboard/editprofile',
  ChangePassword: '/dashboard/changepassword',
  RegisteredItems: '/dashboard/registered_items',
  EditRegisteredItems: '/dashboard/registered_items/edit/:id',
  EligibleItems: '/dashboard/registereditems/eligible_items',
  Invoices: '/dashboard/invoices',
  Invoice: '/dashboard/invoices/:id',
  Receipts: '/dashboard/receipts',
  receipt: '/dashboard/receipts/:id',
  TermsAndConditions: '/dashboard/terms_of_use',
  Pricing: '/dashboard/pricing',
  Messages: '/dashboard/messages',
  FAQs: '/dashboard/FAQs',
  DashHistory: '/dashboard/history/:id',
  /* Admin */
  AdminDash: '/admin',
  AdminSignIn: '/accounts/admin/signin',
  AdminOfficers: '/admin/officers',
  AdminAddOfficers: '/admin/officers/add',
  AdminEditOfficer: '/admin/officers/:id',
  AdminClients: '/admin/clients',
  AdminClient: '/admin/clients/:id',
  AdminInvoices: '/admin/invoices',
  AdminInvoice: '/admin/invoices/:id',
  AdminReceipts: '/admin/receipts',
  AdminVariables: '/admin/variables',
  AdminReceipt: '/admin/receipts/:id',
  AdminMerchants: '/admin/merchants',
  /* Officer */
  OfficerDash: '/officers',
  OfficerSuspended: '/officer/suspended',
  OfficerSignin: '/accounts/officer/signin',
  OfficerNewPassword: '/officer/change_password',
  OfficerLocalOfficer: '/officers/officer',
  OfficerMessages: '/officers/messages',
  OfficerClients: '/officers/officer/clients',
  OfficerClient: '/officers/officer/clients/:id',
  OfficerInvoices: '/officers/officer/invoices',
  OfficerInvoice: '/officers/officer/invoices/:id',
  OfficerReceipts: '/officers/officer/receipts',
  OfficerReceipt: '/officers/officer/receipts/:id',
  OfficerArchived: '/officers/officer/archived',
  PackageHistory: '/officers/officer/history/:id',
  /* Foreman */
  Foreman: '/officers/foreman',
  ForemanEditOfficer: '/officers/foreman/officers/:id',
}

