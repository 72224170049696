import React from 'react'
import { Link } from 'react-router-dom'
import {
  Table, TableBody, TableCell, TableRow, Card, CardHeader,
  Avatar, Paper, Typography, Grid, CardContent, TableHead,
  Input, InputLabel, Button, Divider
} from '@material-ui/core'
const mql = window.matchMedia(`(max-width: 1024px)`)

export const OfficersListing = ({ state, currentUser }) => {
  const Officers = state.officers.map((officer, index) => {
    return (
      <TableRow key={officer.id}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          <Link to={`/${currentUser}/officers/${officer.id}`}>
            {officer.firstName}{' '}{officer.lastName}</Link>
        </TableCell>
        {/* <TableCell>{officer.createdAt}</TableCell>
        <TableCell>{officer.status}</TableCell> */}
      </TableRow>)
  })
  const OfficersCards = state.officers.map((officer, index) => {
    return (
      <Card key={officer.id} style={{
        display: mql.matches ? 'block' : 'none', margin: '2%',
        background: officer.status === 'deactivated' ? 'rgba(255,165,0,0.2)' : 'white'
      }}>
        <CardHeader avatar={<Avatar><small>{index + 1}</small></Avatar>} />
        <CardContent style={{ color: 'darkslategray' }}>
          <Typography className="admin_officers_card_p">
            <b>Name:</b>&nbsp;<Link to={`/admin/officers/${officer.id}`}>
              {officer.firstName}{' '}{officer.lastName}</Link>
          </Typography>
          {/* <Typography className="admin_officers_card_p"><b>Date Added:&nbsp;</b>{'await'}</Typography>
          <Typography className="admin_officers_card_p"><b>Status:&nbsp;</b>{'await'}</Typography> */}
        </CardContent>
      </Card>)
  })
  return (<Grid item xs={12}>
    <Paper style={{ margin: '3.2%' }}>
      <Table style={{
        display: mql.matches ? 'none' : 'block',
        background: 'white', boxShadow: '0 0 6px 0 silver'
      }}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            {/* <TableCell>Date Added</TableCell>
            <TableCell>Status</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {Officers}
        </TableBody>
      </Table>
    </Paper>
    {/* For Mobile Screens */}
    {OfficersCards}
  </Grid>)
}

export const EditOfficerForm = ({ state, props, id, onFormInputChange,
  onEditOfficer }) => {
  // const Warehouses = state.warehouses.map(warehouse => {
  //   if (currentUser === 'admin') {
  //     return (
  //       <MenuItem value={warehouse.id} key={warehouse.id} >{warehouse.name}</MenuItem>
  //     )
  //   } else {
  //     return (<div></div>)
  //   }
  // })
  return (<Grid container spacing={0}>
    <Grid item xs={12}>
      <Paper className="clean_paper"
        style={{ fontSize: '1.2em', textAlign: 'center' }}>
        EDIT OFFICER FORM
      </Paper>
      <Paper style={{
        fontSize: '1.2em', margin: '3.2% auto', padding: '2%',
        width: mql.matches ? '80%' : '50%'
      }}>
        <form onSubmit={onEditOfficer}>
          <InputLabel>First name</InputLabel>
          <Input name="firstName" onChange={onFormInputChange} required
            value={state.firstName || ''} style={{ width: '100%', marginBottom: '5%' }} />
          <InputLabel>Last name</InputLabel>
          <Input name="lastName" onChange={onFormInputChange} required
            value={state.lastName || ''} style={{ width: '100%', marginBottom: '5%' }} />
          {/* <InputLabel>Warehouse</InputLabel>
          <Select name="warehouse" onChange={onFormSelectChange} required
            disabled={currentUser === 'admin'? false : true}
            style={{width: '100%', marginBottom: '2%'}}
            value={state.warehouse || ''}>
            {Warehouses}
          </Select> */}
          <div style={{ color: 'orangered' }}>{state.formErrors}</div>
          <Button type="submit" style={{ background: '#232C39', color: 'white' }}>Submit</Button>
        </form>
        <Paper className="clean_paper" style={{ marginTop: '4%', textAlign: 'center' }}>
          <Divider />
          {/* <Button onClick={() => {
            props.changeOfficerRole(
              state.officer.id, 'foreman'
            )
          }} color="primary" style={{margin: '2%'}}>Promote</Button>
          <Button onClick={() => {
             props.changeOfficerRole(
              state.officer.id, 'officer'
            )
          }} color="default" style={{margin: '2%'}}>Demote</Button>
          <Button onClick={() => {
          props.suspendOfficer(this.state.officer.id)
          }}
            color="secondary" style={{margin: '2%'}}>Suspend</Button> */}
          {/* <Button onClick={() => {
            const status = ''
            props.activate(status, id)
          }} color="primary" style={{ margin: '2%' }}>Activate</Button> */}
        </Paper>
      </Paper>
    </Grid>
  </Grid>)
}