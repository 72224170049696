import React, { useEffect, useState } from 'react'
import { Routes } from '../../../routes'
import { Paper, makeStyles, } from '@material-ui/core'
import { useStyles, AdminDrawer } from '../drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
import { EditOfficerForm } from '../../../utils/officersUtil'
import moment from "moment";
import '../../dashboard/Dashboard.scss'
import '../Admin.scss'
import { useParams } from 'react-router'
import { useSelector } from "react-redux";

const mql = window.matchMedia(`(max-width: 1024px)`)

const AdminEditOfficer = props => {
  const [state, setState] = useState({
    drawerOpen: false,
    profileAnchorEl: null,
    firstName: '', lastName: '', email: '',
    officer: {},
    formErrors: '',
    startDate: moment().add(-30, 'days'),
    endDate: moment(),
    dateError: null
  })

  const { id } = useParams()

  useEffect(() => {
    props.getOfficer(id)
  },
  // eslint-disable-next-line
  [])

  const officer = useSelector(state => state.admin.officer)

  console.log(officer)

  useEffect(() => {
    if (officer && Object.keys(officer).length) {
      const { firstName, lastName, email } = officer

      setState({ ...state, officer, firstName, lastName, email })
    }
  },
  // eslint-disable-next-line
  [officer])

  useEffect(() => {
    const { officerAdded } = props.admin
    if (officerAdded) {
      props.history.push(Routes.AdminOfficers)
    }
  },
  // eslint-disable-next-line
  [])

  const onLogout = () => {
    setState({ ...state, profileAnchorEl: null })
  }
  const openProfileMenu = (event) => {
    setState({ ...state, profileAnchorEl: event.currentTarget })
  }
  const closeProfileMenu = () => {
    setState({ ...state, profileAnchorEl: null })
  }
  const toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setState({ ...state, drawerOpen: open })
  }
  const onFormInputChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    })
  }
  const onFormSelectChange = (event) => {
    const { name, value } = event.target
    setState({ ...state, [name]: value })
  }
  const onEditOfficer = (event) => {
    event.preventDefault()
    setState({ ...state, formErrors: '' })
    const { firstName, lastName } = state
    if (!firstName && !lastName) {
      setState({ ...state, formErrors: '* All fields are required' })
      return
    } else {
      props.editOfficer(id, firstName, lastName)
    }
  }

  const onChangeStartDate = date => {
    setState({ ...state, dateError: null })
    const { endDate } = state
    if (moment(date).isBefore(endDate)) {
      setState({ ...state, startDate: moment(date) }, () => {
        props.getMetrics(date, endDate)
      })
    } else {
      setState({ ...state, dateError: 'Start date should be any day before the end date' })
    }
  }

  const onChangeEndDate = date => {
    setState({ ...state, dateError: null })
    const { startDate } = state
    if (moment(date).isBefore(moment())) {
      if (moment(date).isAfter(startDate)) {
        setState({ ...state, endDate: moment(date) }, () => {
          props.getMetrics(startDate, date)
        })
      } else {
        setState({ ...state, dateError: 'The end date cannot be after the start date' })
      }
    } else {
      setState({ ...state, dateError: "Oops! we cannot tell the future" })
    }
  }

  const classes = makeStyles(useStyles)()
  const { adminHttpSuccess, adminHttpErrors } = props.admin

  return (
    <div>
      <AdminDrawer
        classes={classes}
        state={state}
        props={props}
        activeLink='dash'
        toggleDrawer={toggleDrawer}
        openProfile={openProfileMenu}
        closeProfile={closeProfileMenu}
        profileAnchorEl={state.profileAnchorEl}
        changeStartDate={onChangeStartDate}
        changeEndDate={onChangeEndDate}
        logout={onLogout}
      />
      <Paper square={true} style={{
        background: 'rgba(0,0,0,0)', boxShadow: 'none',
        paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
      }}>
        <EditOfficerForm state={state} props={props}
          id={id}
          onFormInputChange={onFormInputChange}
          onFormSelectChange={onFormSelectChange}
          onEditOfficer={onEditOfficer}
          currentUser="admin" />
      </Paper>
      {adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
      {adminHttpSuccess ? <HttpSuccess errorMessage={adminHttpSuccess} /> : null}
    </div>
  )

}

export default AdminEditOfficer
