import React, { Component } from 'react'
import { Routes } from '../../../routes'
import { HttpError, HttpSuccess } from '../../snackbars'
import {
	Paper, withStyles, Grid, Typography,
	FormControlLabel, Radio, Button, LinearProgress, Dialog,
	DialogTitle, DialogContent, RadioGroup, DialogActions,
	Divider, Input, ExpansionPanelSummary,
	ExpansionPanel, ExpansionPanelDetails,
} from '@material-ui/core'
import { PrintOutlined, ExpandMoreOutlined } from '@material-ui/icons'
import { format } from "date-fns";
import { useStyles, DashDrawer } from '../drawer'
import { deliveryPrices } from '../../../config'
// import PaypalExpressBtn from 'react-paypal-express-checkout'
// confiscated
import Moment from 'moment'
import '../Dashboard.scss'
import './Invoices.scss'
import { addComma } from '../../../utils/stringConverter'

const mql = window.matchMedia(`(max-width: 1024px)`)

const code = 'vitumob-llc-e1470522d902'

class Invoice extends Component {

	constructor(props) {
		super(props)
		this.state = {
			drawerOpen: false,
			profileAnchorEl: null,
			eligibleItems: [],
			receivedItems: [],
			shippedItems: [],
			notifications: [],
			invoices: [],
			invoice: {},
			deliveryRows: [],
			deliveryTotal: 0,
			deliveryAddressIndex: 0,
			deliverySet: false,
			openPayment: false,
			paymentNumber: '',
			deliveryOptionDialogOpen: false,
			paymentDialogOpen: false,
			paypalTotal: 0,
			paypalError: '',
			paypalSuccess: '',
			notificationsDialogOpen: false,
			errorNoAddress: false,
			totalDemmurage: 0,
			confiscated: false,
			hazardousGoods: 0,
			userAddress: [],
			partialPayment: 0,
			emptyPartial: false,
			totalDebitsKsh: 0,
			totalCreditsKsh: 0,
			totalPaymentsKsh: 0,
			totalDebitsUsd: 0,
			totalCreditsUsd: 0,
			totalPaymentsUsd: 0.

		}
	}
	componentWillMount = () => {
		this.props.getUserInfo()
		this.props.getInvoice(this.props.match.params.id)
		if (localStorage.invoiceLink) {
			localStorage.removeItem('invoiceLink');
		}
	}

	componentWillReceiveProps = (nextProps) => {
		const { invoice, invoices, userInfo, userAddress } = nextProps.dashboard
		if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}

		this.setState({
			invoice, invoices, userAddress,
			totalDemmurage: parseFloat(invoice.totalDemurrageFee),
			paypalTotal: parseFloat(invoice.shippingAmount),
			paymentNumber: userInfo.phoneNumber || '',
			hazardousGoods: parseFloat(invoice.dangerousGoodsFeeUsd),
			partialPayment: invoice.totalCostKes,
			deliverySet: invoice && invoice.debits && invoice.debits.some(debit => debit.description.includes("Delivery"))
		})

		if (invoice.debits) {
			let totalKsh = invoice.debits.map(debit => Number(debit.amountKes)).reduce((a, b) => { return a + b });
			let totalUsd = invoice.debits.map(debit => Number(debit.amountUsd)).reduce((a, b) => { return a + b });

			this.setState({ totalDebitsKsh: totalKsh });
			this.setState({ totalDebitsUsd: totalUsd })
		}

		if (invoice.credits) {
			let totalKsh = invoice.credits.map(credit => Number(credit.amountKes)).reduce((a, b) => { return a + b });
			let totalUsd = invoice.credits.map(credit => Number(credit.amountUsd)).reduce((a, b) => { return a + b });

			this.setState({ totalCreditsKsh: totalKsh });
			this.setState({ totalCreditsUsd: totalUsd })
		}

		if (invoice.payments) {
			let totalKsh = invoice.payments.map(payment => Number(payment.amountPaidKes)).reduce((a, b) => { return a + b });
			let totalUsd = invoice.payments.map(payment => Number(payment.amountPaidUsd)).reduce((a, b) => { return a + b });

			this.setState({ totalPaymentsKsh: totalKsh });
			this.setState({ totalPaymentsUsd: totalUsd })
		}
	}

	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}

	toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}
	toggleNotifications = () => {
		this.setState({ notificationsDialogOpen: !this.state.notificationsDialogOpen })
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
	onOpenPaymentDialog = () => {
		if (this.state.deliverySet) {
			const { userAddress } = this.state
			if (userAddress.length === 0) {
				this.setState({ errorNoAddress: true, })
				return
			}
			this.setState({ paymentDialogOpen: true })
		} else {
			this.setState({ deliveryOptionDialogOpen: true, openPayment: true })
		}

	}
	onPrintInvoice = () => {

		var content = document.getElementById("invoice_to_print")
		var iframe = document.getElementById("print_invoice_iframe").contentWindow

		// const iframe = window.open('', '', '')
		iframe.document.write('<html><head><title>Invoice</title>');
		iframe.document.write('<base href="' + window.location.origin + window.location.pathname + '">')
		iframe.document.write(`<link rel="stylesheet" href="/invoices.css" />`)
		iframe.document.write(`<style type="text/css">
		#invoice_to_print{
			background: green;
		}
		</style>`)
		iframe.document.write('</head><body >');
		iframe.document.write(content.innerHTML)
		iframe.document.write('</body></html>');

		iframe.document.close()
		console.log(iframe.document)
		iframe.focus()
		iframe.print()
	}

	onDeliveryOptionChange = async event => {
		const { userAddress } = this.state
		if (userAddress.length === 0) {
			this.setState({ errorNoAddress: true, deliveryOptionDialogOpen: false, })
			return
		}
		const { invoice } = this.state
		const { exchangeRates } = invoice.exchangeRate
		let exchangeRate = parseFloat(exchangeRates)

		const deliveryInUsd = Math.round(event.target.value) / exchangeRate
		this.setState({
			deliveryTotal: Math.round(event.target.value),
			// debit to the system
			deliverySet: true,

			paypalTotal: Math.round(Math.round(invoice.shippingAmount) + deliveryInUsd),
			deliveryRows: [{
				name: `Delivery: ${event.target.name} region`,
				quantity: '',
				price: Math.round(event.target.value)
			}]
		})
	}
	onPayInvoice = () => {
		const { userAddress, partialPayment } = this.state
		if (partialPayment === 0) {
			this.setState({ emptyPartial: true })
			return;
		} else {
			this.setState({ emptyPartial: false })
		}
		if (userAddress.length === 0) {
			this.setState({ errorNoAddress: true, paymentDialogOpen: false, })
			return
		}
		this.setState({ paymentDialogOpen: false })
		const id = this.props.match.params.id
		const mobile = this.state.paymentNumber
		const address = userAddress[this.state.deliveryAddressIndex]
		this.props.payInvoice(id, mobile, partialPayment, address)
	}
	onAddDelivery = () => {
		const { deliveryRows } = this.state;
		if (deliveryRows.length === 0) {
			return;
		}
		const payload = {};
		payload.description = deliveryRows[0].name;
		payload.amountKes = deliveryRows[0].price.toString();
		payload.invoiceNumber = this.props.match.params.id
		this.props.addDelivery(payload)
		this.setState({ deliveryOptionDialogOpen: false })
	}
	render() {
		const { classes } = this.props
		const { errorMsg, successMsg, userInfoFetched, invoiceSuccess,
			userInfo } = this.props.dashboard
		if (!userInfoFetched) {
			return (
				<div className="linear_progress">
					<LinearProgress />
				</div>
			)
		} else if (!invoiceSuccess) {
			return (<div>
				<DashDrawer classes={classes} state={this.state} props={this.props}
					activeLink='inv' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
					closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
					logout={this.onLogout} toggleNotifications={this.toggleNotifications} />
				<Paper square={true} style={{
					background: 'rgba(0,0,0,0)', boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px', paddingTop: '10vh'
				}}>
				</Paper>
			</div>)
		} else {

			let invoiceWeight = ''
			if ('consolidatedItem' in this.state.invoice) {
				invoiceWeight = this.state.invoice.consolidatedItem.map(item => Number(item.weight)).reduce((a, b) => {
					return a + b;
				}, 0)
			}

			const Regions = deliveryPrices.map(price => {
				let weight = 'consolidatedItems' in this.state.invoice ? this.state.invoice.consolidatedItems.map(item => Number(item.weight)).reduce((a, b) => {
					return a + b;

				}, 0) : invoiceWeight

				if (weight >= price.weightFrom && weight <= price.weightTo) {
					return (<Paper className="clean_paper" key={price.region}>
						<FormControlLabel value={`${price.price}`}
							onChange={this.onDeliveryOptionChange} name={`${price.region}`}
							label={`${price.region}`}
							control={<Radio color="primary" />} />
						&nbsp;&nbsp;&nbsp;Ksh. {price.price}
						<Divider />
					</Paper>)
				}
				return (<div key={Math.random()}></div>)
			})

			return (
				<div id="single-invoice">
					<DashDrawer classes={classes} state={this.state} props={this.props}
						activeLink='inv' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
						closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
						logout={this.onLogout} toggleNotifications={this.toggleNotifications} />
					<Paper square={true} style={{
						background: 'rgba(0,0,0,0)', boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px', paddingTop: '10vh'
					}}>
						{/* Invoice printing */}
						<iframe id="print_invoice_iframe" title="Print invoice"
							style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe>
						{/* The Invoice */}
						<Grid item xs={12}>
							<div className={this.state.invoice.paid ? "invoice_card_paid" : "invoice_card"}
								id="invoice_to_print">
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<Paper className="invoice_head">
											<Grid container spacing={0}>
												<Grid item xs={12} style={{ background: '#303e52', paddingTop: '20px', color: 'white', fontStyle: 'bold' }}>
													<span style={{ fontSize: '2.2em', paddingLeft: '5px' }}>Invoice</span>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 3} style={{ marginTop: '15px' }}>
													<span>
														<Paper className="dashboard_clear_drawer_logo" style={{ cursor: 'default' }}
														></Paper>
													</span>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 3} style={{ marginTop: '15px' }}>
													<span>
														Midnight Company Limited,<br />
														Storage Central Self-Storage 1st Floor,<br />
														Mombasa Road,<br />
															Nairobi.
														</span>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 5} style={{ margin: '15px' }}>
													<table style={{ width: '100%' }}>
														<tr className="issued">
															<th>
																Date Issued
														</th>
															<th>
																Invoice  #
														</th>
														</tr>
														<tr>
															<td>
																{Moment(new Date(this.state.invoice.invoiceUpdatedOn) || new Date(this.state.invoice.invoiceGenerationDate)).format('LL') || ''}
															</td>
															<td>
																{this.state.invoice.id && this.state.invoice.id.toUpperCase()}
															</td>
														</tr>
													</table>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 6} style={{ margin: '15px', marginTop: '30px' }}>
													<table style={{ width: '90%' }}>
														<tr className='billed'>
															<th>
																Bill To:
														</th>
														</tr>

														<tr>
															<td className="">
																{`${userInfo.firstName} ${userInfo.lastName}`}<br />
																{this.state.userAddress[this.state.deliveryAddressIndex].addressTown}<br />
																{userInfo.phoneNumber}<br />
															</td>
														</tr>
													</table>

												</Grid>
												<Grid item xs={mql.matches ? 12 : 3} style={{ margin: '15px', marginTop: '30px' }}>

													{
														this.state.invoice.paid || this.state.invoice.paymentStatus === "FULLY_PAID" ?
															(<>
																<img src="/paid.png" width={100} alt="" />
															</>)
															: (<Button onClick={this.onOpenPaymentDialog}
																className="invoice_options_paper_btn"
																style={{ background: '#f07829', color: "#fff" }}>Proceed to Payment</Button>)}
												</Grid>
												<Grid item xs={12} style={{ margin: '15px' }}>
													{/* Box items */}
													{'consolidatedItems' in this.state.invoice && Boolean(this.state.invoice.consolidatedItems.length) &&
														this.state.invoice.consolidatedItems.map((box, index) => {
															return (<table key={Math.random()} className="long-table" style={{ width: '100%' }}>
																<tr>
																	<th>
																		Box:&nbsp;<b>{index + 1}</b>,&nbsp;&nbsp;&nbsp;
																		Box weight:&nbsp;<b>{box.weight} Kg</b>,&nbsp;&nbsp;&nbsp;
																		Total box items:&nbsp;<b>{box.packages && box.packages.length}</b>
																	</th>
																</tr>
																{box.packages && box.packages.map((item, index) => {
																	return (<tr key={index} className="long-table-height">
																		<td>Tracking Number: &nbsp;{item.trackingNumber}</td>
																	</tr>)
																})}
															</table>)
														})}
													<table style={{ width: '100%', marginTop: '10px' }}>
														<tr className="long-table">
															<th>
																Cost Category
														</th>
															<th>
																Debit
														</th>
															<th>
																Credit
														</th>
														</tr>
														{/* {this.state.invoice._specialCharges.map((item) => {
														return (<td key={item.id}>
															<td>{item.description}</td>
															<td>USD. {item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
														</td>)}
													)} */}
														<tr>
															<td style={{ color: 'blue' }}>Total Weight -
															{
																	'consolidatedItems' in this.state.invoice && this.state.invoice.consolidatedItems.map(item => Number(item.weight)).reduce((a, b) => {
																		return a + b;
																	}, 0)
																} Kg
														</td>
														<td>Ksh. {
																addComma(Number(this.state.invoice.weightCostKes))
															} -
																	USD. {Number(this.state.invoice.weightCostUsd)}</td>
															<td></td>
														</tr>
														{('dangerousGoodsFeeKes' in this.state.invoice || 'dangerousGoodsFeeUsd' in this.state.invoice) && (
															<tr>
																<td>Hazardous Goods Fee</td>
																<td>
																	Ksh. {(this.state.invoice.dangerousGoodsFeeKes)} - USD. {this.state.invoice.dangerousGoodsFeeUsd}
																</td>
																<td></td>
															</tr>
														)}
														{this.state.invoice.debits && this.state.invoice.debits.map(debit => {
															return (
																<tr>
																	<td>{debit.description}</td>
																	<td>Ksh. {addComma(debit.amountKes)} - USD. {debit.amountUsd}</td>
																	<td></td>
																</tr>
															)
														})}
														{this.state.invoice.credits && this.state.invoice.credits.map(credit => {
															return (
																<tr>
																	<td>{credit.description}</td>
																	<td></td>
																	<td>Ksh. {addComma(credit.amountKes)} - USD. {credit.amountUsd}</td>
																</tr>
															)
														})}
														{this.state.invoice.payments && this.state.invoice.payments.map(payment => {
															return (
																<tr>
																	<td>Payment: {payment.paymentType}</td>
																	<td></td>
																	<td>Ksh. {addComma(payment.amountPaidKes)} - USD. {payment.amountPaidUsd}</td>
																</tr>
															)
														})}
														{this.state.invoice.storageCosts && this.state.invoice.storageCosts.map(cost => {
															return (
																<tr>
																	<td>Storage Cost: {format(new Date(cost.addedOn), 'dd/MM/yyyy')}</td>
																	<td>Ksh. {addComma(cost.amountKes)} - USD. {cost.amountUsd}</td>
																	<td></td>
																</tr>
															)
														})}
													</table>
												</Grid>
												{/* <Grid item xs={mql.matches ? 12 : 7} style={{ margin: '15px', marginTop: '30px' }}>
													<div>
														<p>US Pricing: <br />Up to 2 kg - $25. <br />2 kg or more - $15 per kg.</p>
													</div>
												</Grid> */}
												<Grid item xs={mql.matches ? 12 : 7} style={{ margin: '15px', marginTop: '30px' }}>
													<div>
														<h4 style={{ color: "#F07829" }}>Terms & Conditions</h4>
														<p>For our full terms and conditions - <a href="/dashboard/terms_of_use" target="_blank" rel="noopener noreferrer">here</a></p>
													</div>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 4} style={{ margin: '15px', marginTop: '30px' }}>
													<table style={{ width: '90%' }} className='inverted'>
														<tr>
															<th>
																Invoice Total (USD)
														</th>
															<td>
																<span>{this.state.invoice.totalCostUsd}</span>
															</td>
														</tr>
														<tr>
															<th>
																Invoice Total (KES)
														</th>
															<td>
																<span>{addComma(this.state.invoice.totalCostKes)}</span>
															</td>

														</tr>
													</table>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 12} style={{ margin: '15px', marginTop: '30px' }}>
													<div>
														<p>Please initiate your payment through this invoice or if your like to pay via cash transfer please use the information below:</p>
														<p>
															<i>
																Account name: Midnight Company Limited,<br />
																Bank: Equity Bank Ltd,<br />
																Branch: Fourways Towers<br />
																Kes Acc no. 0020260517036,<br />
																USD Acc no. 1450274915919,
															</i>
														</p>
														<p>
															<i>Call assistance: 0717 363433</i>
														</p>
													</div>
												</Grid>
											</Grid>
										</Paper>
									</Grid>

									<Grid item xs={12}>
										{/* <Paper className="invoice_summary_paper" style={{}}> */}
										{this.state.deliverySet ?
											(<div></div>)
											: (<div>
												<Button onClick={() => {
													this.setState({ deliveryOptionDialogOpen: true })
												}}
													style={{
														color: 'darkblue', background: 'rgba(0,0,255,0.4)',
														display: Boolean(this.state.deliveryRows.length) ? 'none' : 'block'
													}}>
													Add Delivery</Button>
											</div>)}
										{/* </Paper> */}
									</Grid>
									{this.state.errorNoAddress === true ?
										(<Grid item xs={12}>
											<Paper style={{
												margin: '2%', padding: '1%',
												background: 'rgba(255,69,0,0.2)', color: 'red'
											}}>
												You need a delivery address in order to pay for an invoice.
												Please add at least one address&nbsp;
												<Button onClick={() => this.props.history.push(Routes.EditProfile)}
													color="primary">here</Button>
											</Paper>
										</Grid>)
										: (<></>)}
									<Grid item xs={12}>
										<Paper className="invoice_options_paper">
											{this.state.invoice.paid || this.state.invoice.paymentStatus === "FULLY_PAID" ?
												(<div></div>)
												: (<Button onClick={this.onOpenPaymentDialog}
													className="invoice_options_paper_btn"
													style={{ background: '#f07829' }}>Proceed to Payment</Button>)}
											<Button className="invoice_options_paper_btn"
												onClick={this.onPrintInvoice}
												style={{ background: '#232C39' }}><PrintOutlined />&nbsp;Print</Button>
										</Paper>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Paper>
					{/* Delivery dialog */}
					<Dialog open={this.state.deliveryOptionDialogOpen}
						onClose={() => this.setState({ deliveryOptionDialogOpen: false })}>
						<DialogTitle>Region</DialogTitle>
						<DialogContent>
							<RadioGroup>
								{Regions}
							</RadioGroup>
							<FormControlLabel value={`0`}
								onChange={this.onDeliveryOptionChange} name={`Pick up `}
								label={`Pick up`}
								control={<Radio color="primary" />} />
						</DialogContent>
						<DialogActions>
							<Button color="primary"
								onClick={() => this.setState({ deliveryOptionDialogOpen: false })}>Close</Button>
							<Button color="primary"
								onClick={() => {
									this.onAddDelivery();
									if (this.state.openPayment) {
										this.setState({
											paymentDialogOpen: true
										})
									}
								}}>Add</Button>
						</DialogActions>
					</Dialog>
					{/* Payment Dialog */}
					<Dialog open={this.state.paymentDialogOpen} fullWidth
						onClose={() => this.setState({ paymentDialogOpen: false })}>
						<DialogTitle>Payment</DialogTitle>
						<DialogContent>
							{/* M-PESA */}
							<ExpansionPanel>
								<ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
									<Typography className="invoice_pay_mpesa_logo">Pay with M-PESA</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Typography>1. Confirm or enter your payment phone number below</Typography>
								</ExpansionPanelDetails>
								<ExpansionPanelDetails>
									<Input value={this.state.paymentNumber} name="paymentNumber"
										onChange={event => this.setState({ paymentNumber: event.target.value })}
										placeholder="Phone number" style={{ margin: '3%', width: '90%' }} />
								</ExpansionPanelDetails>
								<ExpansionPanelDetails>
									<Typography>2. Enter the price you wish to pay</Typography>
								</ExpansionPanelDetails>
								<ExpansionPanelDetails>
									<Input value={this.state.partialPayment} name="partialPayment"
										onChange={event => this.setState({ partialPayment: event.target.value })}
										placeholder="Enter amount" style={{ margin: '3%', width: '90%' }} />
								</ExpansionPanelDetails>
								<ExpansionPanelDetails>
									<Typography>4. Make sure your phone is unlocked</Typography>
								</ExpansionPanelDetails>
								<ExpansionPanelDetails>
									<Typography>5. Click the pay button below</Typography>
								</ExpansionPanelDetails>
								<ExpansionPanelDetails>
									<Typography>6. You will be prompted to enter your M-Pesa pin to pay
										KES. {addComma(this.state.partialPayment)}
									</Typography>
								</ExpansionPanelDetails>
								<ExpansionPanelDetails>
									<Paper className="clean_paper" style={{ textAlign: 'right' }}>
										<Button onClick={this.onPayInvoice}
											style={{ background: 'green', color: 'white' }}>Pay</Button>
									</Paper>
								</ExpansionPanelDetails>
							</ExpansionPanel>
							{/* Fatt Merchant */}
							<ExpansionPanel>
								<ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
									<Typography className="invoice_pay_fatt_merchant_logo">Pay with Credit Card</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Typography>Please click the link below</Typography>
								</ExpansionPanelDetails>
								<ExpansionPanelDetails>
									<Typography>


										<a style={{ textDecoration: 'none', color: '#00416A' }}
											href={`https://omni.fattmerchant.com/#/pay/${code}?memo=${this.state.invoice.id}&total=${this.state.invoice.totalCostUsd}&r=${window.location.href}`}
										>
											<Button style={{ background: '#EF6B20' }}> Pay				</Button>
										</a>

									</Typography>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</DialogContent>
					</Dialog>
					{this.state.emptyPartial ? <HttpError errorMessage={'The cost cannot be empty'} /> : null}
					{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
					{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				</div>
			)
		}
	}
}

export default withStyles(useStyles)(Invoice)
