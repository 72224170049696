import React, { Component } from 'react'
import {
  withStyles, Paper, LinearProgress, Table, TableCell,
  TableRow, TableHead, TableBody, Button, Card, CardHeader,
  CardContent, Typography, Avatar, Input
} from '@material-ui/core'
// import { ArrowBackIosOutlined, ArrowForwardIosRounded } from '@material-ui/icons'
import { useStyles, AdminDrawer } from '../drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
import { CSVLink } from 'react-csv';
import _ from 'lodash'
import TablePagination from '@material-ui/core/TablePagination';

import '../../dashboard/Dashboard.scss'
import '../Admin.scss'
import Axios from 'axios';
import { baseUrl } from '../../../actions/DashAction';

const mql = window.matchMedia(`(max-width: 1024px)`)

const getClientsCount = async () => {
  try {
    let data = (await Axios.get(`${baseUrl}/clients/count`)).data
    if (data.count) return data.count
    return 0
  } catch (err) {

    return 0
  }
}

function filterClients(collection, substring) {
  const clients = _.filter(collection, _.flow(
    _.identity, _.values, _.join, _.toLower, _.partialRight(
      _.includes,
      substring.replace(/\s/g, '').toLowerCase()
    )
  ))
  return clients
}

class AdminClients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      profileAnchorEl: null,
      clients: [],
      clientsJSON: [],
      clientsCSV: '',
      clientsCount: 0,
      filteredClients: [],
      filterString: '',
      filterLimit: 10,
      filterSkip: 0,
      page: 0,
      rowsPerPage: 10,
    }
  }
  componentDidMount = () => {
    const { filterLimit, filterSkip } = this.state
    getClientsCount().then(v => {
      this.setState({ ...this.state, clientsCount: v })
    })
    this.props.getClients(filterLimit, filterSkip)
    this.props.getClientsJSON(filterLimit, filterSkip)
  }

  componentWillReceiveProps = (nextProps) => {
    const { clients, clientsFetched, clientsJSON, clientsJSONFetched } = nextProps.admin
    if (nextProps.auth.unauthorized) {
      window.location.href = '/accounts/signin'
    }
    if (clientsFetched) {
      this.setState({ clients, filteredClients: clients, clientsJSON })
    }
    if (clientsJSONFetched) {
      let headers = {
        "client_number": "Client Number",
        "name": "Name",
        "mobile": "Phone Number",
        // "location": "Location",
        // "date_joined": "Date Joined",
        // "status": "Status"
      };

      let clients = clientsJSON.map(client => {
        return {
          'client_number': `${client.id}`,
          'name': `${client.firstName} ${client.lastName}`,
          'mobile': `${client.phoneNumber}`,
          // 'location': `${'null*'}`,
          // 'date_joined': `${'null*'}`,
          // 'status': `${'todo'}`
        }
      });
      clients.unshift(headers);

      this.setState({
        clientsCSV: this.ConvertToCSV(clients)
      })
    }

  }
  onLogout = () => {
    this.setState({ profileAnchorEl: null })
    window.location.href = '/accounts/signin'
  }
  openProfileMenu = (event) => {
    this.setState({ profileAnchorEl: event.currentTarget })
  }
  closeProfileMenu = (event) => {
    this.setState({ profileAnchorEl: null })
  }
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
  onFilterClients = substring => {
    this.setState({
      filterString: substring,
      filteredClients: filterClients(this.state.clients, substring)
    })
  }

  onPaginationNext = () => {
    const { filterLimit, filterSkip, clients } = this.state
    if (clients.length === 0) return
    this.setState({ filterSkip: filterSkip + filterLimit }, () => {
      const { filterLimit, filterSkip } = this.state
      this.props.getClients(filterLimit, filterSkip)
    })
  }
  onPaginationPrevious = () => {
    const { filterLimit, filterSkip } = this.state
    if (filterSkip === 0) return
    this.setState({ filterSkip: filterSkip - filterLimit }, () => {
      const { filterLimit, filterSkip } = this.state
      this.props.getClients(filterLimit, filterSkip)
    })
  }

  patternRegex = key => {
    // eslint-disable-next-line
    return `[A-Za-z.\s]*${key}[A-Za-z.\s]*`;
  }

  handleChangePage = (event, newPage) => {
    const { filterLimit } = this.state;

    this.setState({ page: newPage, filterSkip: newPage * filterLimit }, () => {
      const { filterLimit, filterSkip } = this.state;
      this.props.getClients(filterLimit, filterSkip);
    })
  };

  // Convert JSON to CSV Function
  ConvertToCSV = (objArray) => {
    let array = objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ','

        line += array[i][index];
      }

      str += line + '\r\n';
    }

    return str;
  }

  render() {
    const { classes } = this.props
    const { adminHttpErrors, adminHttpSuccess, clientsFetched } = this.props.admin
    if (!clientsFetched) {
      return (
        <div className="linear_progress">
          <LinearProgress />
        </div>
      )
    } else {
      // const Pagination = () => {
      //   const { clients, clientsCount, filterSkip, filterLimit } = this.state
      //   const pages = Math.ceil(clientsCount / clients.length)
      //   const currentPage = Math.ceil(filterSkip / filterLimit)
      //   return (<TableRow>
      //     <TableCell align="right" colSpan={6}>
      //       <span style={{color: 'darkslategray'}}>Page {currentPage + 1} of {pages}&nbsp;</span>
      //       {Boolean(currentPage)?
      //         (<Button onClick={this.onPaginationPrevious}>
      //           <ArrowBackIosOutlined htmlColor="darkslategray" />
      //         </Button>)
      //       : (<span></span>)}
      //       {Boolean(currentPage + 1 === pages)?
      //         (<span></span>)
      //       : (<Button
      //         onClick={this.onPaginationNext}>
      //         <ArrowForwardIosRounded htmlColor="darkslategray" />
      //       </Button>)}
      //     </TableCell>
      //   </TableRow>)
      // }
      return (
        <div>
          <AdminDrawer classes={classes} state={this.state} props={this.props}
            activeLink='clnts' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
            closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
            logout={this.onLogout} />
          <Paper square={true} style={{
            background: 'rgba(0,0,0,0)', boxShadow: 'none',
            paddingLeft: mql.matches ? '0' : '200px', paddingTop: '10vh'
          }}>
            <Paper style={{ margin: '3.2%', padding: '3%' }}>
              {this.state.clientsJSON.length > 0 ?
                <CSVLink style={{ float: 'right' }} data={this.state.clientsCSV}>Download as CSV</CSVLink> :
                null
              }
              <Input
                value={this.state.filterString}
                placeholder="Search clients"
                //onChange={event => {this.onFilterClients(event.target.value)}}
                onChange={event => {
                  this.setState({ filterString: event.target.value })
                }}
                onKeyUp={e => {
                  if (e.key === 'Enter' || e.keyCode === 13) {
                    const { filterString } = this.state;
                    // const keywordChange = RegExp.escape(filterString);
                    // const pattern = this.patternRegex(keywordChange);
                    this.props.searchClient(filterString);
                  }
                }}
                className={classes.searchField} />
              <Table style={{ display: mql.matches ? 'none' : 'table', width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Client Number</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Mobile</TableCell>
                    {/* <TableCell>Email</TableCell> */}
                    {/* <TableCell>Location</TableCell>
                    <TableCell>Date joined</TableCell>
                    <TableCell>Status</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.filteredClients.map((client, index) => {

                    return (<TableRow key={client.id}>
                      <TableCell>
                        <Button style={{ color: 'blue' }}
                          onClick={() => this.props.history.push(`/admin/clients/${client.id}`)}>
                          {`${client.id}`}</Button>
                      </TableCell>
                      <TableCell>{client.firstName}{' '}{client.lastName}</TableCell>
                      <TableCell>{client.phoneNumber}</TableCell>
                      {/* <TableCell>{client.email}</TableCell>
                      {client._addresses && client._addresses.length > 0 ? (
                        <TableCell>
                          {client._addresses.map(address => address.town)}
                        </TableCell>
                      ) : (<TableCell></TableCell>)} */}
                      {/* <TableCell> */}
                      {/* {Moment(client.createdAt).format('LLL')} */}
                      {/* </TableCell>
                      <TableCell>{client.status}</TableCell> */}
                    </TableRow>)
                  })}
                  {/*<Pagination />*/}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={this.state.clientsCount}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                rowsPerPage={this.state.rowsPerPage}
                rowsPerPageOptions={[10]}
                style={{ margin: '0 auto', width: '50%' }}
              />
              {/* Mobile screens */}
              {this.state.clients.map((client, index) => {
                return (<Card key={client.id} style={{ display: mql.matches ? 'block' : 'none', margin: '2%' }}>
                  <CardHeader avatar={<Avatar><small>{index + 1}</small></Avatar>} />
                  <CardContent style={{ color: 'darkslategray' }}>
                    <Typography className="admin_officers_card_p">
                      <b>Client Number:&nbsp;</b>
                      <Button style={{ color: 'blue' }}
                        onClick={() => this.props.history.push(`/admin/clients/${client.id}`)}>
                        {`${client.id}`}</Button>
                    </Typography>
                    <Typography className="admin_officers_card_p">
                      <b>Name:&nbsp;</b>{client.firstName}{' '}{client.lastName}</Typography>
                    {/* <Typography className="admin_officers_card_p">
                      <b>Email:&nbsp;</b>{client.email}</Typography> */}
                    <Typography className="admin_officers_card_p">
                      <b>Mobile:&nbsp;</b>{client.phoneNumber}</Typography>
                    {/* <Typography className="admin_officers_card_p">
                      <b>Address:&nbsp;</b>{client._addresses && client._addresses.length > 0 ? (
                        client._addresses.map(address => address.town)
                      ) : null}</Typography>
                    <Typography className="admin_officers_card_p">
                      <b>Status:&nbsp;</b>Todo:</Typography> */}
                  </CardContent>
                </Card>)
              })}
            </Paper>
          </Paper>
          {adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
          {adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
        </div>
      )
    }
  }
}

export default withStyles(useStyles)(AdminClients)
