import React, { Component } from 'react'
import { HttpError, HttpSuccess } from '../snackbars'
import {
	LinearProgress, Card,
	withStyles, Paper, Input, Button, CardHeader, Avatar,
	CardContent, CardActions, Dialog, DialogTitle,
	DialogContent, DialogActions, FormControl,
	MenuItem, InputLabel, Select
} from '@material-ui/core'
import { useStyles, DashDrawer } from './drawer'
import Moment from 'moment'
import './Dashboard.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)

class DashMessages extends Component {
	constructor(props) {
		super(props)
		this.state = {
			drawerOpen: false,
			profileAnchorEl: null,
			messages: [],
			notArrivedItems: [],
			eligibleItems: [],
			receivedItems: [],
			shippedItems: [],
			notifications: [],
			invoices: [],
			notificationsDialogOpen: false,
			newIssue: '',
			newIssueSelect: '',
			issueResponse: '',
			newIssueDialogOpen: false,
		}
	}
	componentDidMount = () => {
		this.props.getUserInfo()
		this.props.getMessages()
	}
	componentWillReceiveProps = (nextProps) => {
		const { messages, recentUploads,
			recentUploadsSuccess, invoices, messageActionsSucces } = nextProps.dashboard
		if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
		if (messageActionsSucces) {
			this.props.getMessages()
		}
		if (recentUploadsSuccess) {
			const notArrivedItems = []
			const receivedItems = []
			const shippedItems = []
			let eligibleItems = []
			recentUploads.map(item => {
				if (item.status === 'registered') notArrivedItems.push(item)
				if (item.status === 'checkedIn') receivedItems.push(item)
				if (item.status === 'shipped') shippedItems.push(item)
				if (item.eligible_for_protection === true) eligibleItems.push(item)
				return item
			})
			this.setState({ notArrivedItems, receivedItems, shippedItems, eligibleItems })
		}
		this.setState({ messages, invoices })
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
	toggleNotifications = () => {
		this.setState({ notificationsDialogOpen: !this.state.notificationsDialogOpen })
	}
	toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}
	onCreateIssue = () => {
		const { newIssue, newIssueSelect } = this.state
		if (newIssue === '') return
		this.props.createIssue(newIssue, newIssueSelect)
		this.setState({ newIssueDialogOpen: false, newIssue: '' })
	}
	onReplyToIssue = (event, id) => {
		event.preventDefault()
		this.props.replyToIssue(id, event.target[0].value)
	}
	onCloseIssue = id => {
		this.props.closeIssue(id)
	}
	render() {
		const { successMsg, errorMsg, userInfo, userInfoFetched } = this.props.dashboard
		const { classes } = this.props
		if (!userInfoFetched) {
			return (
				<div className="linear_progress">
					<LinearProgress />
				</div>
			)
		} else {
			return (
				<div>
					<DashDrawer classes={classes} state={this.state} props={this.props}
						activeLink='dash' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
						closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
						logout={this.onLogout} toggleNotifications={this.toggleNotifications} />
					<Paper square={true} style={{
						background: 'rgba(0,0,0,0)', boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
					}}>
						<Button onClick={() => this.setState({ newIssueDialogOpen: true })}
							style={{ margin: '1% 4%', background: '#232C39', color: 'white' }}>
							Add new message</Button>
						{this.state.messages.map(message => {
							return (<Card key={message.id} style={{ margin: '1% 4%', paddingBottom: '2%' }}>
								<CardHeader avatar={<Avatar>{userInfo.first_name.substring(0, 2)}</Avatar>}
									title={`${message.issue}`} style={{ color: 'blue' }}
									subheader={`${Moment(message.createdAt).format('LLL')}`} />
								{message._responses.map(response => {
									if (response.userType === 'client') {
										return (<CardContent style={{
											padding: '0.6%', margin: '0% 7%',
											background: 'rgba(0,0,255,0.05)', color: 'blue'
										}} key={response.id}>
											<b>{userInfo.first_name}:&nbsp;</b>{response.message}
										</CardContent>)
									}
									return (<CardContent style={{
										padding: '0.6%', margin: '0% 7%',
										background: 'whitesmoke', color: 'darkslategray'
									}} key={response.id}>
										<b>Officer:&nbsp;</b>{response.message}
									</CardContent>)
								})}
								<CardContent style={{ padding: '0.4% 7%', margin: '0', marginTop: '2%' }}>
									<form onSubmit={event => this.onReplyToIssue(event, message.id)}>
										<Input required placeholder="Reply to this issue"
											style={{ width: '60%', marginRight: '2%' }} />
										<Button type="submit"
											style={{ background: '#232C39', color: 'white' }}>Reply</Button>
									</form>
								</CardContent>
								<CardActions>
									<Button onClick={() => this.onCloseIssue(message.id)}
										style={{ margin: '0 6%' }} color="secondary">Close message</Button>
								</CardActions>
							</Card>)
						})}
					</Paper>
					{/* New issue Dialog */}
					<Dialog open={this.state.newIssueDialogOpen} fullWidth
						onClose={() => this.setState({ newIssueDialogOpen: false })}>
						<DialogTitle style={{ color: 'blue' }}>Message</DialogTitle>
						<DialogContent style={{ color: 'darkslategray' }}>
							Type your message or issue bellow.
							We will respond as soon as possible
						</DialogContent>
						<DialogContent>
							<FormControl style={{ marginBottom: '2%' }} fullWidth>
								<InputLabel id="issues_category_select">Category (optional)</InputLabel>
								<Select labelId="issues_category_select" value={this.state.newIssueSelect}
									onChange={event => this.setState({ newIssueSelect: event.target.value })}>
									<MenuItem value="Lost items">Lost items</MenuItem>
									<MenuItem value="Queries on cost">Queries on cost</MenuItem>
									<MenuItem value="Delivery timelines">Delivery timelines</MenuItem>
									<MenuItem value="Amendment of orders">Amendment of orders</MenuItem>
									<MenuItem value="Cancelation of orders">Cancelation of orders</MenuItem>
									<MenuItem value="General">General</MenuItem>
								</Select>
							</FormControl>
							<Input name="newIssue" value={this.state.newIssue}
								onChange={event => this.setState({ newIssue: event.target.value })}
								placeholder="Text message" style={{ marginBottom: '2%' }} fullWidth />
						</DialogContent>
						<DialogActions style={{ paddingRight: '6%' }}>
							<Button onClick={this.onCreateIssue} color="primary">Send</Button>
						</DialogActions>
					</Dialog>
					{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
					{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				</div>
			)
		}
	}
}

export default withStyles(useStyles)(DashMessages)
