import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Routes } from '../routes'
import {
  withStyles, Grid, Paper, Typography, Button,
  TextField, AppBar, Icon, FormControl, Select, MenuItem,
  Dialog, DialogTitle, DialogContent, RadioGroup,
  FormControlLabel, Radio, Tooltip, SwipeableDrawer, Modal, Fade
} from '@material-ui/core'
import { PhoneOutlined, MailOutlined, MenuOutlined, Info } from '@material-ui/icons'
import {
  Accordion, AccordionItem, AccordionItemButton,
  AccordionItemHeading, AccordionItemPanel
} from 'react-accessible-accordion'
import Axios from 'axios'
import { baseUrl } from '../config'
import { FAQs } from '../data/FAQs'
import { HttpSuccess, HttpError } from './snackbars'
import { PriceList } from './static/Pricing'
import { TermsAndConditions } from './static/TermsAndC'
// import { addComma } from '../utils/stringConverter'
import TwitterIcon from '../assets/images/twitter.svg'
import FacebookIcon from '../assets/images/facebook.svg'
import InstagramIcon from '../assets/images/instagram.svg'
import LinkedInIcon from '../assets/images/linkedin.svg'
import { logout } from '../actions/AccountsActions';
import store from '../store/store'


import './Index.scss'


const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
const mql = window.matchMedia(`(max-width: 1024px)`)
const useStyles = {
  imageIcon: { height: '36px' },
  iconRoot: { textAlign: 'center' },
  drawer: {
    backgroundColor: '#303E52',
    visibility: mql.matches ? 'visible' : 'hidden',
    width: '240px'
  }
}

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#efeffb', color: 'rgb(100,100,100)',
    boxShadow: theme.shadows[1], fontSize: 10, fontWeight: '400'
  },
}))(Tooltip)
const LightCalcTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#efeffb', color: 'rgb(100,100,100)',
    boxShadow: theme.shadows[1], fontSize: 16, fontWeight: '400'
  },
}))(Tooltip)

class IndexComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      weight: '',
      length: '',
      width: '',
      height: '',
      selectedWeight: 'Kgs',
      selectedOrigin: 'USA',
      volumetricWeight: 0,
      totalCost: 0,
      usdToKes: 0,
      gbpToKes: 0,
      contactUsDialogOpen: false,
      calcErrors: '', selectedGreaterWeight: '',
      name: '', email: '', phoneNumber: '',
      subject: '', message: '',
      httpSuccess: null, httpError: null,
      pricingDialogOpen: false,
      termsDialogOpen: false,
      faqsDialogOpen: false,
      protectionAnchorEl: null,
      ukLargeWeight: 0,
      ukLessWeight: 0,
      usaLargeWeight: 0,
      usaLessWeight: 0,
      ukExchangeRate: 0,
      drawerOpen: false,
      showModal: false,
    }
  }
  componentDidMount = () => {
    const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/api';
    Axios.get(`${baseUrl}/system-variables/exchange-rate`).then(snaps => {
      console.log(snaps.data)
      this.setState({
        usdToKes: snaps.data.exchangeRate,
        gbpToKes: snaps.data.GBP ? snaps.data.GBP : 0
      })
    }).catch(() => {
      this.setState({ calcErrors: 'An error occured while getting exchange rates.' })
    })
    Axios.get(`${baseUrl}/calculation-variables`).then(snaps => {
      this.setState({
        ukLargeWeight: snaps.data.ukLargeWeight,
        ukLessWeight: snaps.data.ukLessWeight,
        usaLargeWeight: snaps.data.usaLargeWeight,
        usaLessWeight: snaps.data.usaLessWeight,
        ukExchangeRate: snaps.data.ukExchangeRate
      })
    }).catch(() => {
      this.setState({ calcErrors: 'An error occured while getting calculation rates.' })
    })
  }
  onGetStarted = () => {
    if (this.props.auth.officerSignedIn) {
      this.props.history.push(Routes.OfficerDash)
    } else if (this.props.auth.clientAuthenticated) {
      this.props.history.push(Routes.Dashboard)
    } else {
      this.props.history.push(Routes.Signup)
    }
  }
  handleClose = () => {
    this.setState({ ...this.state, showModal: false })
  };
  onToCalculator = () => {
    this.refs.calculator.scrollIntoView()
  }
  onToCalculator2 = () => {
    this.refs.calculator2.scrollIntoView()
  }
  onResetCalculator = () => {
    this.setState({
      weight: '', length: '', width: '', height: '',
      selectedWeight: 'Kgs', selectedOrigin: 'USA',
      volumetricWeight: 0, totalCost: 0, calcErrors: '',
      selectedGreaterWeight: ''
    })
  }

  autoReset = () => {
    this.setState({
      weight: '', length: '', width: '', height: '',
      volumetricWeight: 0, totalCost: 0, calcErrors: '',
      selectedGreaterWeight: ''
    })
  }
  // onCalculateCost = () => {
  //   this.setState({ calcErrors: '', selectedGreaterWeight: '' })
  //   const { weight, length, width, height } = this.state
  //   // if only weight available
  //   if (weight !== '' && length === '' && width === '' && height === '') {
  //     return this.setState({
  //       totalCost: this.weightToPrice(weight),
  //       selectedGreaterWeight: 'Using actual weight'
  //     });
  //   }
  //   else {
  //     if (length === '' || width === '' || height === '') {
  //       return this.setState({
  //         totalCost: this.weightToPrice(weight), selectedGreaterWeight: 'Using actual weight'
  //       })
  //     } else {
  //       const volumetricWeight = (parseFloat(length) * parseFloat(width) * parseFloat(height)) / 6000
  //       if (volumetricWeight > parseFloat(weight)) {
  //         return this.setState({
  //           totalCost: this.weightToPrice(volumetricWeight),
  //           selectedGreaterWeight: 'Using volumetric weight', volumetricWeight
  //         })
  //       } else {
  //         return this.setState({
  //           totalCost: this.weightToPrice(weight),
  //           selectedGreaterWeight: 'Using actual weight', volumetricWeight
  //         })
  //       }
  //     }
  //   }
  // }

  weightToPrice = weight => {
    const { usdToKes, selectedOrigin, usaLessWeight, usaLargeWeight, ukLargeWeight, ukExchangeRate } = this.state
    const itemWeight = weight

    if (selectedOrigin === 'USA') {
      if (itemWeight > 0 && itemWeight <= 2) return parseFloat(usaLessWeight) * usdToKes
      else return (itemWeight * parseFloat(usaLargeWeight) * usdToKes);
    }

    return parseFloat(ukLargeWeight) * itemWeight * parseFloat(ukExchangeRate)
  }
  handleContactInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  onContactUs = event => {
    event.preventDefault()
    const { name, email, phoneNumber, subject, message } = this.state
    Axios.post(`${baseUrl}/contactUs`, {
      name, email, phoneNumber, subject, message
    }).then(() => {
      this.setState({ httpSuccess: 'Message sent.' })
    }).catch(() => {
      this.setState({ httpError: 'An error occured. Please retry.' })
    })
    this.setState({
      name: '', email: '', phoneNumber: '', subject: '', message: '',
      contactUsDialogOpen: false
    })
  }
  toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}
  render() {
    const { classes } = this.props;
    const { weight, length, width, height } = this.state
    return (<div>
      {/* Sidebar */}
      <SwipeableDrawer PaperProps={{ classes: { root: classes ? classes.drawer : {} } }}
        open={this.state.drawerOpen} variant={mql.matches ? 'temporary' : 'permanent'}
        onClose={this.toggleDrawer(false)}
        onOpen={this.toggleDrawer(true)}
        disableBackdropTransition={!iOS} disableDiscovery={iOS} >
          <Grid spacing={0}>
            <Grid item xs={12} className="home_toggle_icon">
              <Grid>
                <Button onClick={this.toggleDrawer(false)}>
                  <MenuOutlined htmlColor="#ffffff" style={{ display: mql.matches ? 'block' : 'none' }} />
                </Button>
              </Grid>
              <Grid>
                <Paper className="clean_paper home_app_bar_logo"></Paper>
              </Grid>
            </Grid><br/>
            <Grid item xs={8}>
              <Paper className="clean_paper swipeable_sect">
                <Button style={{ textTransform: 'none', color: '#ffffff' }}
                  onClick={() => this.setState({ contactUsDialogOpen: true })}>Contact Us</Button>;
                <Button style={{ textTransform: 'none', color: '#ffffff' }}
                  onClick={() => {
                    this.onToCalculator2();
                    this.setState({ drawerOpen: false })
                    }}>Cost Calculator</Button>
                <Link to={Routes.FAQs} style={{ textDecoration: 'none', color: '#ffffff', padding: ' 10px 0 0 10px' }}>FAQs</Link>         
              </Paper>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      {/* App bar
      ***********
      ***********
      *********/}
      {/* <AppBar position="fixed" style={{ background: 'whitesmoke' }}> */}
      <AppBar position='fixed' className="home_appbars" style={{
        background: 'whitesmoke', color: '#232C39'
      }}>
        <Grid container spacing={0} justify="start"
          alignContent="center" alignItems="center">
          <Grid item xs={6} className="home_toggle_icon">
            <Grid>
              <Button onClick={this.toggleDrawer(true)} className="rt_drawer_menu_home">
                <MenuOutlined htmlColor="#303E52" style={{ display: mql.matches ? 'block' : 'none' }} />
              </Button>
            </Grid>
            <Grid>
              <Paper className="clean_paper home_app_bar_logo"></Paper>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Paper className="clean_paper" style={{ textAlign: 'right', paddingRight: '8%' }}>
            <span style={ mql.matches ? { display: 'none' }: {} }>
            <Button style={{ textTransform: 'none', color: '#232C39' }}
                onClick={() => this.setState({ contactUsDialogOpen: true })}>Contact Us</Button>&nbsp;&nbsp;
              <Button style={{ textTransform: 'none', color: '#232C39' }}
                onClick={this.onToCalculator}>Cost Calculator</Button>&nbsp;&nbsp;
            <Link to={Routes.FAQs} style={{ textDecoration: 'none' }}>FAQs</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;</span>
            {Boolean(localStorage.getItem('token')) ?
                (<Button onClick={() => {
                  store.dispatch(logout('', 2))
                  window.location.href = '/accounts/signin'
                }}
                  style={{ textTransform: 'none', background: '#232C39', color: 'white' }}>Logout</Button>)
                : (<Button onClick={() => window.location.href = '/accounts/signin'}
                  style={{ textTransform: 'none', background: '#232C39', color: 'white' }}>Login</Button>)}
            </Paper>
          </Grid>
        </Grid>
      </AppBar>
      {/* Main card
      *************
      *************
      ***********/}
      <Grid container spacing={0}
        alignContent="center" alignItems="center"
        className="index_grid_main">
        <Grid item xs={12} className="main_paper"
          style={{ margin: mql.matches ? '0%' : '0%', marginTop: '24vh' }}>
          <Grid container spacing={0}>
            <Modal
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
              }}
              onClose={this.handleClose} open={this.state.showModal}>
              <Fade in={this.state.showModal}>
                <div style={
                  {
                    padding: 24,
                    minHeight: 150,
                    border: 'none',
                    borderRadius: 8,
                    background: '#fff',
                    minWidth: 300,
                    maxWidth: 500,
                    textAlign: 'center',
                    outline: 'none'
                  }
                }>
                  <div>
                    <Info
                      fontSize={"large"}
                      color={"crimson"} />
                    <h4>Dear esteemed customer!</h4>
                    <p>
                      Due to KRA increased customs clearance charges for the importers of consolidated cargo, we would like to advise you that there will be an increase in our shipping rate to be put into effect immediately.
                    </p>
                    <p>The new shipping rates are as below:</p>
                    <p style={{textAlign: 'left'}}>
                      From the US: <b>2kgs and below at $30; Above 2kgs, @$16.5 per kg.</b>
                      <br/>From the UK: <b>14.5 pounds per kg.</b>
                    </p>
                    <p>
                      However, if an item is hazardous, we charge an extra fee of $15 from the US and £20 from the UK. Hazardous are electronic items, Lithium-ion batteries, alcohol-based perfumes, and makeup that are categorized as dangerous goods, or anything considered hazardous according to IATA.
                      <br/>For any further clarification or assistance, please call us at; <a style={{ color: '#232C39', textDecoration: 'none' }} href="tel:0780363433">0780363433</a>/<a style={{ color: '#232C39', textDecoration: 'none' }} href="tel:0780363433">07171363433</a>
                    </p>
                    <Button style={{
                      background: "#f3742a"
                    }} onClick={this.handleClose}>
                      Ok
                    </Button>
                  </div>
                </div>
              </Fade>


            </Modal>
            {/* <Grid item xs={12}>
              <Typography variant="h4" style={{ paddingBottom: '4%', color: 'rgb(89,89,89)' }}>
                Ship and track your package directly from North America
                or Europe to your doorstep in East Africa.
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              {/* <Typography variant="h6" style={{ paddingRight: '4%', marginBottom: '15px' }}>
                Shipping has never been this easy and convenient.
              </Typography> */}
              <Grid item xs={12}>
                <Button onClick={this.onGetStarted}
                  style={{ background: '#232C39', color: 'white', textTransform: 'none' }}>
                  {Boolean(localStorage.getItem('token')) ? 'Go to Dashboard' : 'Get Started'}</Button>
                  <Button onClick={this.onToCalculator}
                  style={{color: '#232C39', textTransform: 'none', marginLeft: '10px', textDecoration: 'underline' }}>
                  Calculate Your Costs</Button>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} alignContent="center" alignItems="center" justify="center"
          style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Grid xs={12} class="slider">
            <Grid xs={mql.matches ? 12 : 4} className='vprop'>
              Get your packages in 3 easy steps!
            </Grid>
            <Grid className='vsects'>
              <div className="sect1">
                <h1 className='step'>
                  1
                </h1>
                <span>
                  Sign up on<br /> our platform
                </span>
                <div className="sect-border"></div>
              </div>
              <div className="sect1">
                <h1 className="step">
                  2
                </h1>
                <span>
                  Ship to our<br /> US/EU address
                </span>
                <div className="sect-border"></div>
              </div>
              <div className="sect1">
                <h1 className='step'>
                  3
                </h1>
                <span>
                  Get in it<br /> at your door step
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Shipping features
      *********************
      *********************
      *********************
      *******************/}
      <Grid container spacing={0} justify="center"
        alignContent="center" alignItems="center">
        <Grid item xs={mql.matches ? 6 : 4}>
          <LightTooltip title={<React.Fragment>
            <Typography>
              Shipping protection is a service whereby we reimburse you in case
              your parcel is: lost, stolen, and/or damaged in transit.
            </Typography>
            <Typography>
              The cost of purchasing shipment is fixed at 10% of the value of the
              package being shipped. This amount is non-refundable.
            </Typography>
            <Typography>
              <b>How it works: </b> When your items arrive at our US/UK warehouse,
              you’ll receive an email offering purchase protection, you will have
              48 hours to communicate your interest.
            </Typography>
            <Typography>
              Shippingprotection allows you to file a claim and receive full compensation.
              Should you choose not to have shipping protection, Midnight will not accept
              any liability for loss or damage to your items.
            </Typography>
          </React.Fragment>}>
            <Paper className="index_shadow_paper" style={{ margin: '4%', textAlign: 'center' }}>
              <Button className="index_icns_ftrs sf_protect">&nbsp;</Button>
              <Typography variant="h6">Shipping protection</Typography>
              <Typography style={{ color: 'rgb(100,100,100)' }}>
                Optional cover for your goods against any eventualities
                that may happen during the shipping process
              </Typography>
            </Paper>
          </LightTooltip>
        </Grid>
        <Grid item xs={mql.matches ? 6 : 4}>
          <Paper className="index_shadow_paper" style={{ margin: '4%', textAlign: 'center' }}>
            <Button onClick={() => this.props.history.push(Routes.Pricing)}
              className="index_icns_ftrs sf_delivery">&nbsp;</Button>
            <Typography variant="h6">Doorstep delivery</Typography>
            <Typography style={{ color: 'rgb(100,100,100)' }}>
              Request delivery to any of the listed&nbsp;
              <Button onClick={() => this.setState({ pricingDialogOpen: true })}
                color="primary">Regions</Button>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={mql.matches ? 6 : 4}>
          <Paper className="index_shadow_paper" style={{ margin: '4%', textAlign: 'center' }}>
            <Button className="index_icns_ftrs sf_shipping">&nbsp;</Button>
            <Typography variant="h6">Fast shipping</Typography>
            <Typography style={{ color: 'rgb(100,100,100)' }}>
              We ship once every week and our systems ensure you receive
              your products as fast as possible
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={mql.matches ? 6 : 4}>
          <Paper className="index_shadow_paper" style={{ margin: '4%', textAlign: 'center' }}>
            <Button onClick={() => this.props.history.push(Routes.Dashboard)}
              className="index_icns_ftrs sf_notf">&nbsp;</Button>
            <Typography variant="h6">Activity notifications</Typography>
            <Typography style={{ color: 'rgb(100,100,100)' }}>
              Receive instant notifications for each step or change in
              status of orders and items
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={mql.matches ? 6 : 4}>
          <Paper className="index_shadow_paper" style={{ margin: '4%', textAlign: 'center' }}>
            <Button onClick={() => this.props.history.push(Routes.RegisteredItems)}
              className="index_icns_ftrs sf_track">&nbsp;</Button>
            <Typography variant="h6">Item tracking</Typography>
            <Typography style={{ color: 'rgb(100,100,100)' }}>
              Get to track your items from when you order them to when
              they arrive at your doorstep.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={mql.matches ? 6 : 4}>
          <Paper className="index_shadow_paper" style={{ margin: '4%', textAlign: 'center' }}>
            <Button onClick={() => this.props.history.push(Routes.Dashboard)}
              className="index_icns_ftrs sf_history">&nbsp;</Button>
            <Typography variant="h6">Item history</Typography>
            <Typography style={{ color: 'rgb(100,100,100)' }}>
              All items activity is recorded on the client's dashboard for reference.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={mql.matches ? 6 : 4}>
          <Paper className="index_shadow_paper" style={{ margin: '4%', textAlign: 'center' }}>
            <Button onClick={() => this.onToCalculator()}
              className="index_icns_ftrs sf_cc">&nbsp;</Button>
            <Typography variant="h6">Cost calculator</Typography>
            <Typography style={{ color: 'rgb(100,100,100)' }}>
              Get to know exactly where the cost of your orders and items lie.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={mql.matches ? 6 : 4}>
          <Paper className="index_shadow_paper" style={{ margin: '4%', textAlign: 'center' }}>
            <Button onClick={() => this.props.history.push(Routes.EditProfile)}
              className="index_icns_ftrs sf_perp">&nbsp;</Button>
            <Typography variant="h6">Personalized profiles</Typography>
            <Typography style={{ color: 'rgb(100,100,100)' }}>
              Custom profiles and customer ID for a more efficient tracking
              and overall personalized expericence.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      {/* Cost Calculator Section
      *********************
      *********************
      *********************
      *******************/}
      <Grid container spacing={0} justify="center"
        alignContent="center" alignItems="center"
        style={{ background: 'rgb(35,44,57)' }}
        className="index_grid_calc"
        ref="calculator"
        >
            <Grid item xs={mql.matches ? 12 : 4} className="calc_paper"
              style={{ margin: '2%' }}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ paddingBottom: '4%', color: 'rgb(245,245,245)' }}>
                    Use our calculator to estimate your shipping before you order.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ paddingRight: '4%', marginBottom: '15px' }}>
                    Shipping has never been this easy and convenient.
                  </Typography>
                  <Button onClick={this.onGetStarted}
                    style={{ background: 'rgb(245,245,245)', color: '#232C39', textTransform: 'none' }}>
                    {Boolean(localStorage.getItem('token')) ? 'Go to Dashboard' : 'Get Your Package'}</Button>
                </Grid>
              </Grid>
            </Grid>
          {/* Shipping cost calculator
            ****************************
            ****************************
            **************************/}
            <Grid item xs={mql.matches ? 12 : 7} style={{ marginRight: '5px' }} ref="calculator2">
              <Grid container spacing={0} style={{ background: 'white', boxShadow: "0px 0px 2px 2px rgb(239,239,251)" }}>
                <Grid item xs={12}>
                  <Paper style={{
                    margin: '2%', padding: '2%',
                    boxShadow: '0px 8px 8px 0 #efeffb'
                  }}>
                    <LightCalcTooltip title={`Chargeable weight in Kgs is equal to volume
                    in cubic centimeters divided by 6000.`}>
                      <Typography style={{ paddingBottom: '2%', color: 'rgb(100,100,100)' }}>
                        Shipping weight is the greater of actual weight and
                        chargeable (volumetric) weight.
                      </Typography>
                    </LightCalcTooltip>
                    <Grid container spacing={0}>
                      <Grid item xs={mql.matches ? 12 : 7}>
                        <Grid container spacing={0} justify="center"
                          alignContent="center" alignItems="center">
                          <Grid item xs={12}>
                            <Typography style={{ paddingBottom: '2%', fontWeight: 'bold' }}>
                              Use the calculator below to estimate your shipping.
                            </Typography>
                            <Typography style={{ paddingBottom: '2%' }}>
                              Where are you shipping from?
                            </Typography>
                            <FormControl>
                              <RadioGroup
                                name="selectedOrigin"
                                value={this.state.selectedOrigin}
                                row
                                onChange={(event) => {
                                  this.setState({ selectedOrigin: event.target.value });
                                  this.autoReset();
                                }}>
                                <FormControlLabel value="USA" control={<Radio color="primary" />} label="USA" />
                                <FormControlLabel value="UK" control={<Radio color="primary" />} label="UK" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography style={{ paddingBottom: '2%' }}>
                              Actual weight in Kgs or Pounds
                            </Typography>
                            <FormControl>
                              <Select
                                labelId="weight_select_label"
                                value={this.state.selectedWeight}
                                variant="outlined"
                                onChange={event => {
                                  this.setState({ selectedWeight: event.target.value })
                                  this.autoReset();
                                }}>
                                <MenuItem value="Kgs">Kgs</MenuItem>
                                {/* <MenuItem value="Lbs">Pounds (Lbs)</MenuItem> */}
                              </Select>
                            </FormControl>
                            <TextField
                              name="weight"
                              value={this.state.weight}
                              onChange={(e) => {
                                this.setState({
                                  [e.target.name]: e.target.value
                                })
                                // execute if all fields are there
                                if (e.target.value !== '' && length !== '' && width !== '' && height !== '') {
                                  const volumetricWeight = (parseFloat(length) * parseFloat(width) * parseFloat(height)) / 6000;
                                  if (volumetricWeight > parseFloat(e.target.value)) {
                                    return this.setState({
                                      totalCost: this.weightToPrice(volumetricWeight),
                                      selectedGreaterWeight: 'Using volumetric weight',
                                      volumetricWeight
                                    })
                                  }
                                  return this.setState({
                                    totalCost: this.weightToPrice(e.target.value),
                                    selectedGreaterWeight: 'Using actual weight',
                                    volumetricWeight
                                  })
                                }
                                // execute if only weight selected
                                if (e.target.value !== '' && length === '' && width === '' && height === '') {
                                  return this.setState({
                                    totalCost: this.weightToPrice(e.target.value),
                                    selectedGreaterWeight: 'Using actual weight'
                                  });
                                }
                                // if weight and any other field
                                if (e.target.value !== '' && (length !== '' || width !== '' || height !== '')) {
                                  return this.setState({
                                    totalCost: this.weightToPrice(e.target.value),
                                    selectedGreaterWeight: 'Using actual weight'
                                  });
                                }
                                // if all are zero reset
                                if (e.target.value === '' && length === '' && width === '' && height === '') {
                                  this.autoReset()
                                }
                              }}
                              type="number"
                              label={`Weight in ${this.state.selectedWeight}`}
                              variant="outlined" style={{ width: '50%' }}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: '2%' }}>
                            <Typography>Dimensions in Cm</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              name="length"
                              value={this.state.length}
                              onChange={(e) => {
                                this.setState({
                                  [e.target.name]: e.target.value
                                })
                                // execute if all fields are there
                                if (weight !== '' && e.target.value !== '' && width !== '' && height !== '') {
                                  const volumetricWeight = (parseFloat(e.target.value) * parseFloat(width) * parseFloat(height)) / 6000;
                                  if (volumetricWeight > parseFloat(weight)) {
                                    return this.setState({
                                      totalCost: this.weightToPrice(volumetricWeight),
                                      selectedGreaterWeight: 'Using volumetric weight',
                                      volumetricWeight
                                    })
                                  }
                                  return this.setState({
                                    totalCost: this.weightToPrice(weight),
                                    selectedGreaterWeight: 'Using actual weight',
                                    volumetricWeight
                                  })
                                }
                                // execute if all dimensions and no weight
                                if (weight === '' && e.target.value !== '' && width !== '' && height !== '') {
                                  const volumetricWeight = (parseFloat(e.target.value) * parseFloat(width) * parseFloat(height)) / 6000;
                                  return this.setState({
                                    totalCost: this.weightToPrice(volumetricWeight),
                                    selectedGreaterWeight: 'Using volumetric weight',
                                    volumetricWeight
                                  });
                                }
                                // if all are zero reset
                                if (weight === '' && e.target.value === '' && width === '' && height === '') {
                                  this.autoReset()
                                }
                                // responsive dimensions
                                if (weight === '' && e.target.value === '' && width !== '' && height !== '') {
                                  this.setState({ volumetricWeight: 0, totalCost: 0, selectedGreaterWeight: '' })
                                }
                              }}
                              type="number"
                              style={{ width: '92%' }}
                              margin="dense"
                              variant="outlined"
                              label="Length"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              name="width"
                              value={this.state.width}
                              onChange={(e) => {
                                this.setState({
                                  [e.target.name]: e.target.value
                                })
                                // execute if all fields are there
                                if (weight !== '' && length !== '' && e.target.value !== '' && height !== '') {
                                  const volumetricWeight = (parseFloat(length) * parseFloat(e.target.value) * parseFloat(height)) / 6000;
                                  if (volumetricWeight > parseFloat(weight)) {
                                    return this.setState({
                                      totalCost: this.weightToPrice(volumetricWeight),
                                      selectedGreaterWeight: 'Using volumetric weight',
                                      volumetricWeight
                                    })
                                  }
                                  return this.setState({
                                    totalCost: this.weightToPrice(weight),
                                    selectedGreaterWeight: 'Using actual weight',
                                    volumetricWeight
                                  })
                                }
                                // execute if all dimensions and no weight
                                if (weight === '' && length !== '' && e.target.value !== '' && height !== '') {
                                  const volumetricWeight = (parseFloat(length) * parseFloat(e.target.value) * parseFloat(height)) / 6000;
                                  return this.setState({
                                    totalCost: this.weightToPrice(volumetricWeight),
                                    selectedGreaterWeight: 'Using volumetric weight',
                                    volumetricWeight
                                  });
                                }
                                // if all are zero reset
                                if (weight === '' && length === '' && e.target.value === '' && height === '') {
                                  this.autoReset()
                                }
                                // responsive dimensions
                                if (weight === '' && length !== '' && e.target.value === '' && height !== '') {
                                  this.setState({ volumetricWeight: 0, totalCost: 0, selectedGreaterWeight: '' })
                                }
                              }}
                              type="number"
                              style={{ width: '92%' }}
                              margin="dense"
                              variant="outlined"
                              label="Width"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              name="height"
                              value={this.state.height}
                              onChange={(e) => {
                                this.setState({
                                  [e.target.name]: e.target.value
                                })
                                // execute if all fields are there
                                if (weight !== '' && length !== '' && width !== '' && e.target.value !== '') {
                                  const volumetricWeight = (parseFloat(length) * parseFloat(width) * parseFloat(e.target.value)) / 6000;
                                  if (volumetricWeight > parseFloat(weight)) {
                                    return this.setState({
                                      totalCost: this.weightToPrice(volumetricWeight),
                                      selectedGreaterWeight: 'Using volumetric weight',
                                      volumetricWeight
                                    })
                                  }
                                  return this.setState({
                                    totalCost: this.weightToPrice(weight),
                                    selectedGreaterWeight: 'Using actual weight',
                                    volumetricWeight
                                  })
                                }
                                // execute if all dimensions and no weight
                                if (weight === '' && length !== '' && width !== '' && e.target.value !== '') {
                                  const volumetricWeight = (parseFloat(length) * parseFloat(width) * parseFloat(e.target.value)) / 6000;
                                  return this.setState({
                                    totalCost: this.weightToPrice(volumetricWeight),
                                    selectedGreaterWeight: 'Using volumetric weight',
                                    volumetricWeight
                                  });
                                }
                                // if all are zero reset
                                if (weight === '' && length === '' && width === '' && e.target.value === '') {
                                  this.autoReset()
                                }
                                // responsive dimensions
                                if (weight === '' && length !== '' && width !== '' && e.target.value === '') {
                                  this.setState({ volumetricWeight: 0, totalCost: 0, selectedGreaterWeight: '' })
                                }
                              }}
                              type="number"
                              style={{ width: '92%' }}
                              margin="dense"
                              variant="outlined"
                              label="Height"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography style={{ color: 'orangered' }}>{this.state.calcErrors}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={mql.matches ? 12 : 5}>
                        <Paper className="index_shadow_paper" style={{ paddingBottom: '6%', }}>
                          <Typography>
                            <span style={{ color: 'rgb(149,149,149)' }}>Actual weight:&nbsp;</span>
                            {this.state.weight}&nbsp;{this.state.selectedWeight}
                          </Typography>
                        </Paper>
                        <Paper className="index_shadow_paper" style={{ paddingBottom: '6%', }}>
                          <Typography>
                            <span style={{ color: 'rgb(149,149,149)' }}>Volumetric weight:&nbsp;</span>
                            {(this.state.volumetricWeight).toFixed(2)}&nbsp;{this.state.selectedWeight}
                          </Typography>
                        </Paper>
                        <Paper className="index_shadow_paper">
                          <Typography style={{ color: 'darkblue' }}>{this.state.selectedGreaterWeight}</Typography>
                          <Typography>
                            <span style={{ color: 'rgb(149,149,149)' }}>Total cost:&nbsp;</span>
                            KES. {this.state.totalCost}
                          </Typography>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
        </Grid>
      {/* Get started
      ***************
      ***************
      *************/}
      <Grid container spacing={0} justify="center"
        alignContent="center" alignItems="center"
        style={{ background: 'rgb(235,245,245)' }}>
        <Grid item xs={8}>
          <Paper className="index_shadow_paper" style={{ margin: '8%' }}>
            <Typography variant="h4" style={{ paddingBottom: '1%' }}>
              Ready to get started?
            </Typography>
            <Typography variant="h6">
              Get a guided walkthrough of the dashboard upon Login or Signup
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Button onClick={this.onGetStarted} style={{
            background: 'inherit', color: '#232C39',
            textTransform: 'none', border: '1px solid #232C39'
          }}>
            {Boolean(localStorage.getItem('token')) ? 'Go to Dashboard' : 'Get Started'}</Button>
        </Grid>
      </Grid>
      {/* Footer
      **********
      **********
      ********/}
      <Grid container spacing={0} justify="center"
        alignContent="center" alignItems="center"
        className="index_footer">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Paper className="footer_logo"></Paper>
          <Typography className="align_icons" style={{ color: 'white', paddingRight: '24px' }}>
            <PhoneOutlined htmlColor="white" />&nbsp;US:&nbsp;<a style={{ color: 'white', textDecoration: 'none' }} href="tel:+1609-848-8662">+1 609-848-8662</a>
          </Typography>
          <Typography className="align_icons" style={{ color: 'white', paddingRight: '24px' }}>
            <PhoneOutlined htmlColor="white" />&nbsp;UK:&nbsp;<a style={{ color: 'white', textDecoration: 'none' }} href="tel:02079932763">0207 993 2763</a>
          </Typography>
          <Typography className="align_icons" style={mql.matches ? { paddingLeft: '30px',color: 'white', paddingRight: '24px' } : { color: 'white', paddingRight: '24px' }}>
            <PhoneOutlined htmlColor="white" />&nbsp;Nairobi:&nbsp;
              <span className={`${mql.matches && 'align_numbers'}`}>
                <a style={{ color: 'white', textDecoration: 'none' }} href="tel:0717363433">0717363433</a>&nbsp;/&nbsp;
                <a style={{ color: 'white', textDecoration: 'none' }} href="tel:0780363433">0780363433</a>&nbsp;/&nbsp;
                <a style={{ color: 'white', textDecoration: 'none' }} href="tel:0742363433">0742363433</a>
              </span>
          </Typography>
          <Typography className="align_icons" style={{ color: 'white', paddingRight: '24px' }}>
            <MailOutlined htmlColor="white" />&nbsp;<a style={{ color: 'white', textDecoration: 'none' }} href="mailto:support@midnight.com">support@midnight.com</a>
          </Typography>
          <Typography style={{ paddingTop: '2%' }}>
            <a href="https://twitter.com/VituMob"
              target="_blank" rel="noopener noreferrer">
              <Icon classes={{ root: classes.iconRoot }}>
                <img className={classes.imageIcon} src={TwitterIcon} alt="Twitter logo" />
              </Icon>
            </a>&nbsp;&nbsp;&nbsp;
            <a href="https://www.facebook.com/VituMob/"
              target="_blank" rel="noopener noreferrer">
              <Icon classes={{ root: classes.iconRoot }}>
                <img className={classes.imageIcon} src={FacebookIcon} alt="Facebook logo" />
              </Icon>
            </a>&nbsp;&nbsp;&nbsp;
            <a href="https://www.instagram.com/vitumob/"
              target="_blank" rel="noopener noreferrer">
              <Icon classes={{ root: classes.iconRoot }}>
                <img className={classes.imageIcon} src={InstagramIcon} alt="Instagram logo" />
              </Icon>
            </a>&nbsp;&nbsp;&nbsp;
            <a href="https://www.linkedin.com/company/vitumob/?viewAsMember=true"
              target="_blank" rel="noopener noreferrer">
              <Icon classes={{ root: classes.iconRoot }}>
                <img className={classes.imageIcon} src={LinkedInIcon} alt="LinkedIn logo" />
              </Icon>
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center', paddingTop: '2%' }}>
          <Button onClick={() => this.setState({ termsDialogOpen: true })}
            style={{ textTransform: 'none', color: 'white' }}>Terms and Conditions</Button>&nbsp;
          <Button onClick={() => this.setState({ contactUsDialogOpen: true })}
            style={{ textTransform: 'none', color: 'white' }}>Contact us</Button>&nbsp;
          <Button onClick={() => this.setState({ faqsDialogOpen: true })}
            style={{ textTransform: 'none', color: 'white' }}>FAQs</Button>&nbsp;
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center', color: 'gray' }}>
          &copy; {new Date().getFullYear()}&nbsp;&nbsp;&nbsp;Midnight Shipping
        </Grid>
      </Grid>
      {/* Contact us dialog
      *********************
      *********************
      ********************/}
      <Dialog open={this.state.contactUsDialogOpen}
        onClose={() => this.setState({ contactUsDialogOpen: false })}>
        <DialogTitle>Contact us</DialogTitle>
        <DialogContent>
          <form onSubmit={this.onContactUs}>
            <TextField name="name" value={this.state.name} label="Name"
              onChange={this.handleContactInputChange} required
              margin="normal" variant="outlined" fullWidth />
            <TextField name="email" value={this.state.email} label="Email"
              onChange={this.handleContactInputChange} required
              margin="normal" variant="outlined" fullWidth />
            <TextField name="phoneNumber" value={this.state.phoneNumber} label="Phone"
              onChange={this.handleContactInputChange} required
              margin="normal" variant="outlined" fullWidth />
            <TextField name="subject" value={this.state.subject} label="Subject"
              onChange={this.handleContactInputChange} required
              margin="normal" variant="outlined" fullWidth />
            <TextField name="message" value={this.state.message} label="Message"
              onChange={this.handleContactInputChange} required
              multiline rows="4" margin="normal" variant="outlined" fullWidth />
            <Button type="submit" style={{ textTransform: 'none' }} color="primary">Send message</Button>
          </form>
        </DialogContent>
      </Dialog>
      {/* Pricing dialog
      ******************
      ******************
      ****************/}
      <Dialog open={this.state.pricingDialogOpen} fullWidth
        onClose={() => this.setState({ pricingDialogOpen: false })}>
        <DialogContent>
          <PriceList />
        </DialogContent>
      </Dialog>
      {/* Terms&C dialog
      ******************
      ******************
      ****************/}
      <Dialog open={this.state.termsDialogOpen} fullWidth
        onClose={() => this.setState({ termsDialogOpen: false })}>
        <DialogContent>
          <TermsAndConditions />
        </DialogContent>
      </Dialog>
      {/* FAQs Dialog
      ***************
      ***************
      *************/}
      <Dialog open={this.state.faqsDialogOpen} fullWidth
        onClose={() => this.setState({ faqsDialogOpen: false })}>
        <DialogContent>
          <Accordion>
            {FAQs.map(FAQ => {
              return (<AccordionItem key={Math.random()}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {FAQ.question}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div dangerouslySetInnerHTML={{ __html: FAQ.response }} />
                </AccordionItemPanel>
              </AccordionItem>)
            })}
          </Accordion>
        </DialogContent>
      </Dialog>
      { Boolean(this.state.httpSuccess) ? <HttpSuccess successMessage={this.state.httpSuccess} /> : null}
      { Boolean(this.state.httpError) ? <HttpError errorMessage={this.state.httpError} /> : null}
    </div >)
  }
}

export default withStyles(useStyles)(IndexComponent)
