import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StatusTemplate from "../../officer/localOfficer/statusTemplate";
import { DashDrawer, useStyless } from "../drawer";
import { dashGetUserInfoAction, dashRecentItemsAction } from '../../../actions/DashAction'
import { useParams } from "react-router-dom";
import { officerGetStatusDatesAction } from "../../../actions/OfficerActions";


const PackageHistoryClient = () => {
    const dispatch = useDispatch();
    const classes = useStyless();
    const { id: packageId } = useParams();

    const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		arrivedItems: [],
		invoicedItems: [],
		paidItems: [],
		deliveredItems: [],
		notifications: [],
		invoices: [],
		closeTourDialogOpen: false,
		notificationsDialogOpen: false,
	});

    const { recentUploads, recentUploadsSuccess, invoicesSuccess,
        invoices, statusDates } = useSelector(state => ({
            recentUploads: state.dash.recentUploads,
            recentUploadsSuccess: state.dash.recentUploadsSuccess,
            invoicesSuccess: state.dash.invoicesSuccess,
            invoices: state.dash.invoices,
            statusDates: state.officer.statusDates
}))

    useEffect(() => {
        dispatch(dashGetUserInfoAction());
        dispatch(dashRecentItemsAction());
        dispatch(officerGetStatusDatesAction(packageId));
    },
	// eslint-disable-next-line
	[])

    useEffect(() => {
		if (recentUploadsSuccess) {
			let arrivedItems = []
			let receivedItems = []
			let shippedItems = []
			let invoicedItems = []
			let deliveredItems = [] 
			let paidItems = []
			let eligibleItems = []
			recentUploads && recentUploads.map(item => {
				if (item.packageStatus === 'IN_DELAWARE') receivedItems.push(item)
				if (item.packageStatus === 'IN_TRANSIT') shippedItems.push(item)
				if (item.packageStatus === 'IN_NAIROBI') arrivedItems.push(item)
				if (item.packageStatus === 'invoiced') invoicedItems.push(item)
				if (item.packageStatus === 'paid') paidItems.push(item)
				if (item.packageStatus === 'delivered') deliveredItems.push(item)
				if (item.isEligibleForProtection === true) eligibleItems.push(item)
				return item
			})
			setState({ ...state, receivedItems, shippedItems, eligibleItems, arrivedItems, invoicedItems, paidItems, deliveredItems })
		}

		if (invoicesSuccess) {
			setState({ ...state, invoices: invoices })
		}
	},
	// eslint-disable-next-line
	[recentUploadsSuccess, recentUploads, invoices, invoicesSuccess])

    const toggleDrawer = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return
        }
        setState({ ...state, drawerOpen: open })
        }

    const openProfileMenu = (event) => {
		setState({ ...state, profileAnchorEl: event.currentTarget })
    }
    
    const toggleNotifications = () => {
		setState({ ...state, notificationsDialogOpen: !state.notificationsDialogOpen })
	}

	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null })
    }

    return(
        <div>
            <DashDrawer classes={classes} state={state}
				activeLink='reg' toggleDrawer={toggleDrawer} openProfile={openProfileMenu}
				closeProfile={closeProfileMenu} profileAnchorEl={state.profileAnchorEl}
				toggleNotifications={toggleNotifications}
			/>
            <div style={{paddingTop: '16vh'}}>
            <StatusTemplate statusDates={statusDates} />
            </div>
        </div>
    );
}

export default PackageHistoryClient;