import React, { Component } from 'react'
import { LinearProgress, Card, CardHeader,
  CardContent, CardActions, Button, } from '@material-ui/core'
import { HttpError, HttpSuccess } from '../snackbars'

import '../dashboard/Dashboard.scss'
import '../admin/Admin.scss'

class OfficerSuspended extends Component {
  constructor(props) {
    super(props)
    this.state = {
      officerInfo: {},
		}
  }
  componentWillReceiveProps = (nextProps) => {
    const { officerInfo } = nextProps.officer
    if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
    this.setState({ officerInfo })
  }
  componentDidMount = () => {
    this.props.getOfficerInfo()
  }
  onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
  render() {
    const { first_name, last_name } = this.state.officerInfo
    const { errorMsg, successMsg, officerInfoFetched } = this.props.officer
    if (!officerInfoFetched) {
      return (
        <div className="linear_progress"><LinearProgress /></div>
      )
    } else {
      return (
        <div>
          <Card style={{margin: '5%', marginTop: '30vh', borderLeft: '4px solid #232C39'}}>
            <CardHeader title={`${first_name} ${last_name}`} />
            <CardContent>
              Your account has been suspended. Please contact the administrator
            </CardContent>
            <CardActions>
              <Button onClick={this.onLogout} style={{background: '#232C39', color: 'white'}}>Logout</Button>
            </CardActions>
          </Card>
          {errorMsg? <HttpError errorMessage={errorMsg} /> : null}
          {successMsg? <HttpSuccess successMessage={successMsg} /> : null}
        </div>
      )
    }
  }
}

export default OfficerSuspended