import React, { Component } from 'react'
import { Routes } from '../../routes'
import { withStyles, Paper, LinearProgress, } from '@material-ui/core'
import { useStyles, IntForemanDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../snackbars'
import { EditOfficerForm } from '../../utils/officersUtil'

import '../dashboard/Dashboard.scss'
import '../admin/Admin.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)

class ForemanEditOfficer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      profileAnchorEl: null,
      first_name: '', last_name: '', email: '', role: '',
      warehouses: [],
      officer: {},
      formErrors: '',
      foremanInfo: {},
    }
  }
  componentDidMount = () => {
    this.props.getForemanInfo()
    this.props.getOfficer(this.props.match.params.id)
  }
	componentWillReceiveProps = (nextProps) => {
    const { officer, officerAdded,
      officerRoleChanged } = nextProps.admin
    const { foremanInfo, foremanSuspended, } = nextProps.foreman
		if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
    if (foremanSuspended) {
      this.props.history.push(Routes.OfficerSuspended)
    }
    if (officerAdded || officerRoleChanged) {
      this.props.history.push(Routes.Foreman)
    }
    const { first_name, last_name, email, role } = officer
    this.setState({ foremanInfo, officer, first_name, last_name, email, role })
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
  onFormInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  onFormSelectChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
  onEditOfficer = (event) => {
    event.preventDefault()
    this.setState({ formErrors: '' })
    const { first_name, last_name, email, role, foremanInfo } = this.state
    const id = this.props.match.params.id
    if (!role && !email) {
      this.setState({ formErrors: '* All fields are required' })
      return
    } else {
      this.props.editOfficer(id, first_name, last_name, email, role, foremanInfo.warehouseId)
    }
  }
  render() {
    const { classes } = this.props
    const { adminHttpSuccess, adminHttpErrors, } = this.props.admin
    const { errorMsg, successMsg, foremanInfoFetched } = this.props.foreman
    if (!foremanInfoFetched) {
			return (
				<div className="linear_progress">
					<LinearProgress />
				</div>
			)
		} else {
      return (
        <div>
          <IntForemanDrawer classes={classes} state={this.state} props={this.props}
            activeLink='ofcrs' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
            closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
            logout={this.onLogout} searchFieldChange={this.searchFieldChange}
            searchItem={this.onSearchItem} />
          <Paper square={true} style={{ background: 'rgba(0,0,0,0)', boxShadow: 'none',
            paddingLeft: mql.matches? '0' : '240px', paddingTop: '16vh'}}>
            <EditOfficerForm state={this.state} props={this.props}
              onFormInputChange={this.onFormInputChange}
              onFormSelectChange={this.onFormSelectChange}
              onEditOfficer={this.onEditOfficer}
              currentUser="foreman" />
          </Paper>
          {adminHttpErrors? <HttpError errorMessage={adminHttpErrors} /> : null}
          {adminHttpSuccess? <HttpSuccess errorMessage={adminHttpSuccess} /> : null}
          {errorMsg? <HttpError errorMessage={errorMsg} /> : null}
          {successMsg? <HttpSuccess errorMessage={successMsg} /> : null}
        </div>
      )
    }
  }
}

export default withStyles(useStyles)(ForemanEditOfficer)
