import { connect } from 'react-redux'
import { foremanGetForemanInfoAction, foremanGetOfficersAction,
  foremanAddOfficerAction, } from '../actions/ForemanActions'
import { adminChangeOfficerRoleAction, adminSuspendOfficerAction,
  adminGetOfficerAction, adminEditUserAction } from '../actions/AdminActions'
import ForemanDash from '../components/foreman/Foreman'
import ForemanEditOfficer from '../components/foreman/editOfficer'

/* Foreman */
export const ForemanDashPage = connect(
  state => ({
    auth: state.auth,
    foreman: state.foreman,
    admin: state.admin
  }),
  dispatch => ({
    getForemanInfo() {
      dispatch(foremanGetForemanInfoAction())
    },
    getOfficers(id) {
      dispatch(foremanGetOfficersAction(id))
    },
    addUser(first_name, last_name, email, date_joined, role, warehouse){
      dispatch(foremanAddOfficerAction(first_name, last_name, email, date_joined, role, warehouse))
    },
  })
)(ForemanDash)

/* Foreman edit officer */
export const ForemanEditOfficerPage = connect(
  state => ({
    auth: state.auth,
    admin: state.admin,
    foreman: state.foreman
  }),
  dispatch => ({
    getForemanInfo() {
      dispatch(foremanGetForemanInfoAction())
    },
    getOfficer(id) {
      dispatch(adminGetOfficerAction(id))
    },
    editOfficer(id, first_name, last_name, email, role, warehouse) {
      dispatch(adminEditUserAction(id, first_name, last_name, email, role, warehouse))
    },
    changeOfficerRole(id, role) {
      dispatch(adminChangeOfficerRoleAction(id, role))
    },
    suspendOfficer(id) {
      dispatch(adminSuspendOfficerAction(id))
    }
  })
)(ForemanEditOfficer)
 