import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import rootReducer from '../reducers/rootReducer'

const initialState = {}
const middlewares = process.env.NODE_ENV === 'development'? [thunk, promise, logger] : [thunk, promise]

const store = createStore(
	rootReducer,
	initialState,
	compose(
		applyMiddleware(...middlewares)
	)
)

export default store
