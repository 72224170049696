import React, { Component } from 'react'
import { Routes } from '../../../routes'
import Grid from '@material-ui/core/Grid'
import { withStyles, Paper, Input, InputLabel,
  Button, } from '@material-ui/core'
import { useStyles, AdminDrawer } from '../drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
import moment from 'moment'

import '../../dashboard/Dashboard.scss'
import '../Admin.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)

class AdminAddUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      profileAnchorEl: null,
      firstName: '', lastName: '', email: '', role: '', warehouse: '',
      formErrors: '',
      startDate: moment().add(-30, 'days'),
			endDate: moment(),
			dateError: null
    }
  }

	componentWillReceiveProps = (nextProps) => {
		const { officerAdded } = nextProps.admin
		
    if (officerAdded) {
      this.props.history.push(Routes.AdminOfficers)
    }
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
  onFormInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  onFormSelectChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
  onAddOfficer = (event) => {
    event.preventDefault()
    this.setState({ formErrors: '' })
    const { firstName, lastName, email } = this.state
    if (!firstName && !lastName && !email) {
      this.setState({ formErrors: '* All fields are required' })
      return
    } else {
      this.props.addUser(email, firstName, lastName)
    }
  }

  onChangeStartDate = date => {
    this.setState({ dateError: null })
    const { endDate } = this.state
    if(moment(date).isBefore(endDate)) {
      this.setState({ startDate: moment(date) }, () => {
        this.props.getMetrics(date, endDate)
      })
    } else {
      this.setState({ dateError: 'Start date should be any day before the end date' })
    }
  }

  onChangeEndDate = date => {
    this.setState({ dateError: null })
    const { startDate } = this.state
    if(moment(date).isBefore(moment())) {
      if (moment(date).isAfter(startDate)) {
        this.setState({ endDate: moment(date) }, () => {
          this.props.getMetrics(startDate, date)
        })
      } else {
        this.setState({ dateError: 'The end date cannot be after the start date' })
      }
    } else {
      this.setState({ dateError: "Oops! we cannot tell the future" })
    }
  }

  render() {
    const { classes } = this.props
    const { adminHttpSuccess, adminHttpErrors, } = this.props.admin
    // const Warehouses = this.state.warehouses.map(warehouse => {
    //   return (
    //     <MenuItem value={warehouse.id} key={warehouse.id} >{warehouse.name}</MenuItem>
    //   )
    // })
    // if (!warehousesFetched) {
		// 	return (
		// 		<div className="linear_progress">
		// 			<LinearProgress />
		// 		</div>
		// 	)
		// } else {
      return (
        <div>
          <AdminDrawer
            classes={classes}
            state={this.state}
            props={this.props}
            activeLink='dash'
            toggleDrawer={this.toggleDrawer}
            openProfile={this.openProfileMenu}
            closeProfile={this.closeProfileMenu}
            profileAnchorEl={this.state.profileAnchorEl}
            changeStartDate={this.onChangeStartDate}
            changeEndDate={this.onChangeEndDate}
            logout={this.onLogout}
            />
          <Paper square={true} style={{ background: 'rgba(0,0,0,0)', boxShadow: 'none',
            paddingLeft: mql.matches? '0' : '240px', paddingTop: '16vh'}}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Paper className="clean_paper"
                    style={{fontSize: '1.2em', textAlign: 'center'}}>
                    ADD USER FORM
                  </Paper>
                  <Paper style={{fontSize: '1.2em', margin: '3.2% auto', padding: '2%',
                    width: mql.matches? '80%' : '50%'}}>
                    <form onSubmit={this.onAddOfficer}>
                      <InputLabel>First Name</InputLabel>
                      <Input name="firstName" onChange={this.onFormInputChange} required
                        value={this.state.firstName} style={{width: '100%', marginBottom: '5%'}} />
                      <InputLabel>Last Name</InputLabel>
                      <Input name="lastName" onChange={this.onFormInputChange} required
                        value={this.state.lastName} style={{width: '100%', marginBottom: '5%'}} />
                      <InputLabel>Email</InputLabel>
                      <Input name="email" onChange={this.onFormInputChange} required
                        value={this.state.email} style={{width: '100%', marginBottom: '5%'}} />
                      {/* <InputLabel>Warehouse</InputLabel>
                      <Select name="warehouse" onChange={this.onFormSelectChange} required
                        style={{width: '100%', marginBottom: '2%'}}
                        value={this.state.warehouse}>
                        {Warehouses}
                      </Select> */}
                      <div style={{color: 'orangered'}}>{this.state.formErrors}</div>
                      <Button type="submit" style={{background: '#232C39', color: 'white'}}>Submit</Button>
                    </form>
                  </Paper>
                </Grid>
            </Grid>
          </Paper>
          {adminHttpErrors? <HttpError errorMessage={adminHttpErrors} /> : null}
          {adminHttpSuccess? <HttpSuccess errorMessage={adminHttpSuccess} /> : null}
        </div>
      )
    }
}

export default withStyles(useStyles)(AdminAddUser)
