import React, { useEffect, useState } from 'react';
import {
  Paper,
  Card, Table, TableBody, TableHead, TableRow,
  TableCell, Input, CardHeader, CardContent,
  Avatar, Button, Typography, makeStyles,
} from '@material-ui/core'
// import { ArrowBackIosOutlined, ArrowForwardIosRounded } from '@material-ui/icons'
import { useStyles, LocalOfficerDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
// import _ from 'lodash'
import TablePagination from '@material-ui/core/TablePagination';
import { useSelector } from "react-redux";
import '../../dashboard/Dashboard.scss'
import '../../admin/Admin.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)

// function filterClients(collection, substring) {
//   const clients = _.filter(collection, _.flow(
//     _.identity, _.values, _.join, _.toLower, _.partialRight(
//       _.includes,
//       substring.replace(/\s/g, '').toLowerCase()
//     )
//   ))
//   return clients
// }

const OfficerClients = props => {

  const [state, setState] = useState({
    drawerOpen: false,
    profileAnchorEl: null,
    clients: [],
    filteredClients: [],
    clientsCount: 0,
    officerInfo: { first_name: '', last_name: '' },
    filterLimit: 10,
    filterSkip: 0,
    filterString: '',
    page: 0,
    rowsPerPage: 10
  })

  const clients = useSelector(state => state.officer.clients)
  const clientsCount = useSelector(state => state.officer.clientsCount)



  useEffect(() => {
    const { officerInfo, clients, clientsCount } = props.officer
    if (props.auth.unauthorized) {
      window.location.href = '/accounts/signin'
    }

    setState({ ...state, officerInfo: Object.keys(officerInfo).length ? officerInfo : { first_name: '', last_name: '' }, clients, filteredClients: clients, clientsCount })
  },
  // eslint-disable-next-line
  [])

  useEffect(() => {
    const { filterLimit, filterSkip } = state
    // props.getOfficerInfo()
    let viewing = 'IN_DELAWARE'
    props.getData(filterLimit, filterSkip, viewing)
  },
  // eslint-disable-next-line
  [])


  const onLogout = () => {
    setState({ ...state, profileAnchorEl: null })
    window.location.href = '/accounts/signin'
  }

  const openProfileMenu = (event) => {
    setState({ ...state, profileAnchorEl: event.currentTarget })
  }

  const closeProfileMenu = () => {
    setState({ ...state, profileAnchorEl: null })
  }

  const toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setState({ ...state, drawerOpen: open })
  }

  const classes = makeStyles(useStyles)()


  // onPaginationNext = () => {
  //   const { filterLimit, filterSkip, clients } = state
  //   if (clients.length === 0) return
  //   setState({ filterSkip: filterSkip + filterLimit }, () => {
  //     props.getClients(filterLimit, filterSkip + filterLimit)
  //   })
  // }
  // onPaginationPrevious = () => {
  //   const { filterLimit, filterSkip } = state
  //   if (filterSkip === 0) return
  //   setState({ filterSkip: filterSkip - filterLimit }, () => {
  //     props.getClients(filterLimit, filterSkip - filterLimit)
  //   })
  // }


  const handleChangePage = (event, newPage) => {
    const { filterLimit } = state;

    setState({ ...state, page: newPage, filterSkip: newPage * filterLimit });
  }

  useEffect(() => {
    const { filterLimit, filterSkip } = state;
    let viewing = 'IN_DELAWARE'
    props.getData(filterLimit, filterSkip, viewing);
  },
  // eslint-disable-next-line
  [state.filterLimit, state.filterSkip])


  const { errorMsg, successMsg } = props.officer
  // const Pagination = () => {
  //   const { clients, clientsCount, filterSkip, filterLimit } = state
  //   const pages = Math.ceil(clientsCount / clients.length)
  //   const currentPage = Math.ceil(filterSkip / filterLimit)        
  //   return (<TableRow>
  //     <TableCell align="right" colSpan={6}>
  //       <span style={{color: 'darkslategray'}}>Page {currentPage + 1} of {pages}&nbsp;</span>
  //       {Boolean(currentPage)?
  //         (<Button onClick={onPaginationPrevious}>
  //           <ArrowBackIosOutlined htmlColor="darkslategray" />
  //         </Button>)
  //       : (<span></span>)}
  //       {Boolean(currentPage + 1 === pages)?
  //         (<span></span>)
  //       : (<Button
  //         onClick={onPaginationNext}>
  //         <ArrowForwardIosRounded htmlColor="darkslategray" />
  //       </Button>)}
  //     </TableCell>
  //   </TableRow>)
  // }

  return (
    <div>
      <LocalOfficerDrawer currentUser="officer" classes={classes} state={state} props={props}
        activeLink='clnts' toggleDrawer={toggleDrawer} openProfile={openProfileMenu}
        closeProfile={closeProfileMenu} profileAnchorEl={state.profileAnchorEl}
        logout={onLogout} />
      <Paper square={true} style={{
        background: 'rgba(0,0,0,0)', boxShadow: 'none',
        paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
      }}>
        <Paper style={{ margin: '3.2%', padding: '3%' }}>
          <div style={{
            display: 'flex', justifyContent: 'space-around'
          }}>
            <Input value={state.filterString} placeholder="Search clients"
              // onChange={event => {onFilterClients(event.target.value)}}
              onChange={event => {
                setState({ ...state, filterString: event.target.value })
              }}
              onKeyUp={e => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  const { filterString } = state;
                  props.searchOfficer(filterString);
                }
              }}
              className={classes.searchField} />
            <Button
              variant="contained"
              color="primary"
              style={{ height: '45px' }}
              onClick={() => {
                const { filterString } = state
                props.searchOfficer(filterString)
              }}
            >
              Search
                  </Button>
          </div>
          <Table style={{ display: mql.matches ? 'none' : 'block', width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Client Numbers</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Date joined</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients && clients.map((client, index) => {
                return (<TableRow key={client.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Button style={{ color: 'blue' }}
                      onClick={() => props.history.push(`/officers/officer/clients/${client.id}`)}>
                      {`${client.id}`}</Button>
                  </TableCell>
                  <TableCell>{client.firstName}{' '}{client.lastName}</TableCell>
                  <TableCell>{client.phoneNumber}</TableCell>
                  <TableCell>
                    {/* {Moment(client.createdAt).format('LLL')} */}
                  </TableCell>
                  <TableCell>{client.status}</TableCell>
                </TableRow>)
              })}
              {/*<Pagination />*/}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={clientsCount}
            page={state.page}
            onChangePage={handleChangePage}
            rowsPerPage={state.rowsPerPage}
            rowsPerPageOptions={[10]}
            style={{ margin: '0 auto', width: '50%' }}
          />
          {/* Mobile screens */}
          {clients && clients.map((client, index) => {
            return (<Card key={client.id} style={{ display: mql.matches ? 'block' : 'none', margin: '2%' }}>
              <CardHeader avatar={<Avatar><small>{index + 1}</small></Avatar>} />
              <CardContent style={{ color: 'darkslategray' }}>
                <Typography className="admin_officers_card_p">
                  <b>Client Numbers:&nbsp;</b>
                  <Button style={{ color: 'blue' }}
                    onClick={() => props.history.push(`/officers/officer/clients/${client.id}`)}>
                    {`${client.id}`}</Button>
                </Typography>
                <Typography className="admin_officers_card_p">
                  <b>Name:&nbsp;</b>{client.firstName}{' '}{client.lastName}</Typography>
                <Typography className="admin_officers_card_p">
                  <b>Email:&nbsp;</b>{client.email}<br />{client.secondary_email}</Typography>
                <Typography className="admin_officers_card_p">
                  <b>Mobile:&nbsp;</b>{client.phoneNumber}</Typography>
                <Typography className="admin_officers_card_p">
                  <b>Address:&nbsp;</b>{client.address}</Typography>
                <Typography className="admin_officers_card_p">
                  <b>Status:&nbsp;</b>{client.status}</Typography>
              </CardContent>
            </Card>)
          })}
        </Paper>
      </Paper>
      {errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
      {successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
    </div>
  )


}


export default OfficerClients
