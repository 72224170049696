import React, { Component } from 'react'
import { Routes } from '../../routes'
import {
  withStyles, Paper, LinearProgress, Input, Button, InputLabel,
  Dialog, DialogTitle, DialogContent,
} from '@material-ui/core'
import { useStyles, IntForemanDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../snackbars'
import { OfficersListing } from '../../utils/officersUtil'
import moment from 'moment'

import '../dashboard/Dashboard.scss'
import '../admin/Admin.scss'
import { getOffiersCount } from '../admin/officers/Officers'
import TablePagination from '@material-ui/core/TablePagination';


const mql = window.matchMedia(`(max-width: 1024px)`)

class ForemanDash extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      profileAnchorEl: null,
      addOfficerDialogOpen: false,
      foremanInfo: {},
      officers: [],
      searchQuery: '',
      first_name: '', last_name: '', email: '',
      roles: ['local_officer', 'local_foreman', 'int_officer', 'int_foreman'],
      rolesSanitized: ['Officer', 'Foreman', 'Officer', 'Foreman'],
      count: 0,
      page: 0,
      limit: 10,
      rowsPerPage: 10,
      skip: 0,
    }
  }
  componentWillReceiveProps = (nextProps) => {
    const { foremanInfo, foremanFetchOfficers,
      foremanSuspended, officers, officerInfoUpdated } = nextProps.foreman
    if (nextProps.auth.unauthorized) {
      window.location.href = '/accounts/signin'
    }
    if (foremanSuspended) {
      this.props.history.push(Routes.OfficerSuspended)
    }
    if (foremanFetchOfficers || officerInfoUpdated) {
      this.props.getOfficers(foremanInfo.warehouseId)
    }
    this.setState({ foremanInfo, officers })
  }
  componentDidMount = () => {
    getOffiersCount().then(v => {
      this.setState({ ...this.state, count: v })
    })
    this.props.getForemanInfo()
  }
  onLogout = () => {
    this.setState({ profileAnchorEl: null })
    window.location.href = '/accounts/signin'
  }
  openProfileMenu = (event) => {
    this.setState({ profileAnchorEl: event.currentTarget })
  }
  closeProfileMenu = () => {
    this.setState({ profileAnchorEl: null })
  }
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
  searchFieldChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }
  onFormInputChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }
  onAddUser = event => {
    event.preventDefault()
    this.setState({ addOfficerDialogOpen: false })
    const { first_name, last_name, email, foremanInfo } = this.state
    const date_joined = moment().format('MMMM Do YYYY, h:mm:ss a')
    const role = this.state.foremanInfo.role === 'int_foreman' ? 'int_officer' : 'local_officer'
    this.props.addUser(first_name, last_name, email, date_joined, role, foremanInfo.warehouseId)
  }
  render() {
    const { classes } = this.props
    const { errorMsg, successMsg, foremanInfoFetched } = this.props.foreman
    if (!foremanInfoFetched) {
      return (
        <div className="linear_progress"><LinearProgress /></div>
      )
    } else {
      return (
        <div>
          <IntForemanDrawer classes={classes} state={this.state} props={this.props}
            activeLink='ofcrs' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
            closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
            logout={this.onLogout} searchFieldChange={this.searchFieldChange}
            searchItem={this.onSearchItem} />
          <Paper square={true} style={{
            background: 'rgba(0,0,0,0)', boxShadow: 'none',
            paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
          }}>
            <Button onClick={() => this.setState({ addOfficerDialogOpen: true })}
              style={{ marginLeft: '2%', background: '#232C39', color: 'white' }}>Add Officer</Button>
            <OfficersListing state={this.state} currentUser="foreman" />
            <TablePagination
              component="div"
              count={this.state.count}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              rowsPerPage={this.state.rowsPerPage}
              rowsPerPageOptions={[10]}
              style={{ margin: '0 auto', width: '50%' }}
            />
          </Paper>
          {/* Add officer form */}
          <Dialog open={this.state.addOfficerDialogOpen} fullWidth
            onClose={() => this.setState({ addOfficerDialogOpen: false })}>
            <DialogTitle>Add Officer</DialogTitle>
            <DialogContent>
              <form onSubmit={this.onAddUser}>
                <InputLabel>First name</InputLabel>
                <Input name="first_name" onChange={this.onFormInputChange} required
                  value={this.state.first_name} style={{ width: '100%', marginBottom: '5%' }} />
                <InputLabel>Last name</InputLabel>
                <Input name="last_name" onChange={this.onFormInputChange} required
                  value={this.state.last_name} style={{ width: '100%', marginBottom: '5%' }} />
                <InputLabel>Email</InputLabel>
                <Input name="email" onChange={this.onFormInputChange} required
                  value={this.state.email} style={{ width: '100%', marginBottom: '5%' }} />
                <div style={{ color: 'orangered' }}>{this.state.formErrors}</div>
                <Button type="submit" style={{ background: '#232C39', color: 'white' }}>Submit</Button>
              </form>
            </DialogContent>
          </Dialog>
          {errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
          {successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
        </div>
      )
    }
  }
}

export default withStyles(useStyles)(ForemanDash)