  export const handleError = error => {
    if (typeof error.response !== 'undefined') {
      if (error.response.data && error.response.data.error) {
        const hasErrorMessage = Boolean(error.response.data.error.message)
        if (hasErrorMessage) {
          const errorMessage = error.response.data.error.message;
          return {
            type: 'HTTP_ERROR',
            payload: errorMessage
          }
        }
      }
    }
    return {
      type: 'HTTP_ERROR'
    }
  }

export const handleAuthError = error => {
  if (typeof error.response !== 'undefined') {

    if (!error.response.data) {
      return {
        type: 'HTTP_ERROR',
        payload: 'Connection error'
      }

    }
    if (!error.response.data.error) {
      return {
        type: 'HTTP_ERROR',
        payload: 'Connection error'
      }

    }
    if (!error.response.data.error.statusCode) {
      return {
        type: 'HTTP_ERROR',
        payload: 'Connection error'
      }

    }
    const hasErrorCode = Boolean(error.response.data.error.statusCode)
    const hasErrorMessage = Boolean(error.response.data.error.message)
    if (hasErrorCode && hasErrorMessage) {
      const errorCode = error.response.data.error.statusCode
      const errorMessage = error.response.data.error.message
      if (errorCode === 422) {
        return {
          type: 'HTTP_ERROR',
          payload: 'The mail address provided already exists. Use another email or login instead'
        }
      }
      return {
        type: 'HTTP_ERROR',
        payload: errorMessage
      }
    }
  }
  return {
    type: 'HTTP_ERROR',
    payload: 'An error occured. Please retry'
  }
}
