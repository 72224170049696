import Axios from 'axios'
import { handleError } from './ErrorHandler'

const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/api';
/* Get officer info */
export const officerGetOfficerInfoAction = () => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const userId = localStorage.getItem('client')
  // eslint-disable-next-line 
  const endpoint = `${baseUrl}/officers/${userId}`
  return (dispatch) => {
    dispatch({ type: 'OFFICER_GET_OFFICER_INFO' })
    Axios.get(endpoint).then(snapshots => {
      if (snapshots.data.status === 'deactivated') {
        dispatch({ type: 'OFFICER_SUSPENDED' })
      } else {
        dispatch({
          type: 'OFFICER_GET_OFFICER_INFO_SUCCESS',
          payload: snapshots.data,
        })
      }
    }).catch(error => { })
  }
}

export const officerMarksDeliveredAction = (payload, type) => {
  const endpoint = `${baseUrl}/invoices/mark-delivered`;
  return (dispatch) => {
    dispatch({ type: 'OFFICER_MARKS_DELIVERED' })
    Axios.post(endpoint, payload).then(() => {
      dispatch({
        type: 'OFFICER_MARKS_DELIVERED_SUCCESS',
        payload: 'Delivered'
      })
      if (type === 1) {
        Axios.get(`${baseUrl}/invoices?filter[include][0]=consolidatedItems&filter[order]=invoiceGenerationDate DESC`);
      }
      if (type === 2) {
        dispatch(officerGetInvoiceAction(payload.invoiceNumbers[0]))
      }
    }).catch(error => dispatch(handleError(error.response.data)))
  }
}

export const officerGetStatusDatesAction = (packageId) => {
  const endpoint = `${baseUrl}/status-dates?filter={"where": {"packageId": ${JSON.stringify(packageId)}}}`;
  return (dispatch) => {
    dispatch({ type: 'OFFICER_GET_STATUS_DATES' });
    Axios
      .get(endpoint)
      .then(dates => {
        dispatch({
          type: 'OFFICER_GET_STATUS_DATES_SUCCESS',
          payload: dates.data
        })
      })
      .catch(error => console.log(error))
  }
}

export const officerSendInvoiceAction = (invoiceId) => {
  const endpoint = `${baseUrl}/invoices/send-email`;
  return (dispatch) => {
    dispatch({ type: 'OFFICER_SEND_INVOICE' });
    Axios
      .post(endpoint, { invoiceNumber: invoiceId })
      .then(() => {
        dispatch({
          type: 'OFFICER_SEND_INVOICE_SUCCESS',
          payload: 'Invoice sent'
        })
        dispatch(officerGetInvoiceAction(invoiceId))
      })
      .catch(error => dispatch(handleError(error.response.data)))
  }
}

/* Search shipping */
export const searchShippingAction = (pattern, viewing) => {
  // // eslint-disable-next-line 
  // const endpoint = `${baseUrl}/packages?filter={"where": {"or": [{"clientNumber": {"ilike": 
  // ${JSON.stringify(`%${pattern}%`)}}, "shipmentNumber": {"ilike": 
  // ${JSON.stringify(`%${pattern}%`)}}, "trackingNumber": {"ilike": 
  // ${JSON.stringify(`%${pattern}%`)}}]}, {"packageStatus": 
  // ${JSON.stringify(viewing)}}}`

  const endp = `${baseUrl}/packages?filter[include][0]=statusDates&filter[where][or][0][clientNumber][ilike]=%${pattern}%&filter[where][or][1][shipmentNumber][ilike]=%${pattern}%&filter[where][or][2][trackingNumber][ilike]=%${pattern}%&filter[where][packageStatus]=${viewing}`

  // const endp = `${baseUrl}/packages?filter={"include": ["statusDates"],"where":{"or":[{"clientNumber":{"ilike":
  // ${JSON.stringify(pattern)}}}, {"shipmentNumber":{"ilike":
  // ${JSON.stringify(pattern)}}}, {"trackingNumber":{"ilike":
  // ${JSON.stringify(pattern)}}}]}}`
  return (dispatch) => {
    dispatch({ type: 'SEARCH_SHIPPING' })
    Axios.get(endp).then(snapshots => {
      dispatch({
        type: 'SEARCH_SHIPPING_SUCCESS',
        payload: snapshots.data
      })
    }).catch(error => console.log(error))
  }
}

/* Search officer */
export const searchOfficerAction = pattern => {

  // const endpoint = `${baseUrl}/clients?filter={"where":
  // {"or":[
  //   {"id":{"ilike":${JSON.stringify(pattern)}}},
  //   {"firstName":{"ilike":${JSON.stringify(pattern)}}},
  //   {"lastName":{"ilike":${JSON.stringify(pattern)}}},
  //   {"phoneNumber":{"ilike":${JSON.stringify(pattern)}}}
  // ]
  // }
  // }`
  // const endpoint = `${baseUrl}/clients?filter[search]=${pattern}`
  const endpoint = `${baseUrl}/clients?filter[where][or][0][id][ilike]=%${pattern}%&filter[where][or][1][firstName][ilike]=%${pattern}%&filter[where][or][2][lastName][ilike]=%${pattern}%&filter[where][or][3][phoneNumber][ilike]=%${pattern}%`
  return (dispatch) => {
    dispatch({ type: 'SEARCH_OFFICER' })
    Axios.get(endpoint).then(snapshots => {
      dispatch({
        type: 'SEARCH_OFFICER_SUCCESS',
        payload: snapshots.data
      })
    }).catch(error => dispatch(handleError(error)))
  }
}

export const getArchivedAction = (limit, skip) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const archivedEndpoint = Axios.get(`${baseUrl}/shipping_items?filter[limit]=${limit}&filter[skip]=${skip}&filter[status]=delivered&filter[include]=client`)
  const countEndpoint = Axios.get(`${baseUrl}/shipping_items/count?filter[status]=delivered&filter[include]=client`)
  return (dispatch) => {
    dispatch({ type: 'OFFICER_GET_ARCHIVED' })
    Axios.all([archivedEndpoint, countEndpoint]).then(
      Axios.spread((...snapshots) => {
        dispatch({
          type: 'OFFICER_GET_ARCHIVED_SUCCESS',
          payload: {
            archivedItems: snapshots[0].data,
            countArchived: snapshots[1].data.count,
          }
        })
      })
    ).catch(error => {
      // dispatch(handleError(error))
    })
  }
}

/* Get all data */
export const officerGetDataAction = (limit, skip, viewing) => {
  const shippingItemsEndpoint = Axios.get(`${baseUrl}/packages?filter={"include": ["statusDates"], "limit": ${JSON.stringify(limit)}, "skip": ${JSON.stringify(skip)}, "where": {"packageStatus": ${JSON.stringify(viewing ? viewing : '')}}}`)
  // const messagesEndpoint = Axios.get(`${baseUrl}/messages?filter[where][open]=true&filter[include][client]`)
  const clientsEndpoint = Axios.get(`${baseUrl}/clients?filter[limit]=${limit}&filter[skip]=${skip}&filter[order]=id DESC`);
  const clientsCountEndpoint = Axios.get(`${baseUrl}/clients/count`)
  const invoicesEndpoint = Axios.get(`${baseUrl}/invoices?filter[limit]=${limit}&filter[skip]=${skip}&filter[include][0]=consolidatedItems&filter[order]=paymentStatus DESC&filter[order]=id DESC`);
  const countShipments = Axios.get(`${baseUrl}/packages/count?where[packageStatus]=${viewing ? viewing : ''}`)
  const countInvoices = Axios.get(`${baseUrl}/invoices/count`)

  return (dispatch) => {
    dispatch({ type: 'OFFICER_GET_DATA' })
    Axios.all([shippingItemsEndpoint, invoicesEndpoint, clientsEndpoint, countShipments, countInvoices, clientsCountEndpoint]).then(
      Axios.spread((...snapshots) => {
        dispatch({
          type: 'OFFICER_GET_DATA_SUCCESS',
          payload: {
            shippingItems: snapshots[0].data,
            // messages: snapshots[1].data,
            invoices: snapshots[1].data,
            clients: snapshots[2].data,
            shipmentCount: snapshots[3].data,
            invoicesCount: snapshots[4].data,
            clientsCount: snapshots[5].data.count,
            // id: snapshots[4].data.clientId
          }
        })
      })
    ).catch(error => {
      console.log(error)
    })
  }
}

/* Mark item(s) arrived in Nairobi */
export const receiveItemsInNairobiAction = payload => {
  const endpoint = `${baseUrl}/packages/mark_received`;
  return (dispatch) => {
    dispatch({ type: 'OFFICER_RECEIVES_IN_NAIROBI' })
    Axios
      .patch(endpoint, payload)
      .then(response => {
        dispatch({
          type: 'OFFICER_RECEIVES_IN_NAIROBI_SUCCESS',
          payload: `${payload.trackingNumbers.length === 1 ? 'Item' : 'Items'} arrived in Nairobi`
        })
      })
      .catch(err => dispatch(handleError(err)));
  }
}

/* CheckIn Shipping item(s) */
export const officerCheckInItemAction = payload => {
  console.log(payload)
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const endpoint = `${baseUrl}/shipping_items/single_arrival`
  return (dispatch) => {
    dispatch({ type: 'OFFICER_CHECKIN_SHIPPING_ITEMS' })
    Axios.patch(endpoint, payload).then(snapshots => {
      console.log(snapshots)
      dispatch({
        type: 'OFFICER_CHECKIN_SHIPPING_ITEMS_SUCCESS',
        payload: 'Item checked in'
      })
    }).catch(error => dispatch(handleError(error)))
  }
}

/* CheckIn consolidated item(s) */
export const officerConsolidateAction = (trackingNumbersList, clientNumber, weight) => {
  const endpoint = `${baseUrl}/consolidated-items`;
  return (dispatch) => {
    dispatch({ type: 'OFFICER_CHECKIN_SHIPPING_ITEMS' })
    Axios.post(endpoint, { trackingNumbersList, clientNumber, weight }).then(snapshots => {
      dispatch({
        type: 'OFFICER_CHECKIN_SHIPPING_ITEMS_SUCCESS',
        payload: 'Item checked in'
      })
    }).catch(error => console.log(error))
  }
}

/* Issues */
export const officerReplyIssueAction = (id, message) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const userId = localStorage.getItem('client')
  const endpoint = `${baseUrl}/messages/${id}/responses`
  return (dispatch) => {
    dispatch({ type: 'OFFICER_REPLY_MESSAGE' })
    Axios.post(endpoint, { message, userId, userType: 'officer' }).then(snapshots => {
      dispatch({
        type: 'OFFICER_REPLY_MESSAGE_SUCCESS',
        payload: 'Response sent'
      })
    }).catch(error => dispatch(handleError(error)))
  }
}
/* Invoice */
export const officerGetInvoiceAction = id => {
  const invoiceUrl = Axios.get(`${baseUrl}/invoices/${id}?filter={"include": [{"relation": "consolidatedItems", 
  "scope": {"include":[{"relation": "packages"}]}}, {"relation": "debits"}, {"relation": "payments"}, {"relation": "credits"}, {"relation": "storageCosts"}]}`)
  return (dispatch) => {
    dispatch({ type: 'OFFICER_GET_INVOICE' })
    dispatch({ type: 'OFFICER_GET_CLIENT' })
    dispatch({ type: 'OFFICER_GET_ADDRESS' })

    Axios.all([invoiceUrl]).then(
      Axios.spread((...snapshots) => {

        // client
        Axios.get(`${baseUrl}/users/clients/${snapshots[0].data.clientNumber}`).then(snapshots => {
          console.log(snapshots)
          dispatch({
            type: 'OFFICER_GET_CLIENT_SUCCESS',
            payload: snapshots.data
          })
        }).catch(err => dispatch(handleError(err)))
        // Get address
        Axios.get(`${baseUrl}/addresses?filter[where][clientNumber]=${snapshots[0].data.clientNumber}`).then(snapshots => {
          dispatch({
            type: 'OFFICER_GET_ADDRESS_SUCCESS',
            payload: snapshots.data
          })
        }).catch(err => console.log(err))

        dispatch({
          type: 'OFFICER_GET_INVOICE_SUCCESS',
          payload: {
            invoice: snapshots[0].data,
          },
        })
      })
    ).catch(error => console.log(error))
  }
}

export const officerPayInvoiceAction = (referenceNumber, mobile, amountPaidKes, invoiceNumber, paymentType, getInvoice) => {
  const endpoint = `${baseUrl}/payments`
  let phoneNumber = `254${mobile.substr(mobile.length - 9)}`
  return (dispatch) => {
    dispatch({ type: 'OFFICER_PAY_INVOICE' })
    Axios.post(endpoint, { referenceNumber, phoneNumber, amountPaidKes, invoiceNumber, paymentType, getInvoice }).then(snapshots => {
      dispatch({
        type: 'OFFICER_PAY_INVOICE_SUCCESS',
        payload: 'Invoice paid.'
      })
      dispatch(getInvoice(invoiceNumber))
    }).catch(error => {
      dispatch({
        type: 'OFFICER_INVOICE_LOADING'
      })
      dispatch(handleError(error))
    })
  }
}

/* Client */
export const officerGetClientAction = id => {
  const endpoint = `${baseUrl}/users/clients/${id}?filter={"include":["packages"]}`

  return (dispatch) => {
    dispatch({ type: 'OFFICER_GET_CLIENT' })
    dispatch({ type: 'OFFICER_GET_ADDRESS' })

    // Get address
    Axios.get(`${baseUrl}/addresses?filter[where][clientNumber]=${id}`).then(snapshots => {
      dispatch({
        type: 'OFFICER_GET_ADDRESS_SUCCESS',
        payload: snapshots.data
      })
    }).catch(err => console.log(err))

    // Get client
    Axios.get(endpoint).then(snapshots => {
      dispatch({
        type: 'OFFICER_GET_CLIENT_SUCCESS',
        payload: snapshots.data
      })
    }).catch(error => console.log(error))
  }
}

export const officerGetClientInvoicesAction = clientId => {
  const endpoint = `${baseUrl}/invoices/client/${clientId}`
  return (dispatch) => {
    dispatch({ type: 'OFFICER_GET_CLIENT_INVOICES' })
    Axios.get(endpoint).then(snapshots => {
      dispatch({
        type: 'OFFICER_GET_CLIENT_INVOICES_SUCCESS',
        payload: snapshots.data
      })
    }).catch(error => console.log(error))
  }
}

export const officerSendEmailAction = (payload) => {
  const endpoint = `${baseUrl}/payments`
  return (dispatch) => {
    dispatch({ type: 'OFFICER_PAY_INVOICE' })
    Axios.post(endpoint, { payload }).then(snapshots => {
      dispatch({
        type: 'OFFICER_PAY_INVOICE_SUCCESS',
        payload: 'Invoice paid.'
      })
    }).catch(error => console.log(error))
  }
}