import React, { Component } from 'react'
import { HttpError, HttpSuccess } from '../snackbars'
import {
	Card, withStyles, Paper, CardHeader, Typography,
	CardContent
} from '@material-ui/core'
import { useStyles, DashDrawer } from './drawer'
import './Dashboard.scss'
import { ArrowRight } from '@material-ui/icons'
import { PriceList } from '../static/Pricing'
import Axios from "axios";

const mql = window.matchMedia(`(max-width: 1024px)`)

class DashPricing extends Component {
	constructor(props) {
		super(props)
		this.state = {
			drawerOpen: false,
			profileAnchorEl: null,
			notArrivedItems: [],
			eligibleItems: [],
			receivedItems: [],
			shippedItems: [],
			notifications: [],
			invoices: [],
			notificationsDialogOpen: false,
			scrollIntoPricingView: false,
			ukLargeWeight: "",
			usaLargeWeight: "",
			usaLessWeight: "",
		}
	}
	// componentDidMount() {
	// 	if (queryString.parse(this.props.location.search).from === 'FAQs') {
	// 		this.setState({ scrollIntoPricingView: true })
	// 		this.refs.pricing.scrollIntoView()
	// 	}
	// }
	componentDidMount() {
		const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/api';
		Axios.get(`${baseUrl}/calculation-variables`).then(snaps => {
			this.setState({
				...this.state,
				ukLargeWeight: snaps.data.ukLargeWeight,
			  	usaLargeWeight: snaps.data.usaLargeWeight,
			  	usaLessWeight: snaps.data.usaLessWeight,
			})
		  }).catch(() => {
			console.log({ calcErrors: 'An error occured while getting calculation rates.' })
		  })
	}

	componentWillReceiveProps = (nextProps) => {
		const { recentUploads, recentUploadsSuccess,
			invoicesSuccess, invoices } = nextProps.dashboard
		if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
		if (recentUploadsSuccess) {
			const notArrivedItems = []
			const receivedItems = []
			const shippedItems = []
			let eligibleItems = []
			recentUploads.map(item => {
				if (item.status === 'registered') notArrivedItems.push(item)
				if (item.status === 'checkedIn') receivedItems.push(item)
				if (item.status === 'shipped') shippedItems.push(item)
				if (item.eligible_for_protection === true) eligibleItems.push(item)
				return item
			})
			this.setState({ notArrivedItems, receivedItems, shippedItems, eligibleItems })
		}
		if (invoicesSuccess) {
			this.setState({ invoices })
		}
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
	toggleNotifications = () => {
		this.setState({ notificationsDialogOpen: !this.state.notificationsDialogOpen })
	}
	toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}
	render() {
		const { successMsg, errorMsg } = this.props.dashboard
		const { classes } = this.props
		return (
			<div>
				<DashDrawer classes={classes} state={this.state} props={this.props}
					activeLink='prc' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
					closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
					logout={this.onLogout} toggleNotifications={this.toggleNotifications} />
				<Paper square={true} style={{
					background: 'rgba(0,0,0,0)', boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
				}}>
					<Card style={{ margin: '2%' }}>
						<CardHeader title="United States shipping rates" />
						<CardContent>
							<Typography style={{ fontSize: '1.2em' }} className="align_icons">
								<ArrowRight />Up to 2 kg - ${this.state.usaLessWeight}.</Typography><br />
							<Typography style={{ fontSize: '1.2em' }} className="align_icons">
								<ArrowRight />2 kg or more - ${this.state.usaLargeWeight} per kg.</Typography>
						</CardContent>
					</Card>
					<Card style={{ margin: '2%' }}>
						<CardHeader title="United Kingdom shipping rates" />
						<CardContent>
							<Typography style={{ fontSize: '1.2em' }} className="align_icons">
								<ArrowRight />£{this.state.ukLargeWeight} per kg. (no minimum charge)</Typography>
						</CardContent>
					</Card>
					<Card style={{ margin: '2%' }}>
						<CardHeader title="Delivery fees" />
						<PriceList />
					</Card>
					{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
					{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				</Paper>
			</div>
		)
	}
}

export default withStyles(useStyles)(DashPricing)
