import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Routes } from '../../routes'
import { Grid, Input, InputLabel,
  InputAdornment, Button, } from '@material-ui/core'
import { MailOutlineSharp, LockOutlined } from '@material-ui/icons'

import '../admin/accounts/AdminSignin.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)

class OfficerSignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '', password: '',
    }
  }
  componentWillReceiveProps = (nextProps) => {
    const { officerSignedIn } = nextProps.auth
    if (officerSignedIn) {
      this.props.history.push(Routes.OfficerDash)
    }
  }
  handleFormInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  onSignIn = (event) => {
    event.preventDefault()
    const { email, password } = this.state
    this.props.signIn(email, password)
  }
  render() {
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={mql.matches? 12 : 6}>
            <div className="admin_signin_main_image"></div>
          </Grid>
          <Grid item xs={mql.matches? 12 : 6}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="admin_signin_form_logo"></div>
              </Grid>
              <Grid item xs={12}>
                <div className="admin_signin_form_title">Login to proceed</div>
              </Grid>
              <Grid item xs={12}>
                <form onSubmit={this.onSignIn} className="admin_signin_form" style={{marginTop: '20%'}}>
                  <InputLabel>Email or Username</InputLabel>
                  <Input name="email" onChange={this.handleFormInputChange}
                    value={this.state.email} style={{width: '100%'}} required
                    startAdornment={ <InputAdornment position="start">
                      <MailOutlineSharp /> </InputAdornment> } />
                  <InputLabel>Password</InputLabel>
                  <Input name="password" type="password" onChange={this.handleFormInputChange}
                    value={this.state.password} style={{width: '100%'}} required
                    startAdornment={ <InputAdornment position="start">
                      <LockOutlined /> </InputAdornment> } />
                  <Button type="submit" style={{background: '#232C39',
                  color: 'white'}}>Sign In</Button><br/>
                  <Link to="/accounts/forgot-password/officers"
                  style={{textDecoration: 'none'}}>Forgot password?</Link>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default OfficerSignIn
