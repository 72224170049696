import React, { Component } from 'react'
import Update from 'react-addons-update'
import {
  withStyles, Paper, Table, TableHead,
  LinearProgress, TableRow, TableCell, Typography,
  Card, CardHeader, CardContent, Avatar, TableBody,
  Checkbox, Input, Button, Dialog,
  DialogTitle, DialogContent, Divider
} from '@material-ui/core'
import { useStyles, LocalOfficerDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
import _ from 'lodash'
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import '../../dashboard/Dashboard.scss'
import '../../admin/Admin.scss'
import StatusTemplate from './statusTemplate'

const mql = window.matchMedia(`(max-width: 1024px)`)

function filterItems(collection, substring) {
  const invoices = _.filter(collection, _.flow(
    _.identity, _.values, _.join, _.toLower, _.partialRight(
      _.includes,
      substring.toLowerCase()
    )
  ))
  return invoices
}

class LocalOfficerDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      drawerOpen: false,
      profileAnchorEl: null,
      consolidateBtnOpen: false,
      consolidateDialogOpen: false,
      officerInfo: {},
      shippingItems: [],
      filteredItems: [],
      searchQuery: '',
      consolidatedWeight: '',
      consolidatedLength: '',
      consolidatedWidth: '',
      consolidatedHeight: '',
      formErrors: '',
      filterString: '',
      filterLimit: 10,
      filterSkip: 0,
      shipmentCount: 0,
      viewing: 'IN_DELAWARE',
      newError: false,
      emptyError: false,
      orderBy: 'client',
      order: 'asc',
      packageStatus: false,
      statuses: []
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { officerInfo, shippingItems,
      shippingItemCheckedIn, shippingItemArrived, shipmentCount } = nextProps.officer

    let newShippingItems = []
    shippingItems.map(item => {
      item.weight = item.dlength = item.dwidth = item.dheight = ''
      item.arrived = false
      newShippingItems.push(item)
      return item
    })
    if (shippingItemCheckedIn) {
      const { filterLimit, filterSkip, viewing } = this.state;
      this.props.getData(filterLimit, filterSkip, viewing)
    }
    if (shippingItemArrived) {
      const { filterLimit, filterSkip, viewing } = this.state;
      this.props.getData(filterLimit, filterSkip, viewing)
    }
    this.setState({
      officerInfo, shippingItems: newShippingItems,
      filteredItems: newShippingItems, shipmentCount
    })
  }

  componentDidMount = () => {
    const { filterLimit, filterSkip, viewing } = this.state
    // this.props.getOfficerInfo()
    this.props.getData(filterLimit, filterSkip, viewing)
  }
  onLogout = () => {
    this.setState({ profileAnchorEl: null })
    window.location.href = '/accounts/signin'
  }
  openProfileMenu = (event) => {
    this.setState({ profileAnchorEl: event.currentTarget })
  }
  closeProfileMenu = () => {
    this.setState({ profileAnchorEl: null })
  }
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
  searchFieldChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }
  onFilterItems = substring => {
    this.setState({
      filterString: substring,
      filteredItems: filterItems(this.state.shippingItems, substring)
    })
  }
  onConsildateFieldChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onChangeDimensions = (event, type) => {
    const { filteredItems } = this.state
    console.log(filteredItems);
    const index = filteredItems.findIndex(x => x.id === event.target.name)
    switch (type) {
      case 'weight':
        this.setState({
          filteredItems: Update(filteredItems, { [index]: { weight: { $set: event.target.value } } })
        })
        break
      case 'length':
        this.setState({
          filteredItems: Update(filteredItems, { [index]: { dlength: { $set: event.target.value } } })
        })
        break
      case 'width':
        this.setState({
          filteredItems: Update(filteredItems, { [index]: { dwidth: { $set: event.target.value } } })
        })
        break
      case 'height':
        this.setState({
          filteredItems: Update(filteredItems, { [index]: { dheight: { $set: event.target.value } } })
        })
        break
      default:
        return
    }
  }

  onMarkAsArrived = (event, id) => {
    const { filteredItems } = this.state
    const index = filteredItems.findIndex(x => x.id === id)
    const newShippingItems = [...filteredItems]
    newShippingItems[index].arrived = event.target.checked
    this.setState({
      filteredItems: newShippingItems
    }, () => {
      const arrivedItems = []
      this.state.filteredItems.map(item => {
        if (item.arrived) arrivedItems.push(item)
        return item
      })
      if (arrivedItems.length >= 1) {
        this.setState({ consolidateBtnOpen: true })
      } else {
        this.setState({ consolidateBtnOpen: false })
      }
    })
  }

  onReceiveItems = () => {
    const { filteredItems, shippingItems } = this.state;
    let arrivedItems = [];
    filteredItems.map(item => {
      if (item.arrived) {
        arrivedItems.push(item.trackingNumber)
      }
      return item.trackingNumber
    });

    const payload = {};
    payload.trackingNumbers = arrivedItems

    if (payload.trackingNumbers === 0) {
      return this.setState({ newError: true })
    } else {
      this.setState({ newError: false });
    }

    this.props.receiveItemsInNairobi(payload);
    this.setState({ filteredItems: shippingItems })
  }

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    });
  };

  handleChangePage = (event, newPage) => {
    const { filterLimit } = this.state;

    this.setState({
      ...this.state,
      page: newPage, filterSkip: newPage * filterLimit
    }, () => {
      const { filterLimit, filterSkip, viewing } = this.state;
      this.props.getData(filterLimit, filterSkip, viewing);
    })
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      ...this.state,
      rowsPerPage: parseInt(event.target.value, 10), page: 0,
      filterLimit: parseInt(event.target.value, 10), filterSkip: 0
    }, () => {
      const { filterLimit, filterSkip, viewing } = this.state;
      this.props.getData(filterLimit, filterSkip, viewing);
    })
  };


  onChangeRadio = e => {
    switch (e.target.id) {
      case 'inDeleware':
        return this.setState({ viewing: 'IN_DELAWARE', filterLimit: 10, filterSkip: 0, page: 0, consolidateBtnOpen: false }, () => {
          const { filterLimit, filterSkip, viewing } = this.state;
          this.props.getData(filterLimit, filterSkip, viewing);
        });
      case 'inTransit':
        return this.setState({ viewing: 'IN_TRANSIT', filterLimit: 10, filterSkip: 0, page: 0, consolidateBtnOpen: false }, () => {
          const { filterLimit, filterSkip, viewing } = this.state;
          this.props.getData(filterLimit, filterSkip, viewing);
        });
      case 'inKenya':
        return this.setState({ viewing: 'IN_KENYA', filterLimit: 10, filterSkip: 0, page: 0, consolidateBtnOpen: false }, () => {
          const { filterLimit, filterSkip, viewing } = this.state;
          this.props.getData(filterLimit, filterSkip, viewing);
        });
      case 'inNairobi':
        return this.setState({ viewing: 'IN_NAIROBI', filterLimit: 10, filterSkip: 0, page: 0, consolidateBtnOpen: false }, () => {
          const { filterLimit, filterSkip, viewing } = this.state;
          this.props.getData(filterLimit, filterSkip, viewing);
        });
      case 'beingProcessed':
        return this.setState({ viewing: 'BEING_PROCESSED', filterLimit: 10, filterSkip: 0, page: 0, consolidateBtnOpen: false }, () => {
          const { filterLimit, filterSkip, viewing } = this.state;
          this.props.getData(filterLimit, filterSkip, viewing);
        });
      case 'awaitingPayment':
        return this.setState({ viewing: 'AWAITING_PAYMENT', filterLimit: 10, filterSkip: 0, page: 0, consolidateBtnOpen: false }, () => {
          const { filterLimit, filterSkip, viewing } = this.state;
          this.props.getData(filterLimit, filterSkip, viewing);
        });
      case 'pendingDelivery':
        return this.setState({ viewing: 'PENDING_DELIVERY', filterLimit: 10, filterSkip: 0, page: 0, consolidateBtnOpen: false }, () => {
          const { filterLimit, filterSkip, viewing } = this.state;
          this.props.getData(filterLimit, filterSkip, viewing);
        });
      case 'delivered':
        return this.setState({ viewing: 'DELIVERED', filterLimit: 10, filterSkip: 0, page: 0, consolidateBtnOpen: false }, () => {
          const { filterLimit, filterSkip, viewing } = this.state;
          this.props.getData(filterLimit, filterSkip, viewing);
        });
      default:
        return '';
    }
  }


  onCheckinConsolidatedItems = event => {
    event.preventDefault();

    this.setState({ formErrors: '' })
    const { consolidatedWeight, consolidatedLength,
      consolidatedWidth, consolidatedHeight,
      filteredItems } = this.state
    const arrivedItems = []

    let clientNumbers = [];

    filteredItems.map(item => {
      if (item.arrived) {
        arrivedItems.push(item.trackingNumber)
        clientNumbers.push(item.clientNumber)
      }
      return item
    })

    const isEqual = clientNumbers.every(clientNumber => clientNumber === clientNumbers[0])
    if (!isEqual) {
      return this.setState({ formErrors: 'Packages must belong to the same client' });
    }

    const lwh = (parseFloat(consolidatedLength) * parseFloat(consolidatedWidth) * parseFloat(consolidatedHeight))
    const weight = parseFloat(consolidatedWeight)
    let weightToSend = 0
    if (Boolean(lwh)) {
      const metricWeight = (lwh / 6000)
      if (Boolean(weight)) {
        // Set the larger number
        weightToSend = Math.max(metricWeight, weight)
      } else {
        // Proceed with metric weight only
        weightToSend = metricWeight
      }
    } else {
      if (Boolean(weight)) {
        // Proceed with weight only
        weightToSend = weight
      } else {
        this.setState({ formErrors: 'Fill weight or all dimensions' })
        return
      }
    }

    this.props.consolidateItems(arrivedItems, clientNumbers[0], weightToSend.toString())

    this.setState({
      consolidateDialogOpen: false,
      consolidateBtnOpen: false,
      arrivedItems: [],
      consolidatedWeight: '',
      consolidatedLength: '',
      consolidatedWidth: '',
      consolidatedHeight: '',
      formErrors: ''
    })
  }

  render() {
    // const { officerInfoFetched } = this.props.officer
    const { filteredItems, consolidatedWeight, consolidatedLength,
      consolidatedWidth, consolidatedHeight, viewing } = this.state
    //!officerInfoFetched
    if (false) {
      return (
        <div className="linear_progress">
          <LinearProgress />
        </div>
      )
    } else {
      const { classes } = this.props
      const { errorMsg, successMsg } = this.props.officer
      const arrivedItems = []
      filteredItems.map(item => {
        if (item.arrived) arrivedItems.push(item)
        return item
      })
      // .filter(item => item.status === viewing)
      const { order, orderBy } = this.state;
      const ShippingItemsTable = stableSort(filteredItems, getComparator(order, orderBy))
        //.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
        .map((item, index) => {
          const colors = ['orange', 'blue', 'red', 'cyan', 'magenta', 'purple', 'darkslategray', 'green']
          const states = ['IN_DELAWARE', 'IN_TRANSIT', 'IN_KENYA', 'IN_NAIROBI', 'BEING_PROCESSED', 'AWAITING_PAYMENT', 'PENDING_DELIVERY', 'DELIVERED']
          const colorIndex = states.findIndex(x => x === item.status)
          return (<TableRow key={item.id}
            style={{ borderLeft: `2px solid ${colors[colorIndex] || 'whitesmoke'}` }}>
            <TableCell>{index + this.state.filterSkip + 1}</TableCell>
            <TableCell>{item.clientNumber && item.clientNumber.toUpperCase()}</TableCell>
            <TableCell style={{ color: 'blue', cursor: 'pointer' }} onClick={() => {
              this.setState({
                packageStatus: !this.state.packageStatus,
                statuses: item.statusDates
              })
            }}>{item.trackingNumber}</TableCell>
            <TableCell>{item.shipmentNumber}</TableCell>
            <TableCell>{item.estimatedPackageArrivalDate}</TableCell>
            <TableCell style={{ width: '5%' }}>{item.isDangerous ? 'Yes' : 'No'}</TableCell>
            <TableCell style={{ width: '5%' }}>{item.isShipmentProtected ? 'Yes' : 'No'}</TableCell>
            <TableCell>
              <Checkbox color="primary" checked={item.arrived}
                onClick={event => this.onMarkAsArrived(event, item.id)} />
            </TableCell>
          </TableRow>)
        })
      const ShippingItemsCard = filteredItems.map((item, index) => {
        const colors = ['orange', 'blue', 'red', 'cyan', 'magenta', 'purple', 'darkslategray', 'green']
        const states = ['IN_DELAWARE', 'IN_TRANSIT', 'IN_KENYA', 'IN_NAIROBI', 'BEING_PROCESSED', 'AWAITING_PAYMENT', 'PENDING_DELIVERY', 'DELIVERED']
        const colorIndex = states.findIndex(x => x === item.status)
        return (<Card key={item.id} style={{
          display: mql.matches ? 'block' : 'none',
          margin: '2%', borderLeft: `2px solid ${colors[colorIndex] || 'whitesmoke'}`
        }}>
          <CardHeader avatar={<Avatar><small>{index + 1}</small></Avatar>} />
          <CardContent style={{ color: 'darkslategray' }}>
            <Typography className="admin_officers_card_p"><b>Client number:&nbsp;</b>{item.clientNumber && item.clientNumber.toUpperCase()}</Typography>
            <Typography className="admin_officers_card_p" style={{ color: 'blue', cursor: 'pointer' }} onClick={() => {
              this.setState({
                packageStatus: !this.state.packageStatus,
                statuses: item.statusDates
              })
            }}><b>Tracking Number:&nbsp;</b>{item.trackingNumber}</Typography>
            <Typography className="admin_officers_card_p"><b>Shipment Number:&nbsp;</b>{item.shipmentNumber}</Typography>
            <Typography className="admin_officers_card_p"><b>Harzardous:&nbsp;</b>{item.isDangerous ? 'Yes' : 'No'}</Typography>
            <Typography className="admin_officers_card_p"><b>Protected:&nbsp;</b>{item.isShipmentProtected ? 'Yes' : 'No'}</Typography>
            <Typography className="admin_officers_card_p"><b>Delivery Date:&nbsp;</b>{item.estimatedPackageArrivalDate}</Typography>
            <div className="admin_officers_card_p"><b>Select:&nbsp;</b>
              <Checkbox color="primary" checked={item.arrived}
                onClick={event => this.onMarkAsArrived(event, item.id)} />
            </div>
          </CardContent>
        </Card>)
      })
      return (
        this.state.packageStatus ? (
          <div>
            <LocalOfficerDrawer currentUser="officer" classes={classes} state={this.state} props={this.props}
              activeLink='dash' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
              closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
              logout={this.onLogout} />
            <div style={{ paddingLeft: mql.matches ? '30px' : '272px', paddingTop: '16vh' }}>
              <Button onClick={() => { this.setState({ packageStatus: !this.state.packageStatus }) }}>Back</Button>
            </div>
            <StatusTemplate statusDates={this.state.statuses} />
          </div>
        ) : (
          <div>
            <LocalOfficerDrawer currentUser="officer" classes={classes} state={this.state} props={this.props}
              activeLink='dash' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
              closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
              logout={this.onLogout} />
            <Paper square={true} style={{
              background: 'rgba(0,0,0,0)', boxShadow: 'none',
              paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
            }}>
              <Table style={{
                display: mql.matches ? 'none' : 'block', padding: '3%', margin: '4%',
                marginBottom: '0', width: '86%', boxShadow: '0 0 4px 0 silver', fontSize: '1.2em'
              }}>
                <Typography className="align_icons" style={{ color: "orange", paddingRight: '4%' }}>
                  <input type="radio" id="inDeleware" onChange={this.onChangeRadio} onClick={(e) => {
                    if (e.target.checked) {
                      this.setState({ viewing: '' })
                    }
                  }} checked={viewing === 'IN_DELAWARE'} style={{ height: '20px', width: '20px' }} /><span>&nbsp;In Delaware</span>
                </Typography>
                <Typography className="align_icons" style={{ color: "blue", paddingRight: '4%' }}>
                  <input type="radio" id="inTransit" onChange={this.onChangeRadio} onClick={(e) => {
                    if (e.target.checked) {
                      this.setState({ viewing: '' })
                    }
                  }} checked={viewing === 'IN_TRANSIT'} style={{ height: '20px', width: '20px', border: '1px solid blue' }} /><span>&nbsp;In transit</span>
                </Typography>
                <Typography className="align_icons" style={{ color: "red", paddingRight: '4%' }}>
                  <input type="radio" id="inKenya" onChange={this.onChangeRadio} onClick={(e) => {
                    if (e.target.checked) {
                      this.setState({ viewing: '' })
                    }
                  }} checked={viewing === 'IN_KENYA'} style={{ height: '20px', width: '20px' }} /><span>&nbsp;In Kenya</span>
                </Typography>
                <Typography className="align_icons" style={{ color: "cyan", paddingRight: '4%' }}>
                  <input type="radio" id="inNairobi" onChange={this.onChangeRadio} onClick={(e) => {
                    if (e.target.checked) {
                      this.setState({ viewing: '' })
                    }
                  }} checked={viewing === 'IN_NAIROBI'} style={{ height: '20px', width: '20px' }} /><span>&nbsp;In Nairobi</span>
                </Typography>
                <Typography className="align_icons" style={{ color: "magenta", paddingRight: '4%' }}>
                  <input type="radio" id="beingProcessed" onChange={this.onChangeRadio} onClick={(e) => {
                    if (e.target.checked) {
                      this.setState({ viewing: '' })
                    }
                  }} checked={viewing === 'BEING_PROCESSED'} style={{ height: '20px', width: '20px' }} /><span>&nbsp;Processing</span>
                </Typography>
                <Typography className="align_icons" style={{ color: "darkslategray", paddingRight: '4%' }}>
                  <input type="radio" id="awaitingPayment" onChange={this.onChangeRadio} onClick={(e) => {
                    if (e.target.checked) {
                      this.setState({ viewing: '' })
                    }
                  }} checked={viewing === 'AWAITING_PAYMENT'} style={{ height: '20px', width: '20px' }} /><span>&nbsp;Awaiting Payment</span>
                </Typography>
                <Typography className="align_icons" style={{ color: "purple", paddingRight: '4%' }}>
                  <input type="radio" id="pendingDelivery" onChange={this.onChangeRadio} onClick={(e) => {
                    if (e.target.checked) {
                      this.setState({ viewing: '' })
                    }
                  }} checked={viewing === 'PENDING_DELIVERY'} style={{ height: '20px', width: '20px' }} /><span>&nbsp;Pending Delivery</span>
                </Typography>
                <Typography className="align_icons" style={{ color: "green", paddingRight: '4%' }}>
                  <input type="radio" id="delivered" onChange={this.onChangeRadio} onClick={(e) => {
                    if (e.target.checked) {
                      this.setState({ viewing: '' })
                    }
                  }} checked={viewing === 'DELIVERED'} style={{ height: '20px', width: '20px' }} /><span>&nbsp;Delivered</span>
                </Typography>
                <div style={{
                  display: 'flex', justifyContent: 'space-around'
                }}>
                  <Input value={this.state.filterString} placeholder="Search items"
                    // onChange={event => {this.onFilterItems(event.target.value)}}
                    onChange={event => {
                      this.setState({ filterString: event.target.value })
                    }}
                    onKeyUp={e => {
                      if (e.key === 'Enter' || e.keyCode === 13) {
                        const { filterString, viewing } = this.state;
                        this.props.searchShipping(filterString, viewing);
                      }
                    }}
                    className={classes.searchField} />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: '45px' }}
                    onClick={() => {
                      const { filterString, viewing } = this.state;
                      this.props.searchShipping(filterString, viewing);
                    }}
                  >
                    Search
                      </Button>
                </div>
                <EnhancedTableHead
                  classes={classes}
                  // numSelected={selected.length}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.rowsPerPage}
                  viewing={this.state.viewing}
                />
                <TableBody>
                  {ShippingItemsTable}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={this.state.shipmentCount}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                style={{ margin: '0 auto', width: '50%' }}
              />
              {/* Mobile Screens */}
              {ShippingItemsCard}
              <div>
                <div style={{ float: 'left' }}>
                  {(viewing === 'IN_DELAWARE' || viewing === 'IN_TRANSIT' || viewing === 'IN_KENYA' || viewing === 'IN_NAIROBI') ? (
                    <Button onClick={() => this.setState({ consolidateDialogOpen: true })}
                      style={{
                        background: 'darkgreen', color: 'white', position: 'fixed',
                        bottom: '0', left: mql.matches ? '0' : '240px', borderRadius: '0',
                        display: this.state.consolidateBtnOpen ? 'block' : 'none'
                      }}>
                      Add to invoice
                    </Button>
                  ) : ('')}
                </div>
                <div style={{ float: 'right', bottom: '0', right: '0', position: 'fixed' }}>
                  {(viewing === 'IN_DELAWARE' || viewing === 'IN_TRANSIT' || viewing === 'IN_KENYA') ? (
                    <span><Button onClick={this.onReceiveItems}
                      style={{
                        background: '#232C39', color: 'white', width: '125px',
                        borderRadius: '0', visibility: this.state.consolidateBtnOpen ? '' : 'hidden'
                      }}>Receive
                  </Button><br /></span>
                  ) : ('')}
                </div>
              </div>
            </Paper>
            {errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
            {successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
            {this.state.newError ? <HttpError errorMessage={'You have not selected any item'} /> : null}
            {this.state.emptyError ? <HttpError errorMessage={'Fill weight or all dimensions'} /> : null}
            {/* Consolidate items Dialog */}
            <Dialog open={this.state.consolidateDialogOpen} fullWidth
              onClose={() => this.setState({ consolidateDialogOpen: false })}>
              <DialogTitle style={{ color: 'blue' }}>Consolidated items</DialogTitle>
              <DialogContent>
                {arrivedItems.map((item, index) => {
                  return (<div key={item.id}>
                    <Typography style={{ padding: '2%' }}>{index + 1}.&nbsp;{item.trackingNumber}</Typography>
                    <Divider />
                  </div>)
                })}
                <Card style={{
                  marginTop: '6%', boxShadow: 'none', borderRadius: '0',
                  borderLeft: '2px solid blue'
                }}>
                  <CardContent>
                    <form onSubmit={this.onCheckinConsolidatedItems}>
                      <Input type="number" placeholder="Weight" name="consolidatedWeight"
                        onChange={this.onConsildateFieldChange} value={consolidatedWeight}
                        className={classes.smallinput} />
                      <Input type="number" placeholder="Length" name="consolidatedLength"
                        onChange={this.onConsildateFieldChange} value={consolidatedLength}
                        className={classes.smallinput} />
                      <Input type="number" placeholder="Width" name="consolidatedWidth"
                        onChange={this.onConsildateFieldChange} value={consolidatedWidth}
                        className={classes.smallinput} />
                      <Input type="number" placeholder="Height" name="consolidatedHeight"
                        onChange={this.onConsildateFieldChange} value={consolidatedHeight}
                        className={classes.smallinput} />
                      <Typography style={{ color: 'orangered' }}>
                        {this.state.formErrors}
                      </Typography>
                      <Button type="submit"
                        style={{ background: '#232C39', color: 'white', margin: '2%' }}>
                        Add to Invoice</Button>
                    </form>
                  </CardContent>
                </Card>
              </DialogContent>
            </Dialog>
          </div>
        )
      )
    }
  }
}

function stableSort(array, comparator) {
  // return array.sort((a, b) => a > b ? 1 : -1);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = () => [
  { id: 'client_number', numeric: false, disablePadding: true, label: 'Client Number' },
  { id: 'trackingNumber', numeric: false, disablePadding: false, label: 'Tracking Number' },
  { id: 'shippingNumber', numeric: false, disablePadding: false, label: 'Shipping Number' },
  { id: 'estimatedPackageArrivalDate', numeric: false, disablePadding: false, label: 'Delivery Date' },
  { id: 'isDangerous', numeric: false, disablePadding: false, label: 'Hazardous' },
  { id: 'isShipmentProtected', numeric: false, disablePadding: false, label: 'Protected' },
  { id: 'select', numeric: false, disablePadding: false, label: 'Select' },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow selected>
        <TableCell></TableCell>
        {headCells().map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



export default withStyles(useStyles)(LocalOfficerDashboard)
