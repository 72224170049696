import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Routes } from ".";


const AdminRoute = ({ component: Component, ...rest}) => {
  const { isAuthenticated, isAdmin } = useSelector(state => ({
    isAuthenticated: state.auth.authenticated,
    isAdmin: state.auth.isAdmin
  }))

  return(
    <Route
      {...rest}
      render={props =>
        isAuthenticated && isAdmin ? (
          <div>
            <Component {...props} />
          </div>
        ) : (
          <Redirect to={Routes.Signin} />
        )
      }
    />
  )};

export default AdminRoute;