import React from 'react'
import { Link } from 'react-router-dom'
import { Routes } from '../../routes'
import {
  SwipeableDrawer, List, Divider, ListItem, Toolbar, IconButton,
  InputBase, Menu, MenuItem, Button, Typography, AppBar,
  ListItemIcon, ListItemText, Paper, LinearProgress,
} from '@material-ui/core'
import {
  AccountCircleOutlined, LockOutlined,
  SearchOutlined, MessageOutlined, MenuOutlined,
} from '@material-ui/icons'

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
const mql = window.matchMedia(`(max-width: 1024px)`)

export const useStyles = {
  drawer: { backgroundColor: '#303E52', border: 'none' },
  listItems: { width: '240px' },
  appBarTitle: { flexGrow: 1, },
  smallbutton: { padding: 0, minHeight: 0, minWidth: 0, },
  smallinput: { width: '12vw', minHeight: 0, minWidth: 0, }
}

export const IntForemanDrawer = ({ classes, state, props, activeLink, toggleDrawer,
  openProfile, closeProfile, profileAnchorEl, logout, searchFieldChange,
  searchItem }) => {
  const { first_name, last_name } = state.foremanInfo
  return (
    <div>
      <SwipeableDrawer PaperProps={{ classes: { root: classes.drawer } }}
        open={state.drawerOpen} variant={mql.matches ? 'temporary' : 'permanent'}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableBackdropTransition={!iOS} disableDiscovery={iOS} >
        {
          <div className={classes.listItems}
            role="presentation" onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)} >
            <Paper className="dashboard_drawer_logo"></Paper>
            <Paper className="dashboard_drawer_links">
              <List>
                {/* <Link to={Routes.Foreman} className="links">
                  <ListItem style={{
                    background: activeLink === 'ofcrs'? 'whitesmoke' : '#303E52',
                    color: activeLink === 'ofcrs'? '#232C39' : 'white'}}>
                    <ListItemIcon><PeopleOutlined htmlColor={activeLink === 'ofcrs'? "#303E52" : "white"} /></ListItemIcon>
                    <ListItemText>Officers</ListItemText>                  
                  </ListItem>
                </Link> */}
                <Link to={Routes.OfficerNewPassword} className="links">
                  <ListItem style={{
                    background: activeLink === 'npwd' ? 'whitesmoke' : '#303E52',
                    color: activeLink === 'npwd' ? '#232C39' : 'white'
                  }}>
                    <ListItemIcon><LockOutlined htmlColor={activeLink === 'npwd' ? "#303E52" : "white"} /></ListItemIcon>
                    <ListItemText>Change Password</ListItemText>
                  </ListItem>
                </Link>
              </List>
            </Paper>
            <Divider />
          </div>
        }
      </SwipeableDrawer>
      <AppBar position='fixed' style={{
        paddingLeft: mql.matches ? '0' : '240px',
        background: 'whitesmoke', color: '#232C39'
      }}>
        <Toolbar>
          <Button onClick={toggleDrawer(true)}>
            <MenuOutlined htmlColor="#303E52" style={{ display: mql.matches ? 'block' : 'none' }} />
          </Button>
          <Typography variant="h6" className={classes.appBarTitle}>
          </Typography>
          <InputBase className="search_input" placeholder="Search client" value={state.searchQuery}
            name="searchClientNumber" onChange={searchFieldChange} />
          <IconButton style={{ padding: '10' }} aria-label="Search" onClick={searchItem}>
            <SearchOutlined htmlColor="#303E52" />
          </IconButton>
          <Button><MessageOutlined htmlColor="#303E52" /></Button>|
          <Button onClick={openProfile}>
            <AccountCircleOutlined htmlColor="#303E52" />
            {mql.matches ? '' : `  ${first_name || ''} ${last_name || ''}`}
          </Button>
          {/* Profile Options */}
          <Menu id="simple-menu" anchorEl={profileAnchorEl} keepMounted
            open={Boolean(profileAnchorEl)} onClose={closeProfile} >
            <MenuItem onClick={closeProfile}>{first_name || ''}&nbsp;{last_name || ''}</MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
        <div className="linear_progress">
          <LinearProgress
            style={{ display: props.foreman.fetchingData ? 'block' : 'none' }} />
        </div>
      </AppBar>
    </div>
  )
}

