import { connect } from 'react-redux'
import Index from '../components/Index'
import Dashboard from '../components/dashboard/Dashboard'
import Editprofile from '../components/dashboard/editprofile/Editprofile'
import Changepassword from '../components/dashboard/changepassword/Changepassword'
import Receipts from '../components/dashboard/receipts/Receipts'
import Receipt from '../components/dashboard/receipts/Receipt'
import Invoices from '../components/dashboard/invoices/Invoices'
import Invoice from '../components/dashboard/invoices/Invoice'
import AddItem from '../components/dashboard/additem/AddItem'
import RegisteredItems from '../components/dashboard/registereditems/Registereditems'
import EditRegisteredItem from '../components/dashboard/registereditems/EditItem'
import EligibleItems from '../components/dashboard/registereditems/EligibleItems'
import DashMessages from '../components/dashboard/Messages'
import DashPricing from '../components/dashboard/Pricing'
import TermsAndC from '../components/dashboard/TermsAndC'
import DashFAQs from '../components/dashboard/FAQs'

import {
  dashGetUserInfoAction,
  dashUpdateUserProfileAction,
  dashChangePasswordAction,
  dashBulkUploadAction,
  dashSingleItemUploadAction,
  dashRecentItemsAction,
  dashRecentItemAction,
  dashEditItemAction,
  dashDeleteItemAction,
  dashGetIssuesAction,
  dashCreateIssueAction,
  dashReplyIssueAction,
  dashCloseIssueAction,
  dashGetInvoicesAction,
  dashGetInvoiceAction,
  dashPayInvoiceAction,
  dashAddDeliveryAction,
  dashPayPalPayAction,
  dashAddAddressAction,
  dashRemoveAddressAction,
  dashUpdatePrice,
  dashSanitizedActon,
  dashPayProtectionAction,
  dashGetInvoiceReceiptsAction,
  dashGetPackagesReceiptsAction
} from '../actions/DashAction'

export const IndexPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash
  })
)(Index)

export const DashboardPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getRecentUploads() {
      dispatch(dashRecentItemsAction())
    },
    getMessages() {
      dispatch(dashGetIssuesAction())
    },
    getInvoices() {
      dispatch(dashGetInvoicesAction())
    }
  })
)(Dashboard)

export const EditprofilePage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    updateUserProfile(userInfo) {
      dispatch(dashUpdateUserProfileAction(userInfo))
    },
    addAddress(name, town, street, apartmentName, otherLocationInfo) {
      dispatch(dashAddAddressAction(name, town, street, apartmentName, otherLocationInfo))
    },
    removeAddress(id) {
      dispatch(dashRemoveAddressAction(id))
    }
  })
)(Editprofile)

export const ChangePasswordPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    onChangePassword(oldPassword, newPassword) {
      dispatch(dashChangePasswordAction(oldPassword, newPassword))
    },
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
  })
)(Changepassword)

export const InvoicesPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getInvoices(limit, skip) {
      dispatch(dashGetInvoicesAction(limit, skip))
    }
  })
)(Invoices)


export const InvoicePage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getInvoice(id) {
      dispatch(dashGetInvoiceAction(id))
    },
    payInvoice(id, mobile, partialPayment, address) {
      dispatch(dashPayInvoiceAction(id, mobile, partialPayment, address))
    },
    addDelivery(payload) {
      dispatch(dashAddDeliveryAction(payload))
    },
    paypalPaid(invoiceId, deliveryTotal, address,
      payerID, paymentID, paymentToken) {
      dispatch(dashPayPalPayAction(invoiceId, deliveryTotal, address,
        payerID, paymentID, paymentToken))
    }
  })
)(Invoice)


export const ReceiptsPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getReceipts(limit, skip) {
      dispatch(dashGetInvoiceReceiptsAction(limit, skip))
    },
    getPaymentReceipts(limit, skip) {
      dispatch(dashGetPackagesReceiptsAction(limit, skip))
    }
  })
)(Receipts)

export const ReceiptPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getReceipts(limit, skip) {
      dispatch(dashGetInvoiceReceiptsAction(limit, skip))
    },
    getPaymentReceipts(limit, skip) {
      dispatch(dashGetPackagesReceiptsAction(limit, skip))
    }
  })
)(Receipt)

export const AddItemPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getRecentUploads() {
      dispatch(dashRecentItemsAction())
    },
    bulkUpload(payload) {
      dispatch(dashBulkUploadAction(payload))
    },
    singleUpload(tracking_number, hasLithium, hasToxicLiquid, opt_for_protection, price,
      client_number, clientId, currency) {
      dispatch(dashSingleItemUploadAction(tracking_number, hasLithium, hasToxicLiquid, opt_for_protection, price,
        client_number, clientId, currency))
    },
  })
)(AddItem)

export const RegisteredItemsPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getRecentUploads() {
      dispatch(dashRecentItemsAction())
    },
    deleteItem(id) {
      dispatch(dashDeleteItemAction(id))
    },
    markDelivered(id, getRecentUploads) {
      dispatch(dashSanitizedActon(id, getRecentUploads))
      //setTimeout(getRecentUploads(), 2000)
    }
  })
)(RegisteredItems)

export const EditRegisteredItemPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getRecentUploads() {
      dispatch(dashRecentItemsAction())
    },
    getRecentUpload(id) {
      dispatch(dashRecentItemAction(id))
    },
    editItem(id, tracking_number) {
      dispatch(dashEditItemAction(id, tracking_number))
    },
    deleteItem(id) {
      dispatch(dashDeleteItemAction(id))
    }
  })
)(EditRegisteredItem)

export const EligibleItemsPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash,
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getRecentUploads() {
      dispatch(dashRecentItemsAction())
    },
    updatePrice(payload, getRecentUploads) {
      dispatch(dashUpdatePrice(payload, getRecentUploads))
    },
    payProtection(trackingNumbers, paymentNumber, amountKes, memo) {
      dispatch(dashPayProtectionAction(trackingNumbers, paymentNumber, amountKes, memo))
    }
  })
)(EligibleItems)

export const DashMessagesPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getMessages() {
      dispatch(dashGetIssuesAction())
    },
    getInvoices() {
      dispatch(dashGetInvoicesAction())
    },
    createIssue(issue, category) {
      dispatch(dashCreateIssueAction(issue, category))
    },
    replyToIssue(id, message) {
      dispatch(dashReplyIssueAction(id, message))
    },
    closeIssue(id) {
      dispatch(dashCloseIssueAction(id))
    }
  })
)(DashMessages)

export const DashPricingPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getInvoices() {
      dispatch(dashGetInvoicesAction())
    }
  })
)(DashPricing)

export const TermsAndCPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
    getInvoices() {
      dispatch(dashGetInvoicesAction())
    }
  })
)(TermsAndC)

export const FAQsPage = connect(
  state => ({
    auth: state.auth,
    dashboard: state.dash
  }),
  dispatch => ({
    getUserInfo() {
      dispatch(dashGetUserInfoAction())
    },
  })
)(DashFAQs)
