import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Routes } from ".";


const OfficerRoute = ({ component: Component, ...rest}) => {
  const { isAuthenticated, isOfficer } = useSelector(state => ({
    isAuthenticated: state.auth.authenticated,
    isOfficer: state.auth.isOfficer
  }))

  return(
    <Route
      {...rest}
      render={props =>
        isAuthenticated && isOfficer ? (
          <div>
            <Component {...props} />
          </div>
        ) : (
          <Redirect to={Routes.Signin} />
        )
      }
    />
  )};

export default OfficerRoute;