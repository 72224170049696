import React, { Component } from 'react';
import {
	withStyles, Paper, LinearProgress,
	Grid, Card, TextField, Typography, Button,
	Dialog, DialogTitle, DialogContent, InputLabel,
	FormControl, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core'
import { useStyles, LocalOfficerDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
import Moment from 'moment';
import { format } from "date-fns";
import '../../dashboard/Dashboard.scss'
import '../../admin/Admin.scss'
import { addComma } from '../../../utils/stringConverter';

const mql = window.matchMedia(`(max-width: 1024px)`)

class OfficerInvoice extends Component {
	constructor(props) {
		super(props)
		this.state = {
			drawerOpen: false,
			profileAnchorEl: null,
			officerInfo: {},
			invoice: {},
			paymentType: '',
			referenceNumber: '',
			formErrors: '',
			payInvoiceDialogOpen: false,
			hazardousGoods: 0,
			totalDemmurage: 0,
			deliverlyAmount: 0,
			client: {},
			phoneNumber: '',
			amountPaidKes: '',
			userAddress: [],
			totalDebitsKsh: 0,
			totalCreditsKsh: 0,
			totalPaymentsKsh: 0,
			totalDebitsUsd: 0,
			totalCreditsUsd: 0,
			totalPaymentsUsd: 0
		}
	}
	componentWillReceiveProps = (nextProps) => {
		const { officerInfo, invoice, client, userAddress } = nextProps.officer
		if (nextProps.auth.unauthorized) {
			window.location.href = '/accounts/signin'
		}
		this.setState({
			officerInfo,
			invoice,
			hazardousGoods: parseFloat(invoice.dangerousGoodsFeeKes),
			totalDemmurage: parseFloat(invoice.totalDemurrageFee),
			deliverlyAmount: parseFloat(invoice.deliverlyAmount),
			client,
			userAddress
		})

		if (invoice.debits) {
			let totalKsh = invoice.debits.map(debit => Number(debit.amountKes)).reduce((a, b) => { return a + b });
			let totalUsd = invoice.debits.map(debit => Number(debit.amountUsd)).reduce((a, b) => { return a + b });

			this.setState({ totalDebitsKsh: totalKsh });
			this.setState({ totalDebitsUsd: totalUsd })
		}

		if (invoice.credits) {
			let totalKsh = invoice.credits.map(credit => Number(credit.amountKes)).reduce((a, b) => { return a + b });
			let totalUsd = invoice.credits.map(credit => Number(credit.amountUsd)).reduce((a, b) => { return a + b });

			this.setState({ totalCreditsKsh: totalKsh });
			this.setState({ totalCreditsUsd: totalUsd })
		}

		if (invoice.payments) {
			let totalKsh = invoice.payments.map(payment => Number(payment.amountPaidKes)).reduce((a, b) => { return a + b });
			let totalUsd = invoice.payments.map(payment => Number(payment.amountPaidUsd)).reduce((a, b) => { return a + b });

			this.setState({ totalPaymentsKsh: totalKsh });
			this.setState({ totalPaymentsUsd: totalUsd })
		}
	}
	componentDidMount = () => {
		// this.props.getOfficerInfo()
		this.props.getInvoice(this.props.match.params.id)
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
	toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}
	onInputChange = event => {
		this.setState({ [event.target.name]: event.target.value })
	}
	onPayInvoice = event => {
		event.preventDefault()
		const { referenceNumber, paymentType, phoneNumber, amountPaidKes } = this.state
		if (!Boolean(referenceNumber) || !Boolean(paymentType) || !Boolean(phoneNumber) || !Boolean(amountPaidKes)) {
			this.setState({ formErrors: 'Payment type and all fields are required' })
			return
		}
		this.props.payInvoice(
			referenceNumber,
			phoneNumber,
			amountPaidKes,
			this.props.match.params.id,
			paymentType,
			this.props.getInvoice
		)
		this.setState({ payInvoiceDialogOpen: false, formErrors: '' })
	}
	render() {
		const { invoiceFetched, invoice } = this.props.officer
		if (!invoiceFetched) {
			return (
				<div className="linear_progress">
					<LinearProgress />
				</div>
			)
		} else {
			const { classes } = this.props
			const { errorMsg, successMsg } = this.props.officer
			return (
				<div>
					<LocalOfficerDrawer currentUser="officer" classes={classes} state={this.state} props={this.props}
						activeLink='inv' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
						closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
						logout={this.onLogout} />
					<Paper square={true} style={{
						background: 'rgba(0,0,0,0)', boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
					}}>
						<Grid item xs={12}>
							<Card className="invoice_card">
								<Grid container spacing={1}>
									<Grid item xs={12} style={{ background: '#F07829', paddingTop: '20px', color: 'white', fontStyle: 'bold' }}>
										<span style={{ fontSize: '2.2em', paddingLeft: '5px' }}>Invoice</span>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 3} style={{ marginTop: '15px' }}>
										<span>
											<Paper className="dashboard_clear_drawer_logo" style={{ cursor: 'default' }}
											></Paper>
										</span>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 3} style={{ marginTop: '15px' }}>
										<span>
											Midnight Company Limited,<br />
											Storage Central Self-Storage 1st Floor,<br />
											Mombasa Road,<br />
											Nairobi.
														</span>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 5} style={{ margin: '15px' }}>
										<table style={{ width: '100%' }}>
											<tr className="issued">
												<th>
													Date Issued
														</th>
												<th>
													Invoice  #
														</th>
											</tr>
											<tr>
												<td>
													{Moment(new Date(this.state.invoice.invoiceUpdatedOn) || new Date(this.state.invoice.invoiceGenerationDate)).format('LL') || ''}
												</td>
												<td>
													{this.state.invoice.id && this.state.invoice.id.toUpperCase()}
												</td>
											</tr>
										</table>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 5} style={{ margin: '15px', marginTop: '30px' }}>
										<table style={{ width: '90%' }}>
											<tr className='billed'>
												<th>
													Bill To:
														</th>
											</tr>
											<tr>
												<td className="">
													{`${this.state.client.firstName} ${this.state.client.lastName}`}<br />
													{this.state.userAddress.length > 0 && this.state.userAddress[0].addressTown}<br />
													{this.state.client.phoneNumber}<br />
												</td>
											</tr>
										</table>
									</Grid>
									<Grid item xs={12} style={{ margin: '15px' }}>
										{/* Box items */}
										{'consolidatedItems' in this.state.invoice && Boolean(this.state.invoice.consolidatedItems.length) ?
											this.state.invoice.consolidatedItems.map((box, index) => {
												return (<table key={Math.random()} className="long-table" style={{ width: '100%' }}>
													<tr>
														<th>
															Box:&nbsp;<b>{index + 1}</b>,&nbsp;&nbsp;&nbsp;
																		Box weight:&nbsp;<b>{box.weight} Kg</b>,&nbsp;&nbsp;&nbsp;
																		Total box items:&nbsp;<b>{box.packages && box.packages.length}</b>
														</th>
													</tr>
													{box.packages && box.packages.map((item, index) => {
														return (<tr key={index} className="long-table-height">
															<td>Tracking Number: &nbsp;{item.trackingNumber}</td>
														</tr>)
													})}
												</table>)
											})
											: (<div></div>)}
										<table style={{ width: '100%', marginTop: '10px' }}>
											<tr className="long-table">
												<th>
													Cost Category
														</th>
												<th>
													Debit
														</th>
												<th>
													Credit
														</th>
											</tr>
											{/* {this.state.invoice._specialCharges.map((item) => {
														return (<td key={item.id}>
															<td>{item.description}</td>
															<td>USD. {item.amount}</td>
														</td>)}
													)} */}
											<tr>
												<td style={{ color: 'blue' }}>Total Weight -
															{
														'consolidatedItems' in this.state.invoice && this.state.invoice.consolidatedItems.map(item => Number(item.weight)).reduce((a, b) => {
															return a + b;
														}, 0)
													} Kg
														</td>
														<td>Ksh. {
													addComma(Number(this.state.invoice.weightCostKes))
												} -
														USD. {Number(this.state.invoice.weightCostUsd)}</td>
												<td></td>
											</tr>
											{('dangerousGoodsFeeKes' in this.state.invoice || 'dangerousGoodsFeeUsd' in this.state.invoice) && (
															<tr>
																<td>Hazardous Goods Fee</td>
																<td>
																	Ksh. {(this.state.invoice.dangerousGoodsFeeKes)} - USD. {this.state.invoice.dangerousGoodsFeeUsd}
																</td>
																<td></td>
															</tr>
														)}
											{this.state.invoice.debits && this.state.invoice.debits.map(debit => {
												return (
													<tr>
														<td>{debit.description}</td>
														<td>Ksh. {addComma(debit.amountKes)} - USD. {debit.amountUsd}</td>
														<td></td>
													</tr>
												)
											})}
											{this.state.invoice.credits && this.state.invoice.credits.map(credit => {
												return (
													<tr>
														<td>{credit.description}</td>
														<td></td>
														<td>Ksh. {addComma(credit.amountKes)} - USD. {credit.amountUsd}</td>
													</tr>
												)
											})}
											{this.state.invoice.payments && this.state.invoice.payments.map(payment => {
												return (
													<tr>
														<td>Payment: {payment.paymentType}</td>
														<td></td>
														<td>Ksh. {addComma(payment.amountPaidKes)} - USD. {payment.amountPaidUsd}</td>
													</tr>
												)
											})}
											{this.state.invoice.storageCosts && this.state.invoice.storageCosts.map(cost => {
												return (
													<tr>
														<td>Storage Cost: {format(new Date(cost.addedOn), 'dd/MM/yyyy')}</td>
														<td>Ksh. {addComma(cost.amountKes)} - USD. {cost.amountUsd}</td>
														<td></td>
													</tr>
												)
											})}
											{/* {this.state.deliverlyAmount > 0 ? (
														<tr>
														<td style={{color: 'blue'}}>Hazardous Goods Fee</td>
														<td>
															USD. {this.state.deliverlyAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } -  Ksh. {(this.state.deliverlyAmount * exchangeRate).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
														</td>
													</tr>
													) : (
														null
													)} */}
										</table>
									</Grid>
									{/* <Grid item xs={mql.matches ? 12 : 7} style={{ margin: '15px', marginTop: '30px' }}>
										<div>
											<p>US Pricing: <br />Up to 2 kg - $25. <br />2 kg or more - $15 per kg.</p>
										</div>
									</Grid> */}
									<Grid item xs={mql.matches ? 12 : 7} style={{ margin: '15px', marginTop: '30px' }}>
										<div>
											<h4 style={{ color: "#F07829" }}>Terms & Conditions</h4>
											<p>For our full terms and conditions - <a href="/dashboard/terms_of_use" target="_blank" rel="noopener noreferrer">here</a></p>
										</div>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 4} style={{ margin: '15px', marginTop: '30px' }}>
										<table style={{ width: '90%' }} className='inverted'>
											<tr>
												<th>
													Invoice Total (USD)
														</th>
												<td>
													<span>{this.state.invoice.totalCostUsd}</span>
												</td>
											</tr>
											<tr>
												<th>
													Invoice Total (KES)
														</th>
												<td>
													<span>{addComma(this.state.invoice.totalCostKes)}</span>
												</td>
											</tr>
										</table>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 12} style={{ margin: '15px', marginTop: '30px' }}>
										<div>
											<p>Please initiate your payment through this invoice or if your like to pay via cash transfer please use the information below:</p>
											<p>
												<i>
													Account name: Midnight Company Limited,<br />
																Bank: Equity Bank Ltd,<br />
																Branch: Fourways Towers<br />
																Kes Acc no. 0020260517036,<br />
																USD Acc no. 1450274915919,
															</i>
											</p>
											<p>
												<i>Call assistance: 0717 363433</i>
											</p>
										</div>
									</Grid>
									<Grid item xs={12}>
										<Paper className="clean_paper" style={{ textAlign: 'right' }}>
											{invoice.paymentStatus === 'FULLY_PAID' ?
												(invoice.isDelivered ? (
													<Typography style={{ color: 'rgb(0,255,0)' }}>PAID & DELIVERED</Typography>
												) : (
													<Button onClick={() => {
														let newArray = [];
														newArray.push(invoice.id);
														const payload = {}
														payload.invoiceNumbers = newArray
														this.props.markDelivered(payload, 2)
													}} color="secondary">Mark Delivered</Button>
												))
												: (<div>
													<Button onClick={() => this.setState({ payInvoiceDialogOpen: true })}
														color="secondary">Pay invoice</Button>
												</div>)}
											{!invoice.clientView && (
												<div>
													<Button onClick={() => this.props.sendInvoice(invoice.id)} style={{ color: '#58A147' }}>
														Send Invoice to Client
													</Button>
												</div>
											)}
										</Paper>
									</Grid>
									{/* <Grid item xs={12}>
										<Paper className="invoice_summary_paper">
											<Typography>Weight:&nbsp;<span style={{color: 'blue'}}>
												{(parseFloat(invoice.weight) / 2.20462).toFixed(2)} Kgs</span>
											</Typography>
                      <Typography>Demurrage fee:&nbsp;<span style={{color: 'blue'}}>
                        KES. {parseFloat(invoice.totalDemurrageFee).toFixed(2)}</span>
											</Typography>
											<Typography>Shipping cost:&nbsp;<span style={{color: 'blue'}}>
												USD. {parseFloat(invoice.shippingAmount).toFixed(2)}</span>
											</Typography>
                      <Typography>Hazardous good fee:&nbsp;
												<span style={{color: 'blue'}}>USD. {invoice.totalHazardousGoodFee}</span>
											</Typography>
											<Typography>Delivery cost:&nbsp;
												<span style={{color: 'blue'}}>KSH. {invoice.deliveryAmount}</span>
											</Typography>
										</Paper>
									</Grid> */}
								</Grid>
							</Card>
						</Grid>
					</Paper>
					{/* Pay invoice dialog
          **********************
          **********************
          ********************/}
					{/* this.state.payInvoiceDialogOpen */}
					<Dialog open={this.state.payInvoiceDialogOpen}
						onClose={() => this.setState({ payInvoiceDialogOpen: false })}>
						<DialogTitle>
							Pay invoice
            </DialogTitle>
						<form onSubmit={this.onPayInvoice}>
							<DialogContent>
								<InputLabel>Payment type</InputLabel>
								<FormControl>
									<RadioGroup name="paymentType" value={this.state.paymentType}
										onChange={this.onInputChange} row>
										<FormControlLabel value="MPESA" control={<Radio color="primary" />}
											label="MPesa" />
										<FormControlLabel value="Check" control={<Radio color="primary" />}
											label="Check" />
										<FormControlLabel value="BankTransfer" control={<Radio color="primary" />}
											label="Electronic funds transfer" />
									</RadioGroup>
								</FormControl>
							</DialogContent>
							<DialogContent>
								<TextField name="referenceNumber" value={this.state.referenceNumber}
									onChange={this.onInputChange} variant="outlined"
									label="Payment reference number" required fullWidth />
							</DialogContent>
							<DialogContent>
								<TextField name="phoneNumber" value={this.state.phoneNumber}
									onChange={this.onInputChange} variant="outlined"
									label="Phone number" required fullWidth />
							</DialogContent>
							<DialogContent>
								<TextField name="amountPaidKes" value={this.state.amountPaidKes}
									onChange={this.onInputChange} variant="outlined"
									label="Amount paid in KES" required fullWidth />
							</DialogContent>
							<DialogContent style={{ color: 'orangered' }}>
								{this.state.formErrors}
							</DialogContent>
							<DialogContent style={{ textAlign: 'right' }}>
								<Button type="submit" color="secondary">Pay</Button>
							</DialogContent>
						</form>
					</Dialog>
					{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
					{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
				</div>
			)
		}
	}
}


export default withStyles(useStyles)(OfficerInvoice)
