import React, { Component } from 'react'
import { Routes } from '../../../routes'
import {
  withStyles, Paper, LinearProgress, Typography,
  Card, CardHeader, CardContent, Input,
  Button, InputLabel
} from '@material-ui/core'
import { useStyles, DashDrawer } from '../drawer'
import { HttpSuccess, HttpError } from '../../snackbars'
import '../../admin/Admin.scss'

const mql = window.matchMedia(`(max-width: 1024px)`)

class EditRegisteredItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      profileAnchorEl: null,
      eligibleItems: [],
      receivedItems: [],
      shippedItems: [],
      recentUploads: [],
      recentUpload: {},
      notifications: [],
      invoices: [],
      notificationsDialogOpen: false,
      mobileScreen: mql.matches,
      tracking_number: '',
      formError: '',
    }
  }
  componentDidMount = () => {
    this.props.getUserInfo()
    this.props.getRecentUploads()
    this.props.getRecentUpload(this.props.match.params.id)
  }
  componentWillReceiveProps = (nextProps) => {
    const { recentUploadsSuccess,
      recentUploads, recentUpload, recentUploadSuccess,
      recentItemEdited } = nextProps.dashboard
    if (nextProps.auth.unauthorized) {
      window.location.href = '/accounts/signin'
    }
    if (recentItemEdited) {
      this.props.history.push(Routes.RegisteredItems)
    }
    if (recentUploadsSuccess) {
      const notArrivedItems = []
      const receivedItems = []
      const shippedItems = []
      let eligibleItems = []
      recentUploads.map(item => {
        if (item.status === 'Registered') notArrivedItems.push(item)
        if (item.status === 'CheckedIn') receivedItems.push(item)
        if (item.status === 'Shipped') shippedItems.push(item)
        if (item.eligible_for_protection === true) eligibleItems.push(item)
        return item
      })
      this.setState({
        notArrivedItems, receivedItems, shippedItems, eligibleItems,
        recentUploads
      })
    }
    if (recentUploadSuccess) {
      const { tracking_number } = recentUpload
      this.setState({ recentUpload, tracking_number })
    }
  }
  onLogout = () => {
    this.setState({ profileAnchorEl: null })
    window.location.href = '/accounts/signin'
  }
  toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    this.setState({ drawerOpen: open })
  }
  openProfileMenu = (event) => {
    this.setState({ profileAnchorEl: event.currentTarget })
  }
  closeProfileMenu = () => {
    this.setState({ profileAnchorEl: null })
  }
  toggleNotifications = () => {
    this.setState({ notificationsDialogOpen: !this.state.notificationsDialogOpen })
  }
  handleFormInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleMerchantChange = (newValue, actionType) => {
    if (actionType.action === 'select-option') {
      this.setState({ merchant: newValue.label || '' })
    }
  }
  handleMerchantInputChange = (inputValue, actionType) => {
    if (actionType.action === 'input-change') {
      this.setState({ merchant: inputValue || '' })
    }
  }
  onUpdateCheckinItem = event => {
    event.preventDefault()
    this.setState({ formError: '' })
    const { recentUpload, tracking_number } = this.state
    if (recentUpload.status !== 'registered') {
      return this.setState({ formError: 'You can only edit registered items' })
    }
    const id = this.props.match.params.id
    this.props.editItem(id, tracking_number)
  }
  render() {
    const { classes } = this.props
    const { userInfoFetched, errorMsg, successMsg } = this.props.dashboard
    if (!userInfoFetched) {
      return (
        <div className="linear_progress">
          <LinearProgress />
        </div>
      )
    } else {
      const { recentUploads, tracking_number, formError } = this.state
      return (
        <div>
          <DashDrawer classes={classes} state={this.state} props={this.props}
            activeLink='reg' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
            closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
            logout={this.onLogout} toggleNotifications={this.toggleNotifications} />
          <Paper square={true} style={{
            background: 'rgba(0,0,0,0)', boxShadow: 'none',
            paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
          }}>
            {recentUploads.some(item => item.id === this.props.match.params.id) ?
              (<Card style={{ margin: '4% auto', width: mql.matches ? '88%' : '50%' }}>
                <CardHeader title="Edit shipping item" />
                <CardContent>
                  <form onSubmit={this.onUpdateCheckinItem}>
                    <CardContent>
                      <InputLabel>Item description</InputLabel>
                      <Input value={tracking_number || ''} name="tracking_number" required placeholder="Tracking Number"
                        onChange={this.handleFormInputChange} fullWidth />
                    </CardContent>
                    <CardContent style={{ color: 'orangered' }}>{formError}</CardContent>
                    <Button type="submit" style={{
                      background: '#232C39', color: 'white',
                      marginLeft: '40%'
                    }}>Submit</Button>&nbsp;&nbsp;&nbsp;
                    <Button onClick={() => this.props.deleteItem(this.props.match.params.id)}
                      color="secondary">Delete item</Button>
                  </form>
                </CardContent>
              </Card>)
              : <Typography>Shipping item not found</Typography>}
          </Paper>
          {successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
          {errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
        </div>
      )
    }
  }
}

export default withStyles(useStyles)(EditRegisteredItem)
