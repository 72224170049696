import Axios from 'axios'
import { handleError } from './ErrorHandler'

export const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/api';

// const dashGetExchangeRatesAction = () => {
//   const endpoint = `${baseUrl}/system-variables/exchange-rate`
//   return(dispatch) => {
//     Axios
//       .get(endpoint)
//       .then(rates => {
//         dispatch({
//           type: 'DASH_GET_RATES',
//           payload: rates.data
//         })
//       })
//       .catch(error => dispatch(handleError(error)))
//   }
// }
/* Get User Information */
export const dashGetUserInfoAction = () => {
  const id = localStorage.getItem('profileId');

  const userInfo = Axios.get(`${baseUrl}/users/clients/${id}`)
  const userAddress = Axios.get(`${baseUrl}/addresses?filter[where][clientNumber]=${id}`)
  //const exchangeRates = Axios.get(`${baseUrl}/invoices/exchange-rates-base-to-kes`)
  return (dispatch) => {
    dispatch({ type: 'DASH_GET_USER_INFO' })
    Axios.all([userInfo, userAddress]).then(
      Axios.spread((...snapshots) => {
        dispatch({
          type: 'DASH_GET_USER_INFO_SUCCESS',
          payload: {
            userInfo: snapshots[0].data,
            userAddress: snapshots[1].data
            // exchangeRates: snapshots[1].data
          },
        })
      })
    ).catch(error => dispatch(handleError(error)))
  }
}

/* Update user Information */
export const dashUpdateUserProfileAction = (userInfo) => {
  return (dispatch) => {
    dispatch({ type: 'DASH_UPDATE_USER_INFO' })
    Axios
      .patch(`${baseUrl}/users/clients`, userInfo)
      .then(() => dispatch({ type: 'DASH_UPDATE_USER_INFO_SUCCESS', payload: 'Profile Updated' }))
      .catch(error => dispatch(handleError(error)))
  }
}

/* Update status */
export const dashSanitizedActon = (id, getRecentUploads) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  //const userId = localStorage.getItem('client')
  return (dispatch) => {
    dispatch({ type: 'DASH_UPDATE_STATUS' })
    Axios.patch(`${baseUrl}/shipping_items/${id}/delivered`).then(
      snapshots => {
        dispatch({ type: 'DASH_UPDATE_STATUS_SUCCESS', payload: 'Marked Delivered' })
        getRecentUploads()
      }
    ).catch(error => dispatch(handleError(error)))
  }
}


/* Change User Password */
export const dashChangePasswordAction = (currentPassword, newPassword) => {
  return (dispatch) => {
    dispatch({ type: 'DASH_CHANGE_PASSWORD' })
    Axios
      .patch(`${baseUrl}/users/change_password`, { currentPassword, newPassword })
      .then(() => dispatch({ type: 'DASH_CHANGE_PASSWORD_SUCCESS', payload: 'Password Changed' }))
      .catch(error => dispatch(handleError(error.response.data)));
  }
}

/* Bulk item upload */
export const dashBulkUploadAction = payload => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const userId = localStorage.getItem('client')
  const endpoint = `${baseUrl}/clients/${userId}/bulkUpload`
  return (dispatch) => {
    dispatch({ type: 'DASH_BULK_UPLOAD' })
    Axios.post(endpoint, { items: payload, clientId: userId }).then(snapshots => {
      dispatch({
        type: 'DASH_BULK_UPLOAD_SUCCESS',
        payload: 'Item Uploaded'
      })
    }).catch(error => dispatch(handleError(error)))
  }
}

/* Single item upload */
export const dashSingleItemUploadAction = (tracking_number, hasLithium, hasToxicLiquid, opt_for_protection, price,
  client_number, clientId, currency) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const userId = localStorage.getItem('client')
  const endpoint = `${baseUrl}/clients/${userId}/shippingItems`
  return dispatch => {
    dispatch({ type: 'DASH_BULK_UPLOAD' })
    Axios.post(endpoint, {
      tracking_number, hasLithium, hasToxicLiquid, opt_for_protection, price,
      client_number, clientId, currency
    }).then(
      snapshots => {
        dispatch({
          type: 'DASH_BULK_UPLOAD_SUCCESS',
          payload: 'Item Uploaded'
        })
      }
    ).catch(error => dispatch(handleError(error)))
  }
}

/* Recent client upload */
export const dashRecentItemsAction = () => {
  const userId = localStorage.getItem('profileId')
  const endpoint = `${baseUrl}/users/clients/${userId}?filter={"include":["packages"]}`
  return (dispatch) => {
    dispatch({ type: 'DASH_RECENT_UPLOADS' })
    Axios.get(endpoint).then(snapshots => {
      dispatch({
        type: 'DASH_RECENT_UPLOADS_SUCCESS',
        payload: snapshots.data
      })
    }).catch(error => { })
  }
}

/* Recent client uploads */
export const dashRecentItemAction = id => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const userId = localStorage.getItem('client')
  const endpoint = `${baseUrl}/clients/${userId}/shippingItems/${id}`
  return (dispatch) => {
    dispatch({ type: 'DASH_RECENT_UPLOAD' })
    Axios.get(endpoint).then(snapshots => {
      dispatch({
        type: 'DASH_RECENT_UPLOAD_SUCCESS',
        payload: snapshots.data
      })
    }).catch(error => dispatch(handleError(error)))
  }
}

/* Edit added item */
export const dashEditItemAction = (id, tracking_number) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const endpoint = `${baseUrl}/shipping_items/${id}`
  return (dispatch) => {
    dispatch({ type: 'DASH_EDIT_RECENT_UPLOAD' })
    Axios.patch(endpoint, { tracking_number }).then(snapshots => {
      dispatch({
        type: 'DASH_EDIT_RECENT_UPLOAD_SUCCESS',
        payload: 'Item saved'
      })
    }).catch(error => dispatch(handleError(error)))
  }
}

/* Delete item */
export const dashDeleteItemAction = id => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const endpoint = `${baseUrl}/shipping_items/${id}`
  return (dispatch) => {
    dispatch({ type: 'DASH_EDIT_RECENT_UPLOAD' })
    Axios.delete(endpoint).then(snapshots => {
      dispatch({
        type: 'DASH_EDIT_RECENT_UPLOAD_SUCCESS',
        payload: 'Item deleted'
      })
    }).catch(error => dispatch(handleError(error)))
  }
}

/* Receipts */
export const dashGetInvoiceReceiptsAction = (limit, skip) => {
  const clientNumber = localStorage.getItem('profileId')
  const endpoint = `${baseUrl}/payments/invoice/client/${clientNumber}?limit=${limit || '0'}&skip=${skip || "0"}`;
  const countEndpoint = `${baseUrl}/payments/invoice/count?where[clientNumber]=${clientNumber}`;
  return (dispatch) => {
    dispatch({ type: 'DASH_RECEIPTS' })
    Axios.all([
      Axios.get(endpoint),
      Axios.get(countEndpoint)
    ]).then(Axios.spread((snapshots, count) => {

      dispatch({
        type: 'DASH_RECEIPTS_SUCCESS',
        payload: {
          receipts: snapshots.data,
          count: count.data
        }
      })
    })).catch(error => dispatch(handleError(error)))
  }
}

export const dashGetPackagesReceiptsAction = (limit, skip) => {
  const clientNumber = localStorage.getItem('profileId')
  const endpoint = `${baseUrl}/payments/packages/client/${clientNumber}?limit=${limit || '0'}&skip=${skip || "0"}`;
  const countEndpoint = `${baseUrl}/payments/packages/count?where[clientNumber]=${clientNumber}`;
  return (dispatch) => {
    dispatch({ type: 'DASH_PACKAGE_RECEIPTS' })
    Axios.all([
      Axios.get(endpoint),
      Axios.get(countEndpoint)
    ]).then(Axios.spread((snapshots, count) => {

      dispatch({
        type: 'DASH_PACKAGE_RECEIPTS_SUCCESS',
        payload: {
          packageReceipts: snapshots.data,
          count: count.data
        }
      })
    })).catch(error => dispatch(handleError(error)))
  }
}

/* Invoices */
export const dashGetInvoicesAction = (limit, skip) => {
  const clientNumber = localStorage.getItem('profileId')
  const endpoint = `${baseUrl}/invoices/client/${clientNumber}`;
  const countEndpoint = `${baseUrl}/invoices/count?filter[search]=${clientNumber}`;
  return (dispatch) => {
    dispatch({ type: 'DASH_INVOICES' })
    Axios.all([
      Axios.get(endpoint),
      Axios.get(countEndpoint)
    ]).then(Axios.spread((snapshots, count) => {
      dispatch({
        type: 'DASH_INVOICES_SUCCESS',
        payload: {
          invoices: snapshots.data,
          count: count.data
        }
      })
    })).catch(error => dispatch(handleError(error)))
  }
}

/* Invoice */
export const dashGetInvoiceAction = id => {
  const endpoint = `${baseUrl}/invoices/${id}?filter={"include": [{"relation": "consolidatedItems", 
  "scope": {"include":[{"relation": "packages"}]}}, {"relation": "debits"}, {"relation": "payments"}, {"relation": "credits"}, {"relation": "storageCosts"}]}`
  return (dispatch) => {
    dispatch({ type: 'DASH_INVOICE' })
    Axios.get(endpoint).then(snapshots => {
      dispatch({
        type: 'DASH_INVOICE_SUCCESS',
        payload: snapshots.data
      })
    }).catch(error => dispatch(handleError(error)))
  }
}

/* Invoice payment */
export const dashPayInvoiceAction = (id, mobile, partialPayment, address) => {
  const phone = mobile.substr(mobile.length - 9)
  console.log(address);
  const endpoint = `${baseUrl}/payments/invoice/mpesa/init/${id}`
  return (dispatch) => {
    dispatch({ type: 'DASH_PAY_INVOICE' })
    Axios.post(endpoint, { phoneNumber: `254${phone}`, amountKes: partialPayment }).then(snapshots => {
      dispatch({
        type: 'DASH_PAY_INVOICE_SUCCESS',
        payload: snapshots.data.mpesaMessage
      })
    }).catch(error => console.log(error))
  }
}
/* Paypal */
export const dashPayPalPayAction = (invoiceId, deliveryTotal, address,
  payerID, paymentID, paymentToken) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const endpoint = `${baseUrl}/payPalTransactions/paid`
  return (dispatch) => {
    dispatch({ type: 'DASH_PAY_INVOICE' })
    Axios.post(endpoint, {
      invoiceId, deliveryTotal, address,
      payerID, paymentID, paymentToken
    }).then(snapshots => {
      dispatch({
        type: 'DASH_PAY_INVOICE_SUCCESS',
        payload: snapshots.data.mpesaMessage
      })
    }).catch(error => dispatch(handleError(error)))
  }
}

/* Update price value */
export const dashUpdatePrice = (payload, getRecentUploads) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  // const userId = localStorage.getItem('client')
  const endpoint = `${baseUrl}/clients/shippingItems/optForProtection`
  return (dispatch) => {
    dispatch({ type: 'DASH_UPDATE_PRICE' })
    Axios.patch(endpoint, payload).then(snapshots => {
      dispatch({
        type: 'DASH_UPDATE_PRICE_SUCCESS',
        payload: 'Price updated'
      })
      getRecentUploads()
      //window.location.reload(true);
    }).catch(error => {//dispatch(handleError(error))
      console.log(error);
    })
  }
}

/* Protection payment */
export const dashPayProtectionAction = (trackingNumbers, paymentNumber, amountKes, memo) => {
  const phone = paymentNumber.substr(paymentNumber.length - 9)
  const endpoint = `${baseUrl}/payments/packages/mpesa/init/${memo}`
  return (dispatch) => {
    dispatch({ type: 'DASH_PAY_PROTECTION' })
    Axios
      .post(endpoint, {
        phoneNumber: `254${phone}`
        // , trackingNumbers, amountKes: amountKes 
      })
      .then(snapshots => {
        dispatch({
          type: 'DASH_PAY_PROTECTION_SUCCESS',
          payload: snapshots.data
        })
      }).catch(error => dispatch(handleError(error.response.data)))
  }
}

/* Messages */
export const dashGetIssuesAction = () => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const clientId = localStorage.getItem('client')
  const endpoint = `${baseUrl}/clients/${clientId}/messages?filter[where][open]=true&filter[order]=createdAt%20DESC`
  return (dispatch) => {
    dispatch({ type: 'DASH_MESSAGES' })
    Axios.get(endpoint).then(snapshots => {
      dispatch({
        type: 'DASH_MESSAGES_SUCCESS',
        payload: snapshots.data
      })
    }).catch(error => dispatch(handleError(error)))
  }
}
export const dashCreateIssueAction = (issue, category) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const clientId = localStorage.getItem('client')
  const endpoint = `${baseUrl}/messages`
  return (dispatch) => {
    dispatch({ type: 'DASH_MESSAGES_ACTIONS' })
    Axios.post(endpoint, { clientId, issue, category }).then(snapshots => {
      dispatch({
        type: 'DASH_MESSAGES_ACTIONS_SUCCESS',
        payload: 'Message sent'
      })
    }).catch(error => dispatch(handleError(error)))
  }
}
export const dashReplyIssueAction = (id, message) => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const userId = localStorage.getItem('client')
  const endpoint = `${baseUrl}/messages/${id}/responses`
  return (dispatch) => {
    dispatch({ type: 'DASH_MESSAGES_ACTIONS' })
    Axios.post(endpoint, { message, userId, userType: 'client' }).then(snapshots => {
      dispatch({
        type: 'DASH_MESSAGES_ACTIONS_SUCCESS',
        payload: 'Response sent'
      })
    }).catch(error => dispatch(handleError(error)))
  }
}
export const dashCloseIssueAction = id => {
  Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
  const endpoint = `${baseUrl}/messages/${id}`
  return (dispatch) => {
    dispatch({ type: 'DASH_MESSAGES_ACTIONS' })
    Axios.patch(endpoint, { open: false }).then(snapshots => {
      dispatch({
        type: 'DASH_MESSAGES_ACTIONS_SUCCESS',
        payload: 'Message / Issue closed'
      })
    }).catch(error => dispatch(handleError(error)))
  }
}

export const dashAddAddressAction = (addressName, addressTown, addressStreet, addressApartmentName, addressOtherLocationInfo) => {
  const clientNumber = localStorage.getItem('profileId');
  const endpoint = `${baseUrl}/addresses`
  return (dispatch) => {
    dispatch({ type: 'DASH_ADD_ADDRESS' })
    Axios.post(endpoint, { addressName, clientNumber, addressTown, addressStreet, addressApartmentName, addressOtherLocationInfo }).then(snaps => {
      dispatch({
        type: 'DASH_ADD_ADDRESS_SUCCESS',
        payload: 'Address added'
      })
    }).catch(error => handleError(error))
  }
}

export const dashRemoveAddressAction = id => {
  const endpoint = `${baseUrl}/addresses/${id}`
  return (dispatch) => {
    dispatch({ type: 'DASH_ADD_ADDRESS' })
    Axios.delete(endpoint).then(snaps => {
      dispatch({
        type: 'DASH_ADD_ADDRESS_SUCCESS',
        payload: 'Address removed'
      })
    }).catch(error => handleError(error))
  }
}

export const dashAddDeliveryAction = (payload) => {
  const endpoint = `${baseUrl}/debits`
  return (dispatch) => {
    dispatch({ type: 'DASH_ADD_DELIVERY' })
    Axios.post(endpoint, payload).then(snapshot => {
      dispatch({
        type: 'DASH_ADD_DELIVERY_SUCCESS',
        payload: 'Delivery Added'
      })
      dispatch(dashGetInvoiceAction(payload.invoiceNumber)
      )
    }).catch(error => handleError(error))
  }
}