import React, { Component } from 'react'
import {
	withStyles, Paper, Grid,
	Table, TableRow, TableCell, Button, Typography,
	Input, TableHead, TableBody
} from '@material-ui/core'
import { DoneOutlined, CloseOutlined } from '@material-ui/icons'
import { useStyles, LocalOfficerDrawer } from './drawer'
import { HttpError, HttpSuccess } from '../../snackbars'
import Moment from 'moment';
// import _ from 'lodash'
import TablePagination from '@material-ui/core/TablePagination';
import stringConverter, { addComma } from "../../../utils/stringConverter";

import '../../dashboard/Dashboard.scss'
import '../../admin/Admin.scss'
import Axios from 'axios'
import { baseUrl } from '../../../actions/DashAction'

const mql = window.matchMedia(`(max-width: 1024px)`)

// function filterInvoiceItems(collection, substring) {

// 	const invoices = _.filter(collection, _.flow(
// 		[_.identity, _.values, _.join, _.toLower, _.partialRight(
// 			_.includes,
// 			substring.toLowerCase()
// 		)]
// 	))
// 	return invoices
// }

class OfficerInvoices extends Component {
	constructor(props) {
		super(props)
		this.state = {
			drawerOpen: false,
			profileAnchorEl: null,
			invoices: [],
			filteredInvoices: [],
			filterString: '',
			officerInfo: {},
			filterLimit: 10,
			filterSkip: 0,
			invoicesCount: 0,
			page: 0,
			rowsPerPage: 10,
			deliverOpen: false,
			newError: ''
		}
	}

	async handlesearchInvoices(pattern) {
		try {
			// let filteredInvoices = (await Axios.get(`${baseUrl}/invoices?filter[search]=${pattern}`)).data
			let filteredInvoices = (await Axios.get(`${baseUrl}/invoices?filter[where][or][0][id][ilike]=%${pattern}%&filter[where][or][1][clientNumber][ilike]=%${pattern}%&filter[where][or][2][paymentStatus][ilike]=%${pattern}%&filter[order]=paymentStatus DESC`)).data
			// let { count: invoicesCount } = (await Axios.get(`${baseUrl}/invoices/count?filter[search]=${pattern}`)).data
			let { count: invoicesCount } = (await Axios.get(`${baseUrl}/invoices/count?filter[where][or][0][id][ilike]=%${pattern}%&filter[where][or][1][clientNumber][ilike]=%${pattern}%&filter[where][or][2][paymentStatus][ilike]=%${pattern}%&filter[order]=paymentStatus DESC`)).data
			return {
				filteredInvoices, invoicesCount
			}
		} catch (error) {
			return {
				filteredInvoices: [],
				invoicesCount: 0
			}
		}
	}

	componentWillReceiveProps = (nextProps) => {
		const { officerInfo, invoices, invoicesCount } = nextProps.officer

		let newInvoices = []
		invoices.map(item => {
			item.checked = false
			newInvoices.push(item)
			return item
		})

		this.setState({ officerInfo, invoices, filteredInvoices: newInvoices, invoicesCount })
	}
	componentDidMount = () => {
		const { filterLimit, filterSkip } = this.state
		// this.props.getOfficerInfo()
		let viewing = 'IN_DELAWARE'
		this.props.getData(filterLimit, filterSkip, viewing)
	}
	onLogout = () => {
		this.setState({ profileAnchorEl: null })
		window.location.href = '/accounts/signin'
	}
	openProfileMenu = (event) => {
		this.setState({ profileAnchorEl: event.currentTarget })
	}
	closeProfileMenu = () => {
		this.setState({ profileAnchorEl: null })
	}
	toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		this.setState({ drawerOpen: open })
	}

	handleChangePage = (event, newPage) => {
		const { filterLimit } = this.state;

		this.setState({ page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { filterLimit, filterSkip } = this.state;
			this.props.getData(filterLimit, filterSkip);
		})
	};

	onFilterInvoices = substring => {
		this.setState({ filterString: substring, })
		this.handlesearchInvoices(substring).then(data => {
			this.setState({ ...this.state, ...data })
		})

	}

	onCheckInvoice = (event, id) => {
		const { filteredInvoices } = this.state
		const index = filteredInvoices.findIndex(x => x.id === id)
		const newInvoices = [...filteredInvoices]

		if (newInvoices[index].paymentStatus !== 'FULLY_PAID') {
			return this.setState({ newError: 'The invoice must be fully paid' })
		}

		this.setState({ newError: '' })
		newInvoices[index].checked = event.target.checked
		this.setState({
			filteredInvoices: newInvoices
		}, () => {
			const checkedItems = []
			this.state.filteredInvoices.map(item => {
				if (item.checked) checkedItems.push(item)
				return item
			})
			if (checkedItems.length >= 1) {
				this.setState({ deliverOpen: true })
			} else {
				this.setState({ deliverOpen: false })
			}
		})
	}
	render() {

		const { classes } = this.props
		const { errorMsg, successMsg } = this.props.officer
		let invoices = this.state.filterString.length > 0 ? this.state.filteredInvoices : this.state.invoices

		const UnpaidInvoices = invoices.map(invoice => {
			return (<TableRow key={invoice.id}
				className={invoice.paymentStatus === 'FULLY_PAID' ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}>
				<TableCell>{invoice.clientNumber && invoice.clientNumber.toUpperCase()}</TableCell>
				<TableCell>{invoice.id && invoice.id.toUpperCase()}</TableCell>
				<TableCell>{Moment(invoice.invoiceUpdatedOn).format('LLL')}</TableCell>
				<TableCell>USD. {invoice.totalCostUsd} - KES. {addComma(invoice.totalCostKes)}</TableCell>
				<TableCell>{stringConverter(invoice.paymentStatus)}</TableCell>
				<TableCell>
					<Button onClick={() => this.props.history.push(`/officers/officer/invoices/${invoice.id}`)}
						style={{ color: 'blue' }}>View</Button>
				</TableCell>
				{/* <TableCell>
					{
						invoice.isDelivered ? (
							<Checkbox
								checked={true}
								inputProps={{ 'aria-label': 'primary checkbox' }}
							/>
						) : (
							<Checkbox
								color="primary"
								checked={invoice.checked}
								onClick={event => this.onCheckInvoice(event, invoice.id)} />
						)
					}
				</TableCell> */}
			</TableRow>)
		})

		// let invoices = this.state.filterString.length > 0 ? this.state.filteredInvoices : this.state.invoices

		let UnpaidInvoicesSmall = invoices.length && invoices.map(invoice => {
			if (!invoice.paid) {
				return (<TableRow key={invoice.id} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
					<TableCell style={{ border: 'none' }}>
						<Typography><b>Client number:&nbsp;</b>{invoice.clientNumber && invoice.clientNumber.toUpperCase()}</Typography>
						<Typography><b>Invoice number:&nbsp;</b>{invoice.id && invoice.id.toUpperCase()}</Typography>
						<Typography><b>Date:&nbsp;</b>{Moment(invoice.invoiceUpdatedOn).format('LLL')}</Typography>
						<Typography><b>Shipping cost:&nbsp;</b>
						USD. {invoice.totalCostUsd} - KES. {addComma(invoice.totalCostKes)}</Typography>
					</TableCell>
					<TableCell style={{ border: 'none' }}>
						<Button onClick={() => this.props.history.push(`/officers/officer/invoices/${invoice.id}`)}
							style={{ color: 'blue' }}>View</Button>
					</TableCell>
					{/* <TableCell style={{ border: 'none' }}>
						{
							invoice.isDelivered ? (
								<Checkbox
									checked={true}
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							) : (
								<Checkbox
									color="primary"
									checked={invoice.checked}
									onClick={event => this.onCheckInvoice(event, invoice.id)} />
							)
						}
					</TableCell> */}
				</TableRow>)
			}
			return (<TableRow key={invoice.id}></TableRow>)
		})
		return (
			<div>
				<LocalOfficerDrawer currentUser="officer" classes={classes} state={this.state} props={this.props}
					activeLink='inv' toggleDrawer={this.toggleDrawer} openProfile={this.openProfileMenu}
					closeProfile={this.closeProfileMenu} profileAnchorEl={this.state.profileAnchorEl}
					logout={this.onLogout} />
				<Paper square={true} style={{
					background: 'rgba(0,0,0,0)', boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px', paddingTop: '16vh'
				}}>
					<Grid container spacing={0}>
						<Grid item xs={6}>
							<Paper className="clean_paper" style={{ marginLeft: '8%' }}>
								<Typography style={{ fontSize: '1.6em' }}>INVOICES</Typography>
								<Typography className="align_icons" style={{ color: 'blue' }}>
									<DoneOutlined />&nbsp;Paid
									</Typography><br />
								<Typography className="align_icons" style={{ color: 'rgb(255,122,0)' }}>
									<CloseOutlined />&nbsp;Unpaid
									</Typography>
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper style={{ margin: '3%', padding: '3%' }}>
								<Input value={this.state.filterString} placeholder="Search invoices"
									onChange={event => {
										this.onFilterInvoices(event.target.value)
									}}
									className={classes.searchField} />
								<Table style={{ display: mql.matches ? 'none' : 'block' }}>
									<TableHead>
										<TableRow>
											<TableCell>Client number</TableCell>
											<TableCell>Invoice number</TableCell>
											<TableCell>Date</TableCell>
											<TableCell>Balance Due</TableCell>
											<TableCell>Status</TableCell>
											<TableCell>View</TableCell>
											{/* <TableCell>Select</TableCell> */}
										</TableRow>
									</TableHead>
									<TableBody>
										{UnpaidInvoices}
									</TableBody>
								</Table>
								<TablePagination
									component="div"
									count={typeof this.state.invoicesCount === "number" ? this.state.invoicesCount : 0}
									page={typeof this.state.page === "number" ? this.state.page : 0}
									onChangePage={this.handleChangePage}
									rowsPerPage={typeof this.state.rowsPerPage === "number" ? this.state.rowsPerPage : 0}
									rowsPerPageOptions={[10]}
									style={{ margin: '0 auto', width: '50%' }}
								/>
								{/* Mobile screens */}
								<Table style={{ display: mql.matches ? 'block' : 'none', marginBottom: '15px' }}>
									<TableBody>
										{UnpaidInvoicesSmall}
									</TableBody>
								</Table>
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									{/* <Button
										style={{ background: 'green', color: 'white' }}
										onClick={() => {
											const { filteredInvoices, invoices } = this.state;

											let checkedItems = filteredInvoices.filter(invoice => invoice.checked).map(invoice => invoice.id);
											const payload = {};
											payload.invoiceNumbers = checkedItems

											if (payload.invoiceNumbers.length < 1) {
												return this.setState({ newError: 'You have not selected any item' })
											}

											this.setState({ newError: '' })

											this.props.markDelivered(payload, 1);
											this.setState({ filteredInvoices: invoices })
										}}
									>
										Deliver
										</Button> */}
								</div>
							</Paper>
						</Grid>
					</Grid>
				</Paper>
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
				{this.state.newError.length !== 0 ? <HttpError errorMessage={this.state.newError} /> : null}
			</div>
		)
	}
}



export default withStyles(useStyles)(OfficerInvoices)