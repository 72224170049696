import { Grid, LinearProgress, makeStyles, Paper } from '@material-ui/core';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { baseUrl } from '../../../actions/DashAction';
import { useStyles } from '../../officer/localOfficer/drawer';
import { AdminDrawer } from '../drawer';
import Moment from 'moment';
import { addComma } from '../../../utils/stringConverter';

const mql = window.matchMedia(`(max-width: 1024px)`);

const Receipt = props => {
	// const [query, setQuery] = useState('');
	const { id } = useParams();
	const classes = makeStyles(useStyles)();
	const h = useHistory();

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		userInfo: undefined,
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		notifications: [],
		receipts: [],
		receipt: undefined,
		notificationsDialogOpen: false,
		mobileScreen: mql.matches,
		page: 0,
		rowsPerPage: 10,
		filterLimit: 10,
		filterSkip: 0,
		receiptsCount: 0,
		paymentLimit: 0,
		paymentSkip: 0,
		packageReceipts: [],
		packageReceiptsCount: 0,
		loading: false,
	});

	const getReceipt = async () => {
		const payment = new URL(window.location.href).searchParams.get('payment');
		if (!payment.trim() || !id) {
			return h.push('/dashboard/receipts');
		}

		try {
			setState({
				...state,
				loading: true,
			});
			let data = (await Axios.get(`${baseUrl}/payments/${payment}/${id}`)).data;
			setState({
				...state,
				receipt: data,
				loading: false,
			});
		} catch (error) {
			setState({
				...state,
				loading: false,
			});
		}
	};

	useEffect(() => {
		// props.getUserInfo();
		getReceipt();
	},
	// eslint-disable-next-line
	[window.location.href, id]);

	const onLogout = () => {
		setState({ ...state, profileAnchorEl: null });
		window.location.href = '/accounts/signin';
	};
	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, drawerOpen: open });
	};
	const toggleNotifications = () => {
		setState({ ...state, notificationsDialogOpen: !state.notificationsDialogOpen });
	};
	const openProfileMenu = event => {
		setState({ ...state, profileAnchorEl: event.currentTarget });
	};
	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null });
	};

	useEffect(() => {
		const { userInfo } = props.dashboard;
		if (props.auth.unauthorized) {
			window.location.href = '/accounts/signin';
		}

		setState({
			...state,
			userInfo,
		});
	},
	// eslint-disable-next-line
	[props]);

	return (
		<>
			{state.loading && <LinearProgress />}
			<AdminDrawer
				classes={classes}
				state={state}
				props={props}
				activeLink='rec'
				toggleDrawer={toggleDrawer}
				openProfile={openProfileMenu}
				closeProfile={closeProfileMenu}
				profileAnchorEl={state.profileAnchorEl}
				logout={onLogout}
				toggleNotifications={toggleNotifications}
			/>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<div className='invoice_card' id='invoice_to_print'>
					{state.receipt && (
						<Grid spacing={1} xs={12}>
							<Paper className='invoice_head'>
								<Grid container spacing={0}>
									<Grid
										item
										xs={12}
										style={{
											background: '#303e52',
											paddingTop: '20px',
											color: 'white',
											fontStyle: 'bold',
										}}
									>
										<span style={{ fontSize: '2.2em', paddingLeft: '5px' }}>Receipt</span>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 6} style={{ marginTop: '15px' }}>
										<span>
											<Paper
												className='dashboard_clear_drawer_logo'
												style={{ cursor: 'default' }}
											></Paper>
										</span>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 6} style={{ marginTop: '15px' }}>
										<span>
											Midnight Company Limited,
											<br />
											Storage Central Self-Storage 1st Floor,
											<br />
											Mombasa Road,
											<br />
											Nairobi.
										</span>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 8} style={{ margin: '15px' }}>
										<table style={{ width: '100%' }}>
											<tr className='issued'>
												<th>Date Paid</th>
												<th>Receipt #</th>
												<th>Client Number</th>
											</tr>
											<tr>
												<td>
													{Moment(state.receipt.paymentDate || Date.now()).format('LL') || ''}
												</td>
												<td>{state.receipt.id}</td>
												<td>{state.receipt.clientNumber && state.receipt.clientNumber.toUpperCase()}</td>
											</tr>
										</table>
									</Grid>
									{state.userInfo && (
										<Grid
											item
											xs={mql.matches ? 12 : 8}
											style={{ margin: '15px', marginTop: '30px' }}
										>
											<table style={{ width: '90%' }}>
												<tr className='billed'>
													<th>Billed To:</th>
												</tr>

												<tr>
													<td className=''>
														{`${state.userInfo.firstName} ${state.userInfo.lastName}`}
														<br />

														{state.userInfo.phoneNumber}
														<br />
													</td>
												</tr>
											</table>
										</Grid>
									)}

									<Grid item xs={mql.matches ? 12 : 4} style={{ margin: '15px', marginTop: '30px' }}>
										<table style={{ width: '90%' }} className='inverted'>
											<tr>
												<th>Receipt Total (USD)</th>
												<td>
													<span>{state.receipt.amountPaidUsd}</span>
												</td>
											</tr>
											<tr>
												<th>Receipt Total (KES)</th>
												<td>
													<span>{addComma(state.receipt.amountPaidKes)}</span>
												</td>
											</tr>
										</table>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					)}
				</div>
			</Paper>
		</>
	);
};

export default Receipt;
